const { gql } = require('@apollo/client');

const ME_FRAGMENT = gql`
  fragment MeFragment on Me {
    __typename
    _id
    name
    email
    avatarUrl
    username
    link
    bio
    location
  }
`;

export const GET_ME = gql`
  query GetMe {
    me {
      ...MeFragment
    }
  }
  ${ME_FRAGMENT}
`;

export const UPDATE_ME = gql`
  mutation UpdateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      ...MeFragment
    }
  }
  ${ME_FRAGMENT}
`;
