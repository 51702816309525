import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  FormHelperText,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@mui/material';
import DirectUpload from '../../components/shared/DirectUpload';
import LabelledOutline from '../../components/shared/LabelledOutline';
import Label from '../../components/shared/Label';
import { imageDetails } from '../../utils/imageHelper';

const fields = [
  {
    name: 'title',
    label: 'App Title',
    help: 'Max 25 characters. This title will be used for the app on your phone, not in the App Store.',
  },
  {
    name: 'legalName',
    label: 'Legal Entity Name',
    help: 'This is your legal entity name used in default terms conditions for your customers.',
  },

  {
    name: 'supportEmail',
    label: 'Support Email',
    help: 'Which email do you want your customers want to contact for support?',
  },
];

const General = ({
  className,
  match,
  appInfo,
  setAppInfo,
  saveAppInfo,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [iconHasTransparency, setIconHasTransparency] = useState(false);

  const handleIconUploadSuccess = async (uploadedRes) => {
    const icon = {
      url: uploadedRes.image.url,
      w: uploadedRes.image.w,
      h: uploadedRes.image.h,
    };

    const iconDetails = await imageDetails(icon.url);
    if (iconDetails?.hasAlpha) {
      setIconHasTransparency(true);
    }

    setAppInfo({ ...appInfo, icon });
  };

  const handleIconUploadError = () => {
    enqueueSnackbar(`Media upload failed!`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };
  const handleIconUploadRemove = (file) => {
    setAppInfo({ ...appInfo, icon: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    saveAppInfo();
  };

  const handleChange = (e) => {
    const changes = { ...appInfo };
    changes[e.target.name] = e.target.value;
    setAppInfo(changes);
  };

  return (
    <form className={className} onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            {fields.map((field) => {
              return (
                <Grid key={field.name} item xs={12} md={8}>
                  <TextField
                    fullWidth
                    label={field.label}
                    name={field.name}
                    onChange={handleChange}
                    value={appInfo[field.name] || ''}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormHelperText>{field.help}</FormHelperText>
                </Grid>
              );
            })}
            <Grid item xs={12} md={8}>
              <LabelledOutline id="app-icon" label="App Icon">
                <DirectUpload
                  fileType="image"
                  images={[appInfo.icon]}
                  onError={handleIconUploadError}
                  onSuccess={handleIconUploadSuccess}
                  onRemove={handleIconUploadRemove}
                  maxFiles={1}
                />
              </LabelledOutline>

              <FormHelperText>
                {iconHasTransparency && (
                  <>
                    <Label color="error">
                      Invalid App Store Icon. The Icon can't be transparent nor
                      contain an alpha channel
                    </Label>
                    <br />
                  </>
                )}
                {'Your app icon should be a '}
                <a
                  href="https://developer.apple.com/design/human-interface-guidelines/ios/icons-and-images/app-icon/"
                  rel="noreferrer"
                  target="_blank"
                >
                  square of 1024 x 1024, flattened with no transparency
                </a>
                .
              </FormHelperText>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

General.propTypes = {
  className: PropTypes.string,
};

export default General;
