import React from 'react';

import { Box, Grid } from '@mui/material';

import DownloadCard from '../../components/shared/DownloadCard';

export default function AppBuilds({ appBuilds }) {
  // only use the latest builds instead of all of them to avoid confusion for the trainer seeing
  // multiple versions
  const descOrder = (a, b) => new Date(b.createdAt) - new Date(a.createdAt);

  const latestAndroidBuild = appBuilds
    .slice()
    .sort(descOrder)
    .find((appBuild) => appBuild.platform === 'ANDROID');
  const latestIosBuild = appBuilds
    .slice()
    .sort(descOrder)
    .find((appBuild) => appBuild.platform === 'IOS');

  return (
    <Box mt={4} display="flex">
      <Grid container>
        {[latestAndroidBuild, latestIosBuild]
          .filter((x) => !!x)
          .map((appBuild) => {
            const bodyComponent =
              appBuild.platform === 'ANDROID' ? (
                <>
                  You can upload this file to your{' '}
                  <a
                    href="https://developer.android.com/distribute/console"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Google Play Developer Console
                  </a>{' '}
                  and submit it for publishing.
                </>
              ) : (
                <>
                  You can download the iOS build here. It has also been pushed
                  to your{' '}
                  <a
                    href="https://appstoreconnect.apple.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Apple App Store Connect account
                  </a>
                  where you can submit for Testflight testing and publishing.
                </>
              );
            return (
              <Grid key={appBuild._id} item xs={12} md={4}>
                <DownloadCard
                  style={{ padding: 24, margin: 16 }}
                  key={appBuild._id}
                  name={appBuild.platform}
                  subtitle={`${appBuild.version ?? '-'} ${
                    appBuild.buildStatus
                  } : ${new Date(appBuild.createdAt).toLocaleString()}`}
                  body={
                    appBuild.buildStatus === 'ERROR'
                      ? 'There was an error processing your build. Please contact us via the help chat.'
                      : bodyComponent
                  }
                  url={appBuild.url}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
}
