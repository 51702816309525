import {
  Avatar,
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Link as RouterLink } from 'react-router-dom';
import getInitials from '../../utils/getInitials';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1),
  },
}));

const TrainerResults = ({ trainers }) => {
  const classes = useStyles();
  return (
    <Card>
      <Box minWidth={700}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Total Registered User Count</TableCell>
              <TableCell>Signed Up</TableCell>
              <TableCell>Active Subscription</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainers.map((trainer) => {
              return (
                <TableRow hover key={trainer._id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        className={classes.avatar}
                        src={trainer.avatarUrl}
                      >
                        {getInitials(trainer.name)}
                      </Avatar>
                      <div>
                        <Link
                          color="inherit"
                          component={RouterLink}
                          to={`/admin/trainers/${trainer._id}`}
                          variant="h6"
                        >
                          {trainer.name}
                        </Link>
                      </div>
                    </Box>
                  </TableCell>
                  <TableCell>{trainer._id}</TableCell>

                  <TableCell>{trainer.totalUserCount}</TableCell>
                  <TableCell>{trainer.createdAt}</TableCell>
                  <TableCell>
                    {trainer.hasActiveTrainerSubscription ? 'Yes' : 'No'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default TrainerResults;
