import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
} from '@mui/material';

const TrainingPlansScreen = ({
  match,
  appInfo,
  setAppInfo,
  saveAppInfo,
  ...rest
}) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    saveAppInfo();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h3">
            Training Plans
          </Typography>
          <Box mb={3}>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={appInfo.trainingPlansEnabled}
                      onChange={(event) => {
                        setAppInfo({
                          ...appInfo,
                          trainingPlansEnabled: event.target.checked,
                        });
                      }}
                      name="trainingPlansEnabled"
                    />
                  }
                  label="Training Plans"
                />
              </FormGroup>
              <FormHelperText>
                Turn on training plans will remove the training plans tab. If
                you want your app to be a nutrition only app, you should disable
                training plans.
              </FormHelperText>
            </FormControl>
          </Box>

          <Box mt={2}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

export default TrainingPlansScreen;
