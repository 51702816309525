import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
} from '@mui/material';

const PostsScreen = ({ match, appInfo, setAppInfo, saveAppInfo, ...rest }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    saveAppInfo();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h3">
            Posts
          </Typography>
          <Box mb={3}>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={appInfo.postsEnabled}
                      onChange={(event) => {
                        setAppInfo({
                          ...appInfo,
                          postsEnabled: event.target.checked,
                        });
                      }}
                      name="postsEnabled"
                    />
                  }
                  label="Posts"
                />
              </FormGroup>
              <FormHelperText>
                Turn on posts including your own posts. If this is turned off,
                community posts will be turned off as well.
              </FormHelperText>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={appInfo.communityPostsEnabled}
                      onChange={(event) => {
                        setAppInfo({
                          ...appInfo,
                          communityPostsEnabled: event.target.checked,
                        });
                      }}
                      name="communityPostsEnabled"
                    />
                  }
                  label="Community Posts"
                />
              </FormGroup>
              <FormHelperText>
                Allow your subscribers create posts. If this is off, only you
                can create posts.
              </FormHelperText>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={appInfo.postRepliesEnabled}
                      onChange={(event) => {
                        setAppInfo({
                          ...appInfo,
                          postRepliesEnabled: event.target.checked,
                        });
                      }}
                      name="postRepliesEnabled"
                    />
                  }
                  label="Post Replies"
                />
              </FormGroup>
              <FormHelperText>
                Allow your subscribers to comment on posts.
              </FormHelperText>
            </FormControl>
          </Box>

          <Box mt={2}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

export default PostsScreen;
