import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Page from '../Page';
import Checkout from './Checkout';
import Landing from './Landing';
import config from '../../config';

const tabs = [
  { value: 'landing', label: 'Landing' },
  { value: 'checkout', label: 'Checkout' },
];

const WebSite = ({ match }) => {
  const params = useParams();
  const [currentTab, setCurrentTab] = useState('landing');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };
  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  return (
    <Page title={`Web Site : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Web Site
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          Your web site is where users can purchase your products including
          membership plans and access the content on a web browser.
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          You will need to create{' '}
          <RouterLink to="/trainer/subscription_plans">
            subscription plans on Stripe
          </RouterLink>{' '}
          for customers to buy through this checkout page.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <Tabs
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={currentTab}
                variant="scrollable"
                textColor="secondary"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Box>
            <Divider />
            <Box mt={3}>{currentTab === 'landing' && <Landing />}</Box>
            <Box mt={3}>{currentTab === 'checkout' && <Checkout />}</Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default WebSite;
