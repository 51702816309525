import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, ButtonBase, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import { useAuth } from '../../../hooks/use-auth';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

const Account = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const auth = useAuth();
  const [isOpen, setOpen] = useState(false);
  const authLoading = auth.loading;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (authLoading) {
    return <></>;
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={auth.user.photoURL}
        />
        <ChevronDownIcon fontSize="small" />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/trainer/account">
          {auth.user.email}
        </MenuItem>
        <MenuItem component={RouterLink} to="/trainer/referrals">
          Referral Program
        </MenuItem>
        <MenuItem component={RouterLink} to="/logout">
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
