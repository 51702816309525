import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import QueryString from 'query-string';
import { Grid, Typography, Container } from '@mui/material';

import Page from '../Page';
import AppAndWebPreview from './AppAndWebPreview';
import { GET_APP_PREVIEW_SCREEN_QUERY } from '../../graphql/app_preview';
import ServerError from '../error/ServerError';
import Loading from '../shared/Loading';
import config from '../../config';

const AppPreviewPage = (props) => {
  const location = useLocation();
  const queryParams = QueryString.parse(location.search);
  const trainerId = queryParams.trainerId || null;

  const { data, loading, error } = useQuery(GET_APP_PREVIEW_SCREEN_QUERY, {
    variables: { trainerId },
    fetchPolicy: 'no-cache', // needed so when the app features page is changed, this changes too
  });

  if (error) return <ServerError message="Failed to load" />;
  if (loading) return <Loading />;
  if (!data) return <ServerError message="Failed to load" />;

  const mobilePreviewUrl = data.appPreview?.url;
  const webUrl = data.webPage?.url;
  const hasTrainerSubscription =
    data.myTrainerSubscription?.status === 'active';

  console.log(`has trainer sub ${hasTrainerSubscription}`);
  return (
    <Page title={`App Preview : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography gutterBottom variant="h3">
          App Preview
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppAndWebPreview
              mobilePreviewUrl={mobilePreviewUrl}
              webUrl={webUrl}
              showCheckoutLink={!hasTrainerSubscription}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AppPreviewPage;
