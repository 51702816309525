import React, { useState, useEffect } from 'react';
import { Image as ImageIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Search from '../shared/Search';
import {
  Box,
  ListItemText,
  ListItemAvatar,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Button,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useQuery, gql } from '@apollo/client';
import ServerError from '../error/ServerError';
import Loading from '../shared/Loading';

const MY_CREATED_WORKOUTS_QUERY = gql`
  query GetMyCreatedWorkoutsQuery($input: MyCreatedWorkoutsQueryInput!) {
    myCreatedWorkouts(input: $input) {
      _id
      title
      coverImageUrl
    }
  }
`;

const WorkoutListItem = ({ onSelect, workout }) => {
  let media;
  if (workout.coverImageUrl) {
    media = (
      <img
        alt="Workout"
        src={workout.coverImageUrl}
        style={{ width: 20, height: 20, margin: 3, verticalAlign: 'middle' }}
      />
    );
  } else {
    media = <ImageIcon size={20} />;
  }

  return (
    <ListItem button onClick={() => onSelect(workout)}>
      <ListItemAvatar>{media}</ListItemAvatar>
      <ListItemText
        primary={workout.title}
        primaryTypographyProps={{
          noWrap: true,
          variant: 'h6',
          color: 'textPrimary',
        }}
      />
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
    </ListItem>
  );
};

const TrainingPlanWorkoutSearch = ({ onAddNew, onSelect, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, loading, error, refetch } = useQuery(
    MY_CREATED_WORKOUTS_QUERY,
    {
      variables: { input: { query: searchQuery, limit: 30 } },
    }
  );

  const handleSearchChange = async (event) => {
    try {
      event.persist();
      const { value } = event.target;
      setSearchQuery(value);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    refetch({
      input: {
        query: searchQuery,
      },
    });
  }, [refetch, searchQuery]);

  const workouts = data?.myCreatedWorkouts ?? [];

  return (
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Search
        onChange={handleSearchChange}
        query={searchQuery}
        placeholder="Search Workouts"
        resultLabel="Workouts"
      />
      {!!loading && <Loading circular />}
      {!!error && <ServerError message={error.message} />}
      <List dense>
        {workouts.map(function (w, i) {
          return (
            <React.Fragment key={w._id}>
              <WorkoutListItem onSelect={onSelect} workout={w} />
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
      <Button variant="outlined" onClick={onAddNew}>
        Don't see one? Add a new workout.
      </Button>

      <Box sx={{ display: { xs: 'block', lg: 'none', xl: 'none' }, mt: 2 }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </Box>
    </PerfectScrollbar>
  );
};

export default TrainingPlanWorkoutSearch;
