import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Divider, Tabs, Tab, Box, Container } from '@mui/material';

import Page from '../Page';
import Header from './Header';
import Loading from '../../components/shared/Loading';
import MealPlanDetailsEditor from './MealPlanDetailsEditor';
import { useMutation, useQuery } from '@apollo/client';
import { GET_MEAL_PLAN, UPDATE_MEAL_PLAN } from '../../graphql/nutrition';
import ServerError from '../error/ServerError';
import config from '../../config';

const tabs = [
  { value: 'details', label: 'Details' },
  { value: 'schedule', label: 'Schedule' },
];

const MealPlanEditor = ({ match }) => {
  const [currentTab, setCurrentTab] = useState('details');
  const [mealPlan, setMealPlan] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const id = match ? match.params.id : null;
  const { error, loading, data } = useQuery(GET_MEAL_PLAN, {
    variables: { id },
  });
  const [updateMealPlan] = useMutation(UPDATE_MEAL_PLAN, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });

  useEffect(() => {
    setMealPlan(data?.mealPlan);
  }, [data]);

  const handleSave = (mealPlan) => {
    if (!mealPlan) {
      console.error('cant save, no meal plan input');
      return;
    }
    enqueueSnackbar('Saving...', { autoHideDuration: 2000 });
    updateMealPlan({
      variables: {
        id: mealPlan._id,
        input: {
          title: mealPlan.title,
          tags: mealPlan.tags,
          desc: mealPlan.desc,
          status: mealPlan.status,
          coverImages: mealPlan.coverImages || [],
        },
      },
    });
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading || !mealPlan) {
    return <Loading />;
  }

  return (
    <Page title={`MealPlan Editor : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Box mb={3}>
          <Header item={mealPlan} />
        </Box>
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === 'details' && (
            <MealPlanDetailsEditor
              key="details"
              mealPlan={mealPlan}
              onSave={handleSave}
            />
          )}
          {currentTab === 'schedule' && <div key="schedule">Coming Soon</div>}
        </Box>
      </Container>
    </Page>
  );
};

export default MealPlanEditor;
