import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WorkoutSessionCard from './WorkoutSessionCard';
import Page from '../Page';
import Loading from '../../components/shared/Loading';
import { useSnackbar } from 'notistack';
import { gql, useQuery } from '@apollo/client';
import config from '../../config';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 20,
    marginBottom: 20,
  },
  date: {
    marginLeft: 6,
  },
  media: {
    height: 300,
    width: '100%',
    backgroundPosition: 'top',
    objectFit: 'contain',
  },
}));
const GET_WORKOUT_SESSION_QUERY = gql`
  query workoutSession($id: ID!) {
    workoutSession(id: $id) {
      __typename
      _id
      trainingPlanSessionId
      trainingPlanEventId
      workoutId
      title
      userId
      feel
      notes
      photoUrl
      completedAt
      user {
        _id
        name
        avatarUrl
      }
      trainer {
        _id
        name
        avatarUrl
      }
      workout {
        _id
        title
        exercises {
          _id
          title
        }
      }
      exerciseNotes {
        _id
        workoutId
        workoutExerciseId
        exerciseId
        exercise {
          _id
          title
        }
        weight
        weightUnit
        duration
        reps
        sets
        setIndex
        comments
      }
      trainingPlan {
        _id
        title
        trainingPlanSession {
          _id
          completed
          nextEvent {
            _id
            objectId
            title
          }
        }
      }
      comments {
        _id
        body
        userId
        user {
          _id
          name
          avatarUrl
        }
        createdAt
        image {
          url
        }
        videos {
          url
        }
      }
    }
  }
`;
const WorkoutSessionPage = ({ match, ...rest }) => {
  const id = match ? match.params.id : null;

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [workoutSession, setWorkoutSession] = useState(null);
  const { loading, error, data } = useQuery(GET_WORKOUT_SESSION_QUERY, {
    variables: { id },
  });
  // end note
  useEffect(() => {
    setWorkoutSession(data?.workoutSession);
  }, [data]);

  if (error) {
    console.error(error);
    return <Alert severity="error">Error</Alert>;
  }
  if (loading || !workoutSession) {
    return <Loading />;
  }

  return (
    <Page title={`Workout Session : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <WorkoutSessionCard workoutSession={workoutSession} />
      </Container>
    </Page>
  );
};

export default WorkoutSessionPage;
