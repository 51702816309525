import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Paper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  colors,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  User as UserIcon,
  Star as StarIcon,
  Briefcase as BriefcaseIcon,
  File as FileIcon,
} from 'react-feather';
import { PhoneIphone as PhoneIphoneIcon } from '@mui/icons-material';

import Page from '../Page';
import UserDetails from './UserDetails';
import AppDetails from './AppDetails';
import NextStepsHelp from './NextStepsHelp';
import AppContent from './AppContent';
import { useQuery } from '@apollo/client';
import { GET_ONBOARDING } from '../../graphql/onboarding';
import ServerError from '../error/ServerError';
import Loading from '../shared/Loading';
import config from '../../config';

const steps = [
  {
    label: 'Profile',
    value: 'profile',
    icon: UserIcon,
  },
  {
    label: 'App',
    value: 'app',
    icon: BriefcaseIcon,
  },
  {
    label: 'Content',
    value: 'content',
    icon: FileIcon,
  },
  {
    label: 'Preview',
    value: 'preview',
    icon: PhoneIphoneIcon,
  },
];

const CustomStepConnector = withStyles((theme) => ({
  vertical: {
    marginLeft: 19,
    padding: 0,
  },
  line: {
    borderColor: theme.palette.divider,
  },
}))(StepConnector);

const useCustomStepIconStyles = makeStyles((theme) => ({
  root: {},
  active: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[10],
    color: theme.palette.secondary.contrastText,
  },
  completed: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

const CustomStepIcon = ({ active, completed, icon }) => {
  const classes = useCustomStepIconStyles();

  const Icon = steps[icon - 1].icon;

  return (
    <Avatar
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <Icon size="20" />
    </Avatar>
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  avatar: {
    backgroundColor: colors.red[600],
  },
  stepper: {
    backgroundColor: 'transparent',
  },
}));

const Onboarding = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const history = useHistory();
  const params = useParams();
  const { error, loading, data } = useQuery(GET_ONBOARDING);
  const [currentTab, setCurrentTab] = useState('profile');
  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
      const stepIndex = steps.findIndex((step) => step.value === params.tab);
      setActiveStep(stepIndex);

      if (params.tab === 'preview') {
        setCompleted(true);
      }
    }
  }, [params]);

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading) {
    return <Loading />;
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const step = steps[activeStep + 1];
    setCurrentTab(step.value);
    history.push(`/trainer/onboarding/${step.value}`);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const step = steps[activeStep + 1];
    setCurrentTab(step.value);
    history.push(`/trainer/onboarding/${step.value}`);
  };

  const handleComplete = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCompleted(true);
    const step = steps[activeStep + 1];
    setCurrentTab(step.value);
    history.push(`/trainer/onboarding/${step.value}`);
  };

  return (
    <Page title={`Build your fitness app with ${config.appDisplayName}`}>
      <Container maxWidth="lg">
        <Box mb={3}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              variant="body1"
              color="inherit"
              to="/trainer"
              component={RouterLink}
            >
              {config.appDisplayName}
            </Link>
          </Breadcrumbs>
          <Typography variant="h3" color="textPrimary">
            Get Started Building your Fitness App
          </Typography>
        </Box>
        {!completed ? (
          <Paper>
            <Grid container>
              <Grid item xs={12} md={3}>
                <Stepper
                  activeStep={activeStep}
                  className={classes.stepper}
                  connector={<CustomStepConnector />}
                  orientation="vertical"
                >
                  {steps.map((step) => (
                    <Step key={step.label}>
                      <StepLabel StepIconComponent={CustomStepIcon}>
                        {step.label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box p={3}>
                  {activeStep === 0 && (
                    <UserDetails
                      onNext={handleNext}
                      me={data.me}
                      appInfo={data.appInfo}
                    />
                  )}
                  {activeStep === 1 && (
                    <AppDetails
                      onBack={handleBack}
                      onNext={handleNext}
                      appInfo={data.appInfo}
                    />
                  )}
                  {activeStep === 2 && (
                    <AppContent
                      onBack={handleBack}
                      appInfo={data.appInfo}
                      onNext={handleComplete}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Card>
            <CardContent>
              <Box maxWidth={true} mx="auto">
                <Box display="flex" justifyContent="center">
                  <Avatar className={classes.avatar}>
                    <StarIcon />
                  </Avatar>
                </Box>
                <Box mt={2}>
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    align="center"
                    gutterBottom
                  >
                    You completed the first few steps in building your app!
                  </Typography>
                </Box>

                <NextStepsHelp />

                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    component={RouterLink}
                    to="/trainer/training_plans"
                  >
                    Continue to Setup Your App
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}
      </Container>
    </Page>
  );
};

export default Onboarding;
