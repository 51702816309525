import React from 'react';
import YouTube from 'react-youtube';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from '@mui/material';

import DialogTitle from '../shared/DialogTitle';

const ExercisePreviewDialog = ({ open, onClose, workoutExercise }) => {
  if (!workoutExercise) {
    return <></>;
  }

  const youtubeVideoId = workoutExercise.youtubeVideoId;
  const imageUrl = (workoutExercise.images || [])[0]
    ? workoutExercise.images[0].url
    : null;
  const videoUrl = (workoutExercise.videos || [])[0]
    ? workoutExercise.videos[0].url
    : null;
  const howtoImageUrl = (workoutExercise.howtoImages || [])[0]
    ? workoutExercise.howtoImages[0].url
    : null;
  const howtoVideoUrl = (workoutExercise.howtoVideos || [])[0]
    ? workoutExercise.howtoVideos[0].url
    : null;

  let mediaPlayback;
  if (videoUrl) {
    mediaPlayback = <video controls src={videoUrl} width="200" />;
  } else if (imageUrl) {
    mediaPlayback = (
      <img src={imageUrl} width="200" alt={workoutExercise.title} />
    );
  } else {
    mediaPlayback = (
      <Typography variant="body1" paragraph>
        None
      </Typography>
    );
  }

  let howtoComponent;
  if (youtubeVideoId) {
    howtoComponent = (
      <YouTube
        videoId={youtubeVideoId}
        opts={{ width: 360, height: 240, playerVars: { autoplay: 1 } }}
      />
    );
  } else if (howtoVideoUrl) {
    howtoComponent = <video controls src={howtoVideoUrl} width="200" />;
  } else if (howtoImageUrl) {
    howtoComponent = (
      <img src={howtoImageUrl} width="200" alt={workoutExercise.title} />
    );
  } else {
    howtoComponent = (
      <Typography variant="body1" paragraph>
        None
      </Typography>
    );
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle
        style={{ marginTop: 4 }}
        sx={{ width: '40ch' }}
        onClose={onClose}
      >
        {workoutExercise.title}
      </DialogTitle>

      <DialogContent dividers>
        <Box>
          <Typography variant="h6" gutterBottom>
            Id
          </Typography>

          <Typography variant="body1" gutterBottom>
            {workoutExercise.exerciseId}
          </Typography>

          <Typography variant="h6" gutterBottom>
            Description
          </Typography>

          <Typography variant="body1" gutterBottom>
            {workoutExercise.desc || 'No Description filled out.'}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Equipment
          </Typography>

          <Typography variant="body1" gutterBottom>
            {workoutExercise.equipment === 0 && 'body weight'}
            {workoutExercise.equipment === 1 &&
              'home gym(dumbbells, kettlebells, basics)'}
            {workoutExercise.equipment === 2 &&
              'full gym(machines and large equipment)'}
            {!workoutExercise.equipment ? 'not specified' : ''}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom>
            Media During Playback
          </Typography>
          {mediaPlayback}
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom>
            How To Video
          </Typography>
          {howtoComponent}
        </Box>
        <Box>
          <Button
            href={`/trainer/exercises/${workoutExercise.exerciseId}`}
            rel="noreferrer"
            target="_blank"
          >
            Edit
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExercisePreviewDialog;
