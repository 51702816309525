function getSeparator(locale = 'en-US', separatorType) {
  const numberWithGroupAndDecimalSeparator = 1000.1;
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSeparator)
    .find((part) => part.type === separatorType).value;
}

var helper = {
  currencySymbolMap: { usd: '$', gbp: '£', eur: '€' },
  decimalCharacter: getSeparator(navigator.userLanguage, 'decimal'),
  digitGroupSeparator: getSeparator(navigator.userLanguage, 'group'),

  friendlyDateFormat: (isoString) => {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const date = new Date(isoString);
    return new Intl.DateTimeFormat({}, options).format(date);
  },

  secondsToHoursMinutesSeconds: function (sec) {
    if (!sec) {
      return '0';
    }

    var seconds = parseInt(sec, 10);

    var h = parseInt(seconds / 3600, 10),
      m = parseInt((seconds % 3600) / 60, 10),
      s = parseInt((seconds % 3600) % 60, 10);

    var friendlyTime = [
      helper.zeroPad(h, 2),
      helper.zeroPad(m, 2),
      helper.zeroPad(s, 2),
    ].join(':');

    return friendlyTime;
  },

  secondsToMinutesSeconds: function (sec) {
    if (!sec) {
      return '0';
    }

    var seconds = parseInt(sec, 10);

    var m = parseInt((seconds % 3600) / 60, 10),
      s = parseInt((seconds % 3600) % 60, 10);

    var friendlyTime = [helper.zeroPad(m, 2), helper.zeroPad(s, 2)].join(':');

    return friendlyTime;
  },

  zeroPad: function (num, size) {
    return ('000000000' + num).substr(-size);
  },
};

export default helper;
