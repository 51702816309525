import { gql } from '@apollo/client';

export const APP_WORKOUT_PLAYER_FRAGMENT = gql`
  fragment AppWorkoutPlayerFragment on AppWorkoutPlayer {
    __typename
    _id
    slug
    title
    description
    verticalImageUrl
  }
`;

export const GET_APP_THEME_QUERY = gql`
  query AppWorkoutPlayer {
    appWorkoutPlayers {
      ...AppWorkoutPlayerFragment
    }
  }
  ${APP_WORKOUT_PLAYER_FRAGMENT}
`;
