import config, { apiUrl } from '../../config';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  colors,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import BuildIcon from '@mui/icons-material/BuildOutlined';

const generateResetPasswordLink = (userId, token) => {
  var opts = {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  };

  return fetch(
    `${apiUrl}/api/admin/users/${userId}/generate_reset_password_link`,
    opts
  )
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      const element = document.getElementById('reset-password-link');
      element.innerHTML = `<a href="${json.link}" target="_blank">${json.link}</a>`;
    })
    .catch(function (error) {
      window.alert('Could not reset password.');
      console.error('Request failed', error);
    });
};

const updateEmail = async (userId, newEmail, token) => {
  if (!newEmail) {
    window.alert("Email can't be empty.");
    return;
  }

  var opts = {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify({ email: newEmail }),
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  };

  const response = await fetch(`${apiUrl}/v1/users/${userId}`, opts);

  if (!response.ok) {
    window.alert('Could not change email.');
    console.error('Request failed', response.statusText);
    return;
  }

  window.alert('Email has been changed.');
  window.location.reload(true);
};

const useStyles = makeStyles((theme) => ({
  root: {},
  jobAvatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  cityAvatar: {
    width: 28,
    height: 28,
    backgroundColor: colors.red[600],
  },
}));

const About = ({ className, user, token, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box>
        <Card>
          <CardHeader title="About" />
          <Divider />
          <CardContent>
            <Typography variant="subtitle2" color="textSecondary">
              {user.bio}
            </Typography>
            <List>
              <ListItem disableGutters divider>
                <ListItemText primary={'Id'} secondary={user._id} />
              </ListItem>
              <ListItem disableGutters divider>
                <ListItemText
                  primary={'Location'}
                  secondary={user.location || '-'}
                />
              </ListItem>

              <ListItem disableGutters divider>
                <ListItemText primary={'Timezone'} secondary={user.timezone} />
              </ListItem>

              <ListItem disableGutters divider>
                <ListItemText primary={'Email'} secondary={user.email} />
              </ListItem>
              <ListItem disableGutters divider>
                <ListItemText
                  primary={'Firebase User Id'}
                  secondary={user.firebaseUserId}
                />
              </ListItem>
              <ListItem disableGutters divider>
                <ListItemText
                  primary={'Signed up'}
                  secondary={user.createdAt}
                />
              </ListItem>
              <ListItem disableGutters divider>
                <ListItemText
                  primary={'Trainers'}
                  secondary={
                    <span>
                      {user.trainerIds?.map((id) => {
                        return (
                          <RouterLink key={id} to={`/admin/trainers/${id}`}>
                            {id}
                          </RouterLink>
                        );
                      })}
                    </span>
                  }
                />
              </ListItem>

              <ListItem disableGutters divider>
                <ListItemText
                  primary={'Completed Workouts'}
                  secondary={user.workoutSessionCount}
                />
              </ListItem>
              <ListItem disableGutters divider>
                <ListItemText primary={'Browser'} secondary={user.userAgent} />
              </ListItem>
            </List>

            <Box
              p={1}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              {config.allowPasswordResetLinkGeneration && (
                <div id="reset-password-link">
                  <Button
                    onClick={async () =>
                      await generateResetPasswordLink(user._id, token)
                    }
                    startIcon={<LockOpenIcon />}
                  >
                    Get Password Reset Link
                  </Button>
                </div>
              )}
              <div id="change-email">
                <Button
                  onClick={async () => {
                    let newEmail = window.prompt(
                      'What do you want to change the email to?',
                      user.email
                    );
                    await updateEmail(user._id, newEmail, token);
                  }}
                  startIcon={<EmailIcon />}
                >
                  Change Email
                </Button>
              </div>
              <div id="fix-login">
                <Button
                  onClick={async (event) => {
                    const result = await fetch(
                      `${apiUrl}/api/admin/users/${user._id}/migrate`,
                      {
                        method: 'POST',
                        headers: new Headers({
                          Authorization: `Bearer ${token}`,
                        }),
                      }
                    );
                    const json = await result.json();
                    console.log(json);
                    window.alert('Migrated');
                  }}
                  startIcon={<BuildIcon />}
                >
                  Fix Login
                </Button>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

About.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default About;
