import React, { useEffect } from 'react';
import QueryString from 'query-string';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Link,
  Card,
  Container,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Button,
} from '@mui/material';
import { Image as ImageIcon } from 'react-feather';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import Label from '../../components/shared/Label';
import Page from '../Page';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import config from '../../config';

const MY_CREATED_MEAL_PLANS = gql`
  query MyCreatedMealPlans($trainerId: ID) {
    mealPlans(input: { trainerId: $trainerId, limit: 500 }) {
      _id
      title
      desc
      status
      coverImageUrl
    }
  }
`;

const CREATE_MEAL_PLAN = gql`
  mutation CreateMealPlan($input: MealPlanInput!) {
    createMealPlan(input: $input) {
      _id
    }
  }
`;

const DELETE_MEAL_PLAN = gql`
  mutation DeleteMealPlan($id: ID!) {
    deleteMealPlan(id: $id) {
      message
    }
  }
`;

export default function MealPlanList(props) {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;

  const history = useHistory();
  const { loading, error, data, refetch } = useQuery(MY_CREATED_MEAL_PLANS, {
    variables: { trainerId },
  });
  const [createMealPlan] = useMutation(CREATE_MEAL_PLAN);
  const [deleteMealPlan] = useMutation(DELETE_MEAL_PLAN);

  useEffect(() => {
    // When coming back to this page do refetch()
    refetch();
  }, [refetch, history.location]);

  if (error) return <ServerError message="Failed to load" />;
  if (loading || !data?.mealPlans) return <Loading />;

  return (
    <Page title={`MealPlans : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Meal Plans
        </Typography>
        <Typography component="p" variant="body1" color="textPrimary">
          You can create Meal Plans to show your clients which meal to eat when.
          Start by creating meals and adding them to a meal plan's schedule.
        </Typography>

        <Box mt={3}>
          {/* add item*/}
          <Box mb={3}>
            <Button
              component={RouterLink}
              color="secondary"
              variant="contained"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                createMealPlan({
                  variables: { input: { title: 'Untitled' } },
                }).then((r) => {
                  if (r.data?.createMealPlan?._id) {
                    history.push(
                      `/trainer/meal_plans/${r.data.createMealPlan._id}`
                    );
                  }
                });
              }}
            >
              Create a New Meal Plan
            </Button>
          </Box>
          {/* list */}
          <Card>
            <Box minWidth={700}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Completed</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.mealPlans.map((w, i) => {
                    const url = `/trainer/meal_plans/${w._id}`;
                    let status = <Label color="warning">Draft</Label>;
                    if (w.status === 'FREE') {
                      status = <Label color="secondary">Free</Label>;
                    } else if (w.status === 'PREMIUM') {
                      status = <Label color="primary">Premium</Label>;
                    }

                    let mediaComponent;
                    if (w.coverImageUrl) {
                      mediaComponent = (
                        <img
                          alt="MealPlan"
                          src={w.coverImageUrl}
                          style={{ width: 48, height: 48 }}
                        />
                      );
                    } else {
                      mediaComponent = <ImageIcon size={48} />;
                    }
                    const media = (
                      <Link to={url} component={RouterLink}>
                        {mediaComponent}
                      </Link>
                    );

                    return (
                      <TableRow key={w._id} hover>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Box m={1} bgcolor="background.dark">
                              {media}
                            </Box>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={url}
                              variant="h6"
                            >
                              {w.title}
                            </Link>
                          </Box>
                        </TableCell>
                        <TableCell>{w.desc}</TableCell>
                        <TableCell>{status}</TableCell>
                        <TableCell>{w.completed}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              if (w.completed > 0) {
                                window.alert(
                                  "You can't delete meal_plans that have been completed by a subscriber."
                                );
                                return;
                              }
                              if (!window.confirm('Delete the item?')) {
                                return;
                              }
                              deleteMealPlan({ variables: { id: w._id } })
                                .then((r) => refetch())
                                .catch((e) => {
                                  window.alert(e.toString());
                                });
                            }}
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Card>
          {/* more TODO */}
        </Box>
      </Container>
    </Page>
  );
}
