import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Paper,
  Box,
  Button,
  Card,
  FormHelperText,
  Radio,
  CardContent,
  Grid,
  Typography,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SketchPicker } from 'react-color';

// if using BlockPicker
//const colors = ['#FFFFFF', '#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#000000', '#555555', '#dce775', '#ff8a65', '#ba68c8'];

const useStyles = makeStyles((theme) => ({
  root: {},
  typeOption: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  colorPicker: {
    fontFamily: theme.typography.fontFamily,
  },
  previewButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const AppTheme = ({
  className,
  match,
  appInfo,
  appThemes,
  appWorkoutPlayers,
  setAppInfo,
  saveAppInfo,
  ...rest
}) => {
  const classes = useStyles();

  const handleEditColorMode = (event) => {
    setAppInfo({ ...appInfo, colorMode: event.target.value, autoSave: true });
  };
  const handleEditMainColor = (color) => {
    const hexColor = color.hex;
    setAppInfo({ ...appInfo, mainColor: hexColor, autoSave: true });
  };
  const handleChangeAppTheme = (appTheme) => {
    setAppInfo({ ...appInfo, appThemeId: appTheme._id });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    saveAppInfo();
  };

  return (
    <form className={clsx(classes.root, className)} onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Default Color Mode
              </Typography>
              <FormControl variant="outlined">
                <InputLabel htmlFor="colorMode">Color Mode</InputLabel>
                <Select
                  style={{ width: 200 }}
                  native
                  value={appInfo.colorMode ?? 'DARK'}
                  onChange={handleEditColorMode}
                  label="Color Mode"
                >
                  <option value="DARK">Dark</option>
                  <option value="LIGHT">Light</option>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={5}>
              <Typography variant="h4" gutterBottom>
                Primary Color
              </Typography>
              <SketchPicker
                className={classes.colorPicker}
                triangle="hide"
                color={appInfo.mainColor ?? '#fff'}
                onChangeComplete={handleEditMainColor}
              />
              <FormHelperText>
                Used for button and header background.
              </FormHelperText>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Theme
              </Typography>
              <Grid container spacing={3}>
                {appThemes.map((appTheme) => (
                  <Grid item key={appTheme._id} xs={12} md={6}>
                    <Paper
                      className={classes.typeOption}
                      elevation={appInfo.appThemeId === appTheme._id ? 10 : 1}
                      onClick={() => handleChangeAppTheme(appTheme)}
                    >
                      <Radio
                        checked={appInfo.appThemeId === appTheme._id}
                        onClick={() => handleChangeAppTheme(appTheme)}
                      />
                      <Box ml={2}>
                        <img
                          src={appTheme.imageUrl}
                          height="300"
                          alt={appTheme.title}
                        />
                        <Typography
                          gutterBottom
                          variant="h5"
                          color="textPrimary"
                        >
                          {appTheme.title}
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {appTheme.description}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              <FormHelperText>
                Select the app that fits your content.
              </FormHelperText>
            </Grid>
          </Grid>

          <Box mt={2}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

AppTheme.propTypes = {
  className: PropTypes.string,
};

export default AppTheme;
