import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';

import Page from '../Page';
import DirectUpload from '../shared/DirectUpload';

const DirectS3UploadTest = (props) => {
  const [images, setImages] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const handleUploadSuccess = (file) => {
    console.log(file);
    setImages([file.image]);
    setVideoUrl(file.video.url);

    // transcode if video
    if (file.video) {
      console.log('Video Uploaded');
      // TODO call /trainer/media/transcode to transcode to HLS
    }
  };
  const handleRemove = (file) => {
    console.log(file);
    setImages([]);
  };

  return (
    <Page title="Direct S3 Upload Test : Tempokit">
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Direct S3 Upload Test
        </Typography>

        <DirectUpload
          images={images}
          maxFiles={1}
          onSuccess={handleUploadSuccess}
          onRemove={handleRemove}
        />

        {videoUrl && <p>{videoUrl}</p>}
      </Container>
    </Page>
  );
};

export default DirectS3UploadTest;
