import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Link,
  Divider,
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Fastfood as FastfoodIcon,
  PhoneIphone as PhoneIphoneIcon,
} from '@mui/icons-material';

import {
  Package as PackageIcon,
  Codepen as CodepenIcon,
  Server as ServerIcon,
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  DollarSign as DollarSignIcon,
  File as FileIcon,
  FileText as FileTextIcon,
  Settings as SettingsIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Info as InfoIcon,
  UserCheck as UserCheckIcon,
  ChevronsLeft as ChevronsLeftIcon,
  ChevronsRight as ChevronsRightIcon,
  Zap as ZapIcon,
  MessageCircle as MessageCircleIcon,
} from 'react-feather';
import Logo from '../../../components/shared/Logo';
import YourPlanBox from './YourPlanBox';
import { useAuth } from '../../../hooks/use-auth';
import NavItem from './NavItem';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import config from '../../../config';

const sections = [
  {
    subheader: '',
    items: [
      {
        title: 'Get Started',
        icon: InfoIcon,
        href: '/trainer/onboarding/preview',
      },
    ],
  },
  {
    subheader: 'Users',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/trainer',
      },

      {
        title: 'Users',
        icon: UsersIcon,
        href: '/trainer/users',
      },
      {
        title: 'Chat',
        icon: MessageCircleIcon,
        href: '/trainer/chat',
      },
    ],
  },
  {
    subheader: 'App',
    items: [
      {
        title: 'App Info',
        icon: CodepenIcon,
        href: '/trainer/app_infos/current',
      },
      {
        title: 'Theme',
        icon: LayoutIcon,
        href: '/trainer/app_theme',
      },
      {
        title: 'Screens',
        icon: ZapIcon,
        href: '/trainer/app_features',
      },
      {
        title: 'App Preview',
        icon: PhoneIphoneIcon,
        href: '/trainer/app_preview',
      },
      {
        title: 'Publishing',
        icon: PackageIcon,
        href: '/trainer/app_publishing/current',
      },
    ],
  },
  {
    subheader: 'Storefront',
    items: [
      {
        title: 'Web Site',
        icon: FileTextIcon,
        href: '/trainer/web_site',
      },
      {
        title: 'Payments',
        icon: BriefcaseIcon,
        href: '/trainer/account/payout',
      },
      {
        title: 'Domain',
        icon: SettingsIcon,
        href: '/trainer/web_site/domain',
      },
    ],
  },
  {
    subheader: 'Content',
    items: [
      {
        title: 'Training Plans',
        icon: CalendarIcon,
        href: '/trainer/training_plans',
      },
      {
        title: 'Workouts',
        icon: TrelloIcon,
        href: '/trainer/workouts',
      },
      {
        title: 'Exercises',
        icon: ServerIcon,
        href: '/trainer/exercises',
      },
      {
        title: 'Nutrition',
        icon: FastfoodIcon,
        href: '/trainer/nutrition',
      },
      {
        title: 'Posts',
        icon: FileIcon,
        href: '/trainer/posts',
      },
    ],
  },
  {
    subheader: 'Pricing',
    items: [
      {
        title: 'Subscription Plans',
        icon: DollarSignIcon,
        href: '/trainer/subscription_plans',
      },
    ],
  },
  {
    subheader: 'Account',
    items: [
      {
        title: 'Profile',
        icon: UserIcon,
        href: '/trainer/account',
      },
      {
        title: 'Integrations',
        icon: BriefcaseIcon,
        href: '/trainer/account/integrations',
      },

      {
        title: 'Referrals',
        icon: UserCheckIcon,
        href: '/trainer/referrals',
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0, miniDesktopDrawer }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, depth, miniDesktopDrawer }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, miniDesktopDrawer }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <Tooltip key={`${key}-tooltip`} title={item.title}>
        <div>
          {' '}
          <NavItem
            depth={depth}
            icon={item.icon}
            info={item.info}
            key={key}
            open={Boolean(open)}
            title={miniDesktopDrawer ? '' : item.title}
          >
            {renderNavItems({
              depth: depth + 1,
              pathname,
              items: item.items,
              miniDesktopDrawer,
            })}
          </NavItem>
        </div>
      </Tooltip>
    );
  } else {
    acc.push(
      <Tooltip key={`${key}-tooltip`} title={item.title}>
        <div>
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            info={item.info}
            key={key}
            title={miniDesktopDrawer ? '' : item.title}
          />
        </div>
      </Tooltip>
    );
  }

  return acc;
}

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: drawerWidth,
  },
  desktopDrawerOpen: {
    width: drawerWidth,
    top: 64,
    height: 'calc(100% - 64px)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  desktopDrawerClose: {
    top: 64,
    height: 'calc(100% - 64px)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onDesktopToggle, onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const auth = useAuth();
  const authLoading = auth.loading;
  const user = auth.user;
  const [miniDesktopDrawer, setMiniDesktopDrawer] = useState(false);

  useEffect(() => {
    onDesktopToggle(miniDesktopDrawer);
  }, [onDesktopToggle, miniDesktopDrawer]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  if (authLoading) {
    return <></>;
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Box p={2}>
          {!miniDesktopDrawer && <YourPlanBox user={user} />}
          <Divider />

          {sections.map((section) => {
            return (
              <List
                key={section.subheader}
                subheader={
                  !miniDesktopDrawer && (
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  )
                }
              >
                {renderNavItems({
                  miniDesktopDrawer,
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            );
          })}
        </Box>

        {!miniDesktopDrawer && (
          <Box p={2}>
            <Divider />
            <Box p={2} borderRadius={1} bgcolor="background.dark">
              <Typography variant="h6" color="textPrimary">
                {config.showDocsLink ? 'Need Help?' : ''}
              </Typography>
              <Link
                variant="subtitle1"
                color="secondary"
                href="https://docs.tempokit.com"
                target="_blank"
                rel="noopener"
              >
                {config.showDocsLink ? 'Check our docs' : ''}
              </Link>
            </Box>
          </Box>
        )}
      </PerfectScrollbar>
      <Hidden lgDown>
        <Box
          borderTop={1}
          borderColor="grey.500"
          display="flex"
          justifyContent="start"
        >
          <Button
            color="inherit"
            onClick={() => {
              setMiniDesktopDrawer(!miniDesktopDrawer);
            }}
          >
            <SvgIcon fontSize="small">
              {miniDesktopDrawer ? <ChevronsRightIcon /> : <ChevronsLeftIcon />}
            </SvgIcon>
            {!miniDesktopDrawer && (
              <Typography variant="caption">Collapse sidebar</Typography>
            )}
          </Button>
        </Box>
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          classes={{
            paper: clsx({
              [classes.desktopDrawerOpen]: !miniDesktopDrawer,
              [classes.desktopDrawerClose]: miniDesktopDrawer,
            }),
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
