import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
} from '@mui/material';

const Nutrition = ({ match, appInfo, setAppInfo, saveAppInfo, ...rest }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    saveAppInfo();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h3">
            Nutrition
          </Typography>
          <Box mb={3}>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={appInfo.nutritionEnabled}
                      onChange={(event) => {
                        setAppInfo({
                          ...appInfo,
                          nutritionEnabled: event.target.checked,
                        });
                      }}
                      name="nutritionEnabled"
                    />
                  }
                  label="Nutrition"
                />
              </FormGroup>
              <FormHelperText>
                Allow your users to see a nutrition section where they can
                calculate their marcos and see your meal recipes.
              </FormHelperText>
            </FormControl>
          </Box>

          <Box mt={2}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

export default Nutrition;
