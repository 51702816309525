import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Header = ({ className, product, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <Box mb={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/trainer/products"
            component={RouterLink}
          >
            Back to Products
          </Link>
        </Breadcrumbs>
      </Box>

      <Typography variant="h3" color="textPrimary">
        {product.title}
      </Typography>
      <Typography variant="caption">
        Last Saved: {moment(product.updatedAt).format('LLL')}
      </Typography>
    </div>
  );
};

export default Header;
