import React, { useEffect } from 'react';
import QueryString from 'query-string';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Page from '../Page';
import AppPreview from '../app_preview/AppPreview';
import { Grid, Button, Box, Container, Typography } from '@mui/material';
import PostList from './PostList';
import Loading from '../shared/Loading';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_POST, DELETE_POST, GET_POSTS } from '../../graphql/post';
import ServerError from '../error/ServerError';
import { useSnackbar } from 'notistack';
import config from '../../config';

export default function Posts() {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;
  const userId = queryParams.userId || null;
  const lastId = queryParams.lastId || null;

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { error, loading, data, refetch } = useQuery(GET_POSTS, {
    variables: { input: { trainerId, userId, lastId } },
  });
  const [createPost] = useMutation(CREATE_POST, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onCompleted: (data) => {
      history.push(`/trainer/posts/${data.createPost._id}`);
    },
  });
  const [deletePost] = useMutation(DELETE_POST, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onCompleted: (data) => {
      refetch();
    },
  });

  useEffect(refetch, [refetch, history]);

  const handleCreate = (e) => {
    e.preventDefault();
    enqueueSnackbar(`Creating...`, {
      autoHideDuration: 2000,
    });
    createPost({
      variables: {
        input: { title: 'Untitled' },
      },
    });
  };

  const handleDelete = async (post) => {
    if (!window.confirm('Delete the post?')) {
      return;
    }
    enqueueSnackbar(`Deleting...`, {
      autoHideDuration: 2000,
    });
    deletePost({
      variables: {
        id: post._id,
      },
    });
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!data?.posts) {
    return <ServerError message={'Error'} />;
  }

  return (
    <Page title={`Posts : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Posts
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Post announcements, fitness advice, and nutrition tips.
        </Typography>

        <Box mb={3}>
          <Button
            component={RouterLink}
            color="secondary"
            variant="contained"
            to="#"
            onClick={handleCreate}
          >
            Create a new Post
          </Button>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Box mb={3}>
              <PostList posts={data.posts} onDelete={handleDelete} />
            </Box>
            {data.posts.length === 100 && (
              <Box mb={3}>
                <Button
                  component={RouterLink}
                  color="secondary"
                  variant="outlined"
                  to={`/trainer/posts?lastId=${
                    data.posts[data.posts.length - 1]?._id
                  }`}
                >
                  More
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppPreview trainerId={trainerId} screen="POSTS" />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
