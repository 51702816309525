import React, { useState } from 'react';
import ChipInput from '../../components/shared/ChipInput';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import {
  Grid,
  Card,
  CardContent,
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  Button,
  TextField,
  Container,
} from '@mui/material';

import LabelledOutline from '../../components/shared/LabelledOutline';
import MediaUpload from '../../components/shared/DirectUpload';

const MealPlanDetailsEditor = (props) => {
  const [mealPlan, setMealPlan] = useState(props.mealPlan);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    let newMealPlan = _.set(
      { ...mealPlan },
      event.target.name,
      event.target.value
    );

    setMealPlan(newMealPlan);
  };

  const handleUploadError = () => {
    enqueueSnackbar(`Media upload failed`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };
  const handleUploadSuccess = (uploadedResponse) => {
    setMealPlan({ ...mealPlan, coverImages: [uploadedResponse.image] });
  };
  const handleUploadRemove = () => {
    setMealPlan({ ...mealPlan, coverImages: [] });
  };

  const handleAddTag = (tag) => {
    setMealPlan({ ...mealPlan, tags: [...mealPlan.tags, tag] });
  };

  const handleDeleteTag = (chip, index) => {
    const newTags = (mealPlan.tags || []).filter((item) => item !== chip);
    setMealPlan({ ...mealPlan, tags: newTags });
  };

  return (
    <Container maxWidth={false}>
      <Card id="main">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                onChange={handleChange}
                value={mealPlan.title}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                multiline
                label="Description"
                name="desc"
                type="text"
                rows={4}
                maxLength={320}
                onChange={handleChange}
                value={mealPlan.desc ?? ''}
                variant="outlined"
                placeholder="Describe this meal plan and who it's for."
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <LabelledOutline id="cover-image" label="Cover Image">
                <MediaUpload
                  images={mealPlan.coverImages || []}
                  onError={handleUploadError}
                  onSuccess={handleUploadSuccess}
                  onRemove={handleUploadRemove}
                  maxFiles={1}
                />{' '}
              </LabelledOutline>
              <FormHelperText>A cover image for the meal plan.</FormHelperText>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl>
                <ChipInput
                  label="Tags"
                  value={mealPlan.tags ?? []}
                  onAdd={handleAddTag}
                  onDelete={handleDeleteTag}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <FormHelperText>
                  Add helpful tags for users to find by like "keto"
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  native
                  value={mealPlan.status}
                  onChange={handleChange}
                  label="Status"
                  inputProps={{
                    name: 'status',
                    id: 'status',
                  }}
                >
                  <option value="DRAFT">Draft</option>
                  <option value="FREE">Free</option>
                  <option value="PREMIUM">Premium</option>
                </Select>
                <FormHelperText>
                  Drafts are seen only by you while you create the mealPlan.{' '}
                  <br /> Public are free to view. <br /> Premium are available
                  only to paid members.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => props.onSave(mealPlan)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default MealPlanDetailsEditor;
