import React from 'react';
import { Button, Box, Grid, Slider, Typography } from '@mui/material';
import { withAuthContext } from '../../hooks/use-auth';
import { apiUrl } from '../../config';
import request from 'superagent';
import Cropper from 'react-easy-crop';
import Loading from './Loading';

class ImageCropper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: props.aspectRatio || 4 / 3,
      croppedArea: {},
      croppedAreaPixels: {},
      width: props.width || 300,
      height: props.height || 300,
    };

    this.handleSaveCrop = this.handleSaveCrop.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.upload = this.upload.bind(this);
  }

  /**
   * @async
   * @param {{blob: Blob, w: number, h: number }[]} files
   * @return {Promise<Object>}
   */
  upload(files) {
    return this.props.context.user
      .getIdToken()
      .then((token) => {
        const req = request
          .post(`${apiUrl}/trainer/media`)
          .auth(token, { type: 'bearer' });

        files.forEach(function (file) {
          req.attach(file.blob.name, file.blob);
        });

        return req;
      })
      .then((res) => {
        if (res.status !== 201) {
          throw new Error('Error Uploading.');
        }
        const parsed = JSON.parse(res.text);

        const uploaded = parsed.uploaded;

        if (!uploaded || uploaded.length === 0) {
          alert('Bad response from server');
          return;
        }

        const input = files[0];
        const first = uploaded[0].files[0];
        if (!first) {
          alert('Bad response from server');
          return;
        }

        let uploadedRes = {};

        const image = {
          w: input.w,
          h: input.h,
          url: first.url,
        };

        if (image.w === image.h) {
          image.aspect = 'square';
        } else if (image.w > image.h) {
          image.aspect = 'wide';
        }

        uploadedRes.image = image;
        return uploadedRes;
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  async handleSaveCrop(e) {
    e.preventDefault();
    try {
      this.setState({ saving: true });

      const croppedImage = await getCroppedImg(
        this.props.imageUrl,
        this.state.croppedAreaPixels
      );

      const file = {
        blob: croppedImage,
        w: this.state.croppedAreaPixels.width,
        h: this.state.croppedAreaPixels.height,
      };
      const uploadedRes = await this.upload([file]);
      if (this.props.onSuccess) {
        this.props.onSuccess(uploadedRes);
      }
    } catch (err) {
      console.error(err);
      alert('Crop failed!');
    }
    this.setState({ saving: false });
  }

  handleRemove(e) {
    e.preventDefault();
    if (this.props.onRemove) {
      this.props.onRemove({ url: this.props.imageUrl });
    }
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedArea, croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  render() {
    if (!this.props.imageUrl) {
      return <div />;
    }

    let saving = <div />;
    if (this.state.saving) {
      saving = (
        <Box m={2}>
          <Loading />
        </Box>
      );
    }

    return (
      <Grid container>
        <Grid item xs={6}>
          <div
            className="image-crop"
            style={{
              position: 'relative',
              width: this.state.width,
              height: this.state.height,
            }}
          >
            <Cropper
              image={this.props.imageUrl}
              mediaProps={{ crossOrigin: 'anonymous' }} // needed to avoid cross-origin issues
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={this.state.aspect}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          {saving}
          <Box m={4}>
            <Typography id="slider-zoom" gutterBottom>
              Zoom
            </Typography>
            <Slider
              value={this.state.zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="slider-zoom"
              onChange={(e, zoom) => this.onZoomChange(zoom)}
            />
          </Box>
          <br />
          <Box m={2}>
            <Button
              onClick={this.handleSaveCrop}
              size="small"
              variant="contained"
              color="primary"
            >
              Crop
            </Button>
          </Box>
          <Box m={2}>
            <Button
              onClick={this.handleRemove}
              size="small"
              variant="outlined"
              color="secondary"
            >
              Remove
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

/**
 * @param {String} url
 * @return {Promise<Image>}
 */
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous'; // needed to avoid cross-origin issues
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @async
 * @param {String} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 * @return {Promise<Blob>} image as a Blob.
 */
async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  const dataUrl = canvas.toDataURL('image/jpeg');
  return dataURItoBlob(dataUrl);
}

/**
 * @param {string} dataURI
 * @return {Blob}
 */
function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export default withAuthContext(ImageCropper);
