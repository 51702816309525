import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  FormHelperText,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@mui/material';
import config from '../../config';

const fields = [
  {
    name: 'faqUrl',
    label: 'FAQ (Frequently Asked Questions)',
    help: 'Add a list of Frequently Asked Questions for your users.',
  },
  {
    name: 'aboutUrl',
    label: 'About Page',
    help: 'Add a link to other information about yourself or your business. This could be your web page or blog.',
  },

  {
    name: 'termsUrl',
    label: 'Terms of Use',
    help: "Enter a link to your Terms of Use. If you don't have those, then we'll provide a default version.",
  },
  {
    name: 'privacyPolicyUrl',
    label: 'Privacy Policy',
    help: "Enter a link to your Privacy Policy. If you don't have those, then we'll provide a default version.",
  },
];

const SettingsScreen = ({
  className,
  match,
  appInfo,
  setAppInfo,
  saveAppInfo,
  ...rest
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        blogUrl: appInfo.blogUrl ?? '',
        termsUrl: !config.showTempokitTerms ? '' : appInfo.termsUrl ?? '',
        privacyPolicyUrl: !config.showTempokitTerms
          ? ''
          : appInfo.privacyPolicyUrl ?? '',
        faqUrl: appInfo.faqUrl ?? '',
        aboutUrl: appInfo.aboutUrl ?? '',
      }}
      validationSchema={Yup.object().shape({
        blogUrl: Yup.string().url(),
        termsUrl: Yup.string().url(),
        privacyPolicyUrl: Yup.string().url(),
        faqUrl: Yup.string().url(),
        aboutUrl: Yup.string().url(),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        setAppInfo({ ...appInfo, ...values, autoSave: true });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form className={clsx(className)} onSubmit={handleSubmit} {...rest}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h3">
                Settings Screen
              </Typography>

              <Box mb={3}>
                <Typography variant="body1">
                  These links will be seen on the settings screen in the app.
                  Links should have "https://" as a prefix.
                </Typography>
              </Box>
              {fields.map((field) => {
                return (
                  <Box key={field} mb={3}>
                    <TextField
                      error={Boolean(touched[field.name] && errors[field.name])}
                      fullWidth
                      helperText={touched[field.name] && errors[field.name]}
                      label={field.label}
                      name={field.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[field.name]}
                      variant="outlined"
                    />
                    <FormHelperText>{field.help}</FormHelperText>
                  </Box>
                );
              })}
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default SettingsScreen;
