import React from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Typography,
} from '@mui/material';
import DirectUpload from '../../components/shared/DirectUpload';

const maxOnboardingImages = 3;
const Onboarding = ({ match, appInfo, setAppInfo, saveAppInfo, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleOnboardingImageUploadError = () => {
    enqueueSnackbar(`Media upload failed!`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };

  const handleOnboardingImageUploadSuccess = (uploadedRes) => {
    const newImage = {
      url: uploadedRes.image.url,
      w: uploadedRes.image.w,
      h: uploadedRes.image.h,
      aspect: uploadedRes.image.aspect,
    };

    const onboardingImages = (appInfo.onboardingImages ?? []).concat(newImage);
    setAppInfo({ ...appInfo, onboardingImages });
  };

  const handleOnboardingImageUploadRemove = (file) => {
    let onboardingImages = appInfo.onboardingImages.slice(0);

    if (onboardingImages.length === 1) {
      onboardingImages = [];
    } else {
      onboardingImages = onboardingImages.filter(
        (element) => element.url === file.url
      );
    }
    setAppInfo({ ...appInfo, onboardingImages });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    saveAppInfo();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h3">
            Onboarding Screens
          </Typography>

          <Box mb={3}>
            <DirectUpload
              fileType="image"
              images={appInfo.onboardingImages}
              onError={handleOnboardingImageUploadError}
              onSuccess={handleOnboardingImageUploadSuccess}
              onRemove={handleOnboardingImageUploadRemove}
              maxFiles={maxOnboardingImages}
            />
            <br />

            <FormHelperText>
              Upload up to {maxOnboardingImages} portrait(1080x1920) photos.
              <br />
              These photos will be on the onboarding screen when the user starts
              the app for the very first time.
            </FormHelperText>
          </Box>

          <Box mt={2}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

export default Onboarding;
