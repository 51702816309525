import React from 'react';
import config from '../../config';

const Logo = (props) => {
  return (
    <img alt={config.appDisplayName} src={config.appIconBase64} {...props} />
  );
};

export default Logo;
