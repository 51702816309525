import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../config';
import { useAuth } from '../../hooks/use-auth';
import MuiMarkdown from 'mui-markdown';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import config from '../../config';

const zapierText = `
## What is Zapier and how do I use it connect to other apps?

[Zapier](https://zapier.com/apps/tempokit/integrations) lets you connect APP to 2,000+ other web services. Automated connections called Zaps, set up in minutes with no coding, can automate your day-to-day tasks and build workflows between apps that otherwise wouldn't be possible.

Each Zap has one app as the **Trigger**, where your information comes from and which causes one or more **Actions** in other apps, where your data gets sent automatically. 

## Getting Started with Zapier
Sign up for a free [Zapier](https://zapier.com/apps/tempokit/integrations) account, from there you can jump right in. To help you hit the ground running, here are some popular pre-made Zaps.

## How do I connect APP to Zapier?

Log in to your [Zapier account](https://zapier.com/sign-up) or create a new account.
Navigate to "My Apps" from the top menu bar.
Now click on "Connect a new account..." and search for "Tempokit"
Use your credentials to connect your Tempokit account to Zapier.
Once that's done you can start creating an automation! Use a pre-made Zap or create your own with the Zap Editor. Creating a Zap requires no coding knowledge and you'll be walked step-by-step through the setup. 
Need inspiration? See everything that's possible with [Tempokit and Zapier](https://zapier.com/apps/APP/integrations).

If you have any additional questions, you can reach out to contact@zapier.com.
`;

const Integrations = () => {
  if (!config.showZapierIntegration) {
    return (
      <Card>
        <CardHeader title="API Integrations" />
        <Divider />
        <CardContent>
          <Typography variant="body1">Coming soon.</Typography>
        </CardContent>
      </Card>
    );
  }

  const [apiKey, setApiKey] = useState('-');

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src =
      'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js';
    document.head.appendChild(script);

    // Load CSS
    const stylesheet = document.createElement('link');
    stylesheet.rel = 'stylesheet';
    stylesheet.href =
      'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css';
    document.head.appendChild(stylesheet);
  });

  const fetchAndSetApiKey = async ({ token }) => {
    var opts = {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    };

    try {
      const response = await fetch(`${apiUrl}/v1/accounts/api_key`, opts);
      const json = await response.json();

      if (response.status !== 200) {
        console.error(json.message);
        return;
      }
      setApiKey(json.apiKey);
    } catch (error) {
      console.error('Request failed', error);
    }
  };

  const auth = useAuth();
  useEffect(() => {
    auth.user.getIdToken().then((authToken) => {
      fetchAndSetApiKey({ token: authToken });
    });
  }, [auth]);

  return (
    <Card>
      <CardHeader title="API Integrations" />
      <Divider />
      <CardContent>
        <Typography variant="body1">
          You can use this API key to connect to your {config.appDisplayName}{' '}
          account using Zapier.
        </Typography>
        <Box mt={3}>
          <Typography variant="h4">API Key: {apiKey}</Typography>
        </Box>

        <Box mt={3}>
          <MuiMarkdown>{zapierText}</MuiMarkdown>
        </Box>

        <Box mt={3}>
          <zapier-full-experience
            client-id="ZMc2DBYNBTn5dPa46Xvj5Oze91NSak2uIujMk4DV"
            theme="light"
            app-search-bar-display="show"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default Integrations;
