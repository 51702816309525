import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

import firebase from 'firebase/compat/app';

import { apiUrl } from '../config';

// https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-676886539
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) =>
      key === '__typename' ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

export function createApolloClient() {
  const httpLink = createHttpLink({
    uri: `${apiUrl}/graphql`,
  });
  const authLink = setContext((_, { headers }) => {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
          },
        };
      });
  });

  const client = new ApolloClient({
    link: ApolloLink.from([cleanTypeName, authLink, httpLink]),
    cache: new InMemoryCache({ addTypename: false }),
  });

  return client;
}
