import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ChipInput from '../../components/shared/ChipInput';
import { useSnackbar } from 'notistack';
import {
  Divider,
  Tabs,
  Tab,
  Card,
  CardContent,
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  Box,
  Button,
  TextField,
  Container,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Page from '../Page';
import Header from './Header';
import Loading from '../../components/shared/Loading';
import LabelledOutline from '../../components/shared/LabelledOutline';
import DirectUpload from '../../components/shared/DirectUpload';
import { useMutation, useQuery } from '@apollo/client';
import { GET_POST, UPDATE_POST } from '../../graphql/post';
import ServerError from '../error/ServerError';
import PostPreview from './PostPreview';
import config from '../../config';

const tabs = [
  { value: 'details', label: 'Details' },
  { value: 'preview', label: 'Preview' },
];

const PostEditor = ({ match }) => {
  const params = useParams();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('details');
  const [post, setPost] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const editorRef = useRef();
  const id = match ? match.params.id : null;
  const { error, loading, data } = useQuery(GET_POST, { variables: { id } });

  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  const [updatePost] = useMutation(UPDATE_POST, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });

  useEffect(() => {
    setPost(data?.post);
  }, [data]);

  const handleSave = () => {
    if (!post) {
      return;
    }
    enqueueSnackbar('Saving...', { autoHideDuration: 2000 });
    updatePost({
      variables: {
        id: post._id,
        input: {
          title: post.title,
          content: post.content,
          contentFormat: 'HTML',
          tags: post.tags,
          status: post.status,
          coverImages: post.coverImages || [],
          videos: post.videos,
        },
      },
    });
  };

  const handleEditorChange = (value) => {
    setPost({ ...post, content: value });
  };

  const handleChange = (event) => {
    setPost({ ...post, [event.target.name]: event.target.value });
  };

  const handleUploadError = () => {
    enqueueSnackbar(`Media upload failed`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };
  const handleUploadSuccess = (uploadedResponse) => {
    let newPost = { ...post, coverImages: [uploadedResponse.image] };
    if (uploadedResponse.videos) {
      newPost.videos = uploadedResponse.videos;
    }

    setPost(newPost);
  };
  const handleUploadRemove = () => {
    setPost({ ...post, coverImages: [], videos: null });
  };

  const handleAddTag = (tag) => {
    setPost({ ...post, tags: [...post.tags, tag] });
  };

  const handleDeleteTag = (chip, index) => {
    const newTags = (post.tags || []).filter((item) => item !== chip);
    setPost({ ...post, tags: newTags });
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    history.push(`/trainer/posts/${post._id}/${value}`);
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading || !post) {
    return <Loading />;
  }

  return (
    <Page title={`Post Editor : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Box mb={3}>
          <Header post={post} />
          <Box mt={3}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <Divider />
          </Box>
        </Box>

        {currentTab === 'details' && (
          <Card id="main">
            <CardContent>
              <Box mb={3}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  onChange={handleChange}
                  value={post.title}
                  variant="outlined"
                />
              </Box>
              <Box mb={3}>
                <LabelledOutline id="cover-image" label="Cover Image or Video">
                  <DirectUpload
                    fileType="video"
                    images={post.coverImages || []}
                    onError={handleUploadError}
                    onSuccess={handleUploadSuccess}
                    onRemove={handleUploadRemove}
                    streaming={true}
                    maxFiles={1}
                  />{' '}
                </LabelledOutline>
                <FormHelperText>
                  A cover image/video for the post.
                </FormHelperText>
              </Box>

              <Box style={{ height: 300 }} mb={3}>
                <ReactQuill
                  style={{ height: 250, minHeight: 80 }}
                  ref={editorRef}
                  theme="snow"
                  value={post.content}
                  bounds="#main"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' },
                      ],
                      ['link', 'image', 'video'],
                      ['clean'],
                    ],
                  }}
                  onChange={handleEditorChange}
                />
              </Box>
              <Box mb={3}>
                <FormControl>
                  <ChipInput
                    label="Tags"
                    value={post.tags ?? []}
                    onAdd={handleAddTag}
                    onDelete={handleDeleteTag}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormHelperText>
                    Add helpful tags for users to find by like "nutrition"
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box mb={3}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="status">Status</InputLabel>
                  <Select
                    native
                    value={post.status}
                    onChange={handleChange}
                    label="Status"
                    inputProps={{
                      name: 'status',
                      id: 'status',
                    }}
                  >
                    <option value="DRAFT">Draft</option>
                    <option value="PUBLIC">Public</option>
                    <option value="PREMIUM">Premium</option>
                  </Select>
                  <FormHelperText>
                    Drafts are seen only by you while you create the post.{' '}
                    <br /> Public are free to view. <br /> Premium are available
                    only to paid members.
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box mt={3}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}

        {currentTab === 'preview' && <PostPreview post={post} />}
      </Container>
    </Page>
  );
};

export default PostEditor;
