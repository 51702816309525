import { gql } from '@apollo/client';

const TRAINING_PLAN_FRAGMENT = gql`
  fragment TrainingPlanFragment on TrainingPlan {
    __typename
    _id
    title
    desc
    status
    enrolled
    completed
    coverImageUrl(width: 128, height: 128)
  }
`;

const TRAINING_PLAN_LIST_FRAGMENT = gql`
  fragment TrainingPlanListFragment on TrainingPlanList {
    __typename
    _id
    featuredTrainingPlanIds
    trainingPlans(statuses: [DRAFT, PUBLIC, PRIVATE]) {
      ...TrainingPlanFragment
    }
  }
  ${TRAINING_PLAN_FRAGMENT}
`;

export const GET_TRAINING_PLAN_LIST = gql`
  query GetTrainingPlans($trainerId: String) {
    trainingPlanList(trainerId: $trainerId) {
      ...TrainingPlanListFragment
    }
  }
  ${TRAINING_PLAN_LIST_FRAGMENT}
`;

export const CREATE_TRAINING_PLAN = gql`
  mutation CreateTrainingPlan($input: TrainingPlanInput!) {
    createTrainingPlan(input: $input) {
      _id
    }
  }
`;

export const UPDATE_TRAINING_PLAN_LIST = gql`
  mutation UpdateTrainingPlanList($id: ID!, $input: TrainingPlanListInput!) {
    updateTrainingPlanList(id: $id, input: $input) {
      ...TrainingPlanListFragment
    }
  }
  ${TRAINING_PLAN_LIST_FRAGMENT}
`;

export const DELETE_TRAINING_PLAN = gql`
  mutation DeleteTrainingPlan($id: ID!) {
    deleteTrainingPlan(id: $id) {
      __typename
    }
  }
`;
export const START_TRAINING_PLAN_SESSION = gql`
  mutation StartTrainingPlanSession($trainingPlanId: ID!, $userId: ID) {
    startTrainingPlanSession(trainingPlanId: $trainingPlanId, userId: $userId) {
      __typename
      _id
    }
  }
`;
