import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import {
  MY_TRAINER_SUBSCRIPTION_QUERY,
  CREATE_BILLING_PORTAL_SESSION_MUTATION,
} from '../../graphql/trainer_subscription';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {},
  overview: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  productImage: {
    marginRight: theme.spacing(1),
    height: 48,
    width: 48,
  },
  details: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

const NotSubscribedRow = () => {
  const classes = useStyles();

  return (
    <Box className={classes.overview}>
      <Typography variant="body2" paragraph>
        You're on our Free plan. To publish your app, you need to upgrade your
        account to a paid plan.
      </Typography>
      {config.showSubscriptionPage && (
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          component={RouterLink}
          to="/trainer/checkout"
        >
          See Pricing Info
        </Button>
      )}
    </Box>
  );
};

const SubscriptionRow = ({ subscription }) => {
  const classes = useStyles();
  const showUpgradeLink = !subscription.isProPlusPlan;

  return (
    <Paper variant="outlined">
      <Box className={classes.overview}>
        <div>
          <Typography display="inline" variant="h4" color="textPrimary">
            {subscription.plan.name}
          </Typography>
        </div>
        <Box display="flex" alignItems="center">
          <Typography variant="overline" color="textSecondary">
            {subscription.plan.amount &&
              `$ ${subscription.plan.amount} / ${subscription.plan.interval}`}
          </Typography>
        </Box>
      </Box>
      {showUpgradeLink && config.showSubscriptionPage && (
        <>
          <Divider />
          <Box mt={2} ml={2} mb={2}>
            <Typography variant="body2" color="textSecondary">
              If you want custom domain hosting and oher features, check{' '}
              <a href="https://tempokit.com/pricing">Trainer Pro Plus</a> Plan.
              You can contact us at{' '}
              <a href="mailto:team@tempokit.com">team@tempokit.com</a> to
              upgrade.
            </Typography>
          </Box>
        </>
      )}
    </Paper>
  );
};

const Subscription = ({ className, ...rest }) => {
  const {
    loading: plansLoading,
    error,
    data,
  } = useQuery(MY_TRAINER_SUBSCRIPTION_QUERY);
  const [manageBilling] = useMutation(CREATE_BILLING_PORTAL_SESSION_MUTATION);

  const handleManageBillingClick = async () => {
    try {
      const response = await manageBilling();
      const redirectUrl = response.data.createBillingPortalSession?.url;
      if (!redirectUrl) {
        console.error(`Could not generate redirect url.`);
        console.error(response);
        return;
      }
      window.location = redirectUrl;
    } catch (err) {
      window.alert(err);
    }
  };

  const classes = useStyles();

  if (plansLoading) {
    return <Loading />;
  }
  if (error) return <ServerError message="Failed to load" />;

  const subscription = data.myTrainerSubscription;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Subscription" />
      <Divider />
      <CardContent>
        {subscription && config.showSubscriptionPage ? (
          <>
            <SubscriptionRow subscription={subscription} />
            <Box mt={3}>
              <Button variant="outlined" onClick={handleManageBillingClick}>
                Manage your payment details
              </Button>
            </Box>
          </>
        ) : (
          <NotSubscribedRow />
        )}
      </CardContent>
    </Card>
  );
};

Subscription.propTypes = {
  className: PropTypes.string,
};

export default Subscription;
