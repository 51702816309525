import React, { useState, useEffect } from 'react';
import QueryString from 'query-string';
import { useParams, useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Box,
  Container,
  Tab,
  Tabs,
  Divider,
} from '@mui/material';

import Page from '../Page';
import AppPreview from '../app_preview/AppPreview';
import Onboarding from './Onboarding';
import Nutrition from './Nutrition';
import Chat from './Chat';
import TrainingPlans from './TrainingPlans';
import Posts from './Posts';
import MerchStore from './MerchStore';
import Settings from './Settings';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_APP_INFO } from '../../graphql/app_info';
import { GET_APP_FEATURES_PAGE } from '../../graphql/app_features';

import ServerError from '../error/ServerError';
import Loading from '../shared/Loading';
import { useSnackbar } from 'notistack';
import config from '../../config';

const tabs = [
  { value: 'onboarding', label: 'Onboarding' },
  { value: 'nutrition', label: 'Nutrition' },
  { value: 'posts', label: 'Posts' },
  { value: 'training_plans', label: 'Training Plans' },
  { value: 'merch_store', label: 'Merch Store' },
  { value: 'chat', label: 'Chat' },
  { value: 'settings', label: 'Settings' },
];

const AppFeaturesPage = () => {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;
  const params = useParams();
  const history = useHistory();
  const [iframePreviewRefresh, setIframePreviewRefresh] = useState(1);
  const [currentTab, setCurrentTab] = useState('onboarding');
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    history.push(`/trainer/app_features/${value}`);
  };

  const { enqueueSnackbar } = useSnackbar();
  const [appInfo, setAppInfo] = useState(null);
  const input = trainerId
    ? { variables: { trainerId, screen: currentTab.toUpperCase() } }
    : {};
  const { error, loading, data } = useQuery(GET_APP_FEATURES_PAGE, input);
  const [updateAppInfo] = useMutation(UPDATE_APP_INFO, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });
  const saveAppInfo = () => {
    if (!appInfo) {
      return;
    }
    enqueueSnackbar('Saving...', {
      autoHideDuration: 2000,
    });
    updateAppInfo({
      variables: {
        id: appInfo._id,
        input: {
          title: appInfo.title,
          supportEmail: appInfo.supportEmail,
          icon: appInfo.icon,
          onboardingImages: appInfo.onboardingImages,
          mainColor: appInfo.mainColor,
          secondaryColor: appInfo.secondaryColor,
          appThemeId: appInfo.appThemeId,
          appWorkoutPlayerSlug: appInfo.appWorkoutPlayerSlug,
          legalName: appInfo.legalName,
          blogUrl: appInfo.blogUrl,
          feedbackUrl: appInfo.feedbackUrl,
          supportUrl: appInfo.supportUrl,
          termsUrl: appInfo.termsUrl,
          privacyPolicyUrl: appInfo.privacyPolicyUrl,
          faqUrl: appInfo.faqUrl,
          aboutUrl: appInfo.aboutUrl,
          merchStoreUrl: appInfo.merchStoreUrl,
          nutritionEnabled: appInfo.nutritionEnabled,
          postsEnabled: appInfo.postsEnabled,
          communityPostsEnabled: appInfo.communityPostsEnabled,
          postRepliesEnabled: appInfo.postRepliesEnabled,
          chatEnabled: appInfo.chatEnabled,
          trainingPlansEnabled: appInfo.trainingPlansEnabled,
          merchStoreEnabled: appInfo.merchStoreEnabled,
        },
      },
    });
    setTimeout(() => {
      setIframePreviewRefresh(iframePreviewRefresh + 1);
    }, 2000);
  };

  useEffect(() => {
    setAppInfo(data?.appInfo);
  }, [data]);
  useEffect(() => {
    if (appInfo?.autoSave === true) {
      saveAppInfo();
      setAppInfo({ ...appInfo, autoSave: false });
    }
  }, [appInfo, appInfo?.autoSave]);
  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading || !appInfo) {
    return <Loading />;
  }
  return (
    <Page title={`App Screens : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          App Screens
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Box mt={3}>
              <Tabs
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={currentTab}
                variant="scrollable"
                textColor="secondary"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Box>
            <Divider />

            <Box mt={3}>
              {currentTab === 'onboarding' && (
                <Onboarding
                  appInfo={appInfo}
                  setAppInfo={setAppInfo}
                  saveAppInfo={saveAppInfo}
                />
              )}
              {currentTab === 'nutrition' && (
                <Nutrition
                  appInfo={appInfo}
                  setAppInfo={setAppInfo}
                  saveAppInfo={saveAppInfo}
                />
              )}
              {currentTab === 'posts' && (
                <Posts
                  appInfo={appInfo}
                  setAppInfo={setAppInfo}
                  saveAppInfo={saveAppInfo}
                />
              )}
              {currentTab === 'training_plans' && (
                <TrainingPlans
                  appInfo={appInfo}
                  setAppInfo={setAppInfo}
                  saveAppInfo={saveAppInfo}
                />
              )}
              {currentTab === 'chat' && (
                <Chat
                  appInfo={appInfo}
                  setAppInfo={setAppInfo}
                  saveAppInfo={saveAppInfo}
                />
              )}
              {currentTab === 'merch_store' && (
                <MerchStore
                  appInfo={appInfo}
                  setAppInfo={setAppInfo}
                  saveAppInfo={saveAppInfo}
                />
              )}
              {currentTab === 'settings' && (
                <Settings
                  appInfo={appInfo}
                  setAppInfo={setAppInfo}
                  saveAppInfo={saveAppInfo}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <AppPreview
              key={iframePreviewRefresh}
              trainerId={trainerId}
              screen={currentTab.toUpperCase()}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AppFeaturesPage;
