import {
  Button,
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Image as ImageIcon } from 'react-feather';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LinkIcon from '@mui/icons-material/LinkOutlined';
import Label from '../shared/Label';
import { Link as RouterLink } from 'react-router-dom';
import { subdomainHost } from '../../config';

const ProductList = ({ products, onDelete }) => {
  return (
    <Card>
      <Box minWidth={700}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>CreatedAt</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(products || []).map((product) => {
              let url = `/trainer/products/${product._id}`;
              let mediaComponent;
              if (product.coverImageUrl) {
                mediaComponent = (
                  <Box m={1}>
                    <img
                      alt="Product"
                      src={product.coverImageUrl}
                      style={{ width: 48, height: 48 }}
                    />
                  </Box>
                );
              } else {
                mediaComponent = (
                  <Box m={1} bgcolor="background.dark">
                    <ImageIcon size={48} />
                  </Box>
                );
              }
              const media = (
                <Link to={url} component={RouterLink}>
                  {mediaComponent}
                </Link>
              );
              let status = <Label color="warning">Draft</Label>;
              if (product.status === 'PUBLIC') {
                status = <Label color="secondary">Free</Label>;
              } else if (product.status === 'PREMIUM') {
                status = <Label color="primary">Premium</Label>;
              }

              return (
                <TableRow hover key={product._id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {media}
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={url}
                        variant="h6"
                      >
                        {product.title}
                      </Link>
                    </Box>
                  </TableCell>
                  <TableCell>{status}</TableCell>

                  <TableCell>
                    {new Date(product.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <Button startIcon={<LinkIcon />}>
                      <a
                        href={`https://${product.trainerId}.${subdomainHost}/products/${product._id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Preview
                      </a>
                    </Button>
                    <br />
                    <Button
                      onClick={() => {
                        if (onDelete) {
                          onDelete(product);
                        }
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default ProductList;
