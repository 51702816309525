import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Image as ImageIcon } from 'react-feather';
import displayHelper from '../../utils/displayHelper';
import Loading from '../../components/shared/Loading';
import { GET_WORKOUT_QUERY } from '../../graphql/workout';

import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Alert } from '@mui/material';
import Page from '../Page';
import Header from './Header';
import config from '../../config';

export default function WorkoutDebug({}) {
  const params = useParams();
  const id = params.id;
  const { loading, error, data } = useQuery(GET_WORKOUT_QUERY, {
    variables: { id },
  });

  const workout = data?.workout;

  if (error) {
    return (
      <Container maxWidth={false}>
        <Alert severity="error">
          Error ${error.graphQLErrors.map((e) => e.message)}
        </Alert>
      </Container>
    );
  }
  if (loading || !workout) {
    return <Loading />;
  }

  const intervals = (workout.intervals || []).map((interval, index) => {
    let media = <ImageIcon size={64} />;
    if (interval.exercise.videos && interval.exercise.videos[0]) {
      media = (
        <video
          src={interval.exercise.videos[0].url}
          style={{ marginRight: 8, height: 128 }}
          controls
        />
      );
    } else if (interval.exercise.images && interval.exercise.images[0]) {
      media = (
        <img
          alt="exercise"
          src={interval.exercise.images[0].url}
          style={{ width: 64, height: 64 }}
        />
      );
    }

    return (
      <TableRow key={index} hover>
        <TableCell>
          <ListItem>
            <ListItemAvatar>
              <Box m={1} bgcolor="background.dark">
                {media}
              </Box>
            </ListItemAvatar>
            <ListItemText
              primary={interval.exercise.title ?? ''}
              secondary={interval.exercise.groupName ?? ''}
            />
          </ListItem>
        </TableCell>
        <TableCell>
          <strong>{interval.exercise.reps}</strong>
        </TableCell>
        <TableCell>{interval.round}</TableCell>
        <TableCell>
          <span>
            {displayHelper.secondsToMinutesSeconds(interval.startTime)}
          </span>
        </TableCell>
        <TableCell>
          <span>{displayHelper.secondsToMinutesSeconds(interval.endTime)}</span>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Page title={`Workout Editor : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Header workout={workout} />
        <Card>
          <CardContent>
            <Typography variant="body1">
              Check the order of the exercises and rest times are the expected
              ones.
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">Title</TableCell>
                  <TableCell width="25%">Reps</TableCell>
                  <TableCell width="25%">Round</TableCell>
                  <TableCell width="25%">Start</TableCell>
                  <TableCell width="25%">End</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{intervals}</TableBody>
            </Table>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
