import React from 'react';
import { Image as ImageIcon } from 'react-feather';
import displayHelper from '../../utils/displayHelper';
import Loading from '../../components/shared/Loading';
import AppAndWebPreview from '../../components/app_preview/AppAndWebPreview';

import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

export default function WorkoutPreview({ workout }) {
  if (!workout) {
    return <Loading />;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="body1" paragraph>
          NOTE: Below is a preview which may not have all functionality or look
          like on the mobile phone. Checking the workout on the mobile app on
          your iphone or android is the best way to preview what the customer
          will see.
        </Typography>

        <AppAndWebPreview
          mobilePreviewUrl={workout.mobilePreviewUrl}
          webUrl={workout.webUrl}
        />
      </CardContent>
    </Card>
  );
}
