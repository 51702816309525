import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import _ from 'lodash';
import {
  Drawer,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import converter from 'number-to-words';
import TrainingPlanScheduleDaySquare from './TrainingPlanScheduleDaySquare';
import TrainingPlanWorkoutSearch from './TrainingPlanWorkoutSearch';

const CREATE_WORKOUT = gql`
  mutation CreateWorkout($input: WorkoutInput!) {
    createWorkout(input: $input) {
      _id
      title
      coverImageUrl
    }
  }
`;

const createEventFromWorkout = function (workout, day) {
  return {
    objectId: workout.objectId ?? workout._id,
    eventType: 0,
    day: day,
  };
};

const MAX_MONTH = 4;

const TrainingPlanScheduleEditor = ({ trainingPlan, onSave }) => {
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [addWorkoutOpen, setAddWorkoutOpen] = useState(false);

  const [createWorkout] = useMutation(CREATE_WORKOUT);
  const handleAddWorkoutClose = () => {
    setAddWorkoutOpen(false);
  };
  const handleWorkoutSelected = (workout) => {
    handleAdd(workout, selectedDay);
    setAddWorkoutOpen(false);
  };
  const handleCreateWorkout = async () => {
    const title = prompt('Enter in a Workout Title', '');

    if (!title || title?.trim().length === 0) {
      alert('You need to enter in a title');
      return;
    }

    try {
      const r = await createWorkout({
        variables: { input: { title } },
      });

      const workout = r.data?.createWorkout;
      if (!workout) {
        setAddWorkoutOpen(false);
        alert('Error creating workout.');
        return;
      }
      handleAdd(workout, selectedDay);
    } catch (e) {
      alert(e.message);
      return;
    }

    setAddWorkoutOpen(false);
  };

  const handleOpenAddWorkoutClick = (event, day) => {
    event.preventDefault();
    setSelectedDay(day);
    setAddWorkoutOpen(true);
  };

  const handleRemove = (event) => {
    let changes = { ...trainingPlan };
    changes.events = (trainingPlan.events || []).filter(
      (e) => event._id !== e._id
    );
    onSave(changes);
  };
  const handleAdd = (workout, day) => {
    let changes = { ...trainingPlan };
    const event = createEventFromWorkout(workout, selectedDay);

    if (changes.events && changes.events.length > 0) {
      changes.events = [...changes.events, event];
    } else {
      changes.events = [event];
    }
    onSave(changes);
  };
  const handleMove = (workout, day) => {
    const changes = _.cloneDeep(trainingPlan);
    changes.events = changes.events ?? [];
    changes.events = changes.events.map((i) => {
      if (i._id === workout._id) {
        return {
          ...i,
          day,
        };
      }
      return i;
    });
    onSave(changes);
  };

  const events = trainingPlan && trainingPlan.events ? trainingPlan.events : [];

  let days = [];
  let start = selectedMonth * 7 * 4 + 1;
  let end = start + 7 * 4 - 1;

  console.log(`start=${start} end=${end}`);
  for (let day = start; day <= end; day++) {
    days.push(day);
  }

  const daySquares = days.map((day) => {
    let dayEvents = (events || []).filter(function (i) {
      return i.day === day;
    });
    return (
      <div key={day} className="schedule-board-box">
        <TrainingPlanScheduleDaySquare
          key={day}
          day={day}
          trainingPlan={trainingPlan}
          events={dayEvents}
          onRemove={handleRemove}
          onAdd={handleAdd}
          onOpenAddWorkout={(event) => {
            handleOpenAddWorkoutClick(event, day);
          }}
          onMove={handleMove}
        />
      </div>
    );
  });

  const handlePrevMonth = () => {
    if (selectedMonth === 0) {
      return;
    }

    setSelectedMonth(selectedMonth - 1);
  };

  const handleNextMonth = () => {
    if (selectedMonth > MAX_MONTH) {
      return;
    }

    setSelectedMonth(selectedMonth + 1);
  };

  const paginationComponent = (
    <Box mt={2}>
      <Button onClick={handlePrevMonth}>Back</Button>
      <Typography variant="caption">{` ${converter.toOrdinal(
        selectedMonth + 1
      )} Month `}</Typography>
      <Button onClick={handleNextMonth}>Next</Button>
    </Box>
  );
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mb={3}>
              <Typography variant="h3" color="textPrimary" gutterBottom>
                Schedule
              </Typography>
              {paginationComponent}
            </Box>
            <Box mb={3}>
              <div className="schedule-board">{daySquares}</div>
            </Box>
            {paginationComponent}
          </Grid>
        </Grid>
      </CardContent>
      <Drawer
        sx={{ zIndex: 2000 }}
        anchor="bottom"
        open={addWorkoutOpen}
        onClose={handleAddWorkoutClose}
      >
        <Box p={3}>
          <TrainingPlanWorkoutSearch
            onSelect={handleWorkoutSelected}
            onAddNew={handleCreateWorkout}
            onClose={handleAddWorkoutClose}
          />
        </Box>
      </Drawer>
    </Card>
  );
};

export default TrainingPlanScheduleEditor;
