import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Tab, Tabs } from '@mui/material';
import { Alert } from '@mui/material';
import { useQuery, useMutation, gql } from '@apollo/client';
import Page from '../Page';
import Header from './Header';
import TrainingPlanAnalytics from './TrainingPlanAnalytics';
import TrainingPlanDetailsEditor from './TrainingPlanDetailsEditor';
import TrainingPlanScheduleEditor from './TrainingPlanScheduleEditor';
import TrainingPlanCollectionEditor from './TrainingPlanCollectionEditor';
import TrainingPlanExtraFiles from './TrainingPlanExtraFiles';
import TrainingPlanPreview from './TrainingPlanPreview';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import config from '../../config';

const tabs = [
  { value: 'details', label: 'Details' },
  { value: 'workouts', label: 'Workouts' },
  { value: 'files', label: 'Included Files' },
  { value: 'analytics', label: 'Analytics' },
  { value: 'preview', label: 'Preview' },
  //  { value: 'weeks', label: 'Week Descriptions' },
];

const TRAINING_PLAN_FRAGMENT = gql`
  fragment TrainingPlanFragment on TrainingPlan {
    __typename
    _id
    title
    desc
    tags
    userId
    files {
      _id
      url
      imageUrl
      label
      sizeInBytes
    }
    coverImages {
      __typename
      aspect
      url
      w
      h
    }
    hasSchedule
    level
    status
    events {
      __typename
      _id
      objectId
      eventType
      title
      desc
      day
      weekNumber
      weekday
      coverImageUrl
    }
    eventCount
    weeks {
      __typename
      weekNumber
      title
      desc
      events {
        __typename
        _id
        objectId
        eventType
        title
        desc
        day
        weekNumber
        weekday
        coverImageUrl
      }
    }
    mobilePreviewUrl
    webUrl
  }
`;
const GET_TRAINING_PLAN_QUERY = gql`
  query GetTrainingPlanQuery($id: ID!) {
    trainingPlan(id: $id) {
      ...TrainingPlanFragment
    }
  }
  ${TRAINING_PLAN_FRAGMENT}
`;

const SAVE_TRAINING_PLAN_MUTATION = gql`
  mutation UpdateTrainingPlan($id: ID!, $input: TrainingPlanInput!) {
    updateTrainingPlan(id: $id, input: $input) {
      ...TrainingPlanFragment
    }
  }
  ${TRAINING_PLAN_FRAGMENT}
`;

const TrainingPlanEditor = () => {
  const params = useParams();
  const [previewRefresh, setPreviewRefresh] = useState(1);
  const handleSaveCompleted = (data) => {
    setTrainingPlan(data.updateTrainingPlan);
  };

  const {
    loading: dataLoading,
    error,
    data,
  } = useQuery(GET_TRAINING_PLAN_QUERY, {
    variables: { id: params.id },
  });
  const [saveTrainingPlan, { error: saveError }] = useMutation(
    SAVE_TRAINING_PLAN_MUTATION,
    {
      onCompleted: handleSaveCompleted,
      refetchQueries: { query: GET_TRAINING_PLAN_QUERY },
    }
  );

  const [trainingPlan, setTrainingPlan] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState('details');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleSave = async (trainingPlan) => {
    enqueueSnackbar('Saving...', { autoHideDuration: 1000 });

    console.debug(trainingPlan);
    const changes = {
      title: trainingPlan.title,
      desc: trainingPlan.desc,
      tags: trainingPlan.tags,
      coverImages: trainingPlan.coverImages,
      hasSchedule: trainingPlan.hasSchedule,
      level: parseInt(trainingPlan.level),
      status: parseInt(trainingPlan.status),
      files: trainingPlan.files.map((f) => ({
        url: f.url,
        imageUrl: f.imageUrl,
        label: f.label,
        sizeInBytes: f.sizeInBytes,
      })),
      weeks: trainingPlan.weeks.map((w) => ({
        weekNumber: w.weekNumber,
        title: w.title,
        desc: w.desc,
      })),
      events: trainingPlan.events.map((e) => ({
        _id: e._id,
        objectId: e.objectId,
        eventType: e.eventType || 0,
        day: e.day,
      })),
    };

    try {
      await saveTrainingPlan({
        variables: { id: trainingPlan._id, input: changes },
      });

      setTimeout(() => {
        setPreviewRefresh(previewRefresh + 1, 2000);
      });
    } catch (e) {
      console.error(e);
      alert(e.message);
    }
  };

  useEffect(() => {
    if (data?.trainingPlan) {
      setTrainingPlan(data.trainingPlan);
    }
  }, [data]);

  if (dataLoading) return <Loading />;
  if (error || saveError) return <ServerError message="Failed to load" />;
  if (!data) return <ServerError message="No Data" />;

  if (!trainingPlan) {
    return <Alert severity="error">No training plan</Alert>;
  }

  return (
    <Page title={`Training Plan Editor : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Header trainingPlan={trainingPlan} />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === 'details' && (
            <TrainingPlanDetailsEditor
              key="details"
              trainingPlan={trainingPlan}
              onSave={handleSave}
            />
          )}
          {currentTab === 'workouts' && trainingPlan.hasSchedule && (
            <TrainingPlanScheduleEditor
              key="workouts"
              trainingPlan={trainingPlan}
              onSave={handleSave}
            />
          )}
          {currentTab === 'workouts' && !trainingPlan.hasSchedule && (
            <TrainingPlanCollectionEditor
              key="workouts"
              trainingPlan={trainingPlan}
              onSave={handleSave}
            />
          )}
          {currentTab === 'files' && (
            <TrainingPlanExtraFiles
              key="files"
              trainingPlan={trainingPlan}
              onSave={handleSave}
            />
          )}
          {currentTab === 'preview' && (
            <TrainingPlanPreview key="preview" trainingPlan={trainingPlan} />
          )}
          {currentTab === 'analytics' && (
            <TrainingPlanAnalytics
              key="analytics"
              trainingPlan={trainingPlan}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default TrainingPlanEditor;
