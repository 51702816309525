import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ExerciseNotesTable(props) {
  const classes = useStyles({});
  const rows = props.exerciseNotes || [];
  if (rows.length === 0) {
    return <></>;
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Exercise</TableCell>
            <TableCell align="right">Weight</TableCell>
            <TableCell align="right">Duration</TableCell>
            <TableCell align="right">Round</TableCell>
            <TableCell align="right">Set</TableCell>
            <TableCell align="right">Reps</TableCell>
            <TableCell align="right">Sets</TableCell>
            <TableCell align="right">Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                {row.exerciseTitle || row.exercise?.title || row.exerciseId}
              </TableCell>
              <TableCell align="right">
                {row.weight} {row.weightUnit}
              </TableCell>
              <TableCell align="right">
                {row.duration ? `${row.duration} s` : `-`}
              </TableCell>
              <TableCell align="right">{row.roundIndex}</TableCell>
              <TableCell align="right">{row.setIndex}</TableCell>
              <TableCell align="right">{row.reps}</TableCell>
              <TableCell align="right">{row.sets}</TableCell>
              <TableCell align="right">{row.comments}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
