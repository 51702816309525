import React, { useEffect } from 'react';
import _ from 'lodash';
import ItemTypes from '../../constants/AppConstants';
import {
  Tooltip,
  TextField,
  Select,
  Typography,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  TableCell,
  IconButton,
} from '@mui/material';
import { Image as ImageIcon } from 'react-feather';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { DragSource, DropTarget } from 'react-dnd';

const exerciseSource = {
  beginDrag: function (props) {
    return {
      id: props.workoutExercise._id,
      index: props.index,
    };
  },

  isDragging: function (props, monitor) {
    // If your component gets unmounted while dragged
    // (like a card in Kanban board dragged between lists)
    // you can implement something like this to keep its
    // appearance dragged:
    return monitor.getItem().id === props.id;
  },
};

const collectDragSource = function (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const collectDropTarget = function (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
};

const exerciseTarget = {
  drop: function (targetProps, monitor, component) {
    const sourceIndex = monitor.getItem().index;
    const targetIndex = targetProps.index;

    if (sourceIndex === targetIndex) {
      return;
    }

    // Time to actually perform the action
    targetProps.onMove(sourceIndex, targetIndex);

    // Mutating the monitor item! Generally better to avoid mutations.
    // But good here for performance, to avoid expensive index searches.
    monitor.getItem().index = targetIndex;
  },
};

const shortTextFieldNumberWidth = 72;
const textFieldNumberWidth = 84;

const WorkoutExerciseItem = ({
  index,
  workoutExercise,
  onUpdate,
  onMove,
  onRemove,
  onDuplicate,
  onSelectedExerciseEntry,
  onExerciseEntryPreview,
  isSelected,
  ...props
}) => {
  const isOneSet = workoutExercise.sets === 1;

  useEffect(() => {
    if (isOneSet && !restBetweenSetsDurationRef.current.disabled) {
      restBetweenSetsDurationRef.current.disabled = true;
    } else if (!!restBetweenSetsDurationRef.current.disabled) {
      restBetweenSetsDurationRef.current.disabled = false;
    }
  }, [isOneSet]);

  const setsRef = React.createRef();
  const restBetweenSetsDurationRef = React.createRef();
  const restAfterExerciseDurationRef = React.createRef();

  const handleChangeInt = (e) => {
    const changes = _.cloneDeep(workoutExercise);
    changes[e.target.name] = parseInt(e.target.value);
    if (e.target.name === 'sets' && parseInt(e.target.value) === 1) {
      changes.restBetweenSetsDuration = 0;
    }
    onUpdate(changes);
  };

  const handleChange = (e) => {
    const changes = _.cloneDeep(workoutExercise);
    if (e.target.name === 'isTimed') {
      changes[e.target.name] = !!e.target.checked;
    } else {
      changes[e.target.name] = e.target.value;
    }
    onUpdate(changes);
  };

  const handleRemove = (e) => {
    e.preventDefault();
    onRemove(index);
  };

  const handleDuplicate = (e) => {
    e.preventDefault();
    onDuplicate(index);
  };

  const connectDragSource = props.connectDragSource;
  const connectDropTarget = props.connectDropTarget;

  if (!workoutExercise) {
    return <tr />;
  }

  let media = <ImageIcon size={72} />;

  if (workoutExercise.images && workoutExercise.images[0]) {
    media = (
      <img
        alt={workoutExercise.title}
        src={workoutExercise.images[0].url}
        style={{ width: 72, height: 72, objectFit: 'cover' }}
      />
    );
  }

  const edit = (
    <tr>
      <TableCell padding="checkbox">
        <Checkbox
          checked={!!isSelected}
          value={!!isSelected}
          onChange={(event) =>
            onSelectedExerciseEntry(event, workoutExercise._id)
          }
        />
      </TableCell>

      <TableCell style={{ verticalAlign: 'top' }}>
        <Box mb={1}>{media}</Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography variant="h6" color="textPrimary">
              {workoutExercise.title}
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  //workoutExercise isn't exactly an exercise
                  // mismatched _id
                  onExerciseEntryPreview(workoutExercise);
                }}
              >
                <OpenInBrowserIcon />
              </IconButton>
            </Typography>
            {workoutExercise.groupName && (
              <Typography variant="caption" color="textSecondary">
                {workoutExercise.groupName}
              </Typography>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="outlined"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 1000 } }}
          name="duration"
          onChange={handleChangeInt}
          value={workoutExercise.duration ?? ''}
          style={{ width: textFieldNumberWidth }}
        />
        <br />
        <Tooltip
          title={
            'Uncheck this if you want the player to stay on this exercise until the user clicks next. If unsure, keep it checked.'
          }
          aria-label="timed"
        >
          <FormControlLabel
            control={
              <Checkbox
                value={!!workoutExercise.isTimed}
                checked={!!workoutExercise.isTimed}
                onChange={handleChange}
                name="isTimed"
              />
            }
            label="Timed"
          />
        </Tooltip>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="outlined"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 100 } }}
          name="reps"
          onChange={handleChangeInt}
          value={workoutExercise.reps ?? ''}
          style={{ width: shortTextFieldNumberWidth }}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="outlined"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 100 } }}
          name="sets"
          ref={setsRef}
          onChange={handleChangeInt}
          value={workoutExercise.sets ?? ''}
          style={{ width: shortTextFieldNumberWidth }}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="outlined"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 1000 } }}
          name="weight"
          onChange={handleChangeInt}
          value={workoutExercise.weight ?? ''}
          style={{ width: textFieldNumberWidth }}
        />
        <Select
          variant="outlined"
          native
          name="weightUnit"
          onChange={handleChange}
          value={workoutExercise.weightUnit ?? ''}
        >
          <option value="">-</option>
          <option value="lbs">lbs</option>
          <option value="kg">kg</option>
        </Select>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="outlined"
          name="restBetweenSetsDuration"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 1000 } }}
          ref={restBetweenSetsDurationRef}
          disabled={isOneSet}
          onChange={handleChangeInt}
          value={workoutExercise.restBetweenSetsDuration ?? ''}
          style={{ width: textFieldNumberWidth }}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="outlined"
          name="restAfterExerciseDuration"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 1000 } }}
          ref={restAfterExerciseDurationRef}
          onChange={handleChangeInt}
          value={workoutExercise.restAfterExerciseDuration ?? ''}
          style={{ width: textFieldNumberWidth }}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <Button onClick={handleRemove} startIcon={<DeleteIcon />}>
          Remove
        </Button>
        <br />
        <Button onClick={handleDuplicate} startIcon={<CopyIcon />}>
          Copy
        </Button>
      </TableCell>
    </tr>
  );

  return connectDragSource(connectDropTarget(edit));
};

export default DragSource(
  ItemTypes.EXERCISE,
  exerciseSource,
  collectDragSource
)(
  DropTarget(
    ItemTypes.EXERCISE,
    exerciseTarget,
    collectDropTarget
  )(WorkoutExerciseItem)
);
