const { gql } = require('@apollo/client');

//
// Exercise
//

const EXERCISE_FRAGMENT = gql`
  fragment ExerciseFragment on Exercise {
    __typename
    _id
    title
    desc
    equipment
    tags
    createdAt
    images {
      url
    }
    videos {
      url
    }
    howtoVideos {
      url
    }
    howtoImages {
      url
    }
    youtubeVideoUrl
  }
`;

export const GET_EXERCISE = gql`
  query GetExercise($id: ID!) {
    exercise(id: $id) {
      ...ExerciseFragment
    }
  }
  ${EXERCISE_FRAGMENT}
`;

export const GET_EXERCISES = gql`
  query GetExercises($input: ExercisesQueryInput) {
    exercises(input: $input) {
      ...ExerciseFragment
    }
  }
  ${EXERCISE_FRAGMENT}
`;

export const DELETE_EXERCISE = gql`
  mutation DeleteExercise($id: ID!) {
    deleteExercise(id: $id) {
      id
    }
  }
`;

export const CREATE_EXERCISE = gql`
  mutation CreateExercise($input: ExerciseInput!) {
    createExercise(input: $input) {
      ...ExerciseFragment
    }
  }
  ${EXERCISE_FRAGMENT}
`;

export const UPDATE_EXERCISE = gql`
  mutation UpdateExercise($id: ID!, $input: ExerciseInput!) {
    updateExercise(id: $id, input: $input) {
      ...ExerciseFragment
    }
  }
  ${EXERCISE_FRAGMENT}
`;

export const SEARCH_EXERCISES = gql`
  query SearchExercises($input: ExercisesQueryInput!) {
    exercises(input: $input) {
      ...ExerciseFragment
    }
  }
  ${EXERCISE_FRAGMENT}
`;
