/*eslint no-undef: 0*/

var helper = {
  urlRegex:
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i, //eslint-disable-line

  isValidEmail: function (email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(email);
  },

  updateUrl: function (objectId) {
    if (objectId) {
      var url = new URL(window.location.href);

      var parts = url.pathname.split('/');
      var id = parts.pop();

      if (id !== objectId) {
        parts.push(objectId);
        var newPath = parts.join('/');
        window.history.replaceState({}, null, newPath);
      }
    }
  },

  extractId: function (url) {
    const lastPart = url.split('/').pop();
    const extIndex = lastPart.indexOf('.');

    if (extIndex > 0) {
      return lastPart.substring(0, extIndex);
    } else {
      return lastPart;
    }
  },

  extractYoutubeVideoIdFromYoutubeUrl: function (youtubeVideoUrl) {
    // https://www.youtube.com/watch?v=BksRnijF9tA
    if (youtubeVideoUrl && youtubeVideoUrl !== '') {
      // https://stackoverflow.com/a/8260383/337493
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/; //eslint-disable-line
      var match = youtubeVideoUrl.match(regExp);
      return match && match[7].length === 11 ? match[7] : null;
    } else {
      return null;
    }
  },

  extractYoutubeThumbUrl: function (youtubeVideoUrl) {
    const youtubeVideoId =
      this.extractYoutubeVideoIdFromYoutubeUrl(youtubeVideoUrl);
    if (!youtubeVideoId) {
      return null;
    }

    // maxresdefault.jpg is preferred for 1280x720 images but they don't exist for all youtube images
    return `https://img.youtube.com/vi/${youtubeVideoId}/hqdefault.jpg`;
  },
};

export function getRedirectQueryParameter(location) {
  const queryParamsString = location.search.substring(1); // remove the "?" at the start
  const searchParams = new URLSearchParams(queryParamsString);
  const redirect = searchParams.get('redirect') || '/';
  return redirect;
}

export default helper;
