import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Card,
  CardHeader,
  Typography,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CommentAdd from '../shared/CommentAdd';
import Comment from '../shared/Comment';
import { CREATE_WORKOUT_SESSION_COMMENT } from '../../graphql/workout_session';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import ExerciseNotesTable from '../users/ExerciseNotesTable';

const WorkoutSessionCard = ({ workoutSession }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createComment] = useMutation(CREATE_WORKOUT_SESSION_COMMENT, {
    onError: (error) => {
      enqueueSnackbar(`Error saving comment: ${error.message}`, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      if (!data.commentWorkoutSession) {
        return;
      }
      // TODO change to add to page vs reload
      window.location.reload();
    },
  });

  const handleSubmit = (body) => {
    enqueueSnackbar('Saving...', {
      autoHideDuration: 1000,
    });
    createComment({
      variables: {
        id: workoutSession._id,
        input: {
          body,
        },
      },
    });
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            alt="Person"
            component={RouterLink}
            src={workoutSession.user?.avatarUrl}
            to={`/trainer/users/${workoutSession.userId}`}
          />
        }
        subheader={
          <Box display="flex" alignItems="center">
            <AccessTimeIcon fontSize="small" />
            <Typography variant="caption" color="textSecondary">
              {workoutSession.completedAt}
            </Typography>
          </Box>
        }
        title={
          <Typography color="textPrimary" variant="h6">
            {workoutSession.title}
          </Typography>
        }
      />
      <Box px={3} pb={2}>
        {workoutSession && (
          <Typography variant="body1" color="textPrimary">
            {workoutSession.photoUrl && (
              <img src={workoutSession.photoUrl} alt={workoutSession.title} />
            )}
            {workoutSession.workoutId && (
              <Box mb={2}>
                <RouterLink
                  to={`/trainer/workouts/${workoutSession.workoutId}`}
                >
                  {workoutSession.title}
                </RouterLink>
              </Box>
            )}

            {workoutSession.feel && <Box mb={2}>{workoutSession.feel}</Box>}
            {workoutSession.notes && <Box mb={2}>{workoutSession.notes}</Box>}

            {workoutSession.exerciseNotes && (
              <Box mb={2}>
                <ExerciseNotesTable
                  exerciseNotes={workoutSession.exerciseNotes}
                />
              </Box>
            )}
          </Typography>
        )}

        {workoutSession && (
          <>
            {workoutSession.comments.map((comment) => (
              <Comment comment={comment} key={comment.id} />
            ))}
            <Box my={2}>
              <Divider />
            </Box>
            <CommentAdd onSubmit={handleSubmit} />
          </>
        )}
      </Box>
    </Card>
  );
};

export default WorkoutSessionCard;
