import { useSnackbar } from 'notistack';
import { useParams, Link as RouterLink } from 'react-router-dom';
import React from 'react';
import Loading from '../../components/shared/Loading';
import Label from '../../components/shared/Label';
import { useMutation, useQuery, gql } from '@apollo/client';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  FormControlLabel,
  Avatar,
  Button,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  colors,
  Container,
  Box,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';

import Page from '../Page';

const useStyles = makeStyles((theme) => ({
  cover: {
    position: 'relative',
    height: 110,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:before': {
      position: 'absolute',
      content: '" "',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage:
        'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)',
    },
    '&:hover': {
      '& $changeButton': {
        visibility: 'visible',
      },
    },
  },
  changeButton: {
    visibility: 'hidden',
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: colors.blueGrey[900],
    color: theme.palette.common.white,
    [theme.breakpoints.down('lg')]: {
      top: theme.spacing(3),
      bottom: 'auto',
    },
    '&:hover': {
      backgroundColor: colors.blueGrey[900],
    },
  },
  avatar: {
    border: `2px solid ${theme.palette.common.white}`,
    height: 120,
    width: 120,
    top: -60,
    left: theme.spacing(3),
    position: 'absolute',
  },
  action: {
    marginLeft: theme.spacing(1),
  },
}));

const GET_TRAINER_QUERY = gql`
  query GetTrainer($id: ID!) {
    trainer(id: $id) {
      _id
      avatarUrl
      name
      email
      link
      createdAt

      createdWorkoutCount
      createdExerciseCount

      totalSubscriberCount
      totalAdminSubscriberCount
      totalAppleSubscriberCount
      totalGoogleSubscriberCount
      totalStripeSubscriberCount
    }

    appInfo(trainerId: $id) {
      userId
      title
      supportEmail
      appThemeId
      buildNumber

      icon {
        url
      }

      onboardingImages {
        url
        w
        h
      }

      appleAppId
      appleBundleId
      googleAppId

      appleAppStoreLink
      googlePlayStoreLink

      blogUrl
      supportUrl
      termsUrl
      privacyPolicyUrl
      aboutUrl

      postsEnabled
      communityPostsEnabled
      postRepliesEnabled
      nutritionEnabled
    }

    appChecklist(trainerId: $id) {
      hasRequiredWorkoutCount
      hasRequiredTrainingPlanCount

      hasAppTitle
      hasAppTheme
      hasTrainerSubscription
      hasPayoutAccount
      hasSubscriptionPlans

      hasAppStoreIntegrations

      isComplete
    }

    appBuilds(input: { trainerId: $id }) {
      buildStatus
      buildUrl
      url
      version
      platform
      createdAt
    }

    trainerSubscription(trainerId: $id) {
      trainerId
      vendor
      stripeCustomerId
      stripePlanId
      stripeSubscriptionId
      stripeSubscriptionUrl
      createdAt
      expiresAt
      plan {
        name
        desc
      }
    }

    trainerPayoutAccount(trainerId: $id) {
      trainerId
      stripeConnectAccountId
    }

    trainerReferrals(input: { referrerUserId: $id }) {
      _id
      name
      createdAt
      hasActiveTrainerSubscription
    }

    subscriptionProducts(trainerId: $id) {
      appleMonthSubscriptionProductId
      appleYearSubscriptionProductId

      googleMonthSubscriptionProductId
      googleYearSubscriptionProductId

      stripeMonthSubscriptionPriceId
      stripeYearSubscriptionPriceId
    }

    appStoreIntegrations(trainerId: $id) {
      googleAppId
      hasGoogleServiceAccountCredentials

      appleAppId
      appleBundleId
      appleIapSharedSecret
      appleAppStoreConnectVendorId
      appleAppStoreConnectIssuerId
      appleAppStoreConnectKeyId
      hasAppleAppStoreConnectPrivateKey
    }

    salesPage(trainerId: $id) {
      url
      completionRedirectUrl
    }

    webPage(trainerId: $id) {
      slug
      url
    }

    verifyAppleAppStoreIntegration(trainerId: $id) {
      status
      message
      versions {
        versionString
        appStoreState
      }
      inAppPurchases {
        productId
        state
      }
    }

    posts(input: { trainerId: $id, limit: 10 }) {
      _id
      title
      status
      createdAt
    }

    trainingPlans(input: { trainerId: $id, limit: 10 }) {
      _id
      title
      status
      createdAt
    }

    myCreatedWorkouts(input: { trainerId: $id, limit: 10 }) {
      _id
      title
      status
      createdAt
    }

    users(input: { trainerId: $id, limit: 10 }) {
      _id
      name
      createdAt
      subscriptions(trainerId: $id) {
        _id
        vendor
        createdAt
      }
    }
  }
`;
const PUBLISH_APP_MUTATION = gql`
  mutation PublishAppMutation($trainerId: ID) {
    publishApp(trainerId: $trainerId) {
      status
      message
    }
  }
`;
const PUBLISH_DEMO_APP_MUTATION = gql`
  mutation PublishDemoAppMutation($trainerId: ID) {
    publishDemoApp(trainerId: $trainerId) {
      status
      message
    }
  }
`;

export default function TrainerShow() {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading: dataLoading,
    error,
    data,
  } = useQuery(GET_TRAINER_QUERY, {
    variables: { id: params.id },
  });
  const [publishApp, { data: publishResponseData }] =
    useMutation(PUBLISH_APP_MUTATION);
  const [publishDemoApp, { data: publishDemoResponseData }] = useMutation(
    PUBLISH_DEMO_APP_MUTATION
  );

  const handlePublishDemoApp = async (e) => {
    e.preventDefault();
    enqueueSnackbar('Publishing Demo App...', { autoHideDuration: 2000 });
    try {
      await publishDemoApp({ variables: { trainerId: params.id } });
    } catch (err) {
      alert(err.message);
    }
  };

  const handlePublish = async (e) => {
    e.preventDefault();
    enqueueSnackbar('Publishing...', { autoHideDuration: 2000 });
    try {
      await publishApp({ variables: { trainerId: params.id } });
    } catch (err) {
      alert(err.message);
    }
  };

  if (dataLoading) {
    return <Loading />;
  }

  if (!data) {
    return <Loading />;
  }

  const trainer = data.trainer;
  const appChecklist = data.appChecklist;
  const appBuilds = data.appBuilds;
  const appInfo = data.appInfo || {};
  const trainerSubscription = data.trainerSubscription;
  const trainerPayoutAccount = data.trainerPayoutAccount;
  const trainerReferrals = data.trainerReferrals;
  const subscriptionProducts = data.subscriptionProducts;
  const appStoreIntegrations = data.appStoreIntegrations;
  const trainingPlans = data.trainingPlans;
  const workouts = data.myCreatedWorkouts;
  const posts = data.posts;
  const users = data.users;
  const salesPage = data.salesPage;
  const webPage = data.webPage;

  const hasAddedAppleIap =
    data.verifyAppleAppStoreIntegration?.inAppPurchases?.length === 0
      ? 'Not setup'
      : '';
  const appleAppVersions = data.verifyAppleAppStoreIntegration?.versions
    ?.map((x) => `${x.versionString} ${x.appStoreState}`)
    .join(', ');

  if (!trainer) {
    return <h1>No trainer</h1>;
  }

  const hasPublishResponse =
    (publishResponseData && publishResponseData.publishApp) ||
    (publishDemoResponseData && publishDemoResponseData.publishDemoApp);

  let alertSeverity = 'info';
  if (publishResponseData) {
    if (publishResponseData.publishApp.status === 'CHECKLIST_NOT_COMPLETED') {
      alertSeverity = 'error';
    }
    if (publishResponseData.publishApp.status === 'ERROR') {
      alertSeverity = 'error';
    }
  }
  if (publishDemoResponseData) {
    if (
      publishDemoResponseData.publishDemoApp.status ===
      'CHECKLIST_NOT_COMPLETED'
    ) {
      alertSeverity = 'error';
    }
    if (publishDemoResponseData.publishDemoApp.status === 'ERROR') {
      alertSeverity = 'error';
    }
  }

  return (
    <Page title={`${trainer.name} Trainer Details | Tempokit`}>
      <div className={classes.cover}> </div>
      <Container maxWidth={false}>
        <Box position="relative" mt={1} display="flex" alignItems="center">
          <Avatar
            alt="Person"
            className={classes.avatar}
            src={trainer.avatarUrl}
          />
          <Box marginLeft="160px">
            <Typography variant="h4" color="textPrimary">
              {trainer.name}
            </Typography>
            <Typography variant="overline" color="textSecondary">
              {trainer._id} |{' '}
              {trainer.email ? (
                <a href={`mailto:${trainer.email}`}>{trainer.email}</a>
              ) : (
                ''
              )}
            </Typography>
          </Box>
          {error && (
            <Alert severity="error">
              {error?.graphQLErrors?.map(({ message }, i) => (
                <span key={i}>{message}</span>
              ))}
            </Alert>
          )}
        </Box>
        <Divider />
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={4} xs={12}>
              <Card>
                <CardHeader title="Links" />
                <CardContent>
                  <List>
                    <ListItem
                      dense
                      button
                      component="a"
                      href={`/trainer/users?trainerId=${trainer._id}`}
                    >
                      <ListItemText primary="User List" />
                    </ListItem>

                    {appInfo?.appleAppStoreLink && (
                      <ListItem
                        dense
                        button
                        component="a"
                        href={appInfo.appleAppStoreLink}
                      >
                        <ListItemText primary="Apple App Store Listing" />
                      </ListItem>
                    )}
                    {appInfo?.googlePlayStoreLink && (
                      <ListItem
                        dense
                        button
                        component="a"
                        href={appInfo.googlePlayStoreLink}
                      >
                        <ListItemText primary="Google Play Store Listing" />
                      </ListItem>
                    )}
                    {trainerSubscription &&
                      trainerSubscription.stripeCustomerId && (
                        <ListItem
                          dense
                          button
                          component="a"
                          href={`https://dashboard.stripe.com/customers/${trainerSubscription.stripeCustomerId}`}
                        >
                          <ListItemText primary="Stripe Customer Dashboard" />
                        </ListItem>
                      )}

                    {webPage && webPage.url && (
                      <ListItem
                        dense
                        button
                        component="a"
                        target="_blank"
                        rel="noreferrer"
                        href={webPage.url}
                      >
                        <ListItemText primary="Web Sales Page" />
                      </ListItem>
                    )}

                    {salesPage && salesPage.url && (
                      <ListItem
                        dense
                        button
                        component="a"
                        target="_blank"
                        rel="noreferrer"
                        href={salesPage.url}
                      >
                        <ListItemText primary="Stripe Checkout Page" />
                      </ListItem>
                    )}
                    {trainer.link && (
                      <ListItem
                        dense
                        button
                        component="a"
                        target="_blank"
                        rel="noreferrer"
                        href={trainer.link}
                      >
                        <ListItemText primary="Social Media Page" />
                      </ListItem>
                    )}

                    <ListItem
                      dense
                      button
                      component="a"
                      target="_blank"
                      rel="noreferrer"
                      href={`https://app.hubspot.com/contacts/9112369/contacts/list/view/all/?query=${trainer.email}`}
                    >
                      <ListItemText primary="Hubspot Marketing Page" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={3} sm={4} xs={12}>
              <Card>
                <CardHeader title="Subscriber Count" />
                <CardContent>
                  <List>
                    <ListItem dense>
                      <ListItemText primary="Total" />
                      {trainer.totalSubscriberCount}
                    </ListItem>
                    <ListItem dense>
                      <ListItemText primary="Admin" />
                      {trainer.totalAdminSubscriberCount}
                    </ListItem>
                    <ListItem dense>
                      <ListItemText primary="Apple" />
                      {trainer.totalAppleSubscriberCount}
                    </ListItem>
                    <ListItem dense>
                      <ListItemText primary="Google" />
                      {trainer.totalGoogleSubscriberCount}
                    </ListItem>
                    <ListItem dense>
                      <ListItemText primary="Stripe" />
                      {trainer.totalStripeSubscriberCount}
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Card>
                <CardHeader title={`Trainer Subscription`} />
                <CardContent>
                  {!trainerSubscription && (
                    <Typography variant="body1">Not Subscribed</Typography>
                  )}
                  {trainerSubscription && (
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={`Subscribed: ${trainerSubscription.createdAt}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`ExpiresAt: ${trainerSubscription.expiresAt}`}
                        />
                      </ListItem>
                      <ListItem>
                        <Typography variant="body1">
                          Subscription:{' '}
                          {trainerSubscription.stripeSubscriptionUrl ? (
                            <a
                              href={trainerSubscription.stripeSubscriptionUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {trainerSubscription.stripeSubscriptionId}
                            </a>
                          ) : (
                            'none'
                          )}
                        </Typography>
                      </ListItem>
                    </List>
                  )}
                </CardContent>
              </Card>

              <Box mt={3}>
                <Card>
                  <CardHeader title={`App Info: ${appInfo.title}`} />
                  <CardContent>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      href={`/trainer/app_preview?trainerId=${appInfo.userId}`}
                      component="a"
                      style={{ marginRight: 16 }}
                    >
                      Preview App
                    </Button>

                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      href={`/trainer/app_infos/${appInfo.userId}`}
                      component="a"
                      style={{ marginRight: 16 }}
                    >
                      Edit App Info
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      href={`/trainer/app_features?trainerId=${appInfo.userId}`}
                      component="a"
                      style={{ marginRight: 16 }}
                    >
                      Edit Onboarding Photos
                    </Button>

                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      href={`/trainer/subscription_plans?trainerId=${appInfo.userId}`}
                      component="a"
                    >
                      Edit Subscription Product Ids
                    </Button>
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={
                            appInfo.icon?.url && (
                              <img
                                src={appInfo.icon?.url}
                                alt={`${appInfo.title}`}
                                width="75"
                                height="75"
                              />
                            )
                          }
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Apple App Id: ${appInfo.appleAppId}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Apple Bundle Id: ${appInfo.appleBundleId}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Build Number: ${appInfo.buildNumber}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Apple Subscription Month Product Id: ${subscriptionProducts.appleMonthSubscriptionProductId}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={` Apple Subscription Year Product Id: ${subscriptionProducts.appleYearSubscriptionProductId}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Google App Id: ${appInfo.googleAppId}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Google Subscription Month Product Id:${subscriptionProducts.googleMonthSubscriptionProductId}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Google Subscription Year Product Id:${subscriptionProducts.googleYearSubscriptionProductId}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Stripe Subscription Month Price Id:${subscriptionProducts.stripeMonthSubscriptionPriceId}`}
                        />{' '}
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Stripe Subscription Year Price Id:${subscriptionProducts.stripeYearSubscriptionPriceId}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Stripe Payout Account: ${trainerPayoutAccount?.stripeConnectAccountId}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`App Theme Id: ${appInfo.appThemeId}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Blog Url: ${appInfo.blogUrl}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Terms Url: ${appInfo.termsUrl}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Privacy Url: ${appInfo.privacyPolicyUrl}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`About Url: ${appInfo.aboutUrl}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Blog Url: ${appInfo.blogUrl}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Support Url: ${appInfo.supportUrl}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`# of Workouts: ${trainer.createdWorkoutCount}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`# of Exercises: ${trainer.createdExerciseCount}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Posts Enabled: ${appInfo.postsEnabled}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Community Posts Enabled: ${appInfo.communityPostsEnabled}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Post Replies Enabled: ${appInfo.postRepliesEnabled}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Nutrition Enabled: ${appInfo.nutritionEnabled}`}
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                <Card>
                  <CardHeader title={`App Store Integrations`} />
                  <CardContent>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      to={`/trainer/app_publishing/${appInfo.userId}`}
                      component={RouterLink}
                    >
                      Edit App Store Integrations
                    </Button>
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={`Verifying apple app store connect: ${data.verifyAppleAppStoreIntegration?.message}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Apple app versions:  ${appleAppVersions}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Apple IAP: ${hasAddedAppleIap} ${data.verifyAppleAppStoreIntegration?.inAppPurchases?.map(
                            (x) => `${x.productId} ${x.state}, `
                          )}`}
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={`Has Google service credentials: ${
                            appStoreIntegrations.hasGoogleServiceAccountCredentials
                              ? 'yes'
                              : 'no'
                          }`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Apple Iap Shared Secret: ${appStoreIntegrations.appleIapSharedSecret}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Apple App Store Connect Vendor Id: ${appStoreIntegrations.appleAppStoreConnectVendorId}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Apple App Store Connect Issuer Id: ${appStoreIntegrations.appleAppStoreConnectIssuerId}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Apple App Store Connect Key Id: ${appStoreIntegrations.appleAppStoreConnectKeyId}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Has Apple App Store Connect Private Key: ${
                            appStoreIntegrations.hasAppleAppStoreConnectPrivateKey
                              ? 'yes'
                              : 'no'
                          }`}
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                <Card>
                  <CardHeader
                    title={
                      <RouterLink
                        to={`/trainer/training_plans?trainerId=${trainer._id}`}
                      >
                        Training Plans
                      </RouterLink>
                    }
                  />
                  <CardContent>
                    <List>
                      {trainingPlans.map((trainingPlan) => {
                        return (
                          <ListItem key={trainingPlan._id}>
                            <ListItemText
                              primary={
                                <RouterLink
                                  to={`/trainer/training_plans/${trainingPlan._id}`}
                                >
                                  {trainingPlan.title}
                                </RouterLink>
                              }
                              secondary={trainingPlan.createdAt}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                <Card>
                  <CardHeader
                    title={
                      <RouterLink
                        to={`/trainer/workouts?trainerId=${trainer._id}`}
                      >
                        Workouts
                      </RouterLink>
                    }
                  />
                  <CardContent>
                    <List>
                      {workouts.map((workout) => {
                        return (
                          <ListItem key={workout._id}>
                            <ListItemText
                              primary={
                                <RouterLink
                                  to={`/trainer/workouts/${workout._id}`}
                                >
                                  {workout.title}
                                </RouterLink>
                              }
                              secondary={workout.createdAt}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                <Card>
                  <CardHeader
                    title={
                      <RouterLink
                        to={`/trainer/posts?trainerId=${trainer._id}`}
                      >
                        Posts
                      </RouterLink>
                    }
                  />
                  <CardContent>
                    <List>
                      {posts.map((post) => {
                        return (
                          <ListItem key={post._id}>
                            <ListItemText
                              primary={
                                <RouterLink to={`/trainer/posts/${post._id}`}>
                                  {post.title}
                                </RouterLink>
                              }
                              secondary={post.createdAt}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardContent>
                </Card>
              </Box>

              <Box mt={3}>
                <Card>
                  <CardHeader title={`Recent Signups`} />
                  <CardContent>
                    <List>
                      {users.map((user) => {
                        return (
                          <ListItem key={user._id}>
                            <ListItemText
                              primary={
                                <RouterLink to={`/trainer/users/${user._id}`}>
                                  {user.name}
                                </RouterLink>
                              }
                              secondary={
                                <>
                                  <span>{user.createdAt}</span>
                                  {user.subscriptions.length > 0 && (
                                    <Label>
                                      {user.subscriptions[0].vendor}
                                    </Label>
                                  )}
                                </>
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item lg={4} sm={8} xs={12}>
              <Card>
                <CardHeader title="App Checklist" />
                <CardContent>
                  <List>
                    {Object.entries(appChecklist).map(([key, value]) => {
                      if (key.indexOf('__') === 0) {
                        return <ListItem key="blank"></ListItem>;
                      }

                      return (
                        <ListItem key={key} dense>
                          <ListItemAvatar>
                            <FormControlLabel
                              disabled
                              control={<Checkbox checked={value} name={key} />}
                              label=""
                            />
                          </ListItemAvatar>
                          <ListItemText primary={key} />
                        </ListItem>
                      );
                    })}
                  </List>
                  {hasPublishResponse && (
                    <Box mb={3}>
                      {' '}
                      <Alert severity={alertSeverity}>
                        {publishResponseData?.publishApp?.message ||
                          publishDemoResponseData?.publishDemoApp?.message}
                      </Alert>{' '}
                    </Box>
                  )}

                  {!hasPublishResponse && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handlePublish}
                    >
                      Publish
                    </Button>
                  )}

                  {!hasPublishResponse && (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handlePublishDemoApp}
                    >
                      Publish Demo App
                    </Button>
                  )}
                </CardContent>
              </Card>

              <br />
              <br />

              <Card>
                <CardHeader title="App Builds" />
                <CardContent>
                  {appBuilds.length === 0 && (
                    <Alert severity="info">No build yet.</Alert>
                  )}
                  {appBuilds.map((appBuild) => {
                    return (
                      <List key={appBuild._id}>
                        {Object.entries(appBuild).map(([key, value]) => {
                          if (key.indexOf('__') === 0) {
                            return <ListItem key="blank"></ListItem>;
                          }
                          return (
                            <ListItem key={key} dense>
                              <ListItemText primary={`${key}: ${value}`} />
                            </ListItem>
                          );
                        })}
                      </List>
                    );
                  })}
                </CardContent>
              </Card>
              <br />
              <br />
              <Card>
                <CardHeader title="Referrals" />
                <CardContent>
                  {trainerReferrals.length === 0 && (
                    <Alert severity="info">No referrals yet.</Alert>
                  )}
                  {trainerReferrals.map((trainer) => {
                    return (
                      <List key={trainer._id}>
                        <ListItem dense>
                          <ListItemText>
                            <RouterLink to={`/admin/trainers/${trainer._id}`}>
                              {trainer.name}
                            </RouterLink>

                            {trainer.hasActiveTrainerSubscription ? (
                              <Label color="primary">Has Subscription</Label>
                            ) : (
                              <Label color="secondary">No subscription</Label>
                            )}
                          </ListItemText>
                        </ListItem>
                      </List>
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
