import React from 'react';
import PropTypes from 'prop-types';

import AppConstants from '../../constants/AppConstants';
import TrainingPlanScheduleDay from './TrainingPlanScheduleDay.jsx';
import { DropTarget } from 'react-dnd';

const MAX_EVENTS_PER_DAY = 6;

const squareTarget = {
  canDrop: function (props) {
    return props.events.length < MAX_EVENTS_PER_DAY;
  },

  drop: function (props, monitor) {
    if (monitor.didDrop()) {
      // If you want, you can check whether some nested
      // target already handled drop
      return;
    }
    const item = monitor.getItem();

    if (item.workout && item.workout.status === 0) {
      window.alert(
        'Workout is still in draft, please finish creating it before adding it to a training plan.'
      );
      return props;
    }
    if (item.move) {
      props.onMove(item.workout, props.day);
    } else {
      props.onAdd(item.workout, props.day);
    }

    return props;
  },
};

const collect = function (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
};

class TrainingPlanScheduleDaySquare extends React.Component {
  render() {
    const events = this.props.events || [];
    const connectDropTarget = this.props.connectDropTarget;
    const isOver = this.props.isOver;

    return connectDropTarget(
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <TrainingPlanScheduleDay
          day={this.props.day}
          trainingPlan={this.props.trainingPlan}
          events={events}
          onRemove={this.props.onRemove}
          onOpenAddWorkout={this.props.onOpenAddWorkout}
        />
        {isOver && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 1,
              opacity: 0.5,
              backgroundColor: 'yellow',
            }}
          />
        )}
      </div>
    );
  }
}

TrainingPlanScheduleDaySquare.propTypes = {
  day: PropTypes.number.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
};

export default DropTarget(
  AppConstants.WORKOUT,
  squareTarget,
  collect
)(TrainingPlanScheduleDaySquare);
