import { Remove as RemoveIcon } from '@mui/icons-material';
import { TextField, Box, Button } from '@mui/material';

export default function RecipeInstruction({
  instruction,
  onChange,
  onAdd,
  onRemove,
}) {
  const handleChange = (event) => {
    const changes = {
      ...instruction,
      [event.target.name]: event.target.value,
    };
    onChange(changes);
  };

  return (
    <Box
      pb={2}
      style={{
        alignItems: 'center',
        display: 'flex',
        gap: 14,
      }}
    >
      <TextField
        fullWidth
        label=""
        name={`text`}
        onChange={handleChange}
        value={instruction.text}
        variant="outlined"
      />
      <Button
        onClick={(event) => onRemove(instruction)}
        startIcon={<RemoveIcon fontSize="small" />}
      >
        Remove
      </Button>
    </Box>
  );
}
