import { apiUrl } from '../config';
import { defaultVideoThumbnail } from '../components/shared/UploadStyles';

function getFilenameFromUrl(url) {
  const pathname = new URL(url).pathname;
  const index = pathname.lastIndexOf('/');
  return pathname.substring(index + 1); // if index === -1 then index+1 will be 0
}

export async function directUpload({
  accessToken,
  file,
  streaming,
  removeAudio,
  truncateVideo,
}) {
  console.debug(file);
  let uploadUrl = `${apiUrl}/trainer/media/upload_presigned_post_url`;
  let transcodeUrl = `${apiUrl}/trainer/media/transcode`;

  const res = await fetch(uploadUrl, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      size: file.size,
      name: file.name,
      type: file.type,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const parsed = await res.json();
  const uploadHost = parsed.data.url; // this includes the trailing /
  const fields = parsed.data.fields;

  console.log(uploadHost);
  console.log(fields);

  // BEING upload to s3 here
  let formData = new FormData();
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key]);
  });
  formData.append('file', file);

  const s3UploadResponse = await fetch(uploadHost, {
    method: 'POST',
    body: formData,
  });
  console.log(s3UploadResponse);
  // End upload to s3

  let uploadedRes = {};
  // uploadUrl does not contain `/`, the host has it
  const uploadedUrl = `${uploadHost}${fields.key}`;
  console.log(uploadedUrl);

  let label = getFilenameFromUrl(uploadedUrl);
  let sizeInBytes = file.size;
  if (file.type.startsWith('video')) {
    const transcodeResponse = await fetch(transcodeUrl, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        url: uploadedUrl,
        streaming,
        removeAudio,
        truncateVideo,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!transcodeResponse.ok) {
      throw new Error(transcodeResponse.statusText);
    }

    const parsedTranscodeResponse = await transcodeResponse.json();
    const videos = parsedTranscodeResponse.uploaded[0]?.files.map((f) => {
      console.debug(f);
      return {
        label: getFilenameFromUrl(f.url),
        contentType: f.contentType,
        url: f.url,
        imageUrl: f.imageUrl,
      };
    });

    const uploaded = videos[0];
    if (!uploaded) {
      throw new Error('Could not transcode video.');
    }
    const thumbnailUrl = uploaded.imageUrl;

    uploadedRes.image = {
      label,
      sizeInBytes,
      url: thumbnailUrl || defaultVideoThumbnail,
    };
    uploadedRes.videos = videos;
  } else if (file.type.startsWith('image')) {
    uploadedRes.image = { label, sizeInBytes, url: uploadedUrl };
  } else {
    uploadedRes.file = { label, sizeInBytes, url: uploadedUrl };
  }

  return uploadedRes;
}
