const { gql } = require('@apollo/client');

const ME_FRAGMENT = gql`
  fragment MeFragment on Me {
    __typename
    _id
    name
    link
    avatarUrl
  }
`;

const APP_INFO_FRAGMENT = gql`
  fragment AppInfoFragment on AppInfo {
    __typename
    _id
    title
    legalName
    appThemeId
    icon {
      url
    }
    onboardingImages {
      url
    }
  }
`;

const APP_THEME_FRAGMENT = gql`
  fragment AppThemeFragment on AppTheme {
    __typename
    _id
    slug
    title
    imageUrl
    description
    status
    previewUrl
  }
`;

export const GET_ONBOARDING = gql`
  query GetOnboarding {
    me {
      ...MeFragment
    }
    appInfo {
      ...AppInfoFragment
    }
    appThemes {
      ...AppThemeFragment
    }
  }
  ${ME_FRAGMENT}
  ${APP_INFO_FRAGMENT}
  ${APP_THEME_FRAGMENT}
`;

export const UPDATE_USER_DETAILS = gql`
  mutation UpdateUserDetails(
    $updateMeInput: UpdateMeInput!
    $appInfoId: ID!
    $updateAppInfoInput: AppInfoInput!
  ) {
    updateMe(input: $updateMeInput) {
      ...MeFragment
    }
    updateAppInfo(id: $appInfoId, input: $updateAppInfoInput) {
      ...AppInfoFragment
    }
  }
  ${ME_FRAGMENT}
  ${APP_INFO_FRAGMENT}
`;

export const UPDATE_APP_INFO = gql`
  mutation UpdateAppTemplate(
    $appInfoId: ID!
    $updateAppInfoInput: AppInfoInput!
  ) {
    updateAppInfo(id: $appInfoId, input: $updateAppInfoInput) {
      ...AppInfoFragment
    }
  }
  ${APP_INFO_FRAGMENT}
`;

export const SUBMIT_APP_CONTENT = gql`
  mutation SubmitOnboardingAppContent($input: OnboardingAppContentInput!) {
    submitOnboardingAppContent(input: $input) {
      message
    }
  }
`;
