import { Box, Divider } from '@mui/material';
import ChatMessageAdd from './ChatMessageAdd';
import ChatMessages from './ChatMessages';
import ChatThreadComposer from './ChatThreadComposer';
import ChatThreadToolbar from './ChatThreadToolbar';
import Loading from '../shared/Loading';
import ServerError from '../error/ServerError';
import { useAuth } from '../../hooks/use-auth';

import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CHAT_MESSAGES, CREATE_CHAT_MESSAGE } from '../../graphql/chat';

const ChatConvo = ({ conversation }) => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { error, loading, data, refetch } = useQuery(GET_CHAT_MESSAGES, {
    pollInterval: 4000,
    variables: { input: { conversationId: conversation?._id } },
  });

  // TODO useEffect to call markRead
  const [createChatMessage] = useMutation(CREATE_CHAT_MESSAGE, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onCompleted: (data) => {
      refetch();
    },
  });

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading) {
    return <Loading />;
  }

  const messages = data?.chatMessages || [];
  const { participantUsers } = conversation;

  // In our case there two possible routes
  // one that contains chat/new and one with a chat/:threadKey
  // if threadKey does not exist, it means that the chat is in compose mode
  const mode = true ? 'DETAIL' : 'COMPOSE';

  const handleSendMessage = async ({ body, attachment }) => {
    try {
      const input = {
        conversationId: conversation._id,
        content: body,
        contentFormat: 'TEXT',
      };
      if (attachment && attachment.videos) {
        input.images = [attachment.image];
        input.videos = attachment.videos;
      } else if (attachment && attachment.images) {
        input.images = [attachment.image];
      } else if (attachment) {
        input.files = [attachment.file];
      }

      await createChatMessage({
        variables: {
          input,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      {mode === 'DETAIL' && (
        <ChatThreadToolbar participants={participantUsers} />
      )}
      {mode === 'COMPOSE' && (
        <ChatThreadComposer recipients={participantUsers} />
      )}
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <ChatMessages messages={messages} participants={participantUsers} />
      </Box>
      <Divider />
      <ChatMessageAdd
        avatarUrl={user.photoURL}
        disabled={false}
        onSend={handleSendMessage}
      />
    </Box>
  );
};

export default ChatConvo;
