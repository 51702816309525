import _ from 'lodash';
import { FormHelperText, Grid, TextField } from '@mui/material';

import LabelledOutline from '../../components/shared/LabelledOutline';
import MediaUpload from '../../components/shared/DirectUpload';

export default function FormField({
  handleUploadError,
  handleUploadSuccess,
  handleUploadRemove,
  handleChange,
  webPage,
  field,
}) {
  const value = _.get(webPage, field.name);
  const images = value && field.type === 'upload' ? [{ url: value }] : [];

  return (
    <Grid key={field.name} item xs={12} md={8}>
      {field.type === 'text' && (
        <>
          <TextField
            fullWidth
            label={field.label}
            name={field.name}
            onChange={handleChange}
            value={value || ''}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <FormHelperText>{field.help}</FormHelperText>
        </>
      )}

      {field.type === 'textarea' && (
        <>
          <TextField
            multiline
            rows={3}
            fullWidth
            label={field.label}
            name={field.name}
            onChange={handleChange}
            value={value || ''}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <FormHelperText>{field.help}</FormHelperText>
        </>
      )}

      {field.type === 'upload' && (
        <LabelledOutline id={field.label} label={field.label}>
          <MediaUpload
            key={field.name}
            images={images}
            onError={handleUploadError}
            onSuccess={(uploadRes) =>
              handleUploadSuccess(uploadRes, field.name)
            }
            onRemove={(file) => handleUploadRemove(file, field.name)}
            maxFiles={1}
          />

          <FormHelperText>{field.help}</FormHelperText>
        </LabelledOutline>
      )}
    </Grid>
  );
}
