import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import ChipInput from '../../components/shared/ChipInput';
import UrlHelper from '../../utils/urlHelper';
import DirectUpload from '../../components/shared/DirectUpload';
import ImageCropper from '../../components/shared/ImageCropper';

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import YouTubeIcon from '@mui/icons-material/YouTube';

import LabelledOutline from '../../components/shared/LabelledOutline';
import {
  Alert,
  IconButton,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import Loading from '../../components/shared/Loading.jsx';

const VIDEO_THUMBNAIL =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3xlHzILeVY-vybao5ogJn5HuZdgs3OzvgJg&usqp=CAU';

const WorkoutDetailsEditor = ({ workout, setWorkout, saveWorkout }) => {
  const handleWorkoutTypeEdit = (event, value) => {
    const changes = _.cloneDeep(workout);
    changes.workoutType = value;
    setWorkout(changes);
  };

  const handleEdit = (e) => {
    const changes = _.cloneDeep(workout);
    changes[e.target.name] = e.target.value;
    setWorkout(changes);
  };

  const handleYoutubeVideoUrlEdit = (e) => {
    const changes = _.cloneDeep(workout);
    changes[e.target.name] = e.target.value;

    const coverImageUrl = changes.coverImages[0]?.url;
    const hasDefaultCoverImage = coverImageUrl.includes('tempo-logo');

    if (changes.coverImages?.length === 0 || hasDefaultCoverImage) {
      const thumbnail = UrlHelper.extractYoutubeThumbUrl(e.target.value);
      if (thumbnail) {
        changes.coverImages = [{ url: thumbnail, w: 480, h: 360 }];
      }
    }

    setWorkout(changes);
  };
  const handleEditInt = (e) => {
    const changes = _.cloneDeep(workout);
    changes[e.target.name] = parseInt(e.target.value);
    setWorkout(changes);
  };

  const handleDeleteTag = (chip) => {
    const newTags = (workout.tags || []).filter((item) => item !== chip);
    const changes = _.cloneDeep(workout);
    changes.tags = newTags;
    setWorkout(changes);
  };

  const handleAddTag = (tag) => {
    const changes = _.cloneDeep(workout);
    changes.tags = [...(changes.tags ?? []), tag];
    setWorkout(changes);
  };

  const handleUploadError = (error) => {
    window.alert(error);
    console.error('WorkoutDetailsEditor#handleUploadError');
  };

  const handleUploadSuccess = (uploadedRes) => {
    if (!uploadedRes?.image) {
      console.error(`handleUploadSuccess Missing image ${uploadedRes}`);
      return;
    }

    const changes = _.cloneDeep(workout);
    changes.coverImages = [uploadedRes.image];
    setWorkout(changes);
  };

  const handleUploadRemove = () => {
    const changes = _.cloneDeep(workout);
    changes.coverImages = [];
    setWorkout(changes);
  };

  const handleCropSuccess = (uploadedRes) => {
    if (!uploadedRes?.image) {
      console.error(`handleCropSuccess Missing image ${uploadedRes}`);
      return;
    }

    const changes = _.cloneDeep(workout);

    changes.coverImages = [uploadedRes.image];
    setWorkout(changes);
  };

  const handleFullLengthVideoUploadError = (error) => {
    window.alert(error);
    console.error('WorkoutDetailsEditor#handleFullLengthVideoUploadError');
  };
  const handleFullLengthVideoUploadSuccess = (uploadedRes) => {
    const changes = _.cloneDeep(workout);
    changes.videos = uploadedRes.videos;
    console.log(changes.videos);
    setWorkout(changes);
  };

  const handleFullLengthVideoUploadRemove = () => {
    const changes = _.cloneDeep(workout);
    changes.videos = [];
    setWorkout(changes);
  };

  if (!workout) {
    return <Loading />;
  }

  let coverImageElement = (
    <DirectUpload
      images={workout.coverImages}
      onError={handleUploadError}
      onSuccess={handleUploadSuccess}
      onRemove={handleUploadRemove}
    />
  );

  if (workout.coverImages && workout.coverImages.length > 0) {
    const firstUrl = workout.coverImages[0].url;
    const aspect = workout.coverImages[0].aspect;

    let w = workout.coverImages[0].w;
    let h = workout.coverImages[0].h;

    const MAX_SIZE = 400;
    let resizeFactor;
    if (w > MAX_SIZE || h > MAX_SIZE) {
      if (w > h) {
        resizeFactor = MAX_SIZE / w;
        w = MAX_SIZE;
        h = Math.round(resizeFactor * h);
      } else {
        resizeFactor = MAX_SIZE / h;
        h = MAX_SIZE;
        w = Math.round(resizeFactor * w);
      }
    }

    if (firstUrl && aspect !== 'square' && !firstUrl.includes('youtube')) {
      // youtube images cannot be cropped
      const fileFromCoverImage = { _id: workout.coverImages[0]._id };
      coverImageElement = (
        <div>
          <ImageCropper
            imageUrl={firstUrl}
            aspectRatio={1}
            width={w}
            height={h}
            onSuccess={handleCropSuccess}
            onRemove={() => {
              handleUploadRemove(fileFromCoverImage);
            }}
          />
        </div>
      );
    }
  }

  return (
    <Card>
      <CardContent>
        <form
          id="detailsForm"
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            saveWorkout(workout);
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                type="text"
                maxLength={60}
                onChange={handleEdit}
                value={workout.title ?? ''}
                variant="outlined"
                helperText="Max 60 characters. Examples: Leg Day"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                multiline
                label="Description"
                name="desc"
                type="text"
                rows={4}
                maxLength={320}
                onChange={handleEdit}
                value={workout.desc ?? ''}
                variant="outlined"
                helperText="Max 320"
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <ChipInput
                label="Tags"
                fullWidth
                style={{ marginBottom: 24 }}
                InputLabelProps={{ shrink: true }}
                value={workout.tags ?? []}
                onAdd={handleAddTag}
                onDelete={handleDeleteTag}
                variant="outlined"
                helperText="Pick a few keywords to help athletes find your workout. Press enter after each tag."
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <LabelledOutline id="cover-image" label="Cover Image">
                {coverImageElement}
              </LabelledOutline>
              <FormHelperText>
                This is an optional landscape image which can be shown for the
                workout. Images should be less than 1 MB so it loads fast for
                the user.
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="level">Level</InputLabel>
                <Select
                  native
                  value={workout.level ?? '0'}
                  onChange={handleEditInt}
                  label="Level"
                  inputProps={{
                    name: 'level',
                    id: 'level',
                  }}
                >
                  <option value="0">Beginner</option>
                  <option value="1">Intermediate</option>
                  <option value="2">Advanced</option>
                </Select>
                <FormHelperText>
                  Set to the level of effort for this Workout.
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={8}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  native
                  value={workout.status ?? 'DRAFT'}
                  onChange={handleEdit}
                  label="Status"
                  inputProps={{
                    name: 'status',
                    id: 'status',
                  }}
                >
                  <option value="DRAFT">Draft</option>
                  <option value="FREE">Public</option>
                  <option value="PREMIUM">Premium</option>
                </Select>

                <FormHelperText>
                  Drafts are seen only by you while you create the workout.
                  <br /> Public are free to view. <br />
                  Premium are available only to paid members.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" gutterBottom>
                Workout Playback Content
              </Typography>
              <ToggleButtonGroup
                color="primary"
                exclusive
                value={workout.workoutType ?? 'TIMED'}
                onChange={handleWorkoutTypeEdit}
              >
                <ToggleButton value="TIMED">
                  <IconButton>
                    <FormatListBulletedIcon />
                  </IconButton>{' '}
                  Short Exercise Videos
                </ToggleButton>
                <ToggleButton value="VIDEO">
                  <IconButton>
                    <OndemandVideoIcon />
                  </IconButton>{' '}
                  One Full Length Video
                </ToggleButton>
                <ToggleButton value="YOUTUBE">
                  <IconButton>
                    <YouTubeIcon />
                  </IconButton>{' '}
                  Youtube Video
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {workout.workoutType === 'TIMED' ? (
              <Grid item xs={12} md={8}>
                <Alert severity="info">
                  Add your short exercise videos and its corresponding reps/sets
                  on the{' '}
                  <RouterLink to={`/trainer/workouts/${workout._id}/content`}>
                    'Workout' Tab
                  </RouterLink>
                  .
                </Alert>
              </Grid>
            ) : (
              <div />
            )}

            {workout.workoutType === 'VIDEO' ? (
              <Grid item xs={12} md={8}>
                <Typography variant="caption">
                  Upload a full length workout video:
                </Typography>
                <DirectUpload
                  images={
                    workout.videos?.length > 0 ? [{ url: VIDEO_THUMBNAIL }] : []
                  }
                  onError={handleFullLengthVideoUploadError}
                  onSuccess={handleFullLengthVideoUploadSuccess}
                  onRemove={handleFullLengthVideoUploadRemove}
                  streaming={true}
                />
              </Grid>
            ) : (
              <div />
            )}
            {workout.workoutType === 'YOUTUBE' ? (
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  helperText="Example: https://youtu.be/OUdtI8wdQW4"
                  label="Youtube Video Url"
                  name="youtubeVideoUrl"
                  onChange={handleYoutubeVideoUrlEdit}
                  value={workout.youtubeVideoUrl || ''}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            ) : (
              <div />
            )}
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default WorkoutDetailsEditor;
