import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Base64 } from 'js-base64';
import { Formik } from 'formik';

import {
  APP_STORE_INTEGRATIONS_QUERY,
  SAVE_APP_STORE_INTEGRATIONS_MUTATION,
} from '../../graphql/app_publishing';

import {
  ListItemIcon,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  FormHelperText,
} from '@mui/material';
import Label from '../shared/Label';
import LabelledOutline from '../shared/LabelledOutline';

import { useAuth } from '../../hooks/use-auth';

import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import config from '../../config';

const fields = [
  {
    name: 'googleAppId',
    label: 'Google App Id',
    help: (
      <>
        This should match the Apple Bundle Id. It cannot be changed once set.
        <br />
        Example: com.{config.appDisplayName.toLowerCase()}.appName
      </>
    ),
  },
];

export default function GooglePlayStoreIntegration() {
  const params = useParams();
  const trainerId = params.id;

  const {
    loading: dataLoading,
    error,
    data,
    refetch,
  } = useQuery(APP_STORE_INTEGRATIONS_QUERY, { variables: { trainerId } });
  const [saveAppStoreIntegration, { data: saveData }] = useMutation(
    SAVE_APP_STORE_INTEGRATIONS_MUTATION
  );

  const { enqueueSnackbar } = useSnackbar();
  const [appStoreIntegrationInput, setAppStoreIntegrationInput] = useState({});
  const [
    hasGoogleServiceAccountCredentials,
    setHasGoogleServiceAccountCredentials,
  ] = useState(false);
  const auth = useAuth();
  const authLoading = auth.loading;

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        let base64result = fileReader.result.split(',')[1];
        resolve(base64result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handlePrivateKeyUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    let decoded;
    try {
      decoded = Base64.decode(base64);
    } catch (err) {
      alert('Is this the right file?');
      console.error(err);
    }

    const changes = { ...appStoreIntegrationInput };
    changes.googleServiceAccountCredentials = decoded;

    setAppStoreIntegrationInput(changes);
    setHasGoogleServiceAccountCredentials(true);
  };

  const handleReplacePrivateKey = (e) => {
    const changes = { ...appStoreIntegrationInput };
    changes.googleServiceAccountCredentials = null;
    setAppStoreIntegrationInput(changes);
    setHasGoogleServiceAccountCredentials(false);
  };

  React.useEffect(() => {
    if (!data && !saveData) {
      return;
    }

    let appStoreIntegrations;
    if (saveData && saveData.saveAppStoreIntegrations) {
      appStoreIntegrations = saveData.saveAppStoreIntegrations;
    }
    if (!appStoreIntegrations && data && data.appStoreIntegrations) {
      appStoreIntegrations = data.appStoreIntegrations;
    }

    if (!appStoreIntegrations) {
      return;
    }

    setHasGoogleServiceAccountCredentials(
      appStoreIntegrations.hasGoogleServiceAccountCredentials
    );

    setAppStoreIntegrationInput({
      googleAppId: appStoreIntegrations.googleAppId,
    });
  }, [data, saveData]);

  if (authLoading || dataLoading) {
    return <Loading />;
  }
  if (error) return <ServerError message="Failed to load" />;
  if (!data) return <Loading />;

  return (
    <Box style={{ maxWidth: 1080 }}>
      <Formik
        enableReinitialize
        initialValues={appStoreIntegrationInput}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            setAppStoreIntegrationInput({
              ...appStoreIntegrationInput,
              ...values,
            });

            enqueueSnackbar('Saving...', { autoHideDuration: 1000 });

            values.trainerId = trainerId;
            await saveAppStoreIntegration({
              variables: { input: values },
            });

            resetForm();
            setStatus({ success: true });
            setSubmitting(false);

            refetch();
            enqueueSnackbar('App updated', {
              variant: 'success',
            });
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader title="Google Play Store" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {config.showInstructionalVideos && (
                      <Box mb={4}>
                        <YouTube
                          videoId="bz3g9rijJLs"
                          opts={{
                            height: 480,
                            width: 640,
                            playerVars: { autoplay: 0 },
                          }}
                        />
                      </Box>
                    )}

                    <Typography variant="body1">
                      For us to build your app to the Google Play Store, we need
                      you to do the following on the{' '}
                      <a
                        href="https://developer.android.com/distribute/console"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Google Play Developer Console
                      </a>
                      :
                      <List>
                        <ListItem dense>
                          <ListItemIcon>1.</ListItemIcon>
                          Click "Create App" button on the "All Apps" Page.
                        </ListItem>
                        <ListItem dense>
                          <ListItemIcon>2.</ListItemIcon>
                          On the right sidebar, Click Setup > API Access and
                          then the 'Create Service Account' and follow the
                          instructions to generate the credentials JSON file to
                          upload below.
                        </ListItem>
                      </List>
                    </Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <LabelledOutline
                      id="credentials"
                      label="Google Service Account Credentials JSON File"
                    >
                      {hasGoogleServiceAccountCredentials && (
                        <Box>
                          <Label>* Added </Label>{' '}
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={handleReplacePrivateKey}
                          >
                            Replace
                          </Button>
                        </Box>
                      )}
                      {!hasGoogleServiceAccountCredentials && (
                        <>
                          <input
                            type="file"
                            onChange={handlePrivateKeyUpload}
                          />
                        </>
                      )}
                    </LabelledOutline>
                    <FormHelperText>
                      <Typography variant="caption" paragraph>
                        For more help how to do this, do the steps from{' '}
                        <a
                          href="https://docs.codemagic.io/knowledge-base/google-play-api/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          this guide and on step 7, upload JSON Key file here.
                        </a>
                        .
                      </Typography>
                    </FormHelperText>
                  </Grid>
                  {fields.map((field) => {
                    return (
                      <Grid key={field.name} item xs={8}>
                        <TextField
                          error={Boolean(
                            touched[field.name] && errors[field.name]
                          )}
                          fullWidth
                          helperText={touched[field.name] && errors[field.name]}
                          label={field.label}
                          name={field.name}
                          shrink={true}
                          disabled={field.disabled}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values[field.name]}
                          variant="outlined"
                        />
                        <FormHelperText>{field.help}</FormHelperText>
                      </Grid>
                    );
                  })}
                </Grid>

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Box>
  );
}
