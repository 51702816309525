import React, { useState, useRef } from 'react';
import { Link, Grid, Typography, Container, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Page from '../Page';
import Logo from '../shared/Logo';
import AuthEmbed from '../auth/AuthEmbed';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    width: '100%',
    minHeight: '100vh',
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80,
  },
}));

const LoginPage = () => {
  const authPromptRef = useRef(null);
  const classes = useStyles();
  const [showAuthPrompt, setShowAuthPrompt] = useState(true);

  return (
    <Page
      title={`Login : ${config.appDisplayName}`}
      noindex={false}
      style={{ paddingTop: 0 }}
    >
      <Grid className={classes.root} container>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            backgroundColor: 'background.paper',
          }}
        >
          <Container className={classes.cardContainer} maxWidth="md">
            <Box mb={2} display="flex" justifyContent="center">
              <Link href={config.appLandingPageHost}>
                <Logo />
              </Link>
            </Box>
            <Box mb={2} display="flex" justifyContent="center">
              <Typography variant="h3">Let's get started</Typography>
            </Box>
            <Box mb={2} display="flex" justifyContent="center">
              <Typography variant="subtitle1">
                We're excited to help you start building your fitness app with
                {` ${config.appDisplayName}`}.
              </Typography>
            </Box>
            <Box mb={2} display="flex" justifyContent="center">
              <Typography variant="subtitle1">
                {config.appIsFreeToSignUp
                  ? "It's free to get started. You won't pay until you want to publish your app."
                  : ''}
              </Typography>
            </Box>
            {showAuthPrompt && (
              <AuthEmbed
                ref={authPromptRef}
                show="login"
                onSuccess={() => {
                  setShowAuthPrompt(false);
                }}
                onClose={() => {}}
              />
            )}
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            backgroundColor: 'background.default',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage:
              'url(https://d272s49zbiop50.cloudfront.net/landing-page/stock-photos/female/livits-login-image.jpg)',
          }}
        ></Grid>
      </Grid>
    </Page>
  );
};

export default LoginPage;
