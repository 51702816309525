import React, { useState, useEffect } from 'react';
import { withAuthContext } from '../../hooks/use-auth';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Typography, Box, Button } from '@mui/material';
import Image from 'react-graceful-image';
import Loading from './Loading';
import { directUpload } from '../../utils/uploadHelper';
import {
  defaultVideoThumbnail,
  uploadContainer,
  thumb,
  thumbInner,
} from './UploadStyles';

function DirectUpload(props) {
  let accept =
    props.fileType === 'image'
      ? 'image/*'
      : 'image/*, video/*, application/*,.pdf';
  const [filesUploading, setFilesUploading] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: props.maxFiles ?? 1,
    accept,
    onDropAccepted: async (acceptedFiles) => {
      // Add to filesUploading
      setFilesUploading([
        ...filesUploading,
        ...acceptedFiles.map((f) => {
          return {
            id: f.name,
            url: URL.createObjectURL(f),
          };
        }),
      ]);
      const accessToken = await props.context.user.getIdToken();

      // Upload the file
      acceptedFiles.forEach(async (file) => {
        try {
          const uploadedRes = await directUpload({
            accessToken,
            file,
            streaming: props.streaming,
            removeAudio: props.removeAudio,
            truncateVideo: props.truncateVideo,
          });
          if (props.onSuccess) {
            props.onSuccess(uploadedRes);
          }
        } catch (err) {
          if (props.onError) {
            props.onError(err.message);
          }
          alert(err.message);
        } finally {
          setFilesUploading(filesUploading.filter((j) => j.id !== file.name));
        }
      });
    },
  });

  const uploadingThumbs = filesUploading.map((i) => (
    <React.Fragment key={i.id}>
      <Box width={100}>
        <Loading circular />
      </Box>
    </React.Fragment>
  ));

  const thumbs = (props.images ?? [])
    .filter((x) => !!x)
    .map((file) => {
      return (
        <React.Fragment key={file.url}>
          <div style={thumb}>
            <div style={thumbInner}>
              <Image src={file.url} />
            </div>
          </div>
          <Box>
            <Button
              onClick={() => {
                if (!!props.onRemove) {
                  props.onRemove(file);
                }
              }}
            >
              Remove
            </Button>
          </Box>
        </React.Fragment>
      );
    });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      filesUploading.forEach((i) => URL.revokeObjectURL(i.url));
    },
    [filesUploading]
  );

  return (
    <section>
      <div
        style={{ ...uploadContainer, ...(props.style || {}) }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {thumbs.length === 0 && (
          <Typography variant="subtitle1">
            Drag a file here, or click to upload.
          </Typography>
        )}
        {uploadingThumbs}
        {thumbs}
      </div>
    </section>
  );
}

DirectUpload.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      w: PropTypes.number,
      h: PropTypes.number,
      aspect: PropTypes.string,
    })
  ),
  fileType: PropTypes.string,
  truncateVideo: PropTypes.bool,
  removeAudio: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  onRemove: PropTypes.func,
  maxFiles: PropTypes.number,
};

export default withAuthContext(DirectUpload);
