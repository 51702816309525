import { gql } from '@apollo/client';

const SALES_PAGE_FRAGMENT = gql`
  fragment SalesPageFragment on SalesPage {
    __typename
    _id
    url
    slug
    headline
    subtitle
    completionRedirectUrl
    backgroundImage {
      url
    }
  }
`;

export const GET_SALES_PAGE = gql`
  query GetSalesPage($trainerId: ID) {
    salesPage(trainerId: $trainerId) {
      ...SalesPageFragment
    }
  }
  ${SALES_PAGE_FRAGMENT}
`;

export const UPDATE_SALES_PAGE = gql`
  mutation UpdateSalesPage($id: ID!, $input: SalesPageInput!) {
    updateSalesPage(id: $id, input: $input) {
      ...SalesPageFragment
    }
  }
  ${SALES_PAGE_FRAGMENT}
`;
