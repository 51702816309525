import config, { apiUrl } from '../../config';
import QueryString from 'query-string';
import { Grid, Box, Container, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import { useAuth } from '../../hooks/use-auth.js';
import { useHistory } from 'react-router-dom';
import SearchBox from '../shared/SearchBox';
import UserResults from './UserResults';
import InviteUserDialog from './InviteUserDialog';
import { useQuery } from '@apollo/client';
import Page from '../Page';
import { GET_USERS } from '../../graphql/users';

const LIMIT = 50;

export default function UserList(props) {
  const [showInviteUserDialog, setShowInviteUserDialog] = useState(false);
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;

  const { loading, error, data, fetchMore } = useQuery(GET_USERS, {
    variables: {
      input: {
        limit: LIMIT,
        trainerId,
      },
      trainerId, // for the total user count query
    },
  });
  const history = useHistory();
  const [term, setTerm] = useState('');
  const auth = useAuth();

  const handleSearch = async (event) => {
    event.preventDefault();
    const authToken = await auth.user.getIdToken();

    return fetch(`${apiUrl}/api/admin/users/search?email=${term}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.user) {
          window.alert('No user found.');
          return;
        }

        history.push(`/trainer/users/${response.user._id}`);
      });
  };
  const handleLoadMore = async (event) => {
    event.preventDefault();

    await fetchMore({
      variables: {
        input: {
          limit: LIMIT,
          lastId: users[users.length - 1]._id,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          users: [...previousResult.users, ...fetchMoreResult.users],
        };
      },
    });
  };

  const handleExportToCsv = async (event) => {
    event.preventDefault();
    const authToken = await auth.user.getIdToken();

    return fetch(`${apiUrl}/v1/users/export`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ body: 'CSV' }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        const today = new Date().toISOString().split('T')[0];
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `tempo-users-${today}.csv`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  if (loading) {
    return <Loading />;
  }
  if (error) return <ServerError message="Failed to load" />;
  const users = data.users || [];
  const userCount = data.trainer?.totalUserCount;

  return (
    <Page title={`Users : ${config.appDisplayName}`}>
      <InviteUserDialog
        open={showInviteUserDialog}
        onClose={() => {
          setShowInviteUserDialog(false);
        }}
      />
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          All Customers
        </Typography>
        {<Typography variant="caption"> Total Users: {userCount} </Typography>}

        <Box mb={3}>
          <Button
            color="secondary"
            variant="contained"
            onClick={async (e) => {
              e.preventDefault();
              setShowInviteUserDialog(true);
            }}
          >
            Invite a new User
          </Button>
        </Box>
        <Box mt={3}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <SearchBox
                name="email"
                type="email"
                placeholder="email"
                value={term}
                onChange={(event) => setTerm(event.target.value)}
                onSubmit={handleSearch}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                sx={{ mb: 2 }}
                variant="outlined"
                onClick={handleExportToCsv}
              >
                Export as CSV
              </Button>
            </Grid>
          </Grid>

          <UserResults users={users} />

          {users.length > 1 && users.length >= LIMIT && (
            <Box m={3}>
              <Button variant="outlined" onClick={handleLoadMore}>
                LOAD MORE
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
}
