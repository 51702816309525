import React from 'react';
import ItemTypes from '../../constants/AppConstants';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import Label from '../shared/Label';
import { Link as RouterLink } from 'react-router-dom';
import { Link, TableCell, Box, Button } from '@mui/material';
import { Image as ImageIcon } from 'react-feather';
import { DragSource, DropTarget } from 'react-dnd';

const itemSource = {
  beginDrag: function (props) {
    return {
      trainingPlanId: props.trainingPlanId,
      index: props.index,
    };
  },

  isDragging: function (props, monitor) {
    // If your component gets unmounted while dragged
    // (like a card in Kanban board dragged between lists)
    // you can implement something like this to keep its
    // appearance dragged:
    return monitor.getItem().trainingPlanId === props.trainingPlanId;
  },
};

const collectDragSource = function (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const collectDropTarget = function (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
};

const itemTarget = {
  drop: function (targetProps, monitor, component) {
    const sourceIndex = monitor.getItem().index;
    const targetIndex = targetProps.index;

    if (sourceIndex === targetIndex) {
      return;
    }

    // Time to actually perform the action
    targetProps.onMove(sourceIndex, targetIndex);

    // Mutating the monitor item! Generally better to avoid mutations.
    // But good here for performance, to avoid expensive index searches.
    monitor.getItem().index = targetIndex;
  },
};

const statusMapping = { 0: 'Draft', 1: 'Public', 2: 'Private' };

const TrainingPlanItem = ({
  trainingPlan,
  trainingPlanId,
  featured,
  onMove,
  onDelete,
  onAddToFeatured,
  onRemoveFromFeatured,
  ...props
}) => {
  if (!trainingPlanId) {
    return <tr />;
  }

  const url = `/trainer/training_plans/${trainingPlan._id}`;

  const status = (
    <Label color="warning">{statusMapping[trainingPlan.status]}</Label>
  );

  let mediaComponent;
  if (trainingPlan.coverImageUrl) {
    mediaComponent = (
      <Box m={1}>
        <img
          alt="Program"
          src={trainingPlan.coverImageUrl}
          style={{ width: 48, height: 48 }}
        />
      </Box>
    );
  } else {
    mediaComponent = (
      <Box m={1} bgcolor="background.dark">
        <ImageIcon size={48} />
      </Box>
    );
  }

  const edit = (
    <tr>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Link color="inherit" component={RouterLink} to={url} variant="h6">
            {mediaComponent}
          </Link>
          <Link color="inherit" component={RouterLink} to={url} variant="h6">
            {trainingPlan.title}
          </Link>
          {featured && <Label color="primary">Featured</Label>}
        </Box>
      </TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{trainingPlan.enrolled}</TableCell>
      <TableCell>
        <Button onClick={onDelete} startIcon={<DeleteIcon />}>
          Delete
        </Button>
        <br />
        <Button
          size="small"
          onClick={featured ? onRemoveFromFeatured : onAddToFeatured}
          startIcon={!featured ? <CheckIcon /> : <RemoveIcon />}
        >
          {featured ? 'Remove Featured' : 'Mark Featured'}
        </Button>
      </TableCell>
    </tr>
  );

  return props.connectDragSource(props.connectDropTarget(edit));
};

export default DragSource(
  ItemTypes.TRAINING_PLAN,
  itemSource,
  collectDragSource
)(
  DropTarget(
    ItemTypes.TRAINING_PLAN,
    itemTarget,
    collectDropTarget
  )(TrainingPlanItem)
);
