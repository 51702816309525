import React from 'react';
import { useMutation } from '@apollo/client';
import { Card, CardHeader, Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../hooks/use-auth';
import { CREATE_NOTE } from '../../graphql/note';
import Note from './Note';
import CommentAdd from '../shared/CommentAdd';

const NotesTab = (props) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [createNote] = useMutation(CREATE_NOTE, {
    onError: (error) => {
      enqueueSnackbar(`Error saving comment: ${error.message}`, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      if (!data.createNote) {
        return;
      }
      // TODO change to add comment
      window.location.reload();
    },
  });

  const handleCommentSubmit = (body) => {
    enqueueSnackbar('Saving...', {
      autoHideDuration: 1000,
    });

    createNote({
      variables: {
        input: {
          userId: props.user._id,
          body,
        },
      },
    });
  };

  return (
    <Box mt={3}>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Notes are just for you to see about this user. The user cannot see these
        notes.
      </Typography>

      {props.notes?.length === 0 && (
        <Typography variant="h4" color="textSecondary" gutterBottom>
          You haven't entered any notes about this user yet.
        </Typography>
      )}

      {props.notes?.map((note) => {
        return (
          <Box mb={2} key={note._id}>
            <Note note={note} />
          </Box>
        );
      })}

      <Card>
        <CardHeader title="Add Notes" />

        <Box m={2} mb={5}>
          <CommentAdd onSubmit={handleCommentSubmit} />
        </Box>
      </Card>
    </Box>
  );
};

export default NotesTab;
