import { gql } from '@apollo/client';

const MEAL_FRAGMENT = gql`
  fragment MealFragment on Meal {
    __typename

    _id
    coverImages {
      url
    }
    coverImageUrl
    status
    title
    desc
    createdAt
    updatedAt
    tags
    allergies

    isUsingIngredientMacros

    prepTimeSeconds
    cookTimeSeconds
    totalTimeSeconds
    recipeYield

    macros {
      fatGrams
      carbGrams
      proteinGrams
      calories
    }

    recipeIngredients {
      _id
      name
      quantity
      quantityUnit
      macros {
        fatGrams
        carbGrams
        proteinGrams
        calories
      }
    }
    recipeInstructions {
      _id
      name
      text
      image {
        url
      }
    }

    webUrl
    mobilePreviewUrl
  }
`;

export const GET_MEALS = gql`
  query GetMeals($input: MealsQueryInput) {
    meals(input: $input) {
      ...MealFragment
    }
  }
  ${MEAL_FRAGMENT}
`;

export const GET_MEAL = gql`
  query GetMeal($id: ID!) {
    meal(id: $id) {
      ...MealFragment
    }
  }
  ${MEAL_FRAGMENT}
`;

export const CREATE_MEAL = gql`
  mutation CreateMeal($input: MealInput!) {
    createMeal(input: $input) {
      ...MealFragment
    }
  }
  ${MEAL_FRAGMENT}
`;

export const DUPLICATE_MEAL = gql`
  mutation DuplicateMeal($id: ID!) {
    duplicateMeal(id: $id) {
      ...MealFragment
    }
  }
  ${MEAL_FRAGMENT}
`;

export const DELETE_MEAL = gql`
  mutation DeleteMeal($id: ID!) {
    deleteMeal(id: $id) {
      message
    }
  }
`;

export const UPDATE_MEAL = gql`
  mutation UpdateMeal($id: ID!, $input: MealInput!) {
    updateMeal(id: $id, input: $input) {
      ...MealFragment
    }
  }
  ${MEAL_FRAGMENT}
`;

const MEAL_PLAN_FRAGMENT = gql`
  fragment MealPlanFragment on MealPlan {
    __typename

    _id
    coverImages {
      url
    }
    coverImageUrl
    status
    title
    desc
    createdAt
    updatedAt
    tags
  }
`;

export const GET_MEAL_PLANS = gql`
  query GetMealPlans($input: MealPlansQueryInput) {
    mealPlans(input: $input) {
      ...MealPlanFragment
    }
  }
  ${MEAL_PLAN_FRAGMENT}
`;

export const GET_MEAL_PLAN = gql`
  query GetMealPlan($id: ID!) {
    mealPlan(id: $id) {
      ...MealPlanFragment
    }
  }
  ${MEAL_PLAN_FRAGMENT}
`;

export const CREATE_MEAL_PLAN = gql`
  mutation CreateMealPlan($input: MealPlanInput!) {
    createMealPlan(input: $input) {
      ...MealPlanFragment
    }
  }
  ${MEAL_PLAN_FRAGMENT}
`;

export const DELETE_MEAL_PLAN = gql`
  mutation DeleteMealPlan($id: ID!) {
    deleteMealPlan(id: $id) {
      message
    }
  }
`;

export const UPDATE_MEAL_PLAN = gql`
  mutation UpdateMealPlan($id: ID!, $input: MealPlanInput!) {
    updateMealPlan(id: $id, input: $input) {
      ...MealPlanFragment
    }
  }
  ${MEAL_PLAN_FRAGMENT}
`;
