import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  Divider,
  Card,
  CardHeader,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CommentAdd from '../shared/CommentAdd';
import Comment from '../shared/Comment';
import { useMutation } from '@apollo/client';
import { CREATE_WORKOUT_SESSION_COMMENT } from '../../graphql/workout_session';
import { useSnackbar } from 'notistack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExerciseNotesTable from './ExerciseNotesTable';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20,
  },
  date: {
    marginLeft: 6,
  },
  media: {
    height: 300,
    width: '100%',
    backgroundPosition: 'top',
    objectFit: 'contain',
  },
}));

const ActivityPost = ({ className, activity, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  // note
  // to be removed once we migrate to GraphQL
  const [activityState, setActivityState] = useState(activity);
  useEffect(() => {
    setActivityState(activity);
  }, [activity]);
  // end note
  const [createWorkoutSessionComment] = useMutation(
    CREATE_WORKOUT_SESSION_COMMENT,
    {
      onError: (error) => {
        enqueueSnackbar(`Error saving comment: ${error.message}`, {
          variant: 'error',
        });
      },
      onCompleted: (data) => {
        if (!data.commentWorkoutSession) {
          return;
        }
        const changes = _.cloneDeep(activityState);
        changes.workoutSession = changes.workoutSession ?? {};
        changes.workoutSession.comments = changes.workoutSession.comments ?? [];
        changes.workoutSession.comments.push(data.commentWorkoutSession);
        setActivityState(changes);
      },
    }
  );

  const handleCommentSubmit = (body) => {
    enqueueSnackbar('Saving...', {
      autoHideDuration: 1000,
    });

    if (activityState.workoutSession) {
      createWorkoutSessionComment({
        variables: {
          id: activityState.sessionObjectId,
          input: {
            body,
          },
        },
      });
    }
  };

  let titleLink = activityState.fromUser?._id
    ? `/trainer/users/${activityState.fromUser?._id}`
    : '';
  if (activityState.workoutSession) {
    titleLink = `/trainer/workout_sessions/${activityState.workoutSession._id}`;
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        avatar={
          <Avatar
            alt="Person"
            component={RouterLink}
            src={activityState.fromUser?.avatarUrl}
            to={`/trainer/users/${activityState.fromUser?._id}`}
          />
        }
        subheader={
          <Box display="flex" alignItems="center">
            <AccessTimeIcon fontSize="small" />
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.date}
            >
              {activityState.createdAt}
            </Typography>
          </Box>
        }
        title={
          <Typography color="textPrimary" variant="h6">
            <RouterLink to={titleLink}>{activityState.title}</RouterLink>
          </Typography>
        }
      />
      <Box px={3} pb={2}>
        {activityState.workoutSession && (
          <Typography component="div" variant="body1" color="textPrimary">
            {activityState.workoutSession.photoUrl && (
              <img
                className={classes.media}
                src={activityState.workoutSession.photoUrl}
                alt={activityState.title}
              />
            )}
            {activityState.workout && (
              <Box mb={2}>
                <RouterLink
                  to={`/trainer/workouts/${activityState.workout._id}`}
                >
                  {activityState.workout.title}
                </RouterLink>
              </Box>
            )}

            {activityState.workoutSession.feel && (
              <Box mb={2}>{activityState.workoutSession.feel}</Box>
            )}
            {activityState.workoutSession.notes && (
              <Box mb={2}>{activityState.workoutSession.notes}</Box>
            )}

            {activityState.workoutSession.exerciseNotes &&
              activityState.workoutSession.exerciseNotes.length > 0 && (
                <Box mb={2}>
                  <Accordion
                    onChange={(e, expanded) => {
                      if (expanded) {
                        // TODO network call to get workout session details
                      }
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>
                        See More Workout Notes
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ExerciseNotesTable
                        exerciseNotes={
                          activityState.workoutSession.exerciseNotes
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
          </Typography>
        )}

        {activityState.mealLog && (
          <Typography variant="body1" color="textPrimary">
            {activityState.mealLog.image?.url && (
              <img
                className={classes.media}
                src={activityState.mealLog.image?.url}
                alt={activityState.title}
              />
            )}
            {activityState.meal && (
              <Box mb={2}>
                <RouterLink to={`/trainer/meals/${activityState.meal._id}`}>
                  {activityState.meal.title}
                </RouterLink>
              </Box>
            )}

            {activityState.mealLog.notes && (
              <Box mb={2}>{activityState.mealLog.notes}</Box>
            )}
          </Typography>
        )}

        {activityState.wideCoverImage && activityState.wideCoverImage && (
          <Box mt={2}>
            <img
              className={classes.media}
              src={activityState.wideCoverImage.url}
              alt={activityState.title}
            />
          </Box>
        )}

        {activityState.workoutSession && (
          <>
            {activityState.workoutSession.comments.map((comment) => (
              <Comment comment={comment} key={comment.id} />
            ))}
            <Box my={2}>
              <Divider />
            </Box>
            <CommentAdd onSubmit={handleCommentSubmit} />
          </>
        )}
      </Box>
    </Card>
  );
};

ActivityPost.propTypes = {
  className: PropTypes.string,
  activity: PropTypes.object.isRequired,
};

export default ActivityPost;
