import { gql } from '@apollo/client';

const POST_FRAGMENT = gql`
  fragment PostFragment on Post {
    __typename
    _id
    coverImages {
      url
    }
    coverImageUrl
    videos {
      url
      contentType
    }
    videoUrl
    status
    title
    userId
    user {
      _id
      name
      avatarUrl
    }
    createdAt
    updatedAt
    tags
    content
    webUrl
    mobilePreviewUrl
  }
`;

export const GET_POSTS = gql`
  query GetPosts($input: PostsQueryInput) {
    posts(input: $input) {
      ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;

export const GET_POST = gql`
  query GetPost($id: ID!) {
    post(id: $id) {
      ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;

export const CREATE_POST = gql`
  mutation CreatePost($input: PostInput!) {
    createPost(input: $input) {
      ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;

export const DELETE_POST = gql`
  mutation DeletePost($id: ID!) {
    deletePost(id: $id) {
      message
    }
  }
`;

export const UPDATE_POST = gql`
  mutation UpdatePost($id: ID!, $input: PostInput!) {
    updatePost(id: $id, input: $input) {
      ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;
