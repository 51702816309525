import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppPreviewIframe from './AppPreviewIframe';
import WebPreviewIframe from './WebPreviewIframe';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';

import {
  Button,
  Tabs,
  Tab,
  Divider,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { Alert } from '@mui/material';
import config from '../../config';

const tabs = config.showWebPreview
  ? [
      { value: 'app', label: 'App Preview' },
      { value: 'web', label: 'Web Preview' },
    ]
  : [{ value: 'app', label: 'App Preview' }];

export default function AppAndWebPreview({
  mobilePreviewUrl,
  webUrl,
  showCheckoutLink,
}) {
  const [currentTab, setCurrentTab] = useState('app');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const showCheckoutAlert =
    currentTab === 'app' && showCheckoutLink && config.showSubscriptionPage;

  return (
    <Card>
      <CardContent>
        <Box mb={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />
        </Box>

        {showCheckoutAlert && (
          <Box mb={3}>
            <Alert severity="info">
              Like what you see?
              <br />
              <RouterLink to="/trainer/checkout">
                Sign up for a paid plan to publish the app to the Apple App
                Store.
              </RouterLink>
            </Alert>
          </Box>
        )}

        {currentTab === 'app' && mobilePreviewUrl && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <AppPreviewIframe url={mobilePreviewUrl} />
          </Box>
        )}

        {currentTab === 'web' && webUrl && (
          <Box mt={3}>
            <Box>
              <Typography variant="body1" paragraph>
                <a href={webUrl} target="_blank" rel="noreferrer">
                  {webUrl}
                </a>
              </Typography>
              <Typography variant="body1" paragraph>
                <CopyToClipboard
                  text={webUrl}
                  onCopy={() => {
                    window.alert(`Copied ${webUrl}`);
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<CopyIcon />}
                  >
                    Copy Link
                  </Button>
                </CopyToClipboard>
              </Typography>
            </Box>
            <br />

            <WebPreviewIframe url={webUrl} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
