import React from 'react';
import {
  TextField,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
} from '@mui/material';

const MerchStoreScreen = ({ appInfo, setAppInfo, saveAppInfo }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    saveAppInfo();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h3">
            Merch Store
          </Typography>
          <Box mb={3}>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={appInfo.merchStoreEnabled}
                      onChange={(event) => {
                        setAppInfo({
                          ...appInfo,
                          merchStoreEnabled: event.target.checked,
                        });
                      }}
                      name="merchStoreEnabled"
                    />
                  }
                  label="Enable Merch Store"
                />
              </FormGroup>
              <FormHelperText>
                Enable an embedded view of your merch store in the app.
              </FormHelperText>
            </FormControl>
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              label="Merch Store Url"
              name="merchStoreUrl"
              onChange={(event) => {
                setAppInfo({ ...appInfo, merchStoreUrl: event.target.value });
              }}
              value={appInfo.merchStoreUrl || ''}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />

            <FormHelperText>
              Your Shopify or other merch store url.
            </FormHelperText>
          </Box>

          <Box mt={2}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};
export default MerchStoreScreen;
