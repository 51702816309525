import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Image as ImageIcon } from 'react-feather';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.action.selected,
    boxShadow: `inset 4px 0px 0px ${theme.palette.secondary.main}`,
  },
}));

const ExerciseItem = ({ active, className, exercise, onSelect, ...rest }) => {
  const classes = useStyles();
  let media = null;
  if (exercise.images && exercise.images[0]) {
    media = (
      <img
        alt="exercise"
        src={exercise.images[0].url}
        style={{ width: 28, height: 28 }}
      />
    );
  } else {
    media = <ImageIcon size={28} />;
  }
  return (
    <ListItem
      button
      className={clsx({ [classes.active]: active }, className)}
      onClick={() => onSelect(exercise)}
      {...rest}
    >
      <ListItemAvatar>{media}</ListItemAvatar>
      <ListItemText
        primary={exercise.title}
        primaryTypographyProps={{
          noWrap: true,
          variant: 'h6',
          color: 'textPrimary',
        }}
      />
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
    </ListItem>
  );
};

ExerciseItem.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  exercise: PropTypes.object.isRequired,
};

ExerciseItem.defaultProps = {
  active: false,
  onSelect: () => {},
};

export default ExerciseItem;
