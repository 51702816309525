import config, { apiUrl } from '../../config';

import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Loading from '../../components/shared/Loading';
import { useAuth } from '../../hooks/use-auth';
import {
  Avatar,
  Button,
  Divider,
  Typography,
  Tabs,
  colors,
  Container,
  Box,
  Grid,
  Tab,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import Page from '../Page';
import ActivityTab from './ActivityTab';
import CalendarTab from './CalendarTab';
import NotesTab from './NotesTab';
import ChatTab from './ChatTab';
import NutritionTab from './NutritionTab';
import SubscriptionTab from './SubscriptionTab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cover: {
    position: 'relative',
    height: 110,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:before': {
      position: 'absolute',
      content: '" "',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage:
        'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)',
    },
    '&:hover': {
      '& $changeButton': {
        visibility: 'visible',
      },
    },
  },
  changeButton: {
    visibility: 'hidden',
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: colors.blueGrey[900],
    color: theme.palette.common.white,
    [theme.breakpoints.down('xl')]: {
      top: theme.spacing(3),
      bottom: 'auto',
    },
    '&:hover': {
      backgroundColor: colors.blueGrey[900],
    },
  },
  avatar: {
    border: `2px solid ${theme.palette.common.white}`,
    height: 120,
    width: 120,
    top: -60,
    left: theme.spacing(3),
    position: 'absolute',
  },
  action: {
    marginLeft: theme.spacing(1),
  },
}));

const tabs = [
  { value: 'activity', label: 'Activity' },
  { value: 'calendar', label: 'Calendar' },
  { value: 'chat', label: 'Chat' },
  { value: 'notes', label: 'Private Notes' },
  { value: 'nutrition', label: 'Nutrition' },
  { value: 'subscription', label: 'Subscription' },
];

export default function UserShow(props) {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('activity');

  const [user, setUser] = useState(null);
  const [activities, setActivities] = useState([]);
  const [subscriptionOrders, setSubscriptionOrders] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [notes, setNotes] = useState([]);
  const [subscriptionEvents, setSubscriptionEvents] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [trainingPlanSessions, setTrainingPlanSessions] = useState([]);
  const [workoutSessions, setWorkoutSessions] = useState([]);

  const [token, setToken] = useState(null);
  const auth = useAuth();
  const authLoading = auth.loading;
  const loggedInUser = auth.user;

  const params = useParams();
  const history = useHistory();
  const id = params.id;
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    history.push(`/trainer/users/${id}/${value}`);
  };

  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  useEffect(() => {
    setLoading(true);
    loggedInUser
      .getIdToken()
      .then((token) => {
        setToken(token);
        if (!id) {
          throw new Error('No id');
        }
        return fetch(`${apiUrl}/api/admin/users/${id}`, {
          method: 'GET',
          headers: new Headers({ Authorization: `Bearer ${token}` }),
        });
      })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (!response.user) {
          window.alert('No user found');
          return;
        }
        setUser(response.user);

        setActivities(response.activities);
        setNotes(response.notes);
        setWorkoutSessions(response.workoutSessions);
        setTrainingPlanSessions(response.trainingPlanSessions);
        setSubscriptionOrders(response.subscriptionOrders);
        setSubscriptions(response.subscriptions);
        setSubscriptionEvents(response.subscriptionEvents);
        setPurchases(response.purchases);
      });
  }, [loggedInUser, id]);

  if (!id) {
    return <h3>No id</h3>;
  }

  if (authLoading || isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <h1>No user</h1>;
  }

  return (
    <Page title={`${user.name || user._id} : ${config.appDisplayName}`}>
      <div className={classes.cover}> </div>
      <Container maxWidth={false}>
        <Box position="relative" mt={1} display="flex" alignItems="center">
          <Avatar
            alt="Person"
            className={classes.avatar}
            src={user.avatarUrl}
          />
          <Box marginLeft="160px">
            <Typography variant="h4" color="textPrimary">
              {user.name || user._id}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === 'activity' && (
            <ActivityTab
              token={token}
              user={user}
              trainingPlanSessions={trainingPlanSessions}
              activities={activities}
            />
          )}
          {currentTab === 'calendar' && (
            <CalendarTab workoutSessions={workoutSessions} />
          )}
          {currentTab === 'notes' && <NotesTab user={user} notes={notes} />}
          {currentTab === 'chat' && <ChatTab user={user} />}
          {currentTab === 'nutrition' && <NutritionTab user={user} />}

          {currentTab === 'subscription' && (
            <SubscriptionTab
              token={token}
              user={user}
              subscriptionOrders={subscriptionOrders}
              subscriptions={subscriptions}
              subscriptionEvents={subscriptionEvents}
              purchases={purchases}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
}
