import { gql } from '@apollo/client';

export const GET_CONVERSATIONS = gql`
  query GetConversations($input: ConversationsQueryInput) {
    conversations(input: $input) {
      _id
      hasUnreadMessages
      participantUsers {
        _id
        name
        avatarUrl
      }
    }
  }
`;

export const GET_CONVERSATION = gql`
  query GetConversation($id: ID!) {
    conversation(id: $id) {
      _id
      hasUnreadMessages
      participantUsers {
        _id
        name
        avatarUrl
      }
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation CreateConversation($input: ConversationInput!) {
    createConversation(input: $input) {
      _id
      participantUsers {
        _id
      }
    }
  }
`;

export const CREATE_CHAT_MESSAGE = gql`
  mutation CreateChatMessage($input: ChatMessageInput!) {
    createChatMessage(input: $input) {
      _id
      isLoggedInUserSender
      sentByUserId
      sentByUser {
        _id
        name
        avatarUrl
      }
      content
      contentFormat
      fileUrl
      imageUrl
      videoUrl
      videoStreamingUrl
      createdAt
    }
  }
`;

export const GET_CHAT_MESSAGES = gql`
  query GetChatMessages($input: ChatMessagesQueryInput!) {
    chatMessages(input: $input) {
      _id
      isLoggedInUserSender
      sentByUserId
      sentByUser {
        _id
        name
        avatarUrl
      }
      content
      contentFormat
      fileUrl
      imageUrl
      videoUrl
      videoStreamingUrl
      createdAt
    }
  }
`;
