import React from 'react';

import { Box } from '@mui/material';

export default function WebPreviewIframe({ url }) {
  return (
    <Box
      style={{
        border: '12px solid #000',
        borderRadius: '40px',
        background: '#000',
        height: 820,
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <iframe
        title="Web Preview"
        src={url}
        style={{
          border: 0,
          height: 800,
          width: '100%',
        }}
      />
    </Box>
  );
}
