import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import Page from '../Page';
import General from './General';
import AppPreview from '../app_preview/AppPreview';
import { useMutation, useQuery } from '@apollo/client';
import { GET_APP_INFO_PAGE, UPDATE_APP_INFO } from '../../graphql/app_info';
import ServerError from '../error/ServerError';
import Loading from '../shared/Loading';
import { useSnackbar } from 'notistack';
import config from '../../config';

const tabs = [{ value: 'general', label: 'General' }];

const AppInfo = (props) => {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [appInfo, setAppInfo] = useState(null);
  const [appPreviewRefresh, setAppPreviewRefresh] = useState(1);
  const trainerId = params.id !== 'current' ? params.id : null;
  const input = trainerId ? { variables: { trainerId } } : {};
  const { error, loading, data } = useQuery(GET_APP_INFO_PAGE, input);
  useEffect(() => {
    setAppInfo(data?.appInfo);
  }, [data]);
  useEffect(() => {
    if (appInfo?.autoSave === true) {
      saveAppInfo();
      setAppInfo({ ...appInfo, autoSave: false });
    }
  }, [appInfo, appInfo?.autoSave]);
  const [updateAppInfo] = useMutation(UPDATE_APP_INFO, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });
  const saveAppInfo = () => {
    if (!appInfo) {
      return;
    }
    enqueueSnackbar('Saving...', {
      autoHideDuration: 2000,
    });
    updateAppInfo({
      variables: {
        id: appInfo._id,
        input: {
          title: appInfo.title,
          supportEmail: appInfo.supportEmail,
          icon: appInfo.icon,
          onboardingImages: appInfo.onboardingImages,
          mainColor: appInfo.mainColor,
          secondaryColor: appInfo.secondaryColor,
          appThemeId: appInfo.appThemeId,
          appWorkoutPlayerSlug: appInfo.appWorkoutPlayerSlug,
          legalName: appInfo.legalName,
          blogUrl: appInfo.blogUrl,
          feedbackUrl: appInfo.feedbackUrl,
          supportUrl: appInfo.supportUrl,
          termsUrl: appInfo.termsUrl,
          privacyPolicyUrl: appInfo.privacyPolicyUrl,
          faqUrl: appInfo.faqUrl,
          aboutUrl: appInfo.aboutUrl,
          merchStoreUrl: appInfo.merchStoreUrl,
        },
      },
    });
    setTimeout(() => {
      setAppPreviewRefresh(appPreviewRefresh + 1);
    }, 3500);
  };
  const [currentTab, setCurrentTab] = useState('general');
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading || !appInfo) {
    return <Loading />;
  }

  return (
    <Page title={`App Info : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          App Information
        </Typography>

        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Box mt={3}>
              {currentTab === 'general' && (
                <General
                  appInfo={appInfo}
                  setAppInfo={setAppInfo}
                  saveAppInfo={saveAppInfo}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppPreview
              key={appPreviewRefresh}
              trainerId={trainerId}
              screen="HOME"
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AppInfo;
