import {
  Avatar,
  Button,
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Image as ImageIcon } from 'react-feather';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LinkIcon from '@mui/icons-material/LinkOutlined';
import Label from '../shared/Label';
import { Link as RouterLink } from 'react-router-dom';
import config, { subdomainHost } from '../../config';

const PostList = ({ posts, onDelete }) => {
  return (
    <Card>
      <Box minWidth={700}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>CreatedAt</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(posts || []).map((post) => {
              let url = `/trainer/posts/${post._id}`;
              let mediaComponent;
              if (post.coverImageUrl) {
                mediaComponent = (
                  <Box m={1}>
                    <img
                      alt="Post"
                      src={post.coverImageUrl}
                      style={{ width: 48, height: 48 }}
                    />
                  </Box>
                );
              } else {
                mediaComponent = (
                  <Box m={1} bgcolor="background.dark">
                    <ImageIcon size={48} />
                  </Box>
                );
              }
              const media = (
                <Link to={url} component={RouterLink}>
                  {mediaComponent}
                </Link>
              );
              let status = <Label color="warning">Draft</Label>;
              if (post.status === 'PUBLIC') {
                status = <Label color="secondary">Free</Label>;
              } else if (post.status === 'PREMIUM') {
                status = <Label color="primary">Premium</Label>;
              }

              return (
                <TableRow hover key={post._id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {media}
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={url}
                        variant="h6"
                      >
                        {post.title}
                      </Link>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {
                        <Box m={1}>
                          <Avatar src={post.user?.avatarUrl} />
                        </Box>
                      }
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/trainer/users/${post.userId}`}
                        variant="h6"
                      >
                        {post.user?.name}
                      </Link>
                    </Box>
                  </TableCell>
                  <TableCell>{status}</TableCell>

                  <TableCell>
                    {new Date(post.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {config.showWebPreview && (
                      <Button startIcon={<LinkIcon />}>
                        <a
                          href={`https://${post.userId}.${subdomainHost}/posts/${post._id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Preview
                        </a>
                      </Button>
                    )}
                    <br />
                    <Button
                      onClick={() => {
                        if (onDelete) {
                          onDelete(post);
                        }
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default PostList;
