import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation, gql } from '@apollo/client';

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useAuth } from '../../hooks/use-auth';

import Page from '../Page';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import { Alert } from '@mui/material';

import { formatAmountForDisplay } from '../../utils/stripeHelpers';

import config from '../../config';

const TRAINER_PRODUCTS_QUERY = gql`
  query GetTrainerProducts {
    trainerProducts {
      _id
      name
      desc
      amount
      stripePriceId
      stripeProductId
    }
  }
`;
const PAY_TRAINER_PRODUCT_MUTATION = gql`
  mutation PayTrainerProduct($input: PayTrainerProductInput!) {
    payTrainerProduct(input: $input) {
      _id
      status
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  product: {
    position: 'relative',
    padding: theme.spacing(5, 3),
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

const BuyAppPublishingSetup = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const { loading, error, data } = useQuery(TRAINER_PRODUCTS_QUERY);
  const [payTrainerProduct, { data: payData, error: mutationError }] =
    useMutation(PAY_TRAINER_PRODUCT_MUTATION);

  const auth = useAuth();
  const authLoading = auth.loading;

  const createSubscription = async ({ priceId }) => {
    setSubmitting(true);
    enqueueSnackbar('Paying...', { autoHideDuration: 2000 });
    try {
      await payTrainerProduct({
        variables: { input: { priceId } },
      });
    } catch (err) {
      console.error(err.message);
      // err will be shown with mutationError below
    }
    setSubmitting(false);
  };

  const handleSubmit = async (e, priceId) => {
    e.preventDefault();
    await createSubscription({ priceId });
  };

  if (payData) {
    return (
      <Page title={`Buy App Publishing Setup : ${config.appDisplayName}`}>
        <Container maxWidth="sm">
          <Box mt={3}>
            <Typography align="center" variant="h2" color="textPrimary">
              Payment
            </Typography>
            {payData.payTrainerProduct.status !== 'paid' && (
              <Alert severity="error">The payment didn't succeed</Alert>
            )}

            {payData.payTrainerProduct.status === 'paid' && (
              <>
                <Alert severity="info">
                  We got your payment. We'll start publishing your app and let
                  you know if we need more information.
                </Alert>

                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/trainer/training_plans"
                >
                  Continue Adding More Workout Programs
                </Button>
              </>
            )}
          </Box>
        </Container>
      </Page>
    );
  }

  if (authLoading || loading) {
    return <Loading />;
  }
  if (error) return <ServerError message="Failed to load" />;

  const products = data.trainerProducts;

  if (!products) {
    return <h4>No products found</h4>;
  }

  return (
    <Page title={`Buy App Publishing Setup  ${config.appDisplayName}`}>
      <Container maxWidth="sm">
        <Box mt={3}>
          <Typography align="center" variant="h2" color="textPrimary">
            Get Your App Setup Quick
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography
            align="center"
            variant="subtitle1"
            color="textSecondary"
            paragraph
          >
            Don't want to waste time setting up the App Store Integrations? We
            can help finish publishing your app.
          </Typography>
          <Typography
            align="center"
            variant="subtitle1"
            color="textSecondary"
            paragraph
          >
            All you have to do is just complete the necessary legal forms in the
            App Store, connect our account and we'll do the rest.
          </Typography>
        </Box>

        {mutationError && <Alert severity="error">{`${mutationError}`}</Alert>}
      </Container>

      <Box mt="20px">
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {(products || []).map((product) => {
              if (!product) {
                return <></>;
              }
              return (
                <Grid key={product.stripePriceId} item md={4} xs={12}>
                  <Paper className={classes.product}>
                    <Typography
                      component="h3"
                      gutterBottom
                      variant="overline"
                      color="inherit"
                    >
                      {product.name}
                    </Typography>
                    <div>
                      <Typography
                        component="span"
                        display="inline"
                        variant="h3"
                        color="inherit"
                      >
                        {formatAmountForDisplay(product.amount, 'usd')}
                      </Typography>
                    </div>
                    <Box my={2}>
                      <Divider />
                    </Box>
                    <Typography
                      component="span"
                      display="inline"
                      variant="body2"
                      color="textPrimary"
                    >
                      {product.desc}
                    </Typography>
                    <Box my={2}>
                      <Divider />
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={(e) => handleSubmit(e, product.stripePriceId)}
                      disabled={submitting}
                    >
                      Buy App Publishing Setup
                    </Button>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};

export default BuyAppPublishingSetup;
