import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import { useSnackbar } from 'notistack';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommentAdd from '../shared/CommentAdd';
import Comment from '../shared/Comment';
import ExerciseNotesTable from '../users/ExerciseNotesTable';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Divider,
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@mui/material';
import {
  CREATE_WORKOUT_SESSION_COMMENT,
  GET_WORKOUT_SESSIONS,
} from '../../graphql/workout_session';

export default function WorkoutAnalytics({ workout }) {
  const { enqueueSnackbar } = useSnackbar();
  const input = { workoutId: workout?._id };
  const { loading, error, data } = useQuery(GET_WORKOUT_SESSIONS, {
    variables: { input },
  });
  const [createWorkoutSessionComment] = useMutation(
    CREATE_WORKOUT_SESSION_COMMENT,
    {
      onError: (error) => {
        enqueueSnackbar(`Error saving comment: ${error.message}`, {
          variant: 'error',
        });
      },
      onCompleted: (data) => {
        if (!data.commentWorkoutSession) {
          return;
        }
        // TODO
      },
    }
  );

  const handleCommentSubmit = (body) => {
    enqueueSnackbar('Saving...', {
      autoHideDuration: 1000,
    });

    //if (workoutSession) {
    //  createWorkoutSessionComment({
    //    variables: {
    //      id: workoutSession._id,
    //      input: {
    //        body,
    //      },
    //    },
    //  });
    //}
  };

  if (error) return <ServerError message="Failed to load" />;
  if (loading || !data?.workoutSessions) return <Loading />;
  if (!workout) {
    return <ServerError message="Failed to load" />;
  }
  const workoutSessions = data?.workoutSessions;

  return (
    <Box>
      <Typography variant="h1">{workout.completedCount}</Typography>
      <br />
      <Typography variant="caption">Completed</Typography>
      <br />
      <br />

      <Typography variant="h3" gutterBottom>
        Recent Completed Sessions
      </Typography>
      <Box>
        {workoutSessions.length === 0 && (
          <Typography variant="caption" gutterBottom>
            None completed.
          </Typography>
        )}
        {workoutSessions.map((workoutSession) => {
          return (
            <Card key={workoutSession._id} style={{ marginBottom: 16 }}>
              <CardHeader
                avatar={
                  <Avatar
                    alt="Person"
                    component={RouterLink}
                    src={workoutSession.user.avatarUrl}
                    to={`/trainer/users/${workoutSession.user._id}`}
                  />
                }
                subheader={
                  <Box display="flex" alignItems="center">
                    <Typography variant="caption" color="textSecondary">
                      <RouterLink
                        to={`/trainer/users/${workoutSession.user._id}`}
                      >
                        {workoutSession.user.name}
                      </RouterLink>{' '}
                      completed on {workoutSession.completedAt}
                    </Typography>
                  </Box>
                }
                title={
                  <Typography color="textPrimary" variant="h6">
                    <RouterLink
                      to={`/trainer/workout_sessions/${workoutSession._id}`}
                    >
                      {workoutSession.title}
                    </RouterLink>
                  </Typography>
                }
              />
              <CardContent>
                <Typography component="div" variant="body1" color="textPrimary">
                  <p>{workoutSession.notes}</p>
                  {workoutSession.photoUrl && (
                    <img
                      src={workoutSession.photoUrl}
                      alt={workoutSession.title}
                    />
                  )}
                  <RouterLink
                    to={`/trainer/workout_sessions/${workoutSession._id}`}
                  >
                    See More
                  </RouterLink>
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
}
