import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

const Logout = () => {
  const [loggedOut, setLoggedOut] = useState(false);
  const auth = useAuth();
  const loading = auth.loading;

  useEffect(() => {
    return auth.signout().then(() => {
      setLoggedOut(true);
    });
  }, [auth]);

  if (loading) {
    return <React.Fragment>Loading...</React.Fragment>;
  }

  if (loggedOut) {
    return <Redirect to="/" />;
  }

  return <></>;
};

export default Logout;
