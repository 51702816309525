import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const Page = forwardRef(
  ({ children, title = '', noindex = true, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <div className={classes.root} ref={ref} {...rest}>
        <Helmet>
          <title>{title}</title>
          {noindex && <meta name="robots" content="noindex" />}
        </Helmet>
        {children}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
