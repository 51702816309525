import AppAndWebPreview from '../../components/app_preview/AppAndWebPreview';

export default function PostPreview({ post }) {
  return (
    <AppAndWebPreview
      mobilePreviewUrl={post.mobilePreviewUrl}
      webUrl={post.webUrl}
    />
  );
}
