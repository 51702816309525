import WebPreviewIframe from '../../components/app_preview/WebPreviewIframe';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';

import {
  Button,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

export default function ProductPreview({ product }) {
  const webUrl = product.webUrl || 'https://tempokit.com';

  return <Card>
    <CardContent>
          <Box mt={3}>
            <Box>
              <Typography variant="body1" paragraph>
                <a href={webUrl} target="_blank" rel="noreferrer">
                  {webUrl}
                </a>
              </Typography>
              <Typography variant="body1" paragraph>
                <CopyToClipboard
                  text={webUrl}
                  onCopy={() => {
                    window.alert(`Copied ${webUrl}`);
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<CopyIcon />}
                  >
                    Copy Link
                  </Button>
                </CopyToClipboard>
              </Typography>
            </Box>
            <br />

            <WebPreviewIframe url={webUrl} />
          </Box>
      </CardContent>
    </Card>;
}
