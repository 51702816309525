import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, List } from '@mui/material';
import ExerciseItem from './ExerciseItem';

const ExerciseList = ({ exercises, onAddNew, onSelect, onClose, ...rest }) => {
  return (
    <>
      <List {...rest}>
        {(exercises ?? []).map((exercise) => (
          <ExerciseItem
            key={exercise._id}
            exercise={exercise}
            onSelect={onSelect}
          />
        ))}
      </List>

      <Button variant="outlined" onClick={onAddNew}>
        Don't see one? Add a new exercise.
      </Button>

      <Box sx={{ display: { xs: 'block', lg: 'none', xl: 'none' }, mt: 2 }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </Box>
    </>
  );
};

ExerciseList.propTypes = {
  exercises: PropTypes.array,
};

export default ExerciseList;
