import { gql } from '@apollo/client';

export const APP_THEME_FRAGMENT = gql`
  fragment AppThemeFragment on AppTheme {
    __typename
    _id
    slug
    title
    description
    imageUrl
    embedIframeUrl
    previewUrl
    status
  }
`;

export const GET_APP_THEME_QUERY = gql`
  query AppThemes {
    appThemes {
      ...AppThemeFragment
    }
  }
  ${APP_THEME_FRAGMENT}
`;
