import React from 'react';
import moment from 'moment';

import makeStyles from '@mui/styles/makeStyles';
import { Grid, Card, CardContent } from '@mui/material';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
const useStyles = makeStyles((theme) => ({
  calendar: {
    fontFamily: theme.typography.fontFamily,
    minHeight: 500,
  },
}));

function CalendarTab({ workoutSessions }) {
  const classes = useStyles();

  const handleSelectEvent = (event) => {
    window.location.assign(`/trainer/workout_sessions/${event._id}`);
  };

  const events = workoutSessions.map((workoutSession) => {
    const endedAt = workoutSession.completedAt || workoutSession.createdAt;
    const event = { ...workoutSession };
    event.start = new Date(endedAt);
    event.end = new Date(endedAt);
    return event;
  });

  const latestEvent = events[events.length - 1];

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={10}>
          <Card>
            <CardContent>
              <Calendar
                className={classes.calendar}
                selectable
                localizer={localizer}
                events={events}
                defaultView="month"
                scrollToTime={latestEvent?.end || new Date()}
                defaultDate={new Date()}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={(slotInfo) => {}}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CalendarTab;
