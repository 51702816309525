const { gql } = require('@apollo/client');

export const MY_TRAINER_SUBSCRIPTION_QUERY = gql`
  query myTrainerSubscription {
    myTrainerSubscription {
      trainerId
      vendor
      isProPlusPlan
      stripeCustomerId
      stripePlanId
      stripePriceId
      stripeSubscriptionId
      status
      createdAt
      expiresAt
      plan {
        name
        desc
      }
    }
  }
`;
export const CREATE_BILLING_PORTAL_SESSION_MUTATION = gql`
  mutation createBillingPortalSession {
    createBillingPortalSession {
      url
    }
  }
`;
