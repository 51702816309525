import React from 'react';
import LabelledOutline from '../../components/shared/LabelledOutline';
import { useSnackbar } from 'notistack';
import { TextField, Box, Button, FormHelperText } from '@mui/material';

import MediaUpload from '../shared/DirectUpload';

export default function TestimonialForm({
  testimonial,
  webPage,
  onChange,
  onAdd,
  onRemove,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    let attribute = event.target.name;
    let value = event.target.value;

    let newTestimonialInput = { ...testimonial };
    newTestimonialInput[attribute] = value;
    onChange(newTestimonialInput);
  };

  const handleUploadError = () => {
    enqueueSnackbar(`Media upload failed!`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };

  const handleUploadSuccess = (uploadedRes) => {
    let changes = { ...testimonial };
    changes.imageUrl = uploadedRes.image.url;
    onChange(changes);
  };

  const handleUploadRemove = () => {
    let changes = { ...testimonial };
    changes.imageUrl = null;
    onChange(changes);
  };

  let images = [];
  if (testimonial.imageUrl) {
    images.push({ url: testimonial.imageUrl });
  }

  return (
    <Box>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Quote"
          name="quote"
          onChange={handleChange}
          value={testimonial.quote}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
        <FormHelperText>A short message by the customer.</FormHelperText>
      </Box>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          onChange={handleChange}
          value={testimonial.name}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <LabelledOutline label="Customer Photo">
        <MediaUpload
          images={images}
          onError={handleUploadError}
          onSuccess={(uploadRes) => handleUploadSuccess(uploadRes)}
          onRemove={(file) => handleUploadRemove(file)}
          maxFiles={1}
        />
      </LabelledOutline>

      <Box mb={2}>
        <Button variant="outlined" onClick={() => onRemove(testimonial)}>
          Remove
        </Button>
      </Box>
    </Box>
  );
}
