import React from 'react';
import { Alert } from '@mui/material';
import QueryString from 'query-string';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Card,
  CardContent,
  Box,
  Button,
  FormHelperText,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from '../../hooks/use-auth';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {},
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  },
  googleButton: {
    backgroundColor: theme.palette.common.white,
  },
  providerIcon: {
    marginRight: theme.spacing(2),
  },
  divider: {
    flexGrow: 1,
  },
  dividerText: {
    margin: theme.spacing(2),
  },
}));

const Signup = ({ className, onSuccess, onError, onLoginClick, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const { signup } = useAuth();
  const queryParams = QueryString.parse(location.search);
  const referralCode = queryParams.referralCode;

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const result = await signup(
        values.email,
        values.password,
        referralCode,
        config.appLandingPageHost
      );

      if (!result.success) {
        setStatus({ success: false });
        setErrors({ submit: result.message });
        setSubmitting(false);
        return;
      }

      setStatus({ success: true });
      setSubmitting(false);
      onSuccess(result.user);
    } catch (err) {
      console.error('Error signing up');
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb={3}
        >
          <div>
            <Typography color="textPrimary" gutterBottom variant="h2">
              Signup
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <Link
                color="textSecondary"
                variant="body2"
                href="#"
                onClick={onLoginClick}
              >
                Already have an account? Click here to Login.
              </Link>
            </Typography>
            {referralCode && (
              <Box mt={1}>
                <Alert severity="info">
                  {' '}
                  Thanks for using the referral code: {referralCode}{' '}
                </Alert>
              </Box>
            )}
          </div>
        </Box>
        <Box flexGrow={1} mt={1}>
          <Formik
            initialValues={{
              email: '',
              password: '',
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string()
                .min(7)
                .max(255)
                .required('Password is required'),
            })}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                noValidate
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box alignItems="center" display="flex" mt={2}>
                  <Typography variant="body2" color="textSecondary">
                    {' '}
                    By signing up, I agree to the{' '}
                    <Link
                      component="a"
                      href={`${config.appLandingPageHost}/terms_of_service`}
                      target="_blank"
                      color="secondary"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
                <Box mt={2}>
                  <Button
                    id="signup-page-button"
                    color="secondary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Signup
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Signup;
