import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';

import { Box, Button, Container, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAuth } from '../../hooks/use-auth';

import Page from '../Page';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import PlanSelect from './PlanSelect';
import CheckoutForm from './CheckoutForm';
import config from '../../config';

const text = {
  title: 'Plans and Pricing',
  subtitle: (
    <>
      Subscribe to launch your app. All plans have a 30-day free trial period.{' '}
      <a href="https://tempokit.com/pricing" rel="noreferrer" target="_blank">
        Click here for pricing details
      </a>
    </>
  ),
};

const TRAINER_PLANS_QUERY = gql`
  query GetTrainerSubscriptionPlans {
    trainerSubscriptionPlans {
      name
      desc
      amount
      currency
      interval
      stripePlanId
      agreementUrl
    }
  }
`;

export default function Checkout() {
  const { loading: plansLoading, error, data } = useQuery(TRAINER_PLANS_QUERY);
  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(null);

  const auth = useAuth();
  const authLoading = auth.loading;

  const handlePlanSelected = async (e, priceId) => {
    setSelectedPriceId(priceId);
    setShowCheckout(true);
  };

  useEffect(() => {
    if (data && data.trainerSubscriptionPlans) {
      // We only have one pricing plan so default select it to speed up the checkout
      const first = data.trainerSubscriptionPlans[0];
      setSelectedPriceId(first.stripePlanId);
      setShowCheckout(true);
    }
  }, [data]);

  if (authLoading || plansLoading) {
    return <Loading />;
  }

  if (error) return <ServerError message="Failed to load" />;

  const plans = data.trainerSubscriptionPlans;
  const agreementUrl = plans.find(
    (plan) => plan.stripePlanId === selectedPriceId
  )?.agreementUrl;

  return (
    <Page title={`Pricing : ${config.appDisplayName}`}>
      <Container>
        <Box mt={3}>
          <Typography align="center" variant="h2" color="textPrimary">
            {text.title}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography align="center" variant="subtitle1" color="textSecondary">
            {text.subtitle}
          </Typography>
        </Box>

        <PlanSelect
          prices={plans}
          onPlanSelected={handlePlanSelected}
          selectedPlanId={selectedPriceId}
        />

        <Box mt={3}>
          {showCheckout && (
            <CheckoutForm
              agreementUrl={agreementUrl}
              priceId={selectedPriceId}
            />
          )}{' '}
        </Box>

        <Box mt={3}>
          <Typography
            align="center"
            variant="h4"
            color="textPrimary"
            gutterBottom
          >
            Common Questions
          </Typography>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Are there any other fees?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You will have to pay Apple and Google their developer fee to
                publish your app in your own account.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">
                What forms of payment do you accept?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We use Stripe which supports processing payments in 135+
                currencies. Supported brands include American Express, China
                UnionPay (CUP), Discover & Diners, Japan Credit Bureau (JCB),
                Mastercard and Visa.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">
                What is your cancellation policy?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                If you cancel before your next payment cycle, you will not be
                charged. Once you cancel, your app will stop working.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">
                How do I publish my app after paying?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Connect your App Store accounts and click Publish in our App
                Publishing Section. Reach out to us and we will be happy to help
                you publish your app if you have any issues.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box mt={3}>
          <Typography
            align="center"
            variant="h4"
            color="textPrimary"
            gutterBottom
          >
            More Questions?
          </Typography>

          <Button variant="outlined" href="mailto:team@tempokit.com">
            Email us: team@tempokit.com
          </Button>
        </Box>
      </Container>
    </Page>
  );
}
