import { gql } from '@apollo/client';

const WORKOUT_SESSION_COMMENT_FRAGMENT = gql`
  fragment WorkoutSessionCommentFragment on WorkoutSessionComment {
    __typename
    _id
    user {
      _id
      name
      avatarUrl
    }
    createdAt
    body
  }
`;

export const CREATE_WORKOUT_SESSION_COMMENT = gql`
  mutation CreateWorkoutSessionComment(
    $id: ID!
    $input: WorkoutSessionCommentInput!
  ) {
    commentWorkoutSession(id: $id, input: $input) {
      ...WorkoutSessionCommentFragment
    }
  }
  ${WORKOUT_SESSION_COMMENT_FRAGMENT}
`;

export const GET_WORKOUT_SESSIONS = gql`
  query GetWorkoutSessionsForWorkout($input: WorkoutSessionsQueryInput) {
    workoutSessions(input: $input) {
      _id
      title
      notes
      userId
      trainerId
      trainingPlanSessionId
      trainingPlanEventId
      completedAt
      user {
        _id
        name
        avatarUrl
      }
      comments {
        user {
          _id
          avatarUrl
        }
      }
    }
  }
`;
