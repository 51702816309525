import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import {
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import GlobalStyles from './components/GlobalStyles';

import { createApolloClient } from './utils/apiHelper';

import { renderRoutes, routes } from './routes';
import getStripe from './utils/getStripe';
import { AuthProvider } from './hooks/use-auth';
import { createTheme } from './theme';
import { Elements } from '@stripe/react-stripe-js';

import 'pace-progressbar';
import 'pace-progressbar/themes/blue/pace-theme-minimal.css';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export default function App() {
  const theme = createTheme(adaptV4Theme({ theme: 'LIGHT' }));

  const apolloClient = createApolloClient();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <SnackbarProvider dense maxSnack={3}>
            <BrowserRouter>
              <AuthProvider>
                <Elements stripe={getStripe()}>
                  <GlobalStyles />
                  <ApolloProvider client={apolloClient}>
                    <DndProvider backend={HTML5Backend}>
                      {renderRoutes(routes)}
                    </DndProvider>
                  </ApolloProvider>
                </Elements>
              </AuthProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
