import { gql } from '@apollo/client';

const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    __typename
    _id
    coverImages {
      url
    }
    coverImageUrl
    pricingPlans {
      stripePriceId
      amount
      currency
    }
    stripeProductId
    status
    title
    desc
    tags
    trainerId
    trainingPlanIds
    webUrl
    createdAt
    updatedAt
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($input: ProductsQueryInput) {
    products(input: $input) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_WITH_TRAINING_PLANS = gql`
  query GetProduct($id: ID!, $trainerId: String) {
    product(id: $id) {
      ...ProductFragment
    }
    trainingPlanList(trainerId: $trainerId) {
      trainingPlans {
        __typename
        _id
        title
        coverImageUrl(width: 48, height: 48)
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: ProductInput!) {
    createProduct(input: $input) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      message
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: ID!, $input: ProductInput!) {
    updateProduct(id: $id, input: $input) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;
