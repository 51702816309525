import React, { useState } from 'react';
import _ from 'lodash';
import {
  InputAdornment,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddOutlined';
import WorkoutExerciseItem from './WorkoutExerciseItem';
import ExercisePreviewDialog from './ExercisePreviewDialog';
import Loading from '../shared/Loading';

const ExerciseGroupEditor = ({
  exerciseGroup,
  onAddExerciseClick,
  onEditExerciseClick,
  setWorkout,
  onChange,
}) => {
  const [workoutExerciseForPreview, setWorkoutExerciseForPreview] =
    useState(null);
  const [openWorkoutExercisePreview, setOpenWorkoutExercisePreview] =
    useState(false);

  // const debouncedSaveWorkout = useRef(_.debounce(saveWorkout, 1000));
  const handleWorkoutExercisePreviewClose = () => {
    setOpenWorkoutExercisePreview(false);
  };
  const handleWorkoutExercisePreviewOpen = (workoutExercise) => {
    setWorkoutExerciseForPreview(workoutExercise);
    setOpenWorkoutExercisePreview(true);
  };
  const handleExerciseListItemMove = (sourceIndex, targetIndex) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    const changes = _.cloneDeep(exerciseGroup);
    const movedExercises = changes.exercises.splice(sourceIndex, 1);
    changes.exercises.splice(targetIndex, 0, movedExercises[0]);
    onChange(changes);
  };
  const handleExerciseListItemRemove = (index) => {
    const changes = _.cloneDeep(exerciseGroup);
    changes.exercises.splice(index, 1);
    onChange(changes);
  };
  // TODO, not supported atm
  const handleExerciseListItemDuplicate = (index) => {};

  const handleExerciseListItemAddNotes = (index) => {
    const changes = _.cloneDeep(exerciseGroup);
    const currentWorkoutExercise = changes.exercises[index];
    const notes = prompt(
      'Add Notes (ex. Hold for 3 secs)',
      currentWorkoutExercise.notes || ''
    );
    currentWorkoutExercise.notes = notes;
    onChange(changes);
  };

  const handleExerciseListItemEdit = (exercise) => {
    const changes = _.cloneDeep(exerciseGroup);
    changes.exercises = changes.exercises.map((i) => {
      if (i._id === exercise._id) {
        return exercise;
      }
      return i;
    });
    onChange(changes);
  };

  const handleChange = (event) => {
    const changes = { ...exerciseGroup };
    changes[event.target.name] = event.target.value;
    onChange(changes);
  };
  const handleChangeInt = (e) => {
    const changes = { ...exerciseGroup };
    changes[e.target.name] = parseInt(e.target.value);
    onChange(changes);
  };

  if (!exerciseGroup) {
    return <Loading />;
  }

  // TODO use exerciseList? to corect map inputs
  let items = exerciseGroup.exercises?.map((e, i) => {
    return (
      <WorkoutExerciseItem
        key={`${e._id}_${e.groupName}_${i}`}
        index={i}
        workoutExercise={e}
        onEditExercise={onEditExerciseClick}
        onUpdate={handleExerciseListItemEdit}
        onMove={handleExerciseListItemMove}
        onRemove={handleExerciseListItemRemove}
        onDuplicate={handleExerciseListItemDuplicate}
        onAddNotes={handleExerciseListItemAddNotes}
        onWorkoutExercisePreview={handleWorkoutExercisePreviewOpen}
        isSelected={false}
      />
    );
  });

  return (
    <Card>
      <CardContent>
        <Box>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Exercise</TableCell>
                  <TableCell>Set Duration</TableCell>
                  <TableCell>Reps</TableCell>
                  <TableCell>Sets</TableCell>
                  <TableCell>Suggested Weight</TableCell>
                  <TableCell>Rest Between Sets</TableCell>
                  <TableCell>Rest After Exercise</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{items}</TableBody>
            </Table>
          </TableContainer>
          <Box mt={3}>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                onAddExerciseClick(exerciseGroup);
              }}
            >
              Add Exercise
            </Button>{' '}
            <ExercisePreviewDialog
              workoutExercise={workoutExerciseForPreview || {}}
              open={openWorkoutExercisePreview}
              onClose={handleWorkoutExercisePreviewClose}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ExerciseGroupEditor;
