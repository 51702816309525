import React, { useState, useEffect } from 'react';
import {
  Link,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  TextField,
  Checkbox,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import ChipInput from '../../components/shared/ChipInput';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import Loading from '../../components/shared/Loading';
import MediaUpload from '../../components/shared/DirectUpload';
import ImageCropper from '../../components/shared/ImageCropper';
import LabelledOutline from '../../components/shared/LabelledOutline';
import bytesToSize from '../../utils/bytesToSize';
import config from '../../config';

export default function TrainingPlanExtraFiles(props) {
  const [trainingPlan, setTrainingPlan] = useState(props.trainingPlan);

  const handleSave = (e) => {
    e.preventDefault();

    props?.onSave(trainingPlan);
  };

  const handleEdit = (e, file) => {
    const changes = { ...trainingPlan };

    let files = changes.files;
    let found = files.find((f) => f._id === file._id);
    let newFile = { ...found };
    newFile.label = e.target.value;

    changes.files = files.map((f) => (f._id !== file._id ? f : newFile));

    setTrainingPlan(changes);
  };

  const handleUploadError = (err) => {
    console.error('TrainingPlanExtraFiles#handleUploadError');
    console.error(err);
    window.alert('Error Uploading File');
  };

  const handleUploadSuccess = (uploadedRes) => {
    let files = trainingPlan.files || [];

    if (uploadedRes.file) {
      files = [...files, uploadedRes.file];
    }
    if (uploadedRes.image) {
      files = [...files, uploadedRes.image];
    }
    if (uploadedRes.video) {
      files = [...files, uploadedRes.video];
    }

    const changes = { ...trainingPlan, files };
    setTrainingPlan(changes);
  };

  const handleUploadRemove = (file) => {
    const files = (trainingPlan.files || []).filter((f) => f._id !== file._id);
    const changes = { ...trainingPlan, files };
    setTrainingPlan(changes);
  };

  useEffect(() => {
    setTrainingPlan(props.trainingPlan);
  }, [props.trainingPlan]);

  if (!trainingPlan) {
    return <Loading />;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="body1" paragraph>
          You can include extra files like PDFs to include with this training
          plan.
        </Typography>
        <Typography variant="body1" paragraph>
          Trainers might include detailed how tos or print-outs.
        </Typography>
        {config.showSubscriptionPage && (
          <Typography variant="body1" paragraph>
            NOTE: this functionality is only available in the{' '}
            <Link target="_blank" href="https://tempokit.com/pricing">
              Pro+ plan
            </Link>
            .
          </Typography>
        )}
        <List sx={{ maxWidth: 500 }}>
          {(trainingPlan.files || [])
            .filter((f) => !!f)
            .map((file) => {
              return (
                <ListItem
                  key={file.url}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        handleUploadRemove(file);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton>
                    <ListItemIcon style={{ marginRight: 4 }}>
                      {file.imageUrl && (
                        <img
                          style={{ marginRight: 4 }}
                          width="48"
                          height="48"
                          src={file.imageUrl}
                        />
                      )}
                      {!file.imageUrl && (
                        <Link href={file.url}>
                          <AttachFileIcon /> <br />{' '}
                          <FormHelperText>
                            {bytesToSize(file.sizeInBytes, 0)}
                          </FormHelperText>
                        </Link>
                      )}
                    </ListItemIcon>
                    <TextField
                      label="Name"
                      value={file.label}
                      onChange={(e) => {
                        handleEdit(e, file);
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
        <form
          id="detailsForm"
          className="form-horizontal"
          onSubmit={handleSave}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <MediaUpload
                onError={handleUploadError}
                onSuccess={handleUploadSuccess}
              />
            </Grid>
            <Grid item xs={8}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
