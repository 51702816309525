import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { Grid, Typography, Container } from '@mui/material';

import Page from '../Page';
import AppTheme from './AppTheme';
import AppPreview from '../app_preview/AppPreview';
import { useMutation, useQuery } from '@apollo/client';
import { GET_APP_INFO_PAGE, UPDATE_APP_INFO } from '../../graphql/app_info';
import ServerError from '../error/ServerError';
import Loading from '../shared/Loading';
import { useSnackbar } from 'notistack';
import config from '../../config';

const AppThemePage = (props) => {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [appInfo, setAppInfo] = useState(null);
  const location = useLocation();
  const queryParams = QueryString.parse(location.search);
  const [appPreviewRefresh, setAppPreviewRefresh] = useState(1);
  const trainerId = queryParams.trainerId || null;
  const input = trainerId ? { variables: { trainerId } } : {};
  const { error, loading, data } = useQuery(GET_APP_INFO_PAGE, input);
  const [updateAppInfo] = useMutation(UPDATE_APP_INFO, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });
  const saveAppInfo = () => {
    if (!appInfo) {
      return;
    }
    enqueueSnackbar('Saving...', {
      autoHideDuration: 2000,
    });
    updateAppInfo({
      variables: {
        id: appInfo._id,
        input: {
          title: appInfo.title,
          supportEmail: appInfo.supportEmail,
          icon: appInfo.icon,
          onboardingImages: appInfo.onboardingImages,
          colorMode: appInfo.colorMode,
          mainColor: appInfo.mainColor,
          secondaryColor: appInfo.secondaryColor,
          appThemeId: appInfo.appThemeId,
          appWorkoutPlayerSlug: appInfo.appWorkoutPlayerSlug,
          legalName: appInfo.legalName,
          blogUrl: appInfo.blogUrl,
          feedbackUrl: appInfo.feedbackUrl,
          supportUrl: appInfo.supportUrl,
          termsUrl: appInfo.termsUrl,
          privacyPolicyUrl: appInfo.privacyPolicyUrl,
          faqUrl: appInfo.faqUrl,
          aboutUrl: appInfo.aboutUrl,
        },
      },
    });
    setTimeout(() => {
      setAppPreviewRefresh(appPreviewRefresh + 1);
    }, 2000);
  };

  useEffect(() => {
    setAppInfo(data?.appInfo);
  }, [data]);
  useEffect(() => {
    if (appInfo?.autoSave === true) {
      saveAppInfo();
      setAppInfo({ ...appInfo, autoSave: false });
    }
  }, [appInfo, appInfo?.autoSave]);

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading || !appInfo) {
    return <Loading />;
  }

  return (
    <Page title={`App Theme : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography gutterBottom variant="h3">
          App Theme
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <AppTheme
              appInfo={appInfo}
              appThemes={data?.appThemes ?? []}
              setAppInfo={setAppInfo}
              saveAppInfo={saveAppInfo}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppPreview
              key={appPreviewRefresh}
              trainerId={trainerId}
              screen="HOME"
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AppThemePage;
