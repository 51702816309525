import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import { Integrations } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';

import App from './App';
const tagManagerArgs = {
  gtmId: 'GTM-KTKSVPN',
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: 'https://24b3dca58b71438c9f20112df1e621e1@o74329.ingest.sentry.io/5497131',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
