import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  ListSubheader,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ActivityPost from './ActivityPost';
import About from './About';
import EnrollUserIntoTrainingPlanDialog from './EnrollUserIntoTrainingPlanDialog';

const ActivityTab = ({ token, user, trainingPlanSessions, activities }) => {
  const [showEnrollDialog, setShowEnrollDialog] = useState(false);

  return (
    <Grid container spacing={3}>
      <Grid item lg={3} md={3} xl={3} xs={12}>
        <About token={token} user={user} />
      </Grid>

      <Grid item lg={8} md={8} xl={8} xs={12}>
        {trainingPlanSessions && trainingPlanSessions.length === 0 && (
          <Typography variant="h4" color="textSecondary" sx={{ mb: 2 }}>
            The user hasn't enrolled in a training plan yet.
          </Typography>
        )}

        {trainingPlanSessions && trainingPlanSessions.length > 0 && (
          <List
            sx={{ mb: 3, width: '100%', bgcolor: 'background.paper' }}
            subheader={<ListSubheader>Enrolled Training Plans</ListSubheader>}
          >
            {trainingPlanSessions.map((session) => {
              return (
                <ListItem key={session._id}>
                  <ListItemText
                    primary={session.trainingPlan?.title}
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        variant="caption"
                        color="text.primary"
                      >
                        Joined ${session.createdAt}
                        <br />
                        {session.completedAt
                          ? `Completed ${session.completedAt}`
                          : ''}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        )}

        <EnrollUserIntoTrainingPlanDialog
          user={user}
          open={showEnrollDialog}
          onClose={() => {
            setShowEnrollDialog(false);
          }}
        />
        <Box mb={3}>
          <Button
            variant="outlined"
            onClick={async (e) => {
              e.preventDefault();
              setShowEnrollDialog(true);
            }}
          >
            Enroll the user into a training plan
          </Button>
        </Box>

        {activities && activities.length === 0 && (
          <Typography variant="h4" color="textSecondary">
            The user hasn't done anything yet.
          </Typography>
        )}
        {activities &&
          activities.map((activity) => {
            return <ActivityPost key={activity._id} activity={activity} />;
          })}
      </Grid>
    </Grid>
  );
};

export default ActivityTab;
