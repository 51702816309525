import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Box, Divider, Container, Tab, Tabs, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import Page from '../Page';
import AppleAppStoreIntegration from './AppleAppStoreIntegration';
import GooglePlayStoreIntegration from './GooglePlayStoreIntegration';
import AppStatus from './AppStatus';
import { createPageStyle } from '../../theme';
import config from '../../config';

const useStyles = makeStyles(createPageStyle);

const tabs = [
  { value: 'status', label: 'Status' },
  { value: 'apple_integration', label: 'Apple App Store' },
  { value: 'google_integration', label: 'Google Play Store' },
];

export default function AppPublishing(props) {
  const classes = useStyles({});
  const [currentTab, setCurrentTab] = useState('status');
  const history = useHistory();
  const params = useParams();
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    history.push(`/trainer/app_publishing/current/${value}`);
  };

  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  return (
    <Page title={`App Publishing : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          App Publishing
        </Typography>

        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === 'status' && <AppStatus />}
          {currentTab === 'apple_integration' && <AppleAppStoreIntegration />}
          {currentTab === 'google_integration' && (
            <GooglePlayStoreIntegration />
          )}
        </Box>
      </Container>
    </Page>
  );
}
