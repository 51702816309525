import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import WorkoutExerciseItem from './WorkoutExerciseItemV1';
import ExerciseEditorDialog from './../exercises/ExerciseEditorDialog';
import ExercisePreviewDialog from './ExercisePreviewDialog';
import WorkoutExerciseSearch from './WorkoutExerciseSearch';
import Loading from '../shared/Loading';
import { mongoObjectId } from '../../utils/mongoObjectId';

const pageStyle = makeStyles((theme) => ({
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  table: {
    minWidth: 1200,
  },
}));

// @deprecated older version, use WorkoutContent
const WorkoutExerciseList = ({ workout, setWorkout, saveWorkout }) => {
  const classes = pageStyle();
  const [selectedWorkoutExerciseIds, setSelectedWorkoutExerciseIds] = useState(
    []
  );
  const [exerciseForPreview, setExerciseForPreview] = useState(null);
  const [openExercisePreview, setOpenExercisePreview] = useState(false);
  const [openExerciseCreator, setOpenExerciseCreator] = useState(false);
  const [bulkExerciseGroupName, setBulkExerciseGroupName] = useState('');
  const [enableBulkOperations, setEnableBulkOperations] = useState(false);
  const [selectedSomeExercises, setSelectedSomeExercises] = useState(false);
  const [selectedAllExercises, setSelectedAllExercises] = useState(false);

  useEffect(() => {
    setEnableBulkOperations(selectedWorkoutExerciseIds.length > 0);
  }, [selectedWorkoutExerciseIds]);
  useEffect(() => {
    setSelectedSomeExercises(
      selectedWorkoutExerciseIds.length > 0 &&
        selectedWorkoutExerciseIds.length < workout?.exercises?.length
    );
  }, [workout?.exercises?.length, selectedWorkoutExerciseIds]);
  useEffect(() => {
    setSelectedAllExercises(
      selectedWorkoutExerciseIds.length === workout?.exercises?.length
    );
  }, [workout?.exercises?.length, selectedWorkoutExerciseIds]);

  // const debouncedSaveWorkout = useRef(_.debounce(saveWorkout, 1000));
  const handleExerciseSaved = (exercise) => {
    setOpenExerciseCreator(false);
    handleExerciseSelected(exercise);
  };
  const handleExercisePreviewClose = () => {
    setOpenExercisePreview(false);
  };
  const handleExerciseCreateClose = () => {
    setOpenExerciseCreator(false);
  };
  const handleExercisePreviewOpen = (exercise) => {
    setExerciseForPreview(exercise);
    setOpenExercisePreview(true);
  };
  const handleRemoveSelected = () => {
    const changes = _.cloneDeep(workout);
    changes.exercises = changes.exercises ?? [];
    changes.exercises = changes.exercises.filter(
      (i) => !selectedWorkoutExerciseIds.includes(i._id)
    );
    changes.autoSave = false;
    setWorkout(changes);
  };
  const handleCopySelected = () => {
    const toCopy = _.cloneDeep(
      workout.exercises.filter((i) =>
        selectedWorkoutExerciseIds.includes(i._id)
      )
    );
    if (!toCopy || !toCopy.length) {
      return;
    }
    toCopy.forEach((i) => {
      i._id = mongoObjectId();
    });
    const changes = _.cloneDeep(workout);
    changes.exercises = changes.exercises ?? [];
    changes.exercises = changes.exercises.concat(toCopy);
    changes.autoSave = false;
    setWorkout(changes);
  };
  const handleCreateExerciseOpen = () => {
    setExerciseForPreview(null);
    setOpenExerciseCreator(true);
  };
  const handleExerciseSelected = (exercise) => {
    // add default values
    const copy = _.cloneDeep({
      ...exercise,
      duration: 10,
      reps: 10,
      sets: 1,
      weight: 0,
      weightUnit: 'lbs',
      restBetweenSetsDuration: 0,
      restAfterExerciseDuration: 60,
      isTimed: false,
    });
    copy.exerciseId = exercise._id;
    copy._id = mongoObjectId();
    const changes = _.cloneDeep(workout);
    changes.exercises = changes.exercises ?? [];
    changes.exercises.push(copy);
    changes.autoSave = false;
    setWorkout(changes);
  };
  const handleExerciseListItemMove = (sourceIndex, targetIndex) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    const exercisesChanges = _.cloneDeep(workout.exercises);
    const movedExercises = exercisesChanges.splice(sourceIndex, 1);
    exercisesChanges.splice(targetIndex, 0, movedExercises[0]);

    const changes = _.cloneDeep(workout);
    changes.exercises = exercisesChanges;
    changes.autoSave = false;
    setWorkout(changes);
  };
  const handleExerciseListItemRemove = (index) => {
    const changes = _.cloneDeep(workout);
    changes.exercises.splice(index, 1);
    changes.autoSave = false;
    setWorkout(changes);
  };
  const handleExerciseListItemDuplicate = (index) => {
    const copy = _.cloneDeep(workout.exercises[index]);
    copy._id = mongoObjectId();
    const changes = _.cloneDeep(workout);
    changes.exercises.splice(index, 0, copy);
    changes.autoSave = false;
    setWorkout(changes);
  };
  const handleExerciseListItemEdit = (exercise) => {
    const changes = _.cloneDeep(workout);
    changes.exercises = changes.exercises.map((i) => {
      if (i._id === exercise._id) {
        return exercise;
      }
      return i;
    });
    changes.autoSave = false;
    setWorkout(changes);
    // This feature is disabled
    // https://gitlab.com/justtempo/web/tempo-cms/-/issues/38
    // debouncedSaveWorkout.current(changes);
  };
  const handleSetWorkoutExerciseGroupName = (e) => {
    const groupName = e.target.value;
    setBulkExerciseGroupName(groupName);

    const changes = _.cloneDeep(workout);
    changes.exercises = changes.exercises ?? [];
    changes.exercises.forEach((i) => {
      if (selectedWorkoutExerciseIds.includes(i._id)) {
        i.groupName = groupName;
      }
    });
    changes.autoSave = false;
    setWorkout(changes);
  };
  const handleCircuitChange = (e) => {
    const changes = _.cloneDeep(workout);
    changes[e.target.name] = parseInt(e.target.value);
    changes.autoSave = false;
    setWorkout(changes);
  };
  const handleSelectAllExercises = (e) => {
    const changes = e.target.checked ? workout.exercises.map((e) => e._id) : [];
    setSelectedWorkoutExerciseIds(changes);
  };
  const handleSelectOneWorkoutExercise = (e, workoutExerciseId) => {
    if (!selectedWorkoutExerciseIds.includes(workoutExerciseId)) {
      setSelectedWorkoutExerciseIds([
        ...selectedWorkoutExerciseIds,
        workoutExerciseId,
      ]);
    } else {
      setSelectedWorkoutExerciseIds(
        selectedWorkoutExerciseIds.filter((id) => id !== workoutExerciseId)
      );
    }
  };

  if (!workout) {
    return <Loading />;
  }

  let emptyExercisesMsg;
  if (workout?.exercises?.length === 0) {
    emptyExercisesMsg = (
      <Box>
        <Typography variant="h3">Getting Started</Typography>
        <Typography variant="body1" paragraph>
          Click Add Exercise to add another part to the workout.
        </Typography>
        <Typography variant="body1" paragraph>
          In a yoga workout, exercises can be the different poses: downward dog,
          warrior 1
        </Typography>
        <Typography variant="body1" paragraph>
          In a running workout, exercises can be: Run 400m, Jog for 100m, Run
          800m.
        </Typography>
      </Box>
    );
  }

  let items = workout?.exercises?.map((e, i) => {
    const isSelected = selectedWorkoutExerciseIds.includes(e._id);
    return (
      <WorkoutExerciseItem
        key={`${e._id}_${e.groupName}_${i}`}
        index={i}
        workoutExercise={e}
        onUpdate={handleExerciseListItemEdit}
        onMove={handleExerciseListItemMove}
        onRemove={handleExerciseListItemRemove}
        onDuplicate={handleExerciseListItemDuplicate}
        onSelectedWorkoutExercise={handleSelectOneWorkoutExercise}
        onWorkoutExercisePreview={handleExercisePreviewOpen}
        isSelected={isSelected}
      />
    );
  });

  const circuitDetails = (
    <Grid container spacing={3}>
      <Grid item xs={6} md={4} lg={2}>
        <TextField
          fullWidth
          helperText={
            'How many times should all the exercises below be repeated?'
          }
          label="Rounds"
          name="rounds"
          InputProps={{ inputProps: { min: 0, max: 50 } }}
          type="number"
          onChange={handleCircuitChange}
          value={workout.rounds ?? ''}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2}>
        <TextField
          fullWidth
          helperText={'Rest time in seconds between each round.'}
          label="Rest Between Rounds"
          name="restBetweenRounds"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 1000 } }}
          onChange={handleCircuitChange}
          value={workout.restBetweenRounds ?? ''}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={3}>
            <Card>
              <CardHeader title="Rounds" />
              <CardContent>{circuitDetails}</CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} lg={2}>
              <Box m={3}>
                <Typography variant="h3" color="textPrimary">
                  Exercises
                </Typography>
              </Box>
              <WorkoutExerciseSearch
                onSelect={handleExerciseSelected}
                onAddNew={handleCreateExerciseOpen}
              />
            </Grid>

            <Grid item xs={12} md={9} lg={10}>
              <Box mb={3}>
                {enableBulkOperations && (
                  <div>
                    <div>
                      <Checkbox
                        checked={selectedAllExercises}
                        indeterminate={selectedSomeExercises}
                        onChange={handleSelectAllExercises}
                      />
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="outlined"
                        className={classes.bulkAction}
                        onClick={handleRemoveSelected}
                      >
                        Remove Selected
                      </Button>
                      <Button
                        startIcon={<CopyIcon />}
                        variant="outlined"
                        className={classes.bulkAction}
                        onClick={handleCopySelected}
                      >
                        Copy Selected
                      </Button>

                      <TextField
                        className={classes.bulkAction}
                        helperText={
                          'Can be used to name back to back superset. This will appear as a subtitle to the exercise.'
                        }
                        label="Exercise Group Name"
                        name="groupName"
                        type="text"
                        onChange={handleSetWorkoutExerciseGroupName}
                        value={bulkExerciseGroupName ?? ''}
                        variant="outlined"
                      />
                      <Box component="span" m={1}>
                        <Button
                          color="primary"
                          variant="contained"
                          startIcon={<SaveIcon />}
                          onClick={() => saveWorkout(workout)}
                        >
                          Save
                        </Button>
                      </Box>
                    </div>
                  </div>
                )}
              </Box>

              <Box mb={3}>{emptyExercisesMsg}</Box>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>-</TableCell>
                      <TableCell>Exercise</TableCell>
                      <TableCell>
                        Set Duration
                        <br />
                        (in secs)
                      </TableCell>
                      <TableCell>Reps</TableCell>
                      <TableCell>Sets</TableCell>
                      <TableCell>
                        Suggested
                        <br />
                        Weight
                      </TableCell>
                      <TableCell>
                        Rest Between Sets
                        <br />
                        (in secs)
                      </TableCell>
                      <TableCell>
                        Rest After Exercise
                        <br />
                        (in secs)
                      </TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{items}</TableBody>
                </Table>
              </TableContainer>

              <ExercisePreviewDialog
                exercise={exerciseForPreview || {}}
                open={openExercisePreview}
                onClose={handleExercisePreviewClose}
              />
              <ExerciseEditorDialog
                key={'new'}
                onSaved={handleExerciseSaved}
                open={openExerciseCreator}
                onClose={handleExerciseCreateClose}
                exercise={{}}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default WorkoutExerciseList;
