import {
  List,
  ListItem,
  ListItemText,
  Box,
  Grid,
  Typography,
} from '@mui/material';

import AppPreviewComponent from '../app_preview/AppPreview';
import { Link as RouterLink } from 'react-router-dom';
import config from '../../config';

function NextStepsHelp() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary" gutterBottom>
            App Preview
          </Typography>
          <Typography variant="body1" color="textPrimary" gutterBottom>
            Below is a limited preview of your app. <br />
            <br /> NOTE: You can click <strong>'Skip'</strong> to try app
            without logging in.
          </Typography>
        </Box>
        <Box mt={2} display="flex" justifyContent="center" alignItems="center">
          <AppPreviewComponent showHeader={false} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mt={2}>
          <Typography variant="h4" color="textSecondary" align="center">
            Other Next Steps
          </Typography>

          <List>
            <ListItem>
              <ListItemText>
                🏋️ Add Your{' '}
                <RouterLink to="/trainer/training_plans">
                  Fitness Content
                </RouterLink>
                .
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                🏠 Add a{' '}
                <RouterLink to="/trainer/posts">Welcome Post</RouterLink> for
                your customers.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                💰 Setup your{' '}
                <RouterLink to="/trainer/web_site">Storefront</RouterLink> to
                sell your content.
              </ListItemText>
            </ListItem>

            {config.showSubscriptionPage && (
              <ListItem>
                <ListItemText>
                  📱{' '}
                  <RouterLink to="/trainer/checkout">
                    Subscribe to a paid plan
                  </RouterLink>{' '}
                  to publish your app to the app stores.
                </ListItemText>
              </ListItem>
            )}
          </List>
        </Box>
        <Box mt={2}>
          <Typography variant="h4" color="textSecondary" align="center">
            Learn More
          </Typography>

          <List>
            {config.showDocsLink && (
              <ListItem>
                <ListItemText>
                  🤷{' '}
                  <a
                    href="https://docs.tempokit.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Our Help Center
                  </a>{' '}
                  We have a help center with videos to describe how to configure
                  and publish your app.
                </ListItemText>
              </ListItem>
            )}
            <ListItem>
              <ListItemText>
                💌 <a href={`mailto:${config.emailContactAddress}`}>Email Us</a>{' '}
                Reach out to us for any questions: pricing, feature requests,
                customized designs and setup help.
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Grid>
    </Grid>
  );
}

export default NextStepsHelp;
