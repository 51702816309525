import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@mui/material';

const Header = ({ className, ...rest }) => {
  return (
    <div className={clsx(className)} {...rest}>
      <Typography variant="h3" color="textPrimary">
        Account
      </Typography>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
