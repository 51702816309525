import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  Select,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { Alert } from '@mui/material';
import CurrencyTextField from '../shared/CurrencyTextField';
import Loading from '../shared/Loading';
import displayHelper from '../../utils/displayHelper';

import { useAuth } from '../../hooks/use-auth';

const GET_STRIPE_PRICES_QUERY = gql`
  query StripeSubscriptionPrices($trainerId: ID) {
    trainerPayoutAccount(trainerId: $trainerId) {
      _id
      stripeConnectAccountId
    }

    stripeSubscriptionPrices(trainerId: $trainerId) {
      monthlyPrice
      yearlyPrice
      currency

      stripeMonthSubscriptionPriceId
      stripeYearSubscriptionPriceId
    }
  }
`;

const SAVE_STRIPE_PRICES_MUTATION = gql`
  mutation SaveStripeSubscriptionPrices(
    $input: SaveStripeSubscriptionPricesInput!
  ) {
    saveStripeSubscriptionPrices(input: $input) {
      monthlyPrice
      yearlyPrice
      currency

      stripeMonthSubscriptionPriceId
      stripeYearSubscriptionPriceId
    }
  }
`;

export default function SubscriptionPlansStripe({ trainerId }) {
  const {
    loading: dataLoading,
    error,
    data,
    refetch,
  } = useQuery(GET_STRIPE_PRICES_QUERY, { variables: { trainerId } });
  const [saveSubscriptionPrices, { error: mutationError, data: saveData }] =
    useMutation(SAVE_STRIPE_PRICES_MUTATION);

  const { enqueueSnackbar } = useSnackbar();
  const [subscriptionPricesInput, setSubscriptionPricesInput] = useState({
    currency: 'usd',
  });
  const [saving, setSaving] = useState(false);
  const auth = useAuth();
  const authLoading = auth.loading;

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);

    enqueueSnackbar('Saving...', { autoHideDuration: 2000 });
    try {
      console.log('saving');

      const vars = {
        input: {
          monthlyPrice: subscriptionPricesInput.monthlyPrice,
          yearlyPrice: subscriptionPricesInput.yearlyPrice,
          currency: subscriptionPricesInput.currency || 'usd',
        },
      };

      await saveSubscriptionPrices({ variables: vars });
      await refetch();
    } catch (err) {
      console.error(err.message);
    }
    setSaving(false);
  };

  const handleEdit = (e) => {
    const changes = { ...subscriptionPricesInput };
    changes[e.target.name] = e.target.value;
    console.log(changes);
    setSubscriptionPricesInput(changes);
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setSubscriptionPricesInput(data.stripeSubscriptionPrices);
  }, [data]);

  if (authLoading || dataLoading) {
    return <Loading />;
  }
  if (!data) return <Loading />;

  const hasConnectedStripeAccount =
    !!data.trainerPayoutAccount?.stripeConnectAccountId;

  return (
    <Box>
      <form className="form-horizontal" onSubmit={handleSave}>
        <Box mt={3}>
          <Card>
            <CardHeader title="Stripe Pricing" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {hasConnectedStripeAccount}
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    paragraph
                  >
                    These membership prices will be available to your customers
                    on the{' '}
                    <RouterLink to="/trainer/web_site/checkout">
                      web checkout page
                    </RouterLink>
                    . Stripe only takes a small 3.5% fee compared to Apple's 15%
                    fee.
                  </Typography>

                  {error && <Alert severity="error">{error.message}</Alert>}
                  {mutationError && (
                    <Alert severity="error">{mutationError.message}</Alert>
                  )}
                  {!hasConnectedStripeAccount && (
                    <Alert severity="warning">
                      <RouterLink to="/trainer/account/payout">
                        Connect your Stripe account
                      </RouterLink>{' '}
                      before you can set your pricing.
                    </Alert>
                  )}

                  {hasConnectedStripeAccount && (
                    <Typography variant="body2" color="textSecondary" paragraph>
                      Stripe Account ID:{' '}
                      {data.trainerPayoutAccount?.stripeConnectAccountId}
                    </Typography>
                  )}
                </Grid>

                {hasConnectedStripeAccount && (
                  <>
                    <Grid item xs={12} md={9}>
                      <Select
                        variant="outlined"
                        native
                        name="currency"
                        onChange={handleEdit}
                        value={subscriptionPricesInput.currency ?? 'usd'}
                      >
                        <option value="">-</option>
                        <option value="usd">USD - US Dollar</option>
                        <option value="gbp">GBP - British Pound</option>
                        <option value="eur">EUR - Euro</option>
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <CurrencyTextField
                        currencySymbol={
                          displayHelper.currencySymbolMap[
                            subscriptionPricesInput.currency
                          ] || '$'
                        }
                        label="Monthly Subscription Price"
                        name="monthlyPrice"
                        onChange={handleEdit}
                        value={subscriptionPricesInput.monthlyPrice || ''}
                        decimalCharacter={displayHelper.decimalCharacter}
                        digitGroupSeparator={displayHelper.digitGroupSeparator}
                        type="text"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <CurrencyTextField
                        currencySymbol={
                          displayHelper.currencySymbolMap[
                            subscriptionPricesInput.currency
                          ] || '$'
                        }
                        label="Yearly Subscription Price"
                        name="yearlyPrice"
                        onChange={handleEdit}
                        value={subscriptionPricesInput.yearlyPrice || ''}
                        decimalCharacter={displayHelper.decimalCharacter}
                        digitGroupSeparator={displayHelper.digitGroupSeparator}
                        type="text"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mt={2}>
          <Button color="secondary" variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
}
