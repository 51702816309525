import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Rating } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20,
  },
  date: {
    marginLeft: 6,
  },
  media: {
    height: 300,
    backgroundPosition: 'top',
  },
}));

const AppStoreReview = ({ className, appStoreReview, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        subheader={
          <>
            <Rating
              name="size-small"
              defaultValue={appStoreReview.rating}
              size="small"
            />
            <Typography
              variant="subtitle"
              color="textSecondary"
              className={classes.date}
            >
              By {appStoreReview.author}
            </Typography>
          </>
        }
        title={
          <Typography color="textPrimary" variant="h6">
            <a href={appStoreReview.link}>{appStoreReview.title}</a>
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="body1">{appStoreReview.comment}</Typography>
      </CardContent>
    </Card>
  );
};

AppStoreReview.propTypes = {
  className: PropTypes.string,
  appStoreReview: PropTypes.object.isRequired,
};

export default AppStoreReview;
