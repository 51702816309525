import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Card, CardHeader, Typography } from '@mui/material';
import { useAuth } from '../../hooks/use-auth';

const Note = ({ note }) => {
  const auth = useAuth();

  return (
    <Card>
      <CardHeader
        avatar={<Avatar src={auth.user.photoURL} alt="Me" />}
        subheader={
          <Box display="flex" alignItems="center">
            <Typography variant="caption" color="textSecondary">
              {note.createdAt}
            </Typography>
          </Box>
        }
      />
      <Box px={3} pb={2}>
        <Typography variant="body1">{note.body}</Typography>
      </Box>
    </Card>
  );
};

Note.propTypes = {
  className: PropTypes.string,
  note: PropTypes.object.isRequired,
};

export default Note;
