import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Tab, Tabs, Typography } from '@mui/material';

import Page from '../Page';
import TrainerList from './TrainerList';

const tabs = [{ value: 'trainers', label: 'Trainers' }];

const AdminPage = (props) => {
  const [currentTab, setCurrentTab] = useState('trainers');
  const params = useParams();
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  return (
    <Page title="Trainers : Tempokit">
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          Admin
        </Typography>

        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>{currentTab === 'trainers' && <TrainerList />}</Box>
      </Container>
    </Page>
  );
};

export default AdminPage;
