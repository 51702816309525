import React, { useState, useEffect } from 'react';
import QueryString from 'query-string';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Tabs,
  Tab,
  Divider,
  Typography,
  Box,
  Button,
  FormHelperText,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import LabelledOutline from '../../components/shared/LabelledOutline';
import DirectUpload from '../../components/shared/DirectUpload';
import Loading from '../../components/shared/Loading';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SALES_PAGE, UPDATE_SALES_PAGE } from '../../graphql/sales_page';
import ServerError from '../error/ServerError';
import WebPreviewIframe from '../../components/app_preview/WebPreviewIframe';
const tabs = [
  { value: 'preview', label: 'Preview' },
  { value: 'edit', label: 'Edit' },
];

const fields = [
  {
    name: 'headline',
    label: 'Headline',
    help: 'Max 25 characters. This title will be the large text on the sales page.',
  },
  {
    name: 'subtitle',
    label: 'Subtitle',
    help: 'This will be below your headline',
  },
  {
    name: 'completionRedirectUrl',
    label: 'Completion Redirect Url',
    help: 'Optional: Do you have a separate web page you want to redirect to after purchase? Use https:// prefix.',
  },
];

const General = ({ className, match, ...rest }) => {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;

  const [currentTab, setCurrentTab] = useState('preview');

  const { enqueueSnackbar } = useSnackbar();
  const [salesPage, setSalesPage] = useState({});
  const [previewRefresh, setPreviewRefresh] = useState(1);
  const { error, loading, data } = useQuery(GET_SALES_PAGE, {
    variables: { trainerId },
  });
  const [updateSalesPage] = useMutation(UPDATE_SALES_PAGE, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });

  useEffect(() => {
    setSalesPage(data?.salesPage);
  }, [data]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleUploadError = () => {
    enqueueSnackbar(`Media upload failed!`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };

  const handleUploadSuccess = (uploadedRes) => {
    setSalesPage({ ...salesPage, backgroundImage: uploadedRes.image });
  };

  const handleUploadRemove = (file) => {
    setSalesPage({ ...salesPage, backgroundImage: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!salesPage) {
      return;
    }
    enqueueSnackbar(`Saving...`, {
      autoHideDuration: 2000,
    });

    await updateSalesPage({
      variables: {
        id: salesPage._id,
        input: {
          headline: salesPage.headline,
          subtitle: salesPage.subtitle,
          completionRedirectUrl: salesPage.completionRedirectUrl,
          backgroundImage: salesPage.backgroundImage,
        },
      },
    });

    setTimeout(() => {
      setPreviewRefresh(previewRefresh + 1);
    }, 2000);
  };

  const handleChange = (e) => {
    const changes = { ...salesPage };
    changes[e.target.name] = e.target.value;
    setSalesPage(changes);
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading || !salesPage) {
    return <Loading />;
  }

  const previewUrl = salesPage.url;

  return (
    <Card>
      <CardContent>
        <Box mb={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />
        </Box>

        {currentTab === 'preview' && (
          <Box>
            <Box mb={3}>
              <Typography variant="h5" gutterBottom>
                Link:{' '}
                <a href={previewUrl} target="_blank" rel="noreferrer">
                  {previewUrl}
                </a>
              </Typography>

              <CopyToClipboard
                text={previewUrl}
                onCopy={() => {
                  window.alert(`Copied ${previewUrl}`);
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<CopyIcon />}
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
            </Box>

            <Box>
              <WebPreviewIframe key={previewRefresh} url={previewUrl} />
            </Box>
          </Box>
        )}

        {currentTab === 'edit' && (
          <form className={className} onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {fields.map((field) => {
                return (
                  <Grid key={field.name} item xs={12} md={8}>
                    <TextField
                      fullWidth
                      label={field.label}
                      name={field.name}
                      onChange={handleChange}
                      value={salesPage[field.name] || ''}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormHelperText>{field.help}</FormHelperText>
                  </Grid>
                );
              })}
              <Grid item xs={12} md={8}>
                <LabelledOutline id="background-image" label="Background Image">
                  <DirectUpload
                    fileType="image"
                    key={'sales-page-media-upload'}
                    images={[salesPage.backgroundImage]}
                    onError={handleUploadError}
                    onSuccess={handleUploadSuccess}
                    onRemove={handleUploadRemove}
                    maxFiles={1}
                  />

                  <FormHelperText>
                    This is the background image. Better to make it dark.
                  </FormHelperText>
                </LabelledOutline>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Button variant="contained" color="secondary" type="submit">
                Save
              </Button>
            </Box>
          </form>
        )}
      </CardContent>
    </Card>
  );
};

General.propTypes = {
  className: PropTypes.string,
};

export default General;
