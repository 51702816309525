import React, { useState } from 'react';

import Page from '../Page';
import { Box, Divider, Tab, Tabs, Container, Typography } from '@mui/material';
import MealList from './MealList';
import config from '../../config';

const tabs = [{ value: 'meals', label: 'Meals' }];

export default function Nutrition() {
  const [currentTab, setCurrentTab] = useState('meals');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Page title={`Nutrition : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h4" color="textPrimary">
          Nutrition
        </Typography>

        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>{currentTab === 'meals' && <MealList />}</Box>
      </Container>
    </Page>
  );
}
