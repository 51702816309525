import { gql } from '@apollo/client';

const WORKOUT_EDITOR_FRAGMENT = gql`
  fragment WorkoutEditorFragment on Workout {
    __typename
    _id
    title
    desc
    userId
    durationTotal
    rounds
    restBetweenRounds
    tags
    status
    coverImageUrl
    workoutType
    level
    completedCount
    youtubeVideoUrl
    youtubeVideoId
    mobilePreviewUrl
    webUrl
    videos {
      url
      imageUrl
      contentType
    }
    exercises {
      __typename
      _id
      exerciseId
      title
      desc
      groupName
      notes
      sets
      reps
      percentOf1RM
      duration
      weight
      weightUnit
      restBetweenSetsDuration
      restAfterExerciseDuration
      isTimed
      images {
        url
      }
      videos {
        url
      }
      youtubeVideoId
    }
    exerciseGroups {
      __typename
      _id
      groupName
      rounds
      restBetweenRounds
      exercises {
        __typename
        _id
        exerciseId
        title
        desc
        groupName
        notes
        sets
        reps
        percentOf1RM
        duration
        weight
        weightUnit
        restBetweenSetsDuration
        restAfterExerciseDuration
        isTimed
        images {
          url
        }
        videos {
          url
        }
        youtubeVideoId
      }
    }
    intervals {
      __typename
      intervalType
      startTime
      endTime
      round
      exercise {
        __typename
        _id
        title
        groupName
        reps
        percentOf1RM
        images {
          url
        }
        videos {
          url
        }
      }
    }
  }
`;

export const GET_WORKOUT_QUERY = gql`
  query GetWorkout($id: ID!) {
    workout(id: $id) {
      ...WorkoutEditorFragment
    }
  }
  ${WORKOUT_EDITOR_FRAGMENT}
`;

export const UPDATE_WORKOUT_MUTATION = gql`
  mutation UpdateWorkout($id: ID!, $input: WorkoutInput!) {
    updateWorkout(id: $id, input: $input) {
      ...WorkoutEditorFragment
    }
  }
  ${WORKOUT_EDITOR_FRAGMENT}
`;
