import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, Container, Button, Typography, TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useAuth } from '../../hooks/use-auth';

import Page from '../Page';
import ReferralList from './ReferralList';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const InfoBox = ({ url }) => (
  <Box mb={3}>
    <Box mb={3}>
      <Typography variant="body1" gutterBottom={true}>
        You can refer other trainers and get 10% of {config.appDisplayName}’s
        revenue from that trainer’s subscriptions.
      </Typography>
    </Box>

    <Typography variant="h4" gutterBottom={true}>
      Your Referral Link
    </Typography>

    <Box mb={1} width={800}>
      <TextField
        disabled={true}
        variant="outlined"
        value={url}
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>

    <CopyToClipboard
      text={url}
      onCopy={() => {
        window.alert(`Copied ${url}`);
      }}
    >
      <Button variant="contained" color="secondary" startIcon={<CopyIcon />}>
        Copy
      </Button>
    </CopyToClipboard>
  </Box>
);

const ReferralsPage = (props) => {
  const { user } = useAuth();
  const url = `${window.location.origin}/signup?referralCode=${user.uid}`;

  return (
    <Page title={`Referrals : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom={true}>
          Referrals
        </Typography>

        <Box style={{ maxWidth: 1080 }} mt={3}>
          <InfoBox url={url} />
          <ReferralList />
        </Box>
      </Container>
    </Page>
  );
};

export default ReferralsPage;
