import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import {
  Link,
  IconButton,
  ListItem,
  Typography,
  ListItemText,
} from '@mui/material';
import AppConstants from '../../constants/AppConstants';
import { Image as ImageIcon } from 'react-feather';
import { DragSource } from 'react-dnd';

const workoutSource = {
  beginDrag: function (props) {
    // this get used by squareTarget#drop as monitor.getItem()
    return props;
  },
};

const collect = function (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

class TrainingPlanWorkoutListItem extends React.Component {
  render() {
    const connectDragSource = this.props.connectDragSource;
    const isDragging = this.props.isDragging;
    const workout = this.props.workout; // this is actually the "event", the workout id is workout.objectId
    const eventId = workout._id;

    if (!workout) {
      return <div />;
    }

    let media;
    if (workout.coverImageUrl) {
      media = (
        <img
          alt="Workout"
          src={workout.coverImageUrl}
          style={{ width: 20, height: 20, margin: 3, verticalAlign: 'middle' }}
        />
      );
    } else {
      media = <ImageIcon size={20} />;
    }

    const display = (
      <div key={eventId}>
        {' '}
        <ListItem
          sx={{
            position: 'relative',
            cursor: 'move',
            opacity: isDragging ? 0.5 : 1,
            backgroundColor: '#f4f6f8',
          }}
          disableGutters
          secondaryAction={
            !!this.props.onRemove && (
              <IconButton
                size="small"
                edge="end"
                aria-label="remove"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.onRemove(workout);
                }}
              >
                ×
              </IconButton>
            )
          }
        >
          <Link href={`/trainer/workouts/${workout.objectId}`} target="_blank">
            <ListItemText
              primary={
                <Typography variant="caption">
                  {media}
                  {workout.title}
                </Typography>
              }
            />
          </Link>
        </ListItem>
      </div>
    );

    return connectDragSource(display);
  }
}

TrainingPlanWorkoutListItem.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
};

export default DragSource(
  AppConstants.WORKOUT,
  workoutSource,
  collect
)(withSnackbar(TrainingPlanWorkoutListItem));
