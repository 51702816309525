import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import request from 'superagent';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import config, { apiUrl } from '../../config';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useAuth } from '../../hooks/use-auth';
import Loading from '../../components/shared/Loading';

const useStyles = makeStyles((theme) => ({
  root: {},
  overview: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  productImage: {
    marginRight: theme.spacing(1),
    height: 48,
    width: 48,
  },
  details: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

const Payout = ({
  history,
  className,
  verifyStripeAccountConnected,
  ...rest
}) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [stripeAccount, setStripeAccount] = useState(null);
  const [alertPrompt, setAlertPrompt] = useState(null);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const handleSave = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      // TODO save the legal name here

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
      enqueueSnackbar('Account updated', {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  const handleConnectStripeAccount = async () => {
    const url = `${apiUrl}/v1/stripe_connect/standard_account`;

    const authToken = await auth.user.getIdToken();
    setLoading(true);
    request
      .post(url)
      .auth(authToken, { type: 'bearer' })
      .set('Accept', 'application/json')
      .field('platformUrl', config.appLandingPageHost)
      .then((response) => {
        const redirectUrl = response.body.url;
        window.location = redirectUrl;
      })
      .catch((err) => {
        console.error(err.message);
        alert(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPayout = useCallback(async () => {
    const url = `${apiUrl}/v1/stripe_connect/account/verify`;
    const authToken = await auth.user.getIdToken();
    request
      .post(url)
      .auth(authToken, { type: 'bearer' })
      .set('Accept', 'application/json')
      .then((response) => {
        // verify param is used when we want to prompt a success or failure to connect
        if (verifyStripeAccountConnected) {
          let level = response.body.payoutAccount ? 'info' : 'error';
          setAlertPrompt({ level: level, message: response.body.message });
        }
        if (isMountedRef.current) {
          setStripeAccount(response.body.payoutAccount);
        }
      })
      .catch((err) => {
        console.error(err.message);
        alert(err.message);

        return;
      });
  }, [verifyStripeAccountConnected, auth.user, isMountedRef]);

  useEffect(() => {
    getPayout();
  }, [getPayout]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {alertPrompt && (
        <>
          <Alert severity={alertPrompt.level}>{alertPrompt.message}</Alert>
          <br />
        </>
      )}
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Payout Details" />
        <Divider />
        <CardContent>
          <Box mb={3}>
            <Typography variant="h3" gutterBottom>
              Apple and Google
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              Sales through Apple and Google go directly to your app store
              account.{' '}
            </Typography>
          </Box>

          <Typography variant="h3" gutterBottom>
            Web Payments
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Connect your Stripe account to sell subscriptions on a personalized{' '}
            <RouterLink to="/trainer/web_site/checkout">
              web checkout page
            </RouterLink>
            .
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            By allowing your customers to buy subscriptions outside of the app
            store, you are charged a lower fee(~3% vs 15%) by Stripe compared to
            Apple/Google.
          </Typography>
          {!!stripeAccount && (
            <Typography variant="body2" color="textSecondary" paragraph>
              Stripe Account ID: {stripeAccount.stripeConnectAccountId}
            </Typography>
          )}

          {stripeAccount ? (
            <Button
              component={Link}
              size="large"
              color="primary"
              variant="contained"
              href={stripeAccount.stripeConnectDashboardLink}
            >
              Login to your Stripe account
            </Button>
          ) : (
            <Button
              component={Link}
              size="large"
              color="primary"
              variant="outlined"
              onClick={handleConnectStripeAccount}
            >
              Connect Stripe account
            </Button>
          )}
        </CardContent>
      </Card>
    </>
  );
};

Payout.propTypes = {
  className: PropTypes.string,
};

export default Payout;
