import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { Breadcrumbs, Link, Typography, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Header = ({ className, trainingPlan, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <Box mb={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/trainer/training_plans"
            component={RouterLink}
          >
            Back to Training Plans
          </Link>
        </Breadcrumbs>
      </Box>

      <Typography variant="h3" color="textPrimary" gutterBottom>
        {trainingPlan.title}
      </Typography>
      <Typography variant="caption">
        Last Saved: {moment(trainingPlan.updatedAt).format('LLL')}
      </Typography>
      <br />
      <Typography variant="caption">
        Workout Count: {trainingPlan?.events?.length}
      </Typography>
    </div>
  );
};

export default Header;
