import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../hooks/use-auth';

const NutritionTab = (props) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box mt={3}>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Nutrition coming soon.
      </Typography>
    </Box>
  );
};

export default NutritionTab;
