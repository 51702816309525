import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import NotchedOutline from '@mui/material/OutlinedInput/NotchedOutline';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    position: 'relative',
  },
  content: {
    padding: '18.5px 14px',
    borderRadius: '4px',
    borderColor: '#263238',
  },
  inputLabel: {
    position: 'absolute',
    left: 0,
    top: 0,
    borderColor: 'red',
  },
  notchedOutline: {
    borderColor: '#000000',
    opacity: 0.23,
  },
};

const LabelledOutline = ({ classes, id, label, children }) => {
  return (
    <div style={{ position: 'relative', marginTop: '8px' }}>
      <InputLabel
        htmlFor={id}
        variant="outlined"
        className={classes.inputLabel}
        shrink
      >
        {label}
      </InputLabel>
      <div className={classes.root}>
        <div id={id} className={classes.content}>
          {children}
          <NotchedOutline notched label={id} />
        </div>
      </div>
    </div>
  );
};
export default withStyles(styles)(LabelledOutline);
