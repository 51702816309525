import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { Box, Container, Divider, Tab, Tabs } from '@mui/material';

import Page from '../Page';
import Header from './Header';
import Profile from './Profile';
import Subscription from './Subscription';
import Payout from './Payout';
import Integrations from './Integrations';
import config from '../../config';

const tabs = [
  { value: 'profile', label: 'Profile' },
  { value: 'subscription', label: 'Subscription' },
  { value: 'payout', label: 'Payout' },
  { value: 'integrations', label: 'Integrations' },
];

const AccountView = (props) => {
  const [currentTab, setCurrentTab] = useState('profile');
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    history.push(`/trainer/account/${value}`);
  };

  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  const queryParams = QueryString.parse(location.search);
  const verifyStripeAccountConnected = queryParams.verify;

  return (
    <Page title={`Account : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box style={{ maxWidth: 1080 }} mt={3}>
          {currentTab === 'profile' && <Profile />}
          {currentTab === 'subscription' && <Subscription />}
          {currentTab === 'payout' && (
            <Payout
              verifyStripeAccountConnected={verifyStripeAccountConnected}
            />
          )}
          {currentTab === 'integrations' && <Integrations />}
        </Box>
      </Container>
    </Page>
  );
};

export default AccountView;
