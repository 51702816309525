import React from 'react';
import { useQuery } from '@apollo/client';

import { Divider, Grid, Card, CardHeader, CardContent } from '@mui/material';

import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import AppPreviewIframe from './AppPreviewIframe';

import { GET_APP_PREVIEW_SCREEN_QUERY } from '../../graphql/app_preview';

export default function AppPreview({
  trainerId,
  screen = 'ONBOARDING',
  showHeader = true,
}) {
  console.log(`trainerId=${trainerId}`);
  const { data, loading, error } = useQuery(GET_APP_PREVIEW_SCREEN_QUERY, {
    variables: { screen, trainerId },
    fetchPolicy: 'no-cache', // needed so when the app features page is changed, this changes too
  });

  if (error) return <ServerError message="Failed to load" />;
  if (loading) return <Loading />;
  if (!data) return <ServerError message="Failed to load" />;

  const appPreview = data.appPreview;
  const previewUrl = appPreview.url;

  return (
    <Card>
      {showHeader && <CardHeader title="App Preview" />}
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {previewUrl && <AppPreviewIframe url={previewUrl} />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
