import React from 'react';
import _ from 'lodash';
import LabelledOutline from '../../components/shared/LabelledOutline';
import { Remove as RemoveIcon } from '@mui/icons-material';
import {
  TextField,
  FormControl,
  Select,
  Box,
  Button,
  InputLabel,
} from '@mui/material';

export default function RecipeIngredient({
  ingredient,
  onChange,
  onAdd,
  onRemove,
}) {
  const handleChange = (event) => {
    let value = event.target.name.includes('macros')
      ? parseFloat(event.target.value || 0)
      : event.target.value || 0;

    let attribute = event.target.name;

    let newIngredientInput = _.cloneDeep(ingredient);
    newIngredientInput = _.set(newIngredientInput, attribute, value);

    onChange(newIngredientInput);
  };

  return (
    <LabelledOutline>
      <Box
        m={2}
        style={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px 20px',
        }}
      >
        <TextField
          style={{ width: 300 }}
          label="Item Name"
          name="name"
          onChange={handleChange}
          value={ingredient?.name || ''}
          variant="outlined"
        />

        <TextField
          fullWidth
          label="Quantity"
          name="quantity"
          placeholder="0"
          onChange={handleChange}
          value={ingredient?.quantity || ''}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          type="number"
          style={{ width: 120 }}
        />

        <FormControl variant="outlined">
          <InputLabel shrink={true} id="unit">
            Unit
          </InputLabel>
          <Select
            labelId="Unit"
            name="quantityUnit"
            value={ingredient?.quantityUnit || 'GRAM'}
            label="Unit"
            onChange={handleChange}
            native
          >
            <option value="ITEM">ITEM</option>
            <option value="CUP">CUP</option>
            <option value="OZ">OZ</option>
            <option value="TEASPOON">TEASPOON</option>
            <option value="TABLESPOON">TABLESPOON</option>
            <option value="ML">ML</option>
            <option value="GRAM">GRAM</option>
          </Select>
        </FormControl>

        {!!ingredient?._id && (
          <Button
            style={{ marginLeft: 2 }}
            onClick={(event) => onRemove(ingredient)}
            startIcon={<RemoveIcon fontSize="small" />}
          >
            Remove
          </Button>
        )}

        <div style={{ flexBasis: '100%', height: 0 }} />
        <div
          style={{ marginBottom: 10, marginTop: 10, display: 'flex', gap: 20 }}
        >
          {[
            { label: 'Fat Grams', attribute: 'fatGrams' },
            { label: 'Carb Grams', attribute: 'carbGrams' },
            {
              label: 'Protein Grams',
              attribute: 'proteinGrams',
            },
            { label: 'Calories', attribute: 'calories' },
          ].map(({ label, attribute }) => {
            let value = 0;
            if (ingredient?.macros) {
              value = ingredient?.macros[attribute] || '';
            }

            return (
              <div key={attribute}>
                <TextField
                  fullWidth
                  label={label}
                  name={`macros.${attribute}`}
                  onChange={handleChange}
                  value={value}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  placeholder="0"
                  type="number"
                  style={{ width: 120 }}
                />
              </div>
            );
          })}
        </div>
        <div style={{ flexBasis: '100%', height: 0 }} />
      </Box>
    </LabelledOutline>
  );
}
