import React, { useState, useEffect } from 'react';
import QueryString from 'query-string';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToAppOutlined';
import {
  FormHelperText,
  TextField,
  InputAdornment,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Container,
} from '@mui/material';
import Page from '../Page';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_WEB_PAGE,
  UPDATE_WEB_PAGE,
  SAVE_CUSTOM_DOMAIN,
} from '../../graphql/web_page';
import ServerError from '../error/ServerError';
import config, { subdomainHost } from '../../config';
import Loading from '../shared/Loading';
import { useSnackbar } from 'notistack';

const WebSiteDomain = () => {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;
  const { enqueueSnackbar } = useSnackbar();
  const [previewRefresh, setPreviewRefresh] = useState(1);
  const [webPage, setWebPage] = useState({});
  const [customDomainHost, setCustomDomainHost] = useState('');
  const { error, loading, data, refetch } = useQuery(GET_WEB_PAGE, {
    variables: { trainerId },
  });
  const [updateWebPage] = useMutation(UPDATE_WEB_PAGE, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });
  const [saveCustomDomain] = useMutation(SAVE_CUSTOM_DOMAIN, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });

  useEffect(() => {
    setWebPage(data?.webPage);
    setCustomDomainHost(data?.webPage.customDomainHost);
  }, [data]);

  const handleSaveCustomDomain = async (e) => {
    e.preventDefault();
    const input = {
      trainerId: trainerId || webPage.trainerId,
      customDomainHost,
    };

    await saveCustomDomain({
      variables: { input },
    });

    await refetch();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!webPage) {
      return;
    }
    enqueueSnackbar(`Saving...`, {
      autoHideDuration: 2000,
    });

    // TODO only change the slug here
    const input = {
      slug: webPage.slug,
      customDomainHost: webPage.customDomainHost,
      logo: webPage.logo,
      heroSection: webPage.heroSection,
      appDownloadSection: webPage.appDownloadSection,
      testimonialsSection: {
        testimonials: webPage.testimonialsSection?.testimonials.map((t) => {
          return { name: t.name, quote: t.quote, imageUrl: t.imageUrl };
        }),
      },
      aboutSection: webPage.aboutSection,
      footerSection: {
        links: webPage.footerSection?.links.map((l) => {
          return { title: l.title, url: l.url };
        }),
      },
    };

    await updateWebPage({
      variables: {
        id: webPage._id,
        input,
      },
    });

    setTimeout(() => {
      setPreviewRefresh(previewRefresh + 1);
    }, 2000);
  };

  const handleChangeCustomDomain = (e) => {
    e.preventDefault();

    setCustomDomainHost(e.currentTarget.value);
  };

  const handleChange = (e) => {
    e.preventDefault();

    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }

    let changes = _.cloneDeep(webPage);
    _.set(changes, e.target.name, e.target.value);
    setWebPage(changes);
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading || !webPage) {
    return <Loading />;
  }
  const subdomain = `https://${webPage.slug}.${
    webPage.platformSubdomainLandingHost ?? subdomainHost
  }`;
  const customDomain = `https://${customDomainHost}`;

  return (
    <Page title={`Web Site Domain : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Web Site Domain
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          Your web site is where users can purchase your products including
          membership plans and access the content on a web browser.
        </Typography>

        <Card>
          <CardContent>
            <Typography variant="h5" color="textPrimary" gutterBottom>
              Subdomain
            </Typography>

            <form onSubmit={handleSubmit}>
              <Box mt={3}>
                <CopyToClipboard
                  text={subdomain}
                  onCopy={() => {
                    window.alert(`Copied ${subdomain}`);
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<CopyIcon />}
                  >
                    Copy Link
                  </Button>
                </CopyToClipboard>

                <Button
                  sx={{ ml: 2 }}
                  component="a"
                  href={subdomain}
                  variant="outlined"
                  color="secondary"
                  startIcon={<ExitToAppIcon />}
                >
                  Preview Site
                </Button>
              </Box>

              <Box mt={3}>
                <TextField
                  label="URL"
                  sx={{ width: '50ch' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">https://</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        .{webPage.platformSubdomainLandingHost ?? subdomainHost}
                      </InputAdornment>
                    ),
                  }}
                  name="slug"
                  value={webPage.slug}
                  onChange={handleChange}
                />
                <FormHelperText>No spaces allowed.</FormHelperText>
              </Box>

              <Box mt={2}>
                <Button variant="contained" color="secondary" type="submit">
                  Save
                </Button>
              </Box>
            </form>

            <Typography
              sx={{ marginTop: 2 }}
              variant="h5"
              color="textPrimary"
              gutterBottom
            >
              Custom Domain
            </Typography>

            <Typography sx={{ mt: 1, mb: 1 }} variant="body1" paragraph>
              The custom domain will only work if you have upgraded to{' '}
              <RouterLink to="/trainer/account/subscription">
                Pro+ Plan or higher
              </RouterLink>
              .
            </Typography>
            <form onSubmit={handleSaveCustomDomain}>
              <Box mt={3}>
                <CopyToClipboard
                  text={customDomain}
                  onCopy={() => {
                    window.alert(`Copied ${customDomain}`);
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<CopyIcon />}
                  >
                    Copy Link
                  </Button>
                </CopyToClipboard>

                <Button
                  sx={{ ml: 2 }}
                  component="a"
                  href={customDomain}
                  variant="outlined"
                  color="secondary"
                  startIcon={<ExitToAppIcon />}
                >
                  Preview Site
                </Button>
              </Box>

              <Box mt={3}>
                <TextField
                  label="URL"
                  sx={{ width: '50ch' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">https://</InputAdornment>
                    ),
                  }}
                  name="customDomainHost"
                  value={customDomainHost}
                  onChange={handleChangeCustomDomain}
                />
                <FormHelperText>No spaces allowed.</FormHelperText>
              </Box>

              <Box mt={2}>
                <Button variant="contained" color="secondary" type="submit">
                  Save
                </Button>
              </Box>
            </form>

            <Typography
              sx={{ marginTop: 2 }}
              variant="h5"
              color="textPrimary"
              gutterBottom
            >
              DNS Settings
            </Typography>
            <Typography sx={{ marginTop: 2 }}>
              Change your DNS settings on your domain provider like GoDaddy,
              Google Domains, etc.
            </Typography>

            <Typography sx={{ marginTop: 2 }}>
              If you want to use a new domain dedicated just for this landing
              page, use these settings:
              <br />
              Type: 'A'
              <br />
              Name: '@'
              <br />
              Value: '76.76.21.21'
            </Typography>

            <Typography sx={{ marginTop: 2 }}>
              If you want to use a subdomain part of your existing domain. For
              example, app.tomthetrainer.com, use `app` in the name field.
              <br />
              Type: 'CNAME'
              <br />
              Name: [THE SUBDOMAIN VALUE] <br />
              Value: 'cname.vercel-dns.com'
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default WebSiteDomain;
