import { gql } from '@apollo/client';

export const GET_APP_PREVIEW_SCREEN_QUERY = gql`
  query AppPreview($trainerId: ID, $screen: AppScreen) {
    appPreview(screen: $screen, trainerId: $trainerId) {
      trainerId
      url
    }

    webPage(trainerId: $trainerId) {
      url
    }

    myTrainerSubscription {
      trainerId
      vendor
      status
    }
  }
`;
