import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  FormHelperText,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import urlHelper from '../../utils/urlHelper';
import DirectUpload from '../shared/DirectUpload';
import LabelledOutline from '../shared/LabelledOutline';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_DETAILS } from '../../graphql/onboarding';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {},
  stepButton: {
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const UserDetails = ({ className, onBack, onNext, me, appInfo, ...rest }) => {
  const classes = useStyles();
  const [avatarUrl, setAvatarUrl] = useState(me.avatarUrl);
  const { enqueueSnackbar } = useSnackbar();
  const [save] = useMutation(UPDATE_USER_DETAILS, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      if (onNext) {
        onNext();
      }
    },
  });

  const handleUploadSuccess = (uploadedResponse) => {
    setAvatarUrl(uploadedResponse?.image?.url);
  };

  const handleAvatarRemove = (file) => {
    setAvatarUrl(null);
  };

  return (
    <Formik
      initialValues={{
        name: me?.name ?? '',
        legalName: appInfo?.legalName ?? '',
        link: me?.link ?? '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .min(3, 'Must be at least 3 characters')
          .max(50)
          .required('Required'),
        legalName: Yup.string()
          .min(3, 'Must be at least 3 characters')
          .max(50)
          .required('Required'),
        link: Yup.string()
          .matches(urlHelper.urlRegex, 'Must be a valid link')
          .max(300),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setSubmitting(true);
          enqueueSnackbar('Saving...');
          await save({
            variables: {
              updateMeInput: {
                avatarUrl,
                name: values.name,
                link: values.link,
              },
              appInfoId: appInfo._id,
              updateAppInfoInput: {
                legalName: values.legalName,
              },
            },
          });
          setSubmitting(false);
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Typography variant="h3" color="textPrimary">
            Tell us about yourself
          </Typography>
          <Box mt={2}>
            <Typography variant="subtitle1" color="textSecondary" />
          </Box>
          <Box mt={2}>
            <LabelledOutline label="Profile Photo">
              <DirectUpload
                images={[{ url: avatarUrl }]}
                maxFiles={1}
                onSuccess={handleUploadSuccess}
                onRemove={handleAvatarRemove}
              />
            </LabelledOutline>
            <FormHelperText>
              Upload a head shot. This is not the app icon, this is your
              personal headshot which will be used when communicating with your
              users.
            </FormHelperText>
          </Box>

          <Box mt={2}>
            <TextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label="Name"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              variant="outlined"
            />
            <FormHelperText>
              Use the name you use on your social media.
            </FormHelperText>
          </Box>
          <Box mt={2}>
            <TextField
              error={Boolean(touched.legalName && errors.legalName)}
              fullWidth
              helperText={
                touched.legalName && errors.legalName
                  ? `Legal name must be longer than 3 characters.`
                  : ''
              }
              label="Legal Name"
              name="legalName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.legalName}
              variant="outlined"
            />
            <FormHelperText>
              This is your company's name or your legal name. It will be used on
              the terms and privacy policies of your app.
            </FormHelperText>
          </Box>
          <Box mt={2}>
            <TextField
              error={Boolean(touched.link && errors.link)}
              fullWidth
              helperText={touched.link && errors.link}
              label="Link"
              name="link"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.link}
              variant="outlined"
            />
            <FormHelperText>
              Add your most important social media link like your website or
              your instagram channel.
            </FormHelperText>
          </Box>

          <Box mt={6} display="flex">
            {onBack && (
              <Button onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

UserDetails.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  me: PropTypes.object,
  appInfo: PropTypes.object,
};

UserDetails.defaultProps = {
  onNext: () => {},
  onBack: () => {},
};

export default UserDetails;
