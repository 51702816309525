import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';
import DirectUpload from '../../components/shared/DirectUpload';

const GeneralSettings = ({ className, onSave, userProfile, ...rest }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: userProfile.name ?? '',
        username: userProfile.username ?? '',
        link: userProfile.link ?? '',
        avatarUrl: userProfile.avatarUrl ?? '',
        bio: userProfile.bio ?? '',
        location: userProfile.location ?? '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(50).required('Name is required'),
        username: Yup.string().max(50).required('Username is required'),
      })}
      onSubmit={onSave}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                  <Typography variant="h4">Basic Details</Typography>
                </Grid>

                <Grid item md={8} xs={12}>
                  <Box m={2}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                    <FormHelperText>Example: Jane Smith</FormHelperText>
                  </Box>

                  <Box m={2}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      value={userProfile.email}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormHelperText>
                      You can't change this once you created an account.
                    </FormHelperText>
                  </Box>
                  <Box m={2}>
                    <TextField
                      error={Boolean(touched.username && errors.username)}
                      fullWidth
                      helperText={touched.username && errors.username}
                      label="Username"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.username}
                      variant="outlined"
                    />
                    <FormHelperText>Example: janesmith</FormHelperText>
                  </Box>
                </Grid>

                <Grid item md={4} xs={12}>
                  <Typography variant="h4">Profile Photo</Typography>
                </Grid>

                <Grid item md={8} xs={12}>
                  <DirectUpload
                    images={[{ url: values.avatarUrl }]}
                    onError={() => {}}
                    onSuccess={(uploadedResponse) => {
                      setFieldValue('avatarUrl', uploadedResponse.image?.url);
                    }}
                    onRemove={() => {}}
                    maxFiles={1}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography variant="h4">Profile</Typography>
                </Grid>

                <Grid item md={8} xs={12}>
                  <Box m={2}>
                    <TextField
                      error={Boolean(touched.link && errors.link)}
                      fullWidth
                      helperText={touched.link && errors.link}
                      label="Link"
                      name="link"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.link}
                      variant="outlined"
                    />
                    <FormHelperText>
                      Add your most important social media link like your
                      website or your instagram channel.
                    </FormHelperText>
                  </Box>

                  <Box m={2}>
                    <TextField
                      error={Boolean(touched.bio && errors.bio)}
                      fullWidth
                      helperText={touched.bio && errors.bio}
                      label="Bio"
                      name="bio"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bio}
                      variant="outlined"
                    />
                    <FormHelperText>
                      Example: I'm a former professional biker specialized
                      strength training for cyclists.
                    </FormHelperText>
                  </Box>

                  <Box m={2}>
                    <TextField
                      error={Boolean(touched.location && errors.location)}
                      fullWidth
                      helperText={touched.location && errors.location}
                      label="Location"
                      name="location"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.location}
                      variant="outlined"
                    />
                    <FormHelperText>Example: San Franciso, CA</FormHelperText>
                  </Box>
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box p={2} display="flex" justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save Changes
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

GeneralSettings.propTypes = {
  className: PropTypes.string,
  userProfile: PropTypes.object.isRequired,
};

export default GeneralSettings;
