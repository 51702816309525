import { Box, Typography } from '@mui/material';
import Scrollbar from '../shared/Scrollbar';
import ChatConvoList from './ChatConvoList';

const ChatSidebar = ({
  activeConversationId = null,
  onSelect,
  conversations = [],
}) => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        borderRight: 1,
        borderColor: 'divider',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        width: 300,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexShrink: 0,
          height: 64,
          px: 2,
        }}
      >
        <Typography color="textPrimary" variant="h5">
          Chats
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      <Scrollbar options={{ suppressScrollX: true }}>
        <ChatConvoList
          activeConversationId={activeConversationId}
          onSelect={onSelect}
          conversations={conversations}
        />
      </Scrollbar>
    </Box>
  );
};

export default ChatSidebar;
