import React from 'react';

import { Box } from '@mui/material';
import AppChecklist from './AppChecklist';

export default function AppStatus(props) {
  return (
    <Box style={{ maxWidth: 1080 }}>
      <AppChecklist />
    </Box>
  );
}
