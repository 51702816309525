import React, { useState } from 'react';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Drawer,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddIcon from '@mui/icons-material/AddOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExerciseGroupEditor from './ExerciseGroupEditor';
import WorkoutExerciseSearch from './WorkoutExerciseSearch';
import ExerciseEditorDialog from './../exercises/ExerciseEditorDialog';
import ExerciseGroupEditorTitleMenu from './ExerciseGroupEditorTitleMenu';
import Loading from '../shared/Loading';
import { mongoObjectId } from '../../utils/mongoObjectId';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 4,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const WorkoutContent = ({ workout, setWorkout }) => {
  const [selectedExerciseGroup, setSelectedExerciseGroup] = useState(null);
  const [openExerciseCreator, setOpenExerciseCreator] = useState(false); //for new exercises
  const [openExerciseEdit, setOpenExerciseEdit] = useState(false); // for editing existing exercises
  const [addExerciseOpen, setAddExerciseOpen] = useState(false);
  const [selectedExerciseEdit, setSelectedExerciseEdit] = useState(null);

  if (!workout) {
    return <Loading />;
  }
  const handleExerciseCreateClose = () => {
    setOpenExerciseCreator(false);
  };

  let emptyExercisesMsg;
  if ((workout?.exerciseGroups || []).length === 0) {
    emptyExercisesMsg = (
      <Box mb={3}>
        <Card>
          <CardHeader title="Getting Started" />
          <CardContent>
            <Box>
              <Typography variant="body1" paragraph>
                Click Add Exercise Group.
              </Typography>
              <Typography variant="body1" paragraph>
                An exercise group is a group of exercises. For example: "Warm
                up", "Superset 1", "Cool down". If your workout is simple, you
                can just have one group for all your exercises.
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  const handleChange = (event, exerciseGroup) => {
    const exerciseGroupChanges = { ...exerciseGroup };
    exerciseGroupChanges[event.target.name] = event.target.value;

    const changes = _.cloneDeep(workout);
    changes.exerciseGroups = changes.exerciseGroups.map((group) => {
      if (exerciseGroup?._id === group._id) {
        return exerciseGroupChanges;
      }

      return group;
    });
    setWorkout(changes);
  };

  const handleChangeInt = (e, exerciseGroup) => {
    const exerciseGroupChanges = { ...exerciseGroup };
    exerciseGroupChanges[event.target.name] = parseInt(event.target.value);

    const changes = _.cloneDeep(workout);
    changes.exerciseGroups = changes.exerciseGroups.map((group) => {
      if (exerciseGroup?._id === group._id) {
        return exerciseGroupChanges;
      }

      return group;
    });
    setWorkout(changes);
  };

  const handleAddGroup = () => {
    const newGroup = {
      _id: mongoObjectId(),
      groupName: '',
      rounds: 1,
      restBetweenRounds: 0,
      exerciseList: [],
    };
    const exerciseGroups = [...(workout.exerciseGroups || []), newGroup];
    const newWorkout = { ...workout, exerciseGroups };
    setWorkout(newWorkout);
  };

  const handleAddExerciseOpen = (exerciseGroup) => {
    setAddExerciseOpen(true);
    setSelectedExerciseGroup(exerciseGroup);
  };

  const handleAddExerciseClose = () => {
    setAddExerciseOpen(false);
    setOpenExerciseCreator(false);
    setSelectedExerciseGroup(null);
  };

  const handleCreateExerciseOpen = () => {
    setAddExerciseOpen(false);
    setOpenExerciseCreator(true);
  };

  const handleExerciseSelected = (exercise) => {
    // add default values
    const copy = _.cloneDeep({
      ...exercise,
      duration: 10,
      reps: 10,
      sets: 1,
      percentOf1RM: 0,
      weight: 0,
      weightUnit: 'lbs',
      restBetweenSetsDuration: 0,
      restAfterExerciseDuration: 0,
      isTimed: false,
    });
    copy.exerciseId = exercise._id;
    copy._id = mongoObjectId();
    const changes = _.cloneDeep(workout);

    // Add it to the selected exercise group
    changes.exerciseGroups = changes.exerciseGroups.map((group) => {
      if (group?._id !== selectedExerciseGroup?._id) {
        return group;
      }
      group.exercises = group.exercises ?? [];
      group.exercises.push(copy);
      return group;
    });
    changes.autoSave = false;
    setWorkout(changes);
    handleAddExerciseClose();
  };

  const handleExerciseGroupChange = (exerciseGroup) => {
    const changes = _.cloneDeep(workout);
    changes.exerciseGroups = changes.exerciseGroups.map((group) => {
      if (exerciseGroup?._id === group._id) {
        return exerciseGroup;
      }

      return group;
    });
    setWorkout(changes);
  };

  const handleRemoveGroup = (exerciseGroup) => {
    const changes = _.cloneDeep(workout);
    changes.exerciseGroups = changes.exerciseGroups.filter(
      (group) => exerciseGroup._id !== group._id
    );
    setWorkout(changes);
  };

  const handleMoveUpGroup = (exerciseGroup) => {
    const changes = _.cloneDeep(workout);

    const sourceIndex = changes.exerciseGroups.findIndex(
      (group) => exerciseGroup._id === group._id
    );
    const targetIndex = sourceIndex - 1;

    changes.exerciseGroups.splice(sourceIndex, 1);
    changes.exerciseGroups.splice(targetIndex, 0, exerciseGroup);

    setWorkout(changes);
  };

  const handleMoveDownGroup = (exerciseGroup) => {
    const changes = _.cloneDeep(workout);

    const sourceIndex = changes.exerciseGroups.findIndex(
      (group) => exerciseGroup._id === group._id
    );
    const targetIndex = sourceIndex + 1;

    changes.exerciseGroups.splice(sourceIndex, 1);
    changes.exerciseGroups.splice(targetIndex, 0, exerciseGroup);

    setWorkout(changes);
  };

  const handleEditExerciseOpen = (exercise) => {
    setSelectedExerciseEdit(exercise);
    setOpenExerciseEdit(true);
  };
  const handleEditExerciseClose = () => {
    setOpenExerciseEdit(false);
  };
  const handleExerciseSaved = () => {
    setAddExerciseOpen(true);
    setOpenExerciseEdit(false);
  };

  return (
    <Box>
      {emptyExercisesMsg}

      <ExerciseEditorDialog
        key={selectedExerciseEdit?._id ?? 'id'}
        onSaved={handleExerciseSaved}
        open={openExerciseEdit}
        onClose={handleEditExerciseClose}
        exercise={selectedExerciseEdit ?? {}}
      />
      <ExerciseEditorDialog
        key={'new'}
        onSaved={handleExerciseSaved}
        open={openExerciseCreator}
        onClose={handleExerciseCreateClose}
        exercise={{}}
      />

      {(workout.exerciseGroups || []).map((exerciseGroup, index) => {
        return (
          <Accordion disableGutters defaultExpanded={true} key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <Grid container spacing={3}>
                <Grid item xs={11} md={6} lg={6}>
                  <TextField
                    fullWidth
                    label="Group Name (ex. Warm up, circuit 1)"
                    placeholder="Group Name"
                    name="groupName"
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    onChange={(e) => handleChange(e, exerciseGroup)}
                    value={exerciseGroup.groupName ?? ''}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={3} md={1} lg={1}>
                  <TextField
                    fullWidth
                    label="Rounds"
                    name="rounds"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 0, max: 50 } }}
                    type="number"
                    onChange={(e) => handleChangeInt(e, exerciseGroup)}
                    value={exerciseGroup.rounds ?? 1}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label="Rest Between Rounds"
                    name="restBetweenRounds"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: { min: 0, max: 1000 },
                      endAdornment: (
                        <InputAdornment position="end">secs</InputAdornment>
                      ),
                    }}
                    onChange={(e) => handleChangeInt(e, exerciseGroup)}
                    value={exerciseGroup.restBetweenRounds ?? 0}
                    variant="standard"
                    sx={{ width: '20ch' }}
                  />
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                  <ExerciseGroupEditorTitleMenu
                    exerciseGroup={exerciseGroup}
                    onRemove={handleRemoveGroup}
                    onMoveUp={handleMoveUpGroup}
                    onMoveDown={handleMoveDownGroup}
                    onAddExerciseClick={handleCreateExerciseOpen}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <ExerciseGroupEditor
                onEditExerciseClick={handleEditExerciseOpen}
                onAddExerciseClick={handleAddExerciseOpen}
                exerciseGroup={exerciseGroup}
                onChange={handleExerciseGroupChange}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Card elevation={1}>
        <Box p={3} style={{ display: 'flex' }}>
          <div>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddGroup}
            >
              Add Exercise Group
            </Button>
          </div>
        </Box>
      </Card>

      <Drawer
        sx={{ zIndex: 2000 }}
        anchor="bottom"
        open={addExerciseOpen}
        onClose={handleAddExerciseClose}
      >
        <Box p={3}>
          <WorkoutExerciseSearch
            onSelect={handleExerciseSelected}
            onAddNew={handleCreateExerciseOpen}
            onClose={handleAddExerciseClose}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default WorkoutContent;
