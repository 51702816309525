import PropTypes from 'prop-types';
import {
  Link,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Download as DownloadIcon } from 'react-feather';
import bytesToSize from '../../utils/bytesToSize';

const useStyles = makeStyles((theme) => ({
  box: {
    alignItems: 'center',
    color: '#000000',
    display: 'flex',
    height: 180,
    justifyContent: 'center',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const appleStoreIcon =
  'https://tempo-media-assets-production.s3-us-west-2.amazonaws.com/img/misc/apple-appstore-icon.png';
const googleStoreIcon =
  'https://tempo-media-assets-production.s3-us-west-2.amazonaws.com/img/misc/google-playstore-icon.png';

const DownloadCard = (props) => {
  const { name, imageUrl, subtitle, body, size, url, ...other } = props;
  const classes = useStyles();

  let mediaUrl = imageUrl;
  if (name === 'ANDROID') {
    mediaUrl = googleStoreIcon;
  } else if (name === 'IOS') {
    mediaUrl = appleStoreIcon;
  }

  return (
    <Card {...other}>
      <Box className={classes.box}>
        <img src={mediaUrl} alt="screen" height="200" />
      </Box>
      <CardContent className={classes.cardContent}>
        <div>
          <Typography color="textPrimary" variant="subtitle2">
            {name}
          </Typography>
          <Typography color="textSecondary" variant="caption" paragraph>
            {subtitle}
            <br />
            {size && bytesToSize(size)}
          </Typography>
          <Typography color="textSecondary" variant="body1" paragraph>
            {body}
          </Typography>
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        {url && (
          <Button
            color="primary"
            fullWidth
            startIcon={<DownloadIcon fontSize="small" />}
            variant="text"
            component={Link}
            href={url}
          >
            Download
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

DownloadCard.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};

export default DownloadCard;
