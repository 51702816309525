import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  Tabs,
  Tab,
  Link,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  Container,
  Typography,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { Alert } from '@mui/material';
import Loading from '../shared/Loading';
import ServerError from '../error/ServerError';
import SubscriptionPlansStripe from './SubscriptionPlansStripe';
import { Download as DownloadIcon } from 'react-feather';
import Page from '../Page';
import { useAuth } from '../../hooks/use-auth';
import config from '../../config';

const tabs = [
  { value: 'apple', label: 'Apple' },
  { value: 'stripe', label: 'Web Checkout' },
];

const SUBSCRIPTION_PRODUCTS_QUERY = gql`
  query GetSubscriptionProducts($trainerId: ID) {
    subscriptionProducts(trainerId: $trainerId) {
      appleMonthSubscriptionProductId
      appleYearSubscriptionProductId

      googleMonthSubscriptionProductId
      googleYearSubscriptionProductId

      stripeMonthSubscriptionPriceId
      stripeYearSubscriptionPriceId
    }

    verifyAppleAppStoreIntegration(trainerId: $trainerId) {
      inAppPurchases {
        referenceName
        productId
        inAppPurchaseType
        state
      }
    }
  }
`;
const SAVE_SUBSCRIPTION_PRODUCTS_MUTATION = gql`
  mutation SaveSubscriptionProducts($input: SaveSubscriptionProductsInput!) {
    saveSubscriptionProducts(input: $input) {
      appleMonthSubscriptionProductId
      appleYearSubscriptionProductId

      googleMonthSubscriptionProductId
      googleYearSubscriptionProductId

      stripeMonthSubscriptionPriceId
      stripeYearSubscriptionPriceId
    }
  }
`;

const createStripeProductUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://dashboard.stripe.com/products/create'
    : 'https://dashboard.stripe.com/test/products/create';

export default function SubscriptionPlans() {
  const queryParams = new URLSearchParams(useLocation().search);
  const trainerId = queryParams.get('trainerId');
  const params = useParams();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState('stripe');
  const {
    loading: dataLoading,
    error,
    data,
  } = useQuery(SUBSCRIPTION_PRODUCTS_QUERY, { variables: { trainerId } });
  const [saveSubscriptionProducts, { error: mutationError, data: saveData }] =
    useMutation(SAVE_SUBSCRIPTION_PRODUCTS_MUTATION);

  const { enqueueSnackbar } = useSnackbar();
  const [subscriptionProductsInput, setSubscriptionProductsInput] = useState(
    {}
  );
  const [saving, setSaving] = useState(false);
  const auth = useAuth();
  const authLoading = auth.loading;

  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);

    enqueueSnackbar('Saving...', { autoHideDuration: 2000 });
    try {
      console.log('saving');
      console.log(subscriptionProductsInput);

      subscriptionProductsInput.trainerId = trainerId;
      const vars = { input: subscriptionProductsInput };
      console.log(vars);
      await saveSubscriptionProducts({ variables: vars });
    } catch (err) {
      console.error(err.message);
    }
    setSaving(false);
  };

  const handleEdit = (e) => {
    const changes = { ...subscriptionProductsInput };
    changes[e.target.name] = e.target.value;
    setSubscriptionProductsInput(changes);
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    history.push(`/trainer/subscription_plans/${value}`);
  };

  React.useEffect(() => {
    if (!data) {
      return;
    }

    let subscriptionProducts;
    if (!subscriptionProducts && data && data.subscriptionProducts) {
      subscriptionProducts = { ...data.subscriptionProducts };
    }
    if (!subscriptionProducts) {
      return;
    }
    setSubscriptionProductsInput(subscriptionProducts);
  }, [data]);

  if (authLoading || dataLoading) {
    return <Loading />;
  }
  if (error) return <Alert severity="error">{error.message}</Alert>;
  if (!data) return <Loading />;

  const appleIapProducts =
    data.verifyAppleAppStoreIntegration?.inAppPurchases ?? [];
  let appleIapStatus = {};
  if (appleIapProducts.length === 0) {
    appleIapStatus.message = (
      <span>
        You need to add a subscription product on the{' '}
        <a href="https://appstoreconnect.apple.com/apps" target="_blank">
          Apple App Connect Page
        </a>
        .
      </span>
    );
    appleIapStatus.severity = 'error';
  } else if (appleIapProducts.length > 0) {
    // get all the IAP states in app connect store in case trainer added more than one monthly subscription
    const productStates = appleIapProducts.map((p) => p.state);
    const inputtedProductIds = [
      subscriptionProductsInput.appleMonthSubscriptionProductId,
      subscriptionProductsInput.appleYearSubscriptionProductId,
    ];

    const foundMatchingProductId = appleIapProducts.some((x) =>
      inputtedProductIds.includes(x.productId)
    );
    if (foundMatchingProductId && productStates.includes('APPROVED')) {
      appleIapStatus.message = 'Verified your product id on Apple App Connect';
      appleIapStatus.severity = 'info';
    } else if (foundMatchingProductId && !productStates.includes('APPROVED')) {
      appleIapStatus.message = productStates.join(', ');
      if (productStates.includes('WAITING_FOR_SCREENSHOT')) {
        appleIapStatus.message = (
          <p>
            Missing screenshot in the App Connect subscriptions section.{' '}
            <a
              href="https://docs.revenuecat.com/docs/ios-products#add-reviewer-information"
              target="_blank"
              rel="noreferrer"
            >
              Follow these instructions for where to add the screenshot
            </a>
            .
          </p>
        );
      }
      appleIapStatus.severity = 'error';
    } else if (!foundMatchingProductId) {
      appleIapStatus.message =
        "We didn't find a matching product id on Apple App Connect";
      appleIapStatus.severity = 'error';
    }
  }

  return (
    <Page title={`Subscription Plans : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Subscription Plans
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          These subscriptions will grant paying customers full access to your
          membership content marked as PREMIUM.
        </Typography>

        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />
        </Box>

        {mutationError && (
          <Alert severity="error">{mutationError.message}</Alert>
        )}

        {currentTab === 'stripe' && (
          <SubscriptionPlansStripe trainerId={trainerId} />
        )}

        {currentTab === 'apple' && (
          <Box style={{ maxWidth: 1080 }}>
            <form className="form-horizontal" onSubmit={handleSave}>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Apple App Store" />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={9}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                              paragraph
                            >
                              Read this{' '}
                              <a
                                href="https://docs.revenuecat.com/docs/ios-products"
                                target="_blank"
                                rel="noreferrer"
                              >
                                blog post
                              </a>{' '}
                              if you need help getting this setup in the{' '}
                              <a
                                href="https://appstoreconnect.apple.com/apps"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Apple App Connect
                              </a>
                              . You can use <code>com.YOURNAME.monthly</code>{' '}
                              and <code>com.YOURNAME.yearly</code> as the
                              product ids in the Apple App Connect. Copy and
                              paste the product ids below.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Apple Monthly Subscription Product ID"
                                  name="appleMonthSubscriptionProductId"
                                  onChange={handleEdit}
                                  value={
                                    subscriptionProductsInput.appleMonthSubscriptionProductId ||
                                    ''
                                  }
                                  type="string"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Apple Annual Subscription Product ID"
                                  name="appleYearSubscriptionProductId"
                                  onChange={handleEdit}
                                  value={
                                    subscriptionProductsInput.appleYearSubscriptionProductId ||
                                    ''
                                  }
                                  type="string"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Alert
                          style={{ marginTop: 16 }}
                          severity={appleIapStatus.severity}
                        >
                          {appleIapStatus.message}
                        </Alert>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Card>
                          <CardHeader title="Screenshot for Apple" />
                          <Divider />
                          <CardContent>
                            <Box
                              sx={{
                                alignItems: 'center',
                                color: '#000000',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <a
                                href="https://d272s49zbiop50.cloudfront.net/img/screenshots/trainer-iap-screen.png"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="https://d272s49zbiop50.cloudfront.net/img/screenshots/trainer-iap-screen.png"
                                  alt="screen"
                                  height="400"
                                />
                              </a>
                            </Box>
                            <Typography
                              color="textSecondary"
                              variant="body1"
                              paragraph
                            >
                              Upload this screenshot to the in app purchases
                              section in Apple App Store Connect.
                            </Typography>
                            <Divider />
                            <CardActions>
                              <Button
                                color="primary"
                                fullWidth
                                startIcon={<DownloadIcon fontSize="small" />}
                                variant="text"
                                component={Link}
                                href="https://d272s49zbiop50.cloudfront.net/img/screenshots/trainer-iap-screen.png"
                              >
                                Download
                              </Button>
                            </CardActions>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={2}>
                <Button color="secondary" variant="contained" type="submit">
                  Save
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Container>
    </Page>
  );
}
