import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import Loading from '../shared/Loading';
import { Alert } from '@mui/material';
import ServerError from '../error/ServerError';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Step, StepButton, StepContent, Stepper } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, gql } from '@apollo/client';
import AppBuilds from './AppBuilds';
import config from '../../config';

const SUBMIT_TO_APPLE_YOUTUBE_URL =
  'https://www.youtube.com/watch?v=SxSFo1rDLoQ';
const GET_APP_CHECKLIST_QUERY = gql`
  query GetAppChecklist {
    appChecklist {
      hasRequiredWorkoutCount
      hasRequiredTrainingPlanCount

      hasAppTitle
      hasAppTheme
      hasTrainerSubscription
      hasPayoutAccount
      hasSubscriptionPlans
      hasAppStoreIntegrations
      hasSalesPage

      isComplete
    }

    appBuilds {
      _id
      buildStatus
      url
      version
      platform
      createdAt
    }
  }
`;
const PUBLISH_APP_MUTATION = gql`
  mutation PublishAppMutation {
    publishApp {
      status
      message
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const getSteps = ({
  handlePublish,
  appChecklist = {},
  appBuildStarted,
  appBuildCompleted,
}) => {
  return [
    //    {
    //      title: 'Set up your Payout Account to Get Paid',
    //      content: (
    //        <Box>
    //          <Button
    //            component={RouterLink}
    //            variant="outlined"
    //            size="small"
    //            to="/trainer/account/payout"
    //          >
    //            Setup Stripe Payout Account
    //          </Button>
    //        </Box>
    //      ),
    //      completed: appChecklist.hasPayoutAccount,
    //    },
    {
      title: 'Upgrade to a Paid Plan to Launch Your App',
      content: (
        <Box>
          <p>
            Check out our pricing page for options.{' '}
            {config.showSubscriptionPage && (
              <Button
                component={RouterLink}
                variant="outlined"
                size="small"
                to="/trainer/checkout"
              >
                Subscribe
              </Button>
            )}
          </p>
          <p>
            Not ready to commit to paying? Contact us at{' '}
            <a href={`mailto:${config.emailContactAddress}`}>
              {config.emailContactAddress}
            </a>{' '}
            if you want to see a preview of your app on your phone.
          </p>
        </Box>
      ),
      completed: appChecklist.hasTrainerSubscription,
    },
    {
      title: 'Set up Subscription Memberships for your Clients',
      content: (
        <Box>
          <Typography variant="body1">
            Link the subscription plans you created in the Apple App Store and
            Google Play Store.
            <Button
              component={RouterLink}
              variant="outlined"
              size="small"
              to="/trainer/subscription_plans"
            >
              Add Subscription Plans
            </Button>
          </Typography>
          <Typography variant="body1">
            You can also make more money if your members purchase the
            subscription through our website rather than in the app.
            <Button
              component={RouterLink}
              variant="outlined"
              size="small"
              to="/trainer/web_site"
            >
              Setup your sales page
            </Button>
          </Typography>
        </Box>
      ),
      completed: appChecklist.hasSubscriptionPlans,
    },
    {
      title: `Connect ${config.appDisplayName} to the App Stores`,
      content: (
        <Box>
          <Typography variant="body1">
            Connect {config.appDisplayName} to the App Stores to allow us to
            send the app to Apple and Google.
            <Button
              component={RouterLink}
              variant="outlined"
              size="small"
              to="/trainer/app_publishing/current/apple_integration"
            >
              Add App Store Integrations
            </Button>
          </Typography>
        </Box>
      ),
      completed: appChecklist.hasAppStoreIntegrations,
    },
    {
      title: 'Publish your App',
      content: (
        <Box>
          {appBuildStarted && (
            <Typography variant="body1">
              Your app has already started building.
            </Typography>
          )}
          {appBuildCompleted && (
            <Typography variant="body1">
              Your app has finished building. You can now submit the app.{' '}
              <a
                href={SUBMIT_TO_APPLE_YOUTUBE_URL}
                rel="noreferrer"
                target="_blank"
              >
                Watch this video if you need help.
              </a>
            </Typography>
          )}
          {!(appBuildStarted || appBuildCompleted) && (
            <>
              Once you are ready, click{' '}
              <Button
                component={RouterLink}
                variant="outlined"
                size="small"
                onClick={handlePublish}
              >
                Publish
              </Button>{' '}
              and we'll help you review.
            </>
          )}
        </Box>
      ),
    },
  ];
};

export default function AppChecklist() {
  const { loading, data, error } = useQuery(GET_APP_CHECKLIST_QUERY);
  const [publishApp, { data: publishResponseData }] =
    useMutation(PUBLISH_APP_MUTATION);
  const classes = useStyles();

  const [initalLoad, setInitalLoad] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);

  const handlePublish = async (e) => {
    e.preventDefault();
    setSaving(true);

    enqueueSnackbar('Publishing...', { autoHideDuration: 2000 });
    try {
      await publishApp();
    } catch (err) {
      alert(err.message);
    }
    setSaving(false);
  };

  const appBuilds = data?.appBuilds ?? [];
  const latestIosAppBuild = appBuilds.find((b) => b.platform === 'IOS');
  const appBuildStarted = latestIosAppBuild?.buildStatus === 'STARTED';
  const appBuildCompleted = latestIosAppBuild?.buildStatus === 'COMPLETED';

  const steps = getSteps({
    handlePublish,
    appChecklist: data && data.appChecklist,
    appBuildStarted,
    appBuildCompleted,
  });

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (initalLoad) {
      return;
    }
    const index = steps.findIndex((step) => !step.completed);
    setActiveStep(index);
    setInitalLoad(true);
  }, [initalLoad, steps]);

  if (loading) {
    return <Loading />;
  }

  if (error)
    return <ServerError message="Failed to load. Try a hard refresh: CTL R" />;

  if (appBuildCompleted) {
    return (
      <div className={classes.root}>
        <Box mb={3}>
          <Alert severity="info">
            Your app is ready to be submitted to the app stores. <br />
            <br />
            {config.showInstructionalVideos && (
              <a
                href={SUBMIT_TO_APPLE_YOUTUBE_URL}
                rel="noreferrer"
                target="_blank"
              >
                Watch this video if you need help submitting the app to the App
                Stores.
              </a>
            )}
          </Alert>
          <AppBuilds appBuilds={appBuilds} />
        </Box>
      </div>
    );
  }

  const hasPublishResponse =
    publishResponseData && publishResponseData.publishApp;
  let alertSeverity = 'info';
  if (publishResponseData) {
    alertSeverity =
      publishResponseData.publishApp.status === 'CHECKLIST_NOT_COMPLETED'
        ? 'error'
        : 'info';
  }
  return (
    <div className={classes.root}>
      {hasPublishResponse && (
        <Box mb={3}>
          {' '}
          <Alert severity={alertSeverity}>
            {publishResponseData.publishApp.message}
          </Alert>{' '}
        </Box>
      )}
      {appBuildStarted && (
        <Box mb={3}>
          <Alert severity="info">Your app is building now.</Alert>
        </Box>
      )}

      {!hasPublishResponse && (
        <>
          <Typography gutterBottom variant="body1">
            We created a checklist to help you complete the necessary steps to
            publish your app to the Apple and Google app stores.
          </Typography>

          <Stepper nonLinear activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.title}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={step.completed}
                >
                  {step.title}
                </StepButton>
                <StepContent>
                  <Typography>{step.content}</Typography>
                  <div className={classes.actionsContainer}>
                    <div></div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </>
      )}

      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>Your App is Ready!</Typography>
        </Paper>
      )}
    </div>
  );
}
