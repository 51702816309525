import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
}));

const LoadingScreen = ({ circular = false }) => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      {circular ? (
        <Box width={50}>
          <CircularProgress />
        </Box>
      ) : (
        <Box width={400}>
          <LinearProgress />
        </Box>
      )}
    </div>
  );
};

export default LoadingScreen;
