import React from 'react';
import AppAndWebPreview from '../../components/app_preview/AppAndWebPreview';

export default function MealPreview({ meal }) {
  return (
    <AppAndWebPreview
      mobilePreviewUrl={meal.mobilePreviewUrl}
      webUrl={meal.webUrl}
    />
  );
}
