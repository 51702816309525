import React from 'react';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import Label from '../../components/shared/Label';
import { useQuery, gql } from '@apollo/client';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import getInitials from '../../utils/getInitials';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1),
  },
}));

const GET_REFERRAL_QUERY = gql`
  query GetTrainerReferrals {
    trainerReferrals {
      _id
      name
      avatarUrl
      createdAt
      hasActiveTrainerSubscription
    }
  }
`;

export default function ReferralList(props) {
  const classes = useStyles();
  const { loading: dataLoading, error, data } = useQuery(GET_REFERRAL_QUERY);

  if (dataLoading) {
    return <Loading />;
  }

  if (error) return <ServerError message="Failed to load" />;
  const trainers = data.trainerReferrals || [];

  return (
    <Card className={clsx(classes.root)}>
      <Box minWidth={700}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Signed Up</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainers.map((trainer) => {
              return (
                <TableRow hover key={trainer._id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        className={classes.avatar}
                        src={trainer.avatarUrl}
                      >
                        {getInitials(trainer.name)}
                      </Avatar>
                      <div>{trainer.name}</div>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {trainer.hasActiveTrainerSubscription ? (
                      <Label color="primary">Active</Label>
                    ) : (
                      <Label color="secondary">Not subscribed</Label>
                    )}
                  </TableCell>
                  <TableCell>
                    {new Date(trainer.createdAt).toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
}
