import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers($input: UserQueryInput!, $trainerId: ID) {
    users(input: $input) {
      _id
      name
      workoutSessionCount
      createdAt
      subscriptions {
        vendor
        vendorSubscriptionId
        expiresAt
        trainerId
      }
    }

    trainer(id: $trainerId) {
      totalUserCount
    }
  }
`;

export const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      message
    }
  }
`;
