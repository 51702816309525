import { List } from '@mui/material';
import ChatConvoItem from './ChatConvoItem';

const ChatConvoList = ({
  onSelect,
  activeConversationId = null,
  conversations = [],
}) => {
  return (
    <List>
      {conversations.map((conversation) => (
        <ChatConvoItem
          active={activeConversationId === conversation._id}
          key={conversation._id}
          onSelect={onSelect}
          conversation={conversation}
        />
      ))}
    </List>
  );
};

export default ChatConvoList;
