import { InputAdornment, SvgIcon, TextField } from '@mui/material';
import { Search as SearchIcon } from 'react-feather';

const SearchBox = ({
  name = 'searchQuery',
  type = 'text',
  placeholder = 'search',
  value,
  onSubmit,
  onChange,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <TextField
        name={name}
        type={type}
        placeholder={placeholder}
        style={{
          marginRight: 16,
          marginBottom: 16,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        onChange={onChange}
        value={value}
        size="small"
        variant="outlined"
      />
    </form>
  );
};

export default SearchBox;
