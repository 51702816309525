import React from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Box, Typography } from '@mui/material';
import ServerError from '../error/ServerError';
import Loading from '../shared/Loading';
import { useAuth } from '../../hooks/use-auth';
import { GET_CONVERSATIONS, CREATE_CONVERSATION } from '../../graphql/chat';
import ChatConvo from '../chat/ChatConvo';

const ChatTab = ({ user }) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const participantUserIds = [user._id];
  const { data, loading, error, refetch } = useQuery(GET_CONVERSATIONS, {
    variables: { input: { participantUserIds } },
  });
  const [createConversation] = useMutation(CREATE_CONVERSATION, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onCompleted: (data) => {
      refetch();
    },
  });

  const handleCreateConversation = (e) => {
    e.preventDefault();
    createConversation({
      variables: {
        input: { participantUserIds },
      },
    });
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!data?.conversations) {
    return <ServerError message={'Error'} />;
  }

  const conversation = data.conversations[0];

  return (
    <Box mt={3} mb={8}>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Users will only see this if you enable the messaging option.
      </Typography>

      {!conversation && (
        <Box>
          <Button variant="outlined" onClick={handleCreateConversation}>
            Start a Chat with {user.name}
          </Button>
        </Box>
      )}
      {conversation && <ChatConvo conversation={conversation} />}
    </Box>
  );
};

export default ChatTab;
