import React, { useState, useEffect } from 'react';
import QueryString from 'query-string';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Button, Box, Container, Divider, Tab, Tabs } from '@mui/material';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import { Alert } from '@mui/material';
import Page from '../Page';
import Header from './Header';
import WorkoutDetailsEditor from './WorkoutDetailsEditor';
import WorkoutAnalytics from './WorkoutAnalytics';
import WorkoutContent from './WorkoutContent';
import WorkoutContentV1 from './WorkoutContentV1';
import WorkoutPreview from './WorkoutPreview';
import Loading from '../../components/shared/Loading';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../hooks/use-auth';
import {
  UPDATE_WORKOUT_MUTATION,
  GET_WORKOUT_QUERY,
} from '../../graphql/workout';
import config from '../../config';

const tabs = [
  { value: 'details', label: 'Details' },
  { value: 'content', label: 'Workout' },
  { value: 'preview', label: 'Preview' },
  { value: 'analytics', label: 'Analytics' },
];

const WorkoutEditor = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const queryParams = QueryString.parse(location.search);
  const [currentTab, setCurrentTab] = useState('details');
  const [workout, setWorkout] = useState(null);
  const [previewRefresh, setPreviewRefresh] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  let useGroupsEditor = true;
  // TODO remove WorkoutContentV1 WorkoutExerciseItemV1 as well once everyone likes this editor
  // useGroupsEditor = queryParams.useGroupsEditor === 'true';
  // turn off for katie's firebase user id
  // useGroupsEditor = !['k3x4CLj9mzMtr5xB7uF1wtY9vgz2'].includes(user.uid);

  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  const id = params.id;
  const [
    updateWorkout,
    { data: updateWorkoutData, error: updateWorkoutError },
  ] = useMutation(UPDATE_WORKOUT_MUTATION);

  const { loading, error, data } = useQuery(GET_WORKOUT_QUERY, {
    variables: { id },
  });

  useEffect(() => {
    _setWorkout(data?.workout);
  }, [data]);
  useEffect(() => {
    if (updateWorkoutData?.updateWorkout) {
      _setWorkout(updateWorkoutData?.updateWorkout);
    }
  }, [updateWorkoutData]);

  const _setWorkout = (workout) => {
    const w = _.cloneDeep(workout);
    if (w) {
      w.coverImages = [];
      if (w.coverImageUrl) {
        w.coverImages.push({ url: w.coverImageUrl, aspect: 'square' });
      }
    }
    setWorkout(w);
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    history.push(`/trainer/workouts/${workout._id}/${value}`);
  };

  const saveWorkout = (workout) => {
    enqueueSnackbar('Saving...', { autoHideDuration: 2000 });
    const mapExerciseToInput = (i) => {
      const o = {
        _id: i._id,
        exerciseId: i.exerciseId,
        duration: i.duration,
        isTimed: i.isTimed,
        reps: i.reps,
        sets: i.sets,
        weight: i.weight,
        weightUnit: i.weightUnit,
        percentOf1RM: i.percentOf1RM,
        restBetweenSetsDuration: i.restBetweenSetsDuration,
        restAfterExerciseDuration: i.restAfterExerciseDuration,
        groupName: i.groupName,
        notes: i.notes,
      };
      if (!o._id) {
        delete o._id;
      }
      return o;
    };

    const input = {
      title: workout.title,
      desc: workout.desc,
      tags: workout.tags,
      status: workout.status,
      coverImages:
        workout.coverImages != null && !!workout.coverImages.length
          ? workout.coverImages
          : [],
      workoutType: workout.workoutType,
      rounds: workout.rounds,
      restBetweenRounds: workout.restBetweenRounds,
      level: workout.level,
      youtubeVideoUrl: workout.youtubeVideoUrl,
      videos: workout.videos,

      // TODO, use exerciseList as Input don't do this, make sure exerciseList is saved
      exerciseList: workout.exercises?.map(mapExerciseToInput),
    };

    if (useGroupsEditor) {
      input.exerciseGroups = workout.exerciseGroups?.map((group) => {
        const groupCopy = _.cloneDeep(group);
        groupCopy.exerciseList =
          group?.exercises?.map(mapExerciseToInput) || [];
        delete groupCopy.exercises; // exerciseList is used for input, exercises is only for read
        delete groupCopy._id;
        return groupCopy;
      });
    }

    try {
      updateWorkout({ variables: { id: workout._id, input } });
      setTimeout(() => {
        setPreviewRefresh(previewRefresh + 1, 2000);
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Saving failed!', { autoHideDuration: 2000 });
    }
  };

  useEffect(() => {
    if (updateWorkoutError) {
      enqueueSnackbar('Saving failed!', { autoHideDuration: 2000 });
    }
  }, [updateWorkoutError]);

  if (error) {
    return (
      <Container maxWidth={false}>
        <Alert severity="error">
          Error ${error.graphQLErrors.map((e) => e.message)}
        </Alert>
      </Container>
    );
  }
  if (loading || !workout) {
    return <Loading />;
  }

  return (
    <Page title={`Workout Editor : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Header workout={workout} />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box pb={10} mt={3}>
          {currentTab === 'details' && (
            <WorkoutDetailsEditor
              workout={workout}
              setWorkout={setWorkout}
              saveWorkout={saveWorkout}
            />
          )}

          {currentTab === 'content' && !useGroupsEditor && (
            <WorkoutContentV1
              workout={workout}
              setWorkout={setWorkout}
              saveWorkout={saveWorkout}
            />
          )}
          {currentTab === 'content' && !!useGroupsEditor && (
            <WorkoutContent
              workout={workout}
              setWorkout={setWorkout}
              saveWorkout={saveWorkout}
            />
          )}

          {currentTab === 'preview' && (
            <WorkoutPreview key={previewRefresh} workout={workout} />
          )}
          {currentTab === 'analytics' && <WorkoutAnalytics workout={workout} />}
        </Box>
        <div
          style={{
            position: 'fixed',
            minHeight: '80px',
            bottom: 0,
            right: 0,
            width: '100%',
            borderTop: 1,
            padding: 24,
            background: '#fff',
            boxShadow:
              '0 -15px 35px 0 rgba(60,66,87,.03),0 -5px 15px 0 rgba(0,0,0,.06)',
            zIndex: 4,
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 0.8 }}></div>
            <div>
              <Button
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() => saveWorkout(workout)}
              >
                Save Workout
              </Button>
            </div>
            <div style={{ flexGrow: 0.4 }}></div>
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default WorkoutEditor;
