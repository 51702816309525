import React, { useEffect } from 'react';
import QueryString from 'query-string';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Page from '../Page';
import { Grid, Button, Box, Container, Typography } from '@mui/material';
import ProductList from './ProductList';
import Loading from '../shared/Loading';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCTS,
} from '../../graphql/product';
import ServerError from '../error/ServerError';
import { useSnackbar } from 'notistack';
import config from '../../config';

export default function Products() {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { error, loading, data, refetch } = useQuery(GET_PRODUCTS, {
    variables: { input: { trainerId } },
  });
  const [createProduct] = useMutation(CREATE_PRODUCT, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onCompleted: (data) => {
      history.push(`/trainer/products/${data.createProduct._id}`);
    },
  });
  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(refetch, [refetch, history]);

  const handleCreate = (e) => {
    e.preventDefault();
    enqueueSnackbar(`Creating...`, {
      autoHideDuration: 2000,
    });
    createProduct({
      variables: {
        input: { title: 'Untitled' },
      },
    });
  };

  const handleDelete = async (product) => {
    if (!window.confirm('Delete the product?')) {
      return;
    }
    enqueueSnackbar(`Deleting...`, {
      autoHideDuration: 2000,
    });
    deleteProduct({
      variables: {
        id: product._id,
      },
    });
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!data?.products) {
    return <ServerError message={'Error'} />;
  }

  return (
    <Page title={`Products : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Products
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Products are how you sell a package of content including training
          plans, extra supplement files(PDFs, etc) or as a way to charge for
          creating a requested training plan. The content here will be available
          to customers if they bought a one-time purchase OR if they are paying
          subscribers.
        </Typography>

        <Box mb={3}>
          <Button
            component={RouterLink}
            color="secondary"
            variant="contained"
            to="#"
            onClick={handleCreate}
          >
            Create a new Product
          </Button>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={12}>
            <Box mb={3}>
              <ProductList products={data.products} onDelete={handleDelete} />
            </Box>
            {data.products.length === 100 && (
              <Box mb={3}>
                <Button
                  component={RouterLink}
                  color="secondary"
                  variant="outlined"
                  to={`/trainer/products?lastId=${
                    data.products[data.products.length - 1]?._id
                  }`}
                >
                  More
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
