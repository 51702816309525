import React, { Suspense, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import Loading from './components/shared/Loading';
import Logout from './components/auth/Logout';
import LoginPage from './components/auth/LoginPage';
import SignupPage from './components/auth/SignupPage';
import Dashboard from './components/dashboard';

import AccountPage from './components/account';

import AppInfo from './components/app_info';
import AppThemePage from './components/app_theme';
import AppFeaturesPage from './components/app_features';
import AppPublishing from './components/app_publishing';
import AppPreviewPage from './components/app_preview';
import WebSite from './components/web_site';
import WebSiteDomain from './components/web_site/Domain';
import MealList from './components/nutrition/MealList';
import MealEditor from './components/nutrition/MealEditor';
import MealPlanList from './components/nutrition/MealPlanList';
import MealPlanEditor from './components/nutrition/MealPlanEditor';
import Chat from './components/chat';
import WorkoutList from './components/workouts/WorkoutList';
import WorkoutEditor from './components/workouts/WorkoutEditor';
import WorkoutDebug from './components/workouts/WorkoutDebug';
import WorkoutSessionPage from './components/workout_sessions/WorkoutSessionPage';
import TrainingPlanList from './components/training_plans/TrainingPlanList';
import TrainingPlanEditor from './components/training_plans/TrainingPlanEditor';
import CheckoutSuccess from './components/checkout/Success';
import CheckoutCanceled from './components/checkout/Canceled';
import Checkout from './components/checkout/index';
import BuyAppPublishingSetup from './components/checkout/BuyAppPublishingSetup';
import ExerciseList from './components/exercises/ExerciseList';
import ExerciseEditor from './components/exercises/ExerciseEditor';
import Posts from './components/posts';
import PostEditor from './components/posts/PostEditor';
import Nutrition from './components/nutrition';
import SubscriptionPlans from './components/subscription_plans/SubscriptionPlans';
import Products from './components/products/index';
import ProductEditor from './components/products/ProductEditor';
import UserList from './components/users/UserList';
import UserShow from './components/users/UserShow';
import DirectS3UploadTest from './components/admin/DirectS3UploadTest';
import TrainerShow from './components/admin/TrainerShow';
import IncomeCalculator from './components/income_calculator';
import Admin from './components/admin';
import ReferralsPage from './components/referrals';
import Onboarding from './components/onboarding';
import NotFound from './components/error/NotFound';

import AuthGuard from './components/auth/AuthGuard';
import DashboardLayout from './layouts/DashboardLayout';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loading />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
      <Route component={NotFound} />
    </Switch>
  </Suspense>
);

export const routes = [
  {
    exact: true,
    path: '/signup',
    component: SignupPage,
  },
  {
    exact: true,
    path: '/login',
    component: LoginPage,
  },
  {
    exact: true,
    path: '/logout',
    component: Logout,
  },

  {
    exact: true,
    path: '/',
    component: Dashboard,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer',
    component: Dashboard,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/income_calculator',
    component: IncomeCalculator,
  },
  {
    exact: true,
    path: '/trainer/chat',
    component: Chat,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/onboarding/:tab',
    component: Onboarding,
    guard: AuthGuard,
  },
  {
    path: '/trainer/onboarding',
    component: Onboarding,
    guard: AuthGuard,
  },
  {
    path: '/trainer/account/:tab',
    component: AccountPage,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/account',
    component: AccountPage,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/referrals',
    component: ReferralsPage,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/app_preview',
    component: AppPreviewPage,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/app_publishing/:id/:tab',
    component: AppPublishing,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/app_publishing/:id',
    component: AppPublishing,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/app_infos/:id/:tab',
    component: AppInfo,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/app_theme',
    component: AppThemePage,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/app_features/:tab',
    component: AppFeaturesPage,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/app_features',
    component: AppFeaturesPage,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/app_infos/:id',
    component: AppInfo,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/web_site/domain',
    component: WebSiteDomain,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/web_site/:tab',
    component: WebSite,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/web_site',
    component: WebSite,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  // @deprecated, legacy route
  {
    path: '/trainer/sales_pages/:id',
    component: WebSite,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/training_plans',
    component: TrainingPlanList,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/training_plans/:id/:tab',
    component: TrainingPlanEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/training_plans/:id',
    component: TrainingPlanEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },

  {
    exact: true,
    path: '/trainer/posts/:id/:tab',
    component: PostEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/posts/:id',
    component: PostEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/posts',
    component: Posts,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/nutrition',
    component: Nutrition,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/meals/:id/:tab',
    component: MealEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/meals/:id',
    component: MealEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/meals',
    component: MealList,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/meal_plans/:id',
    component: MealPlanEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/meal_plans',
    component: MealPlanList,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/workout_sessions/:id',
    component: WorkoutSessionPage,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/workouts',
    component: WorkoutList,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/workouts/:id/debug',
    component: WorkoutDebug,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/workouts/:id/:tab',
    component: WorkoutEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/workouts/:id',
    component: WorkoutEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/exercises',
    component: ExerciseList,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    path: '/trainer/exercises/:id',
    component: ExerciseEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/subscription_plans/:tab',
    component: SubscriptionPlans,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/subscription_plans',
    component: SubscriptionPlans,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/products',
    component: Products,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/products/:id/preview',
    component: ProductEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/products/:id',
    component: ProductEditor,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/checkout/success',
    component: CheckoutSuccess,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/checkout/canceled',
    component: CheckoutCanceled,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/checkout/buy_app_publishing_setup',
    component: BuyAppPublishingSetup,
    guard: AuthGuard,
    layout: DashboardLayout,
  },

  {
    exact: true,
    path: '/trainer/checkout',
    component: Checkout,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/users/:id',
    component: UserShow,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/users/:id/:tab',
    component: UserShow,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/trainer/users',
    component: UserList,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/admin/direct_s3_upload_test',
    component: DirectS3UploadTest,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/admin/users',
    component: UserList,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/admin/trainers/:id',
    component: TrainerShow,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/admin/:tab',
    component: Admin,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/admin',
    component: Admin,
    guard: AuthGuard,
    layout: DashboardLayout,
  },
];
