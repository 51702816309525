export const defaultVideoThumbnail =
  'https://i.vimeocdn.com/video/499134794_1280x720.jpg';

export const uploadContainer = {
  textAlign: 'center',
  minHeight: 140,
  border: '2px dashed #ebedf3',
  backgroundColor: '#fafafa',
  color: '#b5b5c3',
  padding: '20px 20px',
  cursor: 'pointer',
};

export const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  backgroundColor: '#eaeaea',
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 128,
  height: 128,
  padding: 4,
  boxSizing: 'border-box',
};

export const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

export const img = {
  objectFit: 'cover',
  display: 'block',
  width: 'auto',
  height: '100%',
};
