import { gql } from '@apollo/client';
import { APP_INFO_FRAGMENT } from './app_info';

export const GET_APP_FEATURES_PAGE = gql`
  query AppFeaturesPage($trainerId: ID) {
    appInfo(trainerId: $trainerId) {
      ...AppInfoFragment
    }
  }
  ${APP_INFO_FRAGMENT}
`;
