import { gql } from '@apollo/client';

export const GET_NOTES = gql`
  query GetNotes($input: NotesQueryInput) {
    notes(input: $input) {
      _id
      body
      userId
      trainerId
      createdAt
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CreateNote($input: NoteInput!) {
    createNote(input: $input) {
      _id
      body
      userId
      trainerId
      createdAt
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($id: ID!) {
    deleteNote(id: $id) {
      message
    }
  }
`;
