import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Checkbox,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import ChipInput from '../../components/shared/ChipInput';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import Loading from '../../components/shared/Loading';
import MediaUpload from '../../components/shared/DirectUpload';
import ImageCropper from '../../components/shared/ImageCropper';
import LabelledOutline from '../../components/shared/LabelledOutline';

const ASPECT_WIDTH = 16.0;
const ASPECT_HEIGHT = 9.0;

export default function TrainingPlanDetailsEditor(props) {
  const [trainingPlan, setTrainingPlan] = useState(props.trainingPlan);

  const handleSave = (e) => {
    e.preventDefault();

    props?.onSave(trainingPlan);
  };

  const handleEdit = (e) => {
    const target = e.target;

    const changes = { ...trainingPlan };
    changes[e.target.name] = e.target.value;

    let hasSchedule = trainingPlan.hasSchedule;
    if (target.name === 'hasSchedule' && target.type === 'checkbox') {
      hasSchedule = target.checked;
      changes.hasSchedule = hasSchedule;
    }

    setTrainingPlan(changes);
  };

  const handleAddTag = (tag) => {
    setTrainingPlan({ ...trainingPlan, tags: [...trainingPlan.tags, tag] });
  };

  const handleDeleteTag = (chip) => {
    const newTags = (trainingPlan.tags || []).filter((item) => item !== chip);
    setTrainingPlan({ ...trainingPlan, tags: newTags });
  };

  const handleUploadError = () => {
    console.error('TrainingPlanDetailsEditor#handleUploadError');
  };

  const handleUploadSuccess = (uploadedRes) => {
    if (!uploadedRes.image) {
      console.error(`handleUploadSuccess Missing image ${uploadedRes}`);
      return;
    }

    const changes = { ...trainingPlan, coverImages: [uploadedRes.image] };
    setTrainingPlan(changes);
  };

  const handleUploadRemove = () => {
    const changes = { ...trainingPlan, coverImages: [] };
    setTrainingPlan(changes);
  };

  const handleCropSuccess = async (uploadedRes) => {
    if (!uploadedRes.image) {
      console.error(`handleCropSuccess Missing image ${uploadedRes}`);
      return;
    }

    const changes = { coverImages: [uploadedRes.image] };
    setTrainingPlan({ ...trainingPlan, ...changes });
  };

  const handleCropRemove = () => {
    const changes = { ...trainingPlan, coverImages: [] };
    setTrainingPlan(changes);
  };

  useEffect(() => {
    setTrainingPlan(props.trainingPlan);
  }, [props.trainingPlan]);

  if (!trainingPlan) {
    return <Loading />;
  }

  let coverImageElement = (
    <MediaUpload
      images={trainingPlan.coverImages}
      onError={handleUploadError}
      onSuccess={handleUploadSuccess}
      onRemove={handleUploadRemove}
    />
  );

  if (
    trainingPlan.coverImages &&
    trainingPlan.coverImages.length > 0 &&
    trainingPlan.coverImages[0]
  ) {
    const image = trainingPlan.coverImages[0];
    const firstUrl = image.url;
    const aspect = image.aspect;

    let w = image.w;
    let h = image.h;

    const MAX_SIZE = 400;
    let resizeFactor;
    if (w > MAX_SIZE || h > MAX_SIZE) {
      if (w > h) {
        resizeFactor = MAX_SIZE / w;
        w = MAX_SIZE;
        h = Math.round(resizeFactor * h);
      } else {
        resizeFactor = MAX_SIZE / h;
        h = MAX_SIZE;
        w = Math.round(resizeFactor * w);
      }
    }

    if (firstUrl && aspect !== 'wide') {
      coverImageElement = (
        <ImageCropper
          imageUrl={firstUrl}
          aspectRatio={ASPECT_WIDTH / ASPECT_HEIGHT}
          width={w}
          height={h}
          onSuccess={handleCropSuccess}
          onRemove={handleCropRemove}
        />
      );
    }
  }

  return (
    <Card>
      <CardContent>
        <form
          id="detailsForm"
          className="form-horizontal"
          onSubmit={handleSave}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                label="Title"
                margin="normal"
                name="title"
                type="text"
                maxLength={60}
                onChange={handleEdit}
                value={trainingPlan.title}
                variant="outlined"
                helperText="Max 60 characters. Examples: 5k Training Plan, 30 Days to Stronger Abs"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                multiline
                label="Description"
                margin="normal"
                name="desc"
                type="text"
                rows={4}
                maxLength={540}
                onChange={handleEdit}
                value={trainingPlan.desc ?? ''}
                variant="outlined"
                helperText="Max 540"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <ChipInput
                label="Tags"
                fullWidth
                style={{ marginBottom: 24 }}
                InputLabelProps={{ shrink: true }}
                value={trainingPlan.tags ?? []}
                onAdd={handleAddTag}
                onDelete={handleDeleteTag}
                variant="outlined"
                helperText="Pick a few keywords to help athletes find your plan. Press enter after each tag."
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <LabelledOutline id="cover-image" label="Cover Image">
                {coverImageElement}
              </LabelledOutline>
              <FormHelperText>
                This is an optional landscape image(aspect: {ASPECT_WIDTH}x
                {ASPECT_HEIGHT}) which can be shown for the training plan.
                Images should be less than 2 MB so it loads fast for the user.
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelledOutline id="schedule" label="Schedule">
                <FormControlLabel
                  label="Follows a Schedule?"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={!!trainingPlan.hasSchedule}
                      onChange={handleEdit}
                      name="hasSchedule"
                      color="primary"
                    />
                  }
                />
              </LabelledOutline>
              <FormHelperText>
                Check this if you want the workouts to show the Day as specified
                in the Schedule editor. <br />
                Uncheck if this set of workouts is a category like "Ab Workouts"
              </FormHelperText>
            </Grid>
            <Grid item xs={8}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="level">Level</InputLabel>

                <Select
                  native
                  value={trainingPlan.level}
                  onChange={handleEdit}
                  label="Level"
                  inputProps={{
                    name: 'level',
                    id: 'level',
                  }}
                >
                  <option value="0">Beginner</option>
                  <option value="1">Intermediate</option>
                  <option value="2">Advanced</option>
                </Select>
                <FormHelperText>
                  Set to the level of effort for this training plan.
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={8}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  native
                  value={trainingPlan.status}
                  onChange={handleEdit}
                  label="Status"
                  inputProps={{
                    name: 'status',
                    id: 'status',
                  }}
                >
                  <option value="0">Draft</option>
                  <option value="1">Public</option>
                  <option value="2">Private (Unlisted)</option>
                </Select>
                <FormHelperText>
                  Set to Public when you're ready to have it viewable. <br />
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={8}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
