import { gql } from '@apollo/client';

const WEB_PAGE_FRAGMENT = gql`
  fragment WebPageFragment on WebPage {
    __typename
    _id
    appName
    slug
    customDomainHost
    platformSubdomainLandingHost
    colorTheme
    url
    checkoutUrl
    logo {
      url
    }
    trainerId
    trainer {
      _id
      name
    }

    heroSection {
      title
      subtitle
      ctaButtonText
      imageUrl
      videoUrl
    }
    appDownloadSection {
      title
      subtitle
      ctaButtonText
      imageUrl
    }
    featuresSection {
      title
      imageUrl
      features {
        title
        subtitle
      }
    }
    aboutSection {
      content
      imageUrl
    }
    testimonialsSection {
      testimonials {
        _id
        name
        quote
        imageUrl
      }
    }

    footerSection {
      links {
        _id
        title
        url
      }
    }
    updatedAt
    createdAt
  }
`;

export const GET_WEB_PAGE = gql`
  query GetWebPage($trainerId: ID) {
    webPage(trainerId: $trainerId) {
      ...WebPageFragment
    }
  }
  ${WEB_PAGE_FRAGMENT}
`;

export const UPDATE_WEB_PAGE = gql`
  mutation UpdateWebPage($id: ID!, $input: WebPageInput!) {
    updateWebPage(id: $id, input: $input) {
      ...WebPageFragment
    }
  }
  ${WEB_PAGE_FRAGMENT}
`;

export const SAVE_CUSTOM_DOMAIN = gql`
  mutation SaveCustomDomain($input: CustomDomainInput!) {
    saveCustomDomain(input: $input) {
      trainerId
      customDomainHost
    }
  }
`;
