import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/use-auth';

const AuthGuard = ({ children }) => {
  const auth = useAuth();
  const loggedIn = !!auth.user;
  if (auth.loading) {
    return <></>;
  }

  if (!loggedIn) {
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
