import { Button, Box, Container } from '@mui/material';
import React from 'react';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import { useQuery, gql } from '@apollo/client';
import TrainerResults from './TrainerResults';

const LIMIT = 50;
const GET_TRAINERS_QUERY = gql`
  query GetTrainers($input: TrainersQueryInput) {
    trainers(input: $input) {
      _id
      name
      avatarUrl
      createdAt
      totalUserCount
      hasActiveTrainerSubscription
    }
  }
`;

export default function TrainerList(props) {
  const { loading: dataLoading, error, data, fetchMore } = useQuery(GET_TRAINERS_QUERY);

  if (error) return <ServerError message="Failed to load" />;

  const trainers = data?.trainers;
  const handleLoadMore = async (event) => {
    event.preventDefault();

    await fetchMore({
      variables: {
        input: {
          limit: LIMIT,
          lastId: trainers[trainers.length - 1]._id,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          trainers: [...previousResult.trainers, ...fetchMoreResult.trainers],
        };
      },
    });
  };

  if (dataLoading) {
    return <Loading />;
  }


  return (
    <Container maxWidth={false}>
      <Box mt={3}>
        <TrainerResults trainers={trainers} />
      </Box>
      {trainers.length > 1 && trainers.length >= LIMIT && (
        <Box m={3}>
          <Button variant="outlined" onClick={handleLoadMore}>
            LOAD MORE
          </Button>
        </Box>
      )}
    </Container>
  );
}
