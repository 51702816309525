import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Grid,
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { UPDATE_APP_INFO } from '../../graphql/onboarding';
import DirectUpload from '../shared/DirectUpload';

const maxOnboardingImages = 1;

const AppDetails = ({ className, onBack, onNext, appInfo, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [save] = useMutation(UPDATE_APP_INFO, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      if (onNext) {
        onNext();
      }
    },
  });
  const handleIconUploadError = () => {
    enqueueSnackbar(`Media upload failed!`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };

  const handleIconUploadSuccess = (uploadedRes, setFieldValue) => {
    const icon = {
      url: uploadedRes.image.url,
      w: uploadedRes.image.w,
      h: uploadedRes.image.h,
    };

    setFieldValue('icon', icon);
  };

  const handleIconUploadRemove = (file, setFieldValue) => {
    setFieldValue('icon', null);
  };

  const handleOnboardingImageUploadError = () => {
    enqueueSnackbar(`Media upload failed!`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };

  const handleOnboardingImageUploadSuccess = (uploadedRes, setFieldValue) => {
    const newImage = {
      url: uploadedRes.image.url,
      w: uploadedRes.image.w,
      h: uploadedRes.image.h,
      aspect: uploadedRes.image.aspect,
    };

    const onboardingImages = (appInfo.onboardingImages ?? []).concat(newImage);
    setFieldValue('onboardingImages', onboardingImages);
  };

  const handleOnboardingImageUploadRemove = (file, setFieldValue) => {
    let onboardingImages = appInfo.onboardingImages?.slice(0);
    onboardingImages = (onboardingImages || []).filter(
      (element) => element.url === file.url
    );
    setFieldValue('onboardingImages', onboardingImages);
  };

  return (
    <Formik
      initialValues={{
        title: appInfo.title,
        icon: appInfo.icon,
        onboardingImages: appInfo.onboardingImages || [],
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .min(3, 'Must be at least 3 characters')
          .max(255)
          .required('Required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setSubmitting(true);
          enqueueSnackbar('Saving...');
          await save({
            variables: {
              appInfoId: appInfo._id,
              updateAppInfoInput: {
                title: values.title,
                icon: values.icon,
                onboardingImages: values.onboardingImages,
              },
            },
          });
          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Typography variant="h3" color="textPrimary">
            App Title
          </Typography>
          <Box mt={2}>
            <Typography variant="subtitle1" color="textSecondary">
              Enter some information about your app.
            </Typography>
          </Box>
          <Box mt={2}>
            <TextField
              error={Boolean(touched.title && errors.title)}
              fullWidth
              helperText={touched.title && errors.title}
              label="App Title"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
              variant="outlined"
            />
            <FormHelperText>
              Choose a name that fits your brand. Some of our customers use
              names like 'Train with Ada' or 'Ada Fitness'
            </FormHelperText>
          </Box>

          <Box mt={2}>
            <Typography variant="h4" gutterBottom>
              App Icon
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={7}>
                <DirectUpload
                  images={[values.icon]}
                  onError={handleIconUploadError}
                  onSuccess={(res) => {
                    handleIconUploadSuccess(res, setFieldValue);
                  }}
                  onRemove={(res) => {
                    handleIconUploadRemove(res, setFieldValue);
                  }}
                  maxFiles={1}
                  style={{ minHeight: 256, minWidth: 256 }}
                />
                <br />

                <FormHelperText>
                  Upload a square 1024x1024 icon.
                  <br />
                  This is what your user will see on their iPhone home screen
                  for your app.
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box
                  style={{
                    border: '1px solid #000',
                    borderRadius: '24px',
                    background: '#fff',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <img
                    style={{ objectFit: 'contain', width: '100%' }}
                    alt="example trainer welcome"
                    src="https://image-resizer.tempokit.com/scale?url=https://tempo-media-production.s3-us-west-2.amazonaws.com/290962/8f6b1140-7ab5-11eb-a836-4d2b2c2094e5.jpg&width=512&height=512"
                  />
                </Box>

                <FormHelperText>Example Icon</FormHelperText>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Typography variant="h4" gutterBottom>
              Photos for Welcome Screen
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={7}>
                <DirectUpload
                  images={values.onboardingImages}
                  onError={handleOnboardingImageUploadError}
                  onSuccess={(res) => {
                    handleOnboardingImageUploadSuccess(res, setFieldValue);
                  }}
                  onRemove={(res) => {
                    handleOnboardingImageUploadRemove(res, setFieldValue);
                  }}
                  maxFiles={maxOnboardingImages}
                  style={{ minHeight: 400 }}
                />
                <br />

                <FormHelperText>
                  Upload up a portrait(1080x1920) photo.
                  <br />
                  This photo will be on the onboarding screen when the user
                  starts the app for the very first time.
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box
                  style={{
                    border: '4px solid #000',
                    borderRadius: '4px',
                    background: '#000',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <img
                    style={{ objectFit: 'contain', width: '100%' }}
                    alt="example trainer welcome"
                    src="https://image-resizer.tempokit.com/scale?url=https://tempo-media-production.s3-us-west-2.amazonaws.com/696555/b8749490-5a7a-11eb-b8b8-cffd4fde53ca.jpg&w=1125&h=2436"
                  />
                </Box>

                <FormHelperText>Example Image</FormHelperText>
              </Grid>
            </Grid>
          </Box>

          <Box mt={6} display="flex">
            {onBack && (
              <Button onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

AppDetails.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  appInfo: PropTypes.object,
};

AppDetails.defaultProps = {
  onNext: () => {},
  onBack: () => {},
};

export default AppDetails;
