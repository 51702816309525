import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Scrollbar from '../shared/Scrollbar';
import ChatMessage from './ChatMessage';

const ChatMessages = (props) => {
  const { messages, participants, ...other } = props;
  const rootRef = useRef(null);

  const scrollToBottom = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (rootRef?.current?._container) {
      // eslint-disable-next-line no-underscore-dangle
      rootRef.current._container.scrollTop =
        rootRef.current._container.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  return (
    <Scrollbar options={{ suppressScrollX: true }} ref={rootRef} {...other}>
      <Box>
        {messages.map((message) => {
          const senderType = message.isLoggedInUserSender ? 'user' : 'contact';
          return (
            <ChatMessage
              message={message}
              key={message._id}
              senderType={senderType}
            />
          );
        })}
      </Box>
    </Scrollbar>
  );
};

ChatMessages.propTypes = {
  messages: PropTypes.array,
  participants: PropTypes.array,
};

export default ChatMessages;
