import React, { useEffect, useState } from 'react';
import QueryString from 'query-string';
import { Link as RouterLink } from 'react-router-dom';
import Page from '../Page';
import { Grid, Box, Container, Typography } from '@mui/material';
import Loading from '../shared/Loading';
import { useQuery } from '@apollo/client';
import { GET_CONVERSATIONS } from '../../graphql/chat';
import ServerError from '../error/ServerError';
import ChatSidebar from './ChatSidebar';
import ChatConvo from './ChatConvo';
import config from '../../config';

export default function Chat() {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;
  const conversationIdFromQueryParams = queryParams.conversationId || null;
  const [activeConvo, setActiveConvo] = useState(null);
  const { error, loading, data, refetch } = useQuery(GET_CONVERSATIONS, {
    variables: { input: { trainerId } },
  });

  const handleSelectConvo = (conversation) => {
    setActiveConvo(conversation);
  };

  useEffect(() => {
    if (conversationIdFromQueryParams && data) {
      const convoFromParams = data.conversations.find(
        (x) => conversationIdFromQueryParams === x._id
      );
      if (convoFromParams) {
        setActiveConvo(convoFromParams);
      }
    } else {
      const first = data?.conversations[0];
      if (first) {
        setActiveConvo(first);
      }
    }
  }, [data, conversationIdFromQueryParams]);

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!data?.conversations) {
    return <ServerError message={'Error'} />;
  }

  const sortedConvos =
    data?.conversations
      .slice(0)
      .sort((a, b) => b.hasUnreadMessages - a.hasUnreadMessages) || [];

  return (
    <Page title={`Chat : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Chat
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Chat with your users: form check their movements and progress. Chat
          will only be available to paying app subscribers and if you{' '}
          <RouterLink to="/trainer/app_features/chat">enable it</RouterLink>.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <Box
              sx={{
                backgroundColor: 'background.default',
                display: 'flex',
                height: '100%',
                marginBottom: 24,
              }}
            >
              {(data?.conversations || []).length === 0 && (
                <Box sx={{ padding: 4 }}>
                  <Typography variant="body1">
                    A customer will need to start a chat with you.
                  </Typography>
                </Box>
              )}
              {(data?.conversations || []).length > 0 && (
                <ChatSidebar
                  activeConversationId={activeConvo?._id}
                  onSelect={handleSelectConvo}
                  conversations={sortedConvos}
                />
              )}
              {!!activeConvo && <ChatConvo conversation={activeConvo} />}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
