import React from 'react';
import ExerciseEditorDialog from './ExerciseEditorDialog';
import { useQuery } from '@apollo/client';
import ServerError from '../error/ServerError';
import Loading from '../shared/Loading';
import { GET_EXERCISE } from '../../graphql/exercise';

const ExerciseEditor = ({ match }) => {
  const { data, loading, error } = useQuery(GET_EXERCISE, {
    variables: {
      id: match?.params?.id,
    },
  });

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!data.exercise) {
    return <ServerError message={'Could not load exercise!'} />;
  }

  return (
    <div>
      <ExerciseEditorDialog
        open={true}
        onClose={() => {}}
        exercise={data.exercise}
      />
    </div>
  );
};
export default ExerciseEditor;
