import React, { useState } from 'react';
import {
  FormHelperText,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';

import DialogTitle from '../shared/DialogTitle';
import { useMutation } from '@apollo/client';
import { INVITE_USER } from '../../graphql/users';

import { useSnackbar } from 'notistack';

const InviteUserDialog = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [inviteUser] = useMutation(INVITE_USER, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      alert(data.inviteUser.message);
      setEmail('');
      onClose();
    },
  });
  const handleSubmit = async (event) => {
    await inviteUser({
      variables: { input: { email } },
    });
  };

  const handleEdit = (e) => {
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    }
    if (e.target.name === 'message') {
      setMessage(e.target.value);
    }
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle
        style={{ marginTop: 4 }}
        sx={{ width: '40ch' }}
        onClose={onClose}
      >
        Invite User
      </DialogTitle>

      <DialogContent dividers>
        <Box mb={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Email"
            fullWidth
            variant="outlined"
            name="email"
            value={email}
            onChange={handleEdit}
          />
        </Box>

        <Box mb={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Message"
            fullWidth
            variant="outlined"
            name="message"
            multiline={true}
            rows={4}
            value={message || ''}
            onChange={handleEdit}
          />
          <FormHelperText>
            Include a welcome message for the user.
          </FormHelperText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleSubmit} color="primary">
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserDialog;
