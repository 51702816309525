import { useState } from 'react';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, IconButton, Link, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const ChatMessage = ({ message, senderType = 'user' }) => {
  const {
    content,
    contentFormat,
    fileUrl,
    imageUrl,
    videoUrl,
    videoStreamingUrl,
    createdAt,
    sentByUser,
  } = message;
  // TODO use expandMedia to show image in modal
  const [expandMedia, setExpandMedia] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: senderType === 'user' ? 'row-reverse' : 'row',
          maxWidth: 500,
          ml: senderType === 'user' ? 'auto' : 0,
        }}
      >
        <Avatar
          src={sentByUser.avatarUrl}
          sx={{
            height: 32,
            ml: senderType === 'user' ? 2 : 0,
            mr: senderType === 'user' ? 0 : 2,
            width: 32,
          }}
        />
        <div>
          <Box
            sx={{
              backgroundColor:
                senderType === 'user' ? 'primary.main' : 'background.paper',
              borderRadius: 1,
              boxShadow: 1,
              color:
                senderType === 'user' ? 'primary.contrastText' : 'text.primary',
              px: 2,
              py: 1,
            }}
          >
            <Link
              color="inherit"
              component={RouterLink}
              to={`/trainer/users/${sentByUser._id}`}
              variant="subtitle2"
            >
              {sentByUser.name}
            </Link>
            <Box sx={{ mt: 1 }}>
              {fileUrl && (
                <Box
                  onClick={() => setExpandMedia(true)}
                  sx={{
                    mt: 2,
                  }}
                >
                  <IconButton
                    component="a"
                    href={fileUrl}
                    color="info"
                    size="large"
                  >
                    <FileUploadIcon />
                  </IconButton>
                </Box>
              )}
              {imageUrl && !videoUrl && (
                <Box
                  onClick={() => setExpandMedia(true)}
                  sx={{
                    mt: 2,
                    '& img': {
                      cursor: 'pointer',
                      height: 'auto',
                      maxWidth: '100%',
                      width: 380,
                    },
                  }}
                >
                  <img alt="Attachment" src={imageUrl} />
                </Box>
              )}
              {videoUrl && (
                <Box
                  sx={{
                    mt: 2,
                    '& video': {
                      cursor: 'pointer',
                      height: 'auto',
                      maxWidth: '100%',
                      width: 380,
                    },
                  }}
                >
                  <video controls src={videoUrl} />
                </Box>
              )}
              {content && (
                <Typography color="inherit" variant="body1">
                  {content}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: senderType === 'user' ? 'flex-end' : 'flex-start',
              mt: 1,
              px: 2,
            }}
          >
            <Typography color="textSecondary" noWrap variant="caption">
              {moment(createdAt).format('LLL')}
            </Typography>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default ChatMessage;
