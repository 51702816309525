import React, { useState } from 'react';
import { apiUrl } from '../../config';

import { useAuth } from '../../hooks/use-auth';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  Box,
  Grid,
  TextField,
  FormHelperText,
} from '@mui/material';

const addSubscription = async ({ token, subscriptionInput }) => {
  var opts = {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(subscriptionInput),
  };

  try {
    const response = await fetch(`${apiUrl}/v1/subscriptions`, opts);

    if (response.status !== 201) {
      const json = await response.json();
      window.alert(json.message);
      return;
    }

    window.location.reload();
  } catch (error) {
    window.alert(error.message);
    console.error('Request failed', error);
  }
};

const removeSubscription = async (id, token) => {
  if (!window.confirm('Are you sure you want to delete this subscription?')) {
    return;
  }

  var opts = {
    method: 'DELETE',
    credentials: 'include',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  };

  try {
    const response = await fetch(`${apiUrl}/v1/subscriptions/${id}`, opts);
    const text = await response.text();
    window.alert(text);
    window.location.reload();
  } catch (error) {
    window.alert(error.message);
    console.error('Request failed', error);
  }
};

const updateSubscriptions = (id, token) => {
  var opts = {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  };
  fetch(`${apiUrl}/v1/subscriptions/${id}`, opts)
    .then(function (response) {
      window.location.reload();
    })
    .catch(function (error) {
      console.error('Request failed', error);
    });
};

const SubscriptionOrder = ({ subscriptionOrder, token }) => {
  return (
    <Card>
      <CardHeader title="Subscription Orders" />
      <Divider />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>{subscriptionOrder._id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>{subscriptionOrder.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vendor</TableCell>
              <TableCell>{subscriptionOrder.vendor}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>VendorCustomerId</TableCell>
              <TableCell>
                {subscriptionOrder.isStripeSubscription ? (
                  <a
                    href={`https://dashboard.stripe.com/customers/${subscriptionOrder.vendorCustomerId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {subscriptionOrder.vendorCustomerId}
                  </a>
                ) : (
                  subscriptionOrder.vendorCustomerId
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>VendorSubscriptionId</TableCell>
              <TableCell>
                {subscriptionOrder.isStripeSubscription ? (
                  <a
                    href={`https://dashboard.stripe.com/subscriptions/${subscriptionOrder.vendorSubscriptionId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {subscriptionOrder.vendorSubscriptionId}
                  </a>
                ) : (
                  subscriptionOrder.vendorSubscriptionId
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>vendorPlanId</TableCell>
              <TableCell>{subscriptionOrder.vendorPlanId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CreatedAt</TableCell>
              <TableCell>{subscriptionOrder.createdAt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ExpiresAt</TableCell>
              <TableCell>{subscriptionOrder.expiresAt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CanceledAt</TableCell>
              <TableCell>{subscriptionOrder.canceledAt}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const Subscription = ({ subscription, token }) => {
  return (
    <Card>
      <CardHeader title="Subscriptions" />
      <Divider />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>{subscription._id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Trainer</TableCell>
              <TableCell>
                <a href={`/admin/trainers/${subscription.trainerId}`}>
                  {subscription.trainerId}
                </a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vendor</TableCell>
              <TableCell>{subscription.vendor}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vendor Subscription Id</TableCell>
              {subscription.isStripeSubscription ? (
                <a
                  href={`https://dashboard.stripe.com/subscriptions/${subscription.vendorSubscriptionId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {subscription.vendorSubscriptionId}
                </a>
              ) : (
                subscription.vendorSubscriptionId
              )}
            </TableRow>
            <TableRow>
              <TableCell>Vendor Plan Id</TableCell>
              <TableCell>{subscription.vendorPlanId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CreatedAt</TableCell>
              <TableCell>{subscription.createdAt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ExpiresAt</TableCell>
              <TableCell>{subscription.expiresAt}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>CanceledAt</TableCell>
              <TableCell>{subscription.canceledAt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Button
                  onClick={() => {
                    removeSubscription(subscription._id, token);
                  }}
                  variant="outlined"
                >
                  Remove
                </Button>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const SubscriptionEvent = ({ subscriptionEvent, token }) => {
  return (
    <Card>
      <CardHeader title="Subscription Events" />
      <Divider />

      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>{subscriptionEvent._id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>event</TableCell>
              <TableCell>{subscriptionEvent.event}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vendor</TableCell>
              <TableCell>{subscriptionEvent.vendor}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vendor Subscription Id</TableCell>
              <TableCell>
                {subscriptionEvent.isStripeSubscription ? (
                  <a
                    href="https://dashboard.stripe.com/subscriptions/{{vendorSubscriptionId}}"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {subscriptionEvent.vendorSubscriptionId}
                  </a>
                ) : (
                  subscriptionEvent.vendorSubscriptionId
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vendor Plan Id</TableCell>
              <TableCell>{subscriptionEvent.vendorPlanId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>OccurredAt</TableCell>
              <TableCell>{subscriptionEvent.occurredAt}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const Purchase = ({ purchase }) => {
  return (
    <Card>
      <CardHeader title="Subscription Events" />
      <Divider />

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>{purchase._id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Trainer</TableCell>
            <TableCell>
              <a href={`/admin/trainers/${purchase.trainerId}`}>
                {purchase.trainerId}
              </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Vendor</TableCell>
            <TableCell>{purchase.vendor}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Training Plan Id</TableCell>
            <TableCell>{purchase.trainingPlanId}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>CreatedAt</TableCell>
            <TableCell>{purchase.createdAt}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const UserSubscriptionTab = (props) => {
  const defaultExpiresAt = '2025-03-14';
  const auth = useAuth();
  const [subscriptionInput, setSubscriptionInput] = useState({
    expiresAt: defaultExpiresAt,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const expiresAtDate = new Date(subscriptionInput.expiresAt);
      const token = await auth.user.getIdToken();
      await addSubscription({
        token,
        subscriptionInput: {
          expiresAt: new Intl.DateTimeFormat('en-US').format(expiresAtDate),
          userId: props.user._id,
        },
      });
    } catch (e) {
      alert('invalid date format, try YYYY-MM-DD');
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const changes = { ...subscriptionInput };
    changes[e.target.name] = e.target.value;
    setSubscriptionInput(changes);
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Typography variant="h4" gutterBottom>
                Add a promo subscription
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Expires Date"
                    name="expiresAt"
                    type="date"
                    defaultValue={defaultExpiresAt}
                    onChange={handleChange}
                    value={subscriptionInput.expiresAt}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormHelperText>
                    When do you want this to last until?
                  </FormHelperText>
                </Grid>
                <Grid key="submit" item xs={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Box mt={3}>
              <Typography variant="h4" gutterBottom>
                Update Subscriptions
              </Typography>
              <Button
                onClick={() => updateSubscriptions(props.user._id, props.token)}
                variant="outlined"
              >
                Update
              </Button>
              <FormHelperText>
                This attempts to pull the latest subscription data from Stripe
                and Apple. Use this if a user bought a subscription but isn't
                listed below.
              </FormHelperText>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        {props.subscriptionOrders.length === 0 ? (
          <Typography variant="body1" paragraph>
            No subscriptionOrders
          </Typography>
        ) : null}
        {(props.subscriptionOrders || []).map((subscriptionOrder) => (
          <SubscriptionOrder
            key={subscriptionOrder._id}
            subscriptionOrder={subscriptionOrder}
            token={props.token}
          />
        ))}
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        {props.subscriptions.length === 0 ? (
          <Typography variant="body1" paragraph>
            No subscriptions
          </Typography>
        ) : null}
        {(props.subscriptions || []).map((subscription) => (
          <Subscription
            key={subscription._id}
            subscription={subscription}
            token={props.token}
          />
        ))}
      </Grid>

      <Grid item lg={4} md={6} xl={3} xs={12}>
        {props.subscriptionEvents.length === 0 ? (
          <Typography variant="body1" paragraph>
            No subscriptionEvents
          </Typography>
        ) : null}
        {(props.subscriptionEvents || []).map((subscriptionEvent) => (
          <SubscriptionEvent
            key={subscriptionEvent._id}
            subscriptionEvent={subscriptionEvent}
          />
        ))}
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        {props.purchases.length === 0 ? (
          <Typography variant="body1" paragraph>
            No purchases
          </Typography>
        ) : null}
        {(props.purchases || []).map((purchase) => (
          <Purchase key={purchase._id} purchase={purchase} />
        ))}
      </Grid>
    </Grid>
  );
};

export default UserSubscriptionTab;
