import React, { useState } from 'react';
import Page from '../Page';
import { Slider, Box, Container, Typography } from '@mui/material';
import config from '../../config';

function pricingValueLabelFormat(value) {
  const units = [1, 2, 3, 4];
  let unitIndex = 0;
  let scaledValue = value;

  while (scaledValue >= 1024 && unitIndex < units.length - 1) {
    unitIndex += 1;
    scaledValue /= 1024;
  }

  return `${formatter.format(scaledValue)}`;
}

function calculatePricingValue(value) {
  return 2 ** value;
}

let formatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const monthlyPriceMarks = [2, 3, 4, 5, 6, 7, 8].map((num) => {
  return {
    value: num,
    label: pricingValueLabelFormat(calculatePricingValue(num)),
    key: pricingValueLabelFormat(calculatePricingValue(num)),
  };
});

const numberOfCustomerMarks = [20, 50, 100, 500, 1000].map((num) => {
  return {
    value: num,
    label: num,
    key: num,
  };
});

export default function IncomeCalculator() {
  const [numberOfCustomers, setNumberOfCustomers] = useState(50);
  const [monthlyPrice, setMonthlyPrice] = useState(4);
  const monthlyIncome = numberOfCustomers * calculatePricingValue(monthlyPrice);
  const annualIncome = monthlyIncome * 12;

  return (
    <Page title={`Income Calculator : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Income Calculator
        </Typography>
        <Box mb={3}>
          <Typography variant="h5" color="textPrimary" gutterBottom>
            Number of Customers
          </Typography>

          <Typography variant="h5" color="textPrimary" gutterBottom>
            {numberOfCustomers}
          </Typography>

          <Box mb={3} style={{ width: 600 }}>
            <Slider
              aria-label="Number of Customers"
              onChange={(event) => {
                setNumberOfCustomers(event.target.value);
              }}
              value={numberOfCustomers}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => value}
              marks={numberOfCustomerMarks}
              step={20}
              min={0}
              max={1000}
            />
          </Box>

          <Typography variant="h5" color="textPrimary" gutterBottom>
            Monthly Subscription Price
          </Typography>

          <Typography variant="h5" color="textPrimary" gutterBottom>
            {pricingValueLabelFormat(calculatePricingValue(monthlyPrice))}
          </Typography>

          <Box mb={3} style={{ width: 600 }}>
            <Slider
              aria-label="Monthly Subscription Price"
              onChange={(event) => {
                setMonthlyPrice(event.target.value);
              }}
              value={monthlyPrice}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => pricingValueLabelFormat(value)}
              scale={calculatePricingValue}
              marks={monthlyPriceMarks}
              step={1}
              min={1}
              max={8}
            />
          </Box>

          <Typography variant="h2" color="textPrimary" gutterBottom>
            Monthly Income: {formatter.format(monthlyIncome)}
          </Typography>
          <Typography variant="h2" color="textPrimary" gutterBottom>
            Annual Income: {formatter.format(annualIncome)}
          </Typography>
        </Box>
      </Container>
    </Page>
  );
}
