function check(buffer) {
  var view = new DataView(buffer);

  // is a PNG?
  if (view.getUint32(0) === 0x89504e47 && view.getUint32(4) === 0x0d0a1a0a) {
    // We know format field exists in the IHDR chunk. The chunk exists at
    // offset 8 +8 bytes (size, name) +8 (depth) & +9 (type)
    var depth = view.getUint8(8 + 8 + 8);
    var type = view.getUint8(8 + 8 + 9);

    return {
      depth: depth,
      type: ['G', '', 'RGB', 'Indexed', 'GA', '', 'RGBA'][type],
      buffer: view.buffer,
      hasAlpha: type === 4 || type === 6, // grayscale + alpha or RGB + alpha
    };
  }
}

export async function imageDetails(url) {
  const response = await fetch(url);
  if (response.status !== 200) {
    const msg = await response.body();
    throw new Error(msg);
  }
  const buffer = await response.arrayBuffer();

  return check(buffer);
}
