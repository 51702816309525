import { gql } from '@apollo/client';

export const GET_TRAINING_PLAN_SESSIONS = gql`
  query GetTrainingPlanSessionsForTrainingPlan(
    $input: TrainingPlanSessionsQueryInput
  ) {
    trainingPlanSessions(input: $input) {
      _id
      userId
      trainingPlanId
      trainerId
      completedAt
      enrolledAt
      completedEventCount
      nextEventId
      nextEvent {
        _id
        objectId
        title
      }
      status
      user {
        _id
        name
        avatarUrl
      }
    }
  }
`;
