import React from 'react';
import MuiMarkdown from 'mui-markdown';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { formatAmountForDisplay } from '../../utils/stripeHelpers';

const useStyles = makeStyles((theme) => ({
  product: {
    position: 'relative',
    padding: theme.spacing(5, 3),
    cursor: 'pointer',
  },
}));

const PlanSelect = ({ prices, onPlanSelected, selectedPlanId, disabled }) => {
  const classes = useStyles();

  if (!prices) {
    return <h4>No products found</h4>;
  }
  return (
    <Box mt="20px">
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {(prices || []).map((price) => {
            if (!price) {
              return <></>;
            }
            return (
              <Grid key={price.stripePlanId} item md={4} xs={12}>
                <Paper
                  className={clsx(
                    classes.product,
                    selectedPlanId === price.stripePlanId &&
                      classes.selectedPlan
                  )}
                  sx={{
                    borderColor:
                      selectedPlanId === price.stripePlanId ? 'white' : 'none',
                    background:
                      selectedPlanId === price.stripePlanId ? 'white' : 'none',
                  }}
                  elevation={price.stripePlanId === selectedPlanId ? 20 : 1}
                  onClick={(e) => {
                    onPlanSelected(e, price.stripePlanId);
                  }}
                >
                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="inherit"
                  >
                    {price.name}
                  </Typography>
                  <div>
                    <Typography
                      component="span"
                      display="inline"
                      variant="h3"
                      color="inherit"
                    >
                      {formatAmountForDisplay(price.amount, 'usd')}
                    </Typography>

                    <Typography
                      component="span"
                      display="inline"
                      variant="body2"
                      color="textPrimary"
                    >
                      {' '}
                      and $2 per subscriber{' '}
                    </Typography>
                    <Typography
                      component="span"
                      display="inline"
                      variant="subtitle2"
                      color="inherit"
                    >
                      / {price.interval}
                    </Typography>
                  </div>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography
                    component="span"
                    display="inline"
                    variant="caption"
                    color="textPrimary"
                  >
                    <MuiMarkdown>{price.desc}</MuiMarkdown>
                  </Typography>

                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Button
                    variant={
                      price.stripePlanId === selectedPlanId
                        ? 'contained'
                        : 'outlined'
                    }
                    fullWidth
                    onClick={(e) => {
                      onPlanSelected(e, price.stripePlanId);
                    }}
                    disabled={disabled}
                  >
                    {price.stripePlanId === selectedPlanId && 'Selected'}
                    {price.stripePlanId !== selectedPlanId && 'Select'}
                  </Button>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default PlanSelect;
