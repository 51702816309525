import { gql } from '@apollo/client';

export const APP_STORE_INTEGRATIONS_QUERY = gql`
  query GetAppStoreIntegrations($trainerId: ID) {
    appStoreIntegrations(trainerId: $trainerId) {
      appleAppId
      appleBundleId
      appleIapSharedSecret
      appleAppStoreConnectVendorId
      appleAppStoreConnectIssuerId
      appleAppStoreConnectKeyId
      hasAppleAppStoreConnectPrivateKey
      googleAppId
      hasGoogleServiceAccountCredentials
    }

    verifyAppleAppStoreIntegration(trainerId: $trainerId) {
      status
      message
    }
  }
`;

export const SAVE_APP_STORE_INTEGRATIONS_MUTATION = gql`
  mutation SaveAppStoreIntegrations($input: AppStoreIntegrationInput!) {
    saveAppStoreIntegrations(input: $input) {
      appleAppId
      appleBundleId
      appleIapSharedSecret
      appleAppStoreConnectVendorId
      appleAppStoreConnectIssuerId
      appleAppStoreConnectKeyId
      hasAppleAppStoreConnectPrivateKey
      googleAppId
      hasGoogleServiceAccountCredentials
    }
  }
`;
