import React, { useState, useEffect } from 'react';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  Container,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Button,
} from '@mui/material';
import Loading from '../../components/shared/Loading';
import TrainingPlanItem from './TrainingPlanItem.jsx';
import Page from '../Page';
import ServerError from '../error/ServerError';
import AppPreview from '../app_preview/AppPreview';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_TRAINING_PLAN,
  DELETE_TRAINING_PLAN,
  GET_TRAINING_PLAN_LIST,
  UPDATE_TRAINING_PLAN_LIST,
} from '../../graphql/training_plan';
import { useSnackbar } from 'notistack';
import config from '../../config';

const InfoBox = () => (
  <Box mb={3}>
    <Typography variant="body1">
      Training Plans or Programs are collections of workouts. After you create
      several workouts, you can group them into these collections of related
      workouts ("Upper Body Workouts") or training plans ("30 Days to Summer" or
      "5k Training Program").
    </Typography>
  </Box>
);

const TrainingPlanList = (props) => {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;
  const [appPreviewRefresh, setAppPreviewRefresh] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const { loading, error, data, refetch } = useQuery(GET_TRAINING_PLAN_LIST, {
    variables: { trainerId },
  });
  const [createTrainingPlan] = useMutation(CREATE_TRAINING_PLAN, {
    onCompleted: (data) => {
      if (!data.createTrainingPlan._id) {
        return;
      }
      props.history.push(
        `/trainer/training_plans/${data.createTrainingPlan._id}`
      );
    },
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 1000,
      });
    },
  });
  const [updateTrainingPlanList] = useMutation(UPDATE_TRAINING_PLAN_LIST, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 1000,
      });
    },
  });
  const [deleteTrainingPlan] = useMutation(DELETE_TRAINING_PLAN, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 1000,
      });
    },
  });
  useEffect(() => {
    refetch();
  }, [refetch, props.history.location]);

  const handleCreate = (e) => {
    e.preventDefault();

    createTrainingPlan({
      variables: {
        input: {
          title: 'Untitled',
        },
      },
    });
  };

  const handleMove = async (sourceIndex, targetIndex) => {
    if (sourceIndex === targetIndex || !data) {
      return;
    }
    enqueueSnackbar('Saving...', { autoHideDuration: 1000 });
    const trainingPlanIds = (data.trainingPlanList?.trainingPlans ?? []).map(
      (i) => i._id
    );
    const items = trainingPlanIds.splice(sourceIndex, 1);
    trainingPlanIds.splice(targetIndex, 0, ...items);

    const featuredTrainingPlanIds =
      data.trainingPlanList?.featuredTrainingPlanIds ?? [];
    const newFeaturedTrainingPlanIds = featuredTrainingPlanIds
      .slice()
      .sort(function compareFn(firstPlanId, secondPlanId) {
        if (
          trainingPlanIds.indexOf(firstPlanId) <
          trainingPlanIds.indexOf(secondPlanId)
        ) {
          return -1;
        }
        return 1;
      });

    updateTrainingPlanList({
      variables: {
        id: data.trainingPlanList._id,
        input: {
          trainingPlanIds,
          featuredTrainingPlanIds: newFeaturedTrainingPlanIds,
        },
      },
    });
  };

  const handleAddToFeatured = async (id) => {
    if (!data || !id) {
      return;
    }
    enqueueSnackbar('Saving...', { autoHideDuration: 1000 });

    const trainingPlanIds = (data.trainingPlanList?.trainingPlans ?? []).map(
      (i) => i._id
    );

    const featuredTrainingPlanIds = [
      ...(data.trainingPlanList?.featuredTrainingPlanIds ?? []),
    ];
    featuredTrainingPlanIds.push(id);
    const newFeaturedTrainingPlanIds = Array.from(
      new Set(featuredTrainingPlanIds)
    ).sort(function compareFn(firstPlanId, secondPlanId) {
      if (
        trainingPlanIds.indexOf(firstPlanId) <
        trainingPlanIds.indexOf(secondPlanId)
      ) {
        return -1;
      }
      return 1;
    });

    updateTrainingPlanList({
      variables: {
        id: data.trainingPlanList._id,
        input: {
          featuredTrainingPlanIds: newFeaturedTrainingPlanIds,
        },
      },
    });
  };

  const handleRemoveFromFeatured = async (id) => {
    if (!data || !id) {
      return;
    }
    enqueueSnackbar('Saving...', { autoHideDuration: 1000 });

    const featuredTrainingPlanIds =
      data.trainingPlanList?.featuredTrainingPlanIds ?? [];
    const newFeaturedTrainingPlanIds = featuredTrainingPlanIds.filter(
      (planId) => planId !== id
    );

    updateTrainingPlanList({
      variables: {
        id: data.trainingPlanList._id,
        input: {
          featuredTrainingPlanIds: newFeaturedTrainingPlanIds,
        },
      },
    });
  };

  const handleDelete = (id) => {
    if (!id) {
      return;
    }
    if (!window.confirm('Delete the item?')) {
      return;
    }
    enqueueSnackbar('Deleting...', { autoHideDuration: 1000 });
    deleteTrainingPlan({
      variables: {
        id,
      },
    }).then(() => {
      setAppPreviewRefresh(appPreviewRefresh + 1);
    });
  };

  if (error) {
    return <ServerError message={error.message ?? 'Load failed!'} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Page title={`Training Plans : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Training Plans
        </Typography>

        <InfoBox />

        <Box mb={3}>
          <Typography gutterBottom variant="body1">
            Each plan will appear as a row in your app in the order below. You
            can drag and drop to change the ordering.
          </Typography>

          <Button
            component={Link}
            color="secondary"
            variant="contained"
            to="#"
            onClick={handleCreate}
          >
            Create a new Training Plan
          </Button>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Card>
              <Box minWidth={700}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Enrolled</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(data.trainingPlanList?.trainingPlans ?? []).map(
                      (plan, i) => {
                        return (
                          <TrainingPlanItem
                            key={plan._id}
                            featured={data.trainingPlanList?.featuredTrainingPlanIds.includes(
                              plan._id
                            )}
                            index={i}
                            trainingPlan={plan}
                            trainingPlanId={plan._id}
                            onMove={handleMove}
                            onAddToFeatured={() =>
                              handleAddToFeatured(plan._id)
                            }
                            onRemoveFromFeatured={() =>
                              handleRemoveFromFeatured(plan._id)
                            }
                            onDelete={() => handleDelete(plan._id)}
                          />
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppPreview
              key={appPreviewRefresh}
              trainerId={trainerId}
              screen="TRAINING_PLANS"
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default TrainingPlanList;
