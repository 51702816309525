import React, { useCallback, useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _ from 'lodash';
import Search from '../shared/Search';
import ExerciseList from './ExerciseList';
import { useLazyQuery } from '@apollo/client';
import ServerError from '../error/ServerError';
import Loading from '../shared/Loading';
import { SEARCH_EXERCISES } from '../../graphql/exercise';

const WorkoutExerciseSearch = ({ onAddNew, onSelect, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [search, { loading, error, data }] = useLazyQuery(SEARCH_EXERCISES, {
    variables: {
      input: {
        searchTerm: searchQuery,
        limit: 10,
      },
    },
  });

  const handleSearchChange = async (event) => {
    try {
      event.persist();
      const { value } = event.target;
      setSearchQuery(value);
    } catch (err) {
      console.error(err);
    }
  };

  const delayedSearch = useCallback(_.debounce(search, 500), []);

  useEffect(() => {
    delayedSearch({
      variables: {
        input: {
          searchTerm: searchQuery,
          limit: 15,
        },
      },
    });
  }, [searchQuery]);

  return (
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Search
        onChange={handleSearchChange}
        query={searchQuery}
        placeholder="Search Exercises"
        resultLabel="Exercises"
      />
      {!!loading && <Loading circular />}
      {!!error && <ServerError message={error.message} />}
      <ExerciseList
        exercises={data?.exercises ?? []}
        onSelect={onSelect}
        onAddNew={onAddNew}
        onClose={onClose}
      />
    </PerfectScrollbar>
  );
};

export default WorkoutExerciseSearch;
