import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export default function TrainingPlanSelect({ trainingPlans = [], onSelect }) {
  const loading = trainingPlans?.length === 0 || false;
  const [open, setOpen] = React.useState(false);

  return (
    <Stack spacing={3} sx={{ width: 500 }}>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event, newValue) => {
          onSelect(newValue);
        }}
        options={trainingPlans || []}
        getOptionLabel={(option) => option?.title || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Training Plan"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
}
