import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Avatar, Box, Link, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  bubble: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const Comment = ({ className, comment, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Avatar
        alt="Person"
        component={RouterLink}
        src={comment.user?.avatarUrl ?? ''}
        to="#"
      />
      <Box
        flexGrow={1}
        p={2}
        ml={2}
        bgcolor="background.dark"
        className={classes.bubble}
      >
        <Box display="flex" alignItems="center" mb={1}>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={comment.user?._id}
            variant="h6"
          >
            {comment.user?.name ?? ''}
          </Link>
          <Box flexGrow={1} />
          <Typography color="textSecondary" variant="caption">
            {moment(comment.createdAt).fromNow()}
          </Typography>
        </Box>
        <Typography variant="body1" color="textPrimary">
          {comment.body}
        </Typography>
      </Box>
    </div>
  );
};

Comment.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.object.isRequired,
};

export default Comment;
