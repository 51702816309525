import {
  Avatar,
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Link as RouterLink } from 'react-router-dom';
import getInitials from '../../utils/getInitials';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1),
  },
}));

const UserResults = ({ users }) => {
  const classes = useStyles();
  return (
    <Card>
      <Box minWidth={700}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Signed Up</TableCell>
              <TableCell>Completed Workouts</TableCell>
              <TableCell>Subscription Expires At</TableCell>
              <TableCell>Subscription Source</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((customer) => {
              // TODO if the user has more than one subscription, get the matching `trainerId` one
              const subscription = customer.subscriptions[0];

              return (
                <TableRow hover key={customer._id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        className={classes.avatar}
                        src={customer.mdSquareAvatarUrl}
                      >
                        {getInitials(customer.name)}
                      </Avatar>
                      <div>
                        <Link
                          color="inherit"
                          component={RouterLink}
                          to={`/trainer/users/${customer._id}`}
                          variant="h6"
                        >
                          {customer.name || customer._id}
                        </Link>
                      </div>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {new Date(customer.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell>{customer.workoutSessionCount}</TableCell>
                  <TableCell>
                    {subscription
                      ? new Date(subscription.expiresAt).toLocaleString()
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {subscription ? subscription.vendor : '-'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default UserResults;
