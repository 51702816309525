import React, { useCallback, useState, useEffect } from 'react';
import { apiUrl } from '../../config';

import QueryString from 'query-string';
import {
  Box,
  Tabs,
  Tab,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Container,
  Typography,
} from '@mui/material';
import { Alert } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import Loading from '../shared/Loading';
import { useAuth } from '../../hooks/use-auth';
import ActivityPost from '../users/ActivityPost';
import AppStoreReview from './AppStoreReview';
import NextStepsHelp from './../onboarding/NextStepsHelp';
import Page from '../Page';
import NumberBox from '../shared/NumberBox';
import { Grid } from '@mui/material';
import config from '../../config';

const getStartAndEndDayOfWeek = () => {
  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  return `${week[0]} - ${week[6]}`;
};

const tabs = [
  { value: 'activity', label: 'Activity' },
  { value: 'app-reviews', label: 'App Store Reviews' },
];

export default function Dashboard(props) {
  const [currentTab, setCurrentTab] = useState('activity');
  const [isLoading, setLoading] = useState(false);
  const [appStoreReviews, setAppStoreReviews] = useState([]);
  const [activities, setActivities] = useState([]);
  const [topCompletedWorkouts, setTopCompletedWorkouts] = useState([]);
  const [topEnrolledTrainingPlans, setTopEnrolledTrainingPlans] = useState([]);
  const [recentUsers, setRecentUsers] = useState([]);
  const [totalStats, setTotalStats] = useState({});
  const [weekStats, setWeekStats] = useState({});
  const [todayStats, setTodayStats] = useState({});
  const isMountedRef = useIsMountedRef(); // used to prevent mem leaks
  const auth = useAuth();
  const authLoading = auth.loading;
  const location = useLocation();
  const queryParams = QueryString.parse(location.search);
  const trainerId = queryParams.trainerId || null;
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const getDashboard = useCallback(async () => {
    setLoading(true);

    const authToken = await auth.user.getIdToken();
    let url = `${apiUrl}/v1/dashboard/trainer?include=session,target`;
    if (trainerId) {
      url += `&trainerId=${trainerId}`;
    }

    let response;
    try {
      const res = await fetch(url, {
        method: 'GET',
        headers: new Headers({ Authorization: `Bearer ${authToken}` }),
      });
      response = await res.json();
    } catch (err) {
      console.error(err);
      return;
    }

    setLoading(false);
    if (isMountedRef.current) {
      if (!response.activities) {
        setActivities([]);
        return;
      }
      setAppStoreReviews(response.appStoreReviews);
      setTopCompletedWorkouts(response.topCompletedWorkouts);
      setTopEnrolledTrainingPlans(response.topEnrolledTrainingPlans);
      setRecentUsers(response.recentUsers);
      setTotalStats(response.total);
      setWeekStats(response.week);
      setTodayStats(response.today);
      setActivities(response.activities);
    }
  }, [auth.user, trainerId, isMountedRef]);

  // TODO get this for app store urls
  const appInfo = {};

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  if (isLoading || authLoading) {
    return <Loading />;
  }

  if (activities.length === 0) {
    return (
      <Page title={`Get Started : ${config.appDisplayName}`}>
        <Container maxWidth="lg">
          <Box m={8} display="inline" justifyContent="center">
            <Box>
              <Typography
                align="center"
                variant="h1"
                color="textPrimary"
                gutterBottom
              >
                👋 Welcome!
              </Typography>
            </Box>
            <Box>
              <Typography
                align="center"
                variant="h4"
                color="textSecondary"
                gutterBottom
              >
                Let's build your own branded mobile app with your fitness
                content.
              </Typography>
            </Box>
          </Box>

          <Card>
            <CardContent>
              <NextStepsHelp />
            </CardContent>
          </Card>
        </Container>
      </Page>
    );
  }

  return (
    <Page title={`Dashboard : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          Dashboard
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" color="textSecondary">
              Total
            </Typography>
          </Grid>
          <Grid item lg={3} sm={4} xs={12}>
            <NumberBox title="Signups" value={totalStats.signupCount} />
          </Grid>
          <Grid item lg={3} sm={4} xs={12}>
            <NumberBox
              title="Memberships"
              value={totalStats.totalSubscriberCount}
            />
          </Grid>
          <Grid item lg={3} sm={4} xs={12}>
            <NumberBox
              title="Completed Workouts"
              value={totalStats.workoutSessionCount}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color="textSecondary">
              This week ({getStartAndEndDayOfWeek()})
            </Typography>
          </Grid>
          <Grid item lg={3} sm={4} xs={12}>
            <NumberBox title="Signups" value={weekStats.signupCount} />
          </Grid>
          <Grid item lg={3} sm={4} xs={12}>
            <NumberBox
              title="Memberships"
              value={weekStats.subscriptionCount}
            />
          </Grid>
          <Grid item lg={3} sm={4} xs={12}>
            <NumberBox
              title="Completed Workouts"
              value={weekStats.workoutSessionCount}
            />
          </Grid>

          <Grid item xs={12} md={9}>
            <Box>
              <Tabs
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={currentTab}
                variant="scrollable"
                textColor="secondary"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Box>

            <Box mt={3}>
              {currentTab === 'app-reviews' && (
                <>
                  {appInfo.googlePlayStoreLink && (
                    <Typography variant="h3">
                      <a
                        href={appInfo.googlePlayStoreLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Google App Store Reviews
                      </a>
                    </Typography>
                  )}
                  {appStoreReviews.length === 0 && (
                    <Alert severity="info">
                      There are no app reviews on the app stores yet. Ask a fan
                      to review your app!
                    </Alert>
                  )}
                  {appStoreReviews.map((review, i) => {
                    return <AppStoreReview appStoreReview={review} key={i} />;
                  })}
                </>
              )}

              {currentTab === 'activity' && (
                <>
                  {activities.map((activity) => {
                    return (
                      <ActivityPost key={activity._id} activity={activity} />
                    );
                  })}
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Card>
              <CardHeader title="Top Completed Workouts" />
              <Divider />
              <Table>
                <TableBody>
                  {topCompletedWorkouts.map((w) => {
                    return (
                      <TableRow key={w._id}>
                        <TableCell>
                          <RouterLink to={`/trainer/workouts/${w._id}`}>
                            {w.title}
                          </RouterLink>
                        </TableCell>
                        <TableCell>{w.completed}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>

            <br />
            <br />

            <Card>
              <CardHeader title="Top Enrolled Training Plans" />
              <Divider />
              <Table>
                <TableBody>
                  {topEnrolledTrainingPlans.map((w) => {
                    return (
                      <TableRow key={w._id}>
                        <TableCell>
                          <RouterLink to={`/trainer/training_plans/${w._id}`}>
                            {w.title}
                          </RouterLink>
                        </TableCell>
                        <TableCell>{w.enrolled}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>

            <br />
            <br />

            <Card>
              <CardHeader title="Recent Signups" />
              <Divider />
              <Table>
                <TableBody>
                  {recentUsers.map((u) => {
                    return (
                      <TableRow key={u._id}>
                        <TableCell>
                          <RouterLink to={`/trainer/users/${u._id}`}>
                            {u.name}
                          </RouterLink>
                        </TableCell>
                        <TableCell>{u.createdAt}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
