import React from 'react';
import GeneralSettings from './GeneralSettings';
import Loading from '../shared/Loading';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ME, UPDATE_ME } from '../../graphql/me';
import ServerError from '../error/ServerError';
import { useSnackbar } from 'notistack';

const Profile = ({ className, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { error, loading, data } = useQuery(GET_ME);
  const [updateMe] = useMutation(UPDATE_ME, {
    onCompleted: (data) => {
      enqueueSnackbar('Profile updated', {
        variant: 'success',
        autoHideDuration: 1000,
      });
    },
  });

  const handleUserProfileSave = async (changes) => {
    if (!data?.me) {
      return;
    }
    enqueueSnackbar('Saving...', { autoHideDuration: 1000 });
    updateMe({
      variables: {
        input: { ...changes },
      },
    });
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <GeneralSettings userProfile={data.me} onSave={handleUserProfileSave} />
  );
};

export default Profile;
