import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Typography,
  Avatar,
  Box,
  Divider,
  TextField,
  Tooltip,
} from '@mui/material';
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import SendIcon from '@mui/icons-material/Send';
import RemoveIcon from '@mui/icons-material/ClearOutlined';
import Image from 'react-graceful-image';
import { directUpload } from '../../utils/uploadHelper';
import { useAuth } from '../../hooks/use-auth';

const ChatMessageAdd = (props) => {
  const { avatarUrl, disabled, onSend, ...other } = props;
  const [body, setBody] = useState('');
  const [attachment, setAttachment] = useState(null);
  const { user } = useAuth();

  const handleAttach = async (event) => {
    const accessToken = await user.getIdToken();
    const file = Object(event.target?.files)[0];
    if (!file) {
      console.error('no file');
      return;
    }
    const uploadedRes = await directUpload({ accessToken, file });
    console.log('uploaded');
    console.log(uploadedRes);
    setAttachment(uploadedRes);
  };
  const handleRemoveAttachment = () => {
    setAttachment(null);
  };
  const handleChange = (event) => {
    setBody(event.target.value);
  };

  const handleSend = (event) => {
    if (!body && !attachment) {
      return;
    }

    onSend?.({ body, attachment });
    setBody('');
    setAttachment(null);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          flexShrink: 0,
          px: 2,
          py: 1,
        }}
        {...other}
      >
        <Avatar
          sx={{
            mr: 2,
          }}
          src={avatarUrl}
        />
        <TextField
          disabled={disabled}
          fullWidth
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          placeholder="Type a message"
          value={body}
          size="small"
          variant="outlined"
        />
        <Tooltip title="Send">
          <span>
            <IconButton
              color="primary"
              disabled={(!body && !attachment) || disabled}
              onClick={handleSend}
            >
              <SendIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Box
          sx={{
            alignItems: 'center',
            display: {
              xs: 'none',
              sm: 'flex',
            },
          }}
        >
          <Divider orientation="vertical" sx={{ height: 24 }} />

          <label sx={{ marginLeft: 2 }} htmlFor="message_attachment">
            <AddPhotoIcon fontSize="small" />
          </label>
          <input
            hidden
            id="message_attachment"
            onChange={handleAttach}
            type="file"
          />
        </Box>
      </Box>

      {attachment && (
        <Box style={{ width: 128 }}>
          <Typography style={{ margin: 8 }} variant="caption" gutterBottom>
            Attachments:
          </Typography>
          <List>
            <ListItem
              secondaryAction={
                <IconButton
                  onClick={handleRemoveAttachment}
                  edge="end"
                  aria-label="delete"
                >
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Image
                  style={{
                    margin: 4,
                    objectFit: 'cover',
                    width: 48,
                    height: 48,
                  }}
                  src={attachment.image?.url}
                />
              </ListItemAvatar>
              <ListItemText></ListItemText>
            </ListItem>
          </List>
        </Box>
      )}
    </Box>
  );
};

ChatMessageAdd.propTypes = {
  disabled: PropTypes.bool,
  onSend: PropTypes.func,
};

ChatMessageAdd.defaultProps = {
  disabled: false,
};

export default ChatMessageAdd;
