import React, { useState } from 'react';
import QueryString from 'query-string';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import AppPreview from '../app_preview/AppPreview';
import {
  Chip,
  Grid,
  Box,
  Button,
  Card,
  Container,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { Image as ImageIcon } from 'react-feather';
import Loading from '../../components/shared/Loading';
import Page from '../Page';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import ExerciseEditorDialog from './ExerciseEditorDialog';
import { useMutation, useQuery } from '@apollo/client';
import ServerError from '../error/ServerError';
import SearchBox from '../shared/SearchBox';
import { useSnackbar } from 'notistack';
import {
  CREATE_EXERCISE,
  DELETE_EXERCISE,
  GET_EXERCISES,
} from '../../graphql/exercise';
import config from '../../config';

const ExerciseList = () => {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;
  const [searchQuery, setSearchQuery] = useState(null);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [appPreviewRefresh, setAppPreviewRefresh] = useState(1);
  const [exerciseEdit, setExerciseEdit] = useState(null);
  const [openExerciseEdit, setOpenExerciseEdit] = useState(false);
  const [openExerciseCreator, setOpenExerciseCreator] = useState(false);
  const { data, loading, error, refetch } = useQuery(GET_EXERCISES, {
    variables: { input: { limit: 100, trainerId, searchTerm: searchQuery } },
  });
  const [deleteExercise] = useMutation(DELETE_EXERCISE, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (error) => {
      enqueueSnackbar(`Error deleting exercise: ${error.message}`, {
        variant: 'error',
      });
    },
  });
  const [createExercise] = useMutation(CREATE_EXERCISE, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (error) => {
      enqueueSnackbar(`Error creating exercise: ${error.message}`, {
        variant: 'error',
      });
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSearchSubmit = (event) => {
    const formData = new FormData(event.currentTarget);
    event.preventDefault();
    let searchQuery = formData.get('searchQuery');
    if (searchQuery) {
      setSearchQuery(searchQuery);
    }
  };

  // sort
  const exercisesSort = (exercises) => {
    if (orderBy === 'name') {
      if (order === 'asc') {
        return exercises.map((i) => i).sort((a, b) => a.title > b.title);
      } else if (order === 'desc') {
        return exercises.map((i) => i).sort((a, b) => a.title < b.title);
      }
    } else if (orderBy === 'created') {
      if (order === 'asc') {
        return exercises
          .map((i) => i)
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      } else if (order === 'desc') {
        return exercises
          .map((i) => i)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      }
    }
    return exercises;
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  // end sort

  // DELETE
  const handleDelete = (id) => {
    if (!window.confirm('Delete the item?')) {
      return;
    }
    if (!id) {
      console.error('handleClickDelete but id missing.');
      return;
    }
    setTimeout(() => {
      setAppPreviewRefresh(appPreviewRefresh + 1);
    }, 1000);
    enqueueSnackbar('Deleting...');
    deleteExercise({
      variables: {
        id,
      },
    });
  };
  // SAVE
  const handleSaved = (exercise) => {
    setExerciseEdit(null);
    setOpenExerciseEdit(false);
    setOpenExerciseCreator(false);
    // is new?
    if (data?.exercises?.findIndex((e) => e._id === exercise._id) === -1) {
      refetch();
      setAppPreviewRefresh(appPreviewRefresh + 1);
    }
  };

  //
  const handleNew = (e) => {
    e.preventDefault();
    setOpenExerciseCreator(true);
  };
  const handleEdit = (exercise) => {
    setExerciseEdit(exercise);
    setOpenExerciseEdit(true);
  };
  const handleExerciseCreateClose = () => {
    setOpenExerciseCreator(false);
  };
  const handleExerciseEditClose = () => {
    setOpenExerciseEdit(false);
  };
  const handleDuplicate = ({ exercise, index }) => {
    const input = _.cloneDeep(exercise);
    delete input._id;
    delete input.createdAt;
    input.title = `${input.title} COPY`;
    createExercise({
      variables: {
        input,
      },
    });
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!data.exercises) {
    return <ServerError message={'Could not load exercises!'} />;
  }

  return (
    <Page title={`Exercises : ${config.appDisplayName}`}>
      <ExerciseEditorDialog
        key={exerciseEdit?.id ?? 'id'}
        onSaved={handleSaved}
        open={openExerciseEdit}
        onClose={handleExerciseEditClose}
        exercise={exerciseEdit ?? {}}
      />

      <ExerciseEditorDialog
        key={'new'}
        onSaved={handleSaved}
        open={openExerciseCreator}
        onClose={handleExerciseCreateClose}
        exercise={{}}
      />

      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Exercises
        </Typography>

        <Box mb={3}>
          <Button
            component={Link}
            color="secondary"
            variant="contained"
            href="#"
            onClick={handleNew}
          >
            Create a new Exercise
          </Button>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <SearchBox onSubmit={handleSearchSubmit} />
            {searchQuery && (
              <Box mb={2}>
                <Typography variant="caption">
                  Results for {searchQuery}
                </Typography>
                <Button
                  onClick={() => {
                    setSearchQuery(null);
                  }}
                >
                  Clear Search
                </Button>
              </Box>
            )}

            <Box mb={2}>
              {[
                'full body',
                'abs',
                'legs',
                'arms',
                'shoulders',
                'back',
                'balance',
                'stretches',
              ].map((term) => {
                return (
                  <Chip
                    sx={{ mr: 1 }}
                    key={term}
                    size="small"
                    label={term}
                    onClick={() => {
                      setSearchQuery(term);
                    }}
                    clickable
                  />
                );
              })}
            </Box>

            <Card>
              <Box minWidth={700}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'name'}
                          direction={orderBy === 'name' ? order : 'asc'}
                          onClick={createSortHandler('name')}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Tags</TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === 'created'}
                          direction={orderBy === 'created' ? order : 'asc'}
                          onClick={createSortHandler('created')}
                        >
                          Created At
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {exercisesSort(data.exercises ?? []).map((w, i) => {
                      let mediaComponent;
                      if (w.images && w.images[0]) {
                        mediaComponent = (
                          <Box m={1}>
                            <img
                              src={w.images[0].url}
                              alt="exercise"
                              style={{ maxWidth: 48 }}
                            />
                          </Box>
                        );
                      } else {
                        mediaComponent = (
                          <Box m={1} bgcolor="background.dark">
                            <ImageIcon size={48} />
                          </Box>
                        );
                      }
                      const media = (
                        <Link
                          to={'#'}
                          onClick={() => handleEdit(w)}
                          component={RouterLink}
                        >
                          {mediaComponent}
                        </Link>
                      );

                      return (
                        <TableRow key={w._id} hover>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              {media}
                              <Link
                                to={'#'}
                                component={RouterLink}
                                color="inherit"
                                onClick={() => handleEdit(w)}
                                variant="h6"
                              >
                                {w.title}
                              </Link>
                            </Box>
                          </TableCell>
                          <TableCell>{w.desc}</TableCell>
                          <TableCell>{w.tags.join(', ')}</TableCell>
                          <TableCell>
                            {new Date(w.createdAt).toLocaleString()}
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => handleDelete(w._id)}
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </Button>

                            <br />
                            <Button
                              onClick={() =>
                                handleDuplicate({
                                  exercise: w,
                                  index: i,
                                })
                              }
                              startIcon={<CopyIcon />}
                            >
                              Duplicate
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppPreview
              key={appPreviewRefresh}
              trainerId={trainerId}
              screen="EXERCISES"
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ExerciseList;
