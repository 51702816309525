import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Page from '../Page';
import config from '../../config';

const ServerError = ({ message }) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Page title="Error">
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          color="textPrimary"
        >
          Error
        </Typography>
        <Typography
          align="center"
          variant="subtitle2"
          color="textSecondary"
          paragraph
        >
          {message || `Sorry, something went wrong.`}
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <Button
            color="secondary"
            component="a"
            href={`mailto:${config.supportContactAddress}`}
            variant="outlined"
          >
            Contact us at {config.supportContactAddress}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default ServerError;
