import React from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { Button, Typography, List } from '@mui/material';
import TrainingPlanWorkoutListItem from './TrainingPlanWorkoutListItem';

const dayToWeek = (day) => {
  return Math.floor(day / 7) + (dayOfWeek(day) < 7 ? 1 : 0);
};

const dayOfWeek = (day) => {
  return day % 7 === 0 ? 7 : day % 7;
};

export default function TrainingPlanScheduleDay({
  events,
  onOpenAddWorkout,
  onRemove,
  day,
}) {
  const eventLinks = (events ?? []).map((event) => (
    <div
      key={event._id}
      className="external-event"
      style={{
        backgroundColor: '#f4f6f8',
        cursor: 'auto',
      }}
    >
      <TrainingPlanWorkoutListItem
        key={event._id}
        workout={event}
        move={true}
        onRemove={onRemove}
      />
    </div>
  ));
  return (
    <div>
      <Typography style={{ padding: 4 }} variant="caption">
        Week {dayToWeek(day)} Day {dayOfWeek(day)}
      </Typography>
      <List dense>{eventLinks}</List>
      <Button
        size="small"
        variant="text"
        startIcon={<AddIcon fontSize="small" />}
        onClick={onOpenAddWorkout}
      >
        Add Workout
      </Button>
    </div>
  );
}
