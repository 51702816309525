// https://vercel.com/guides/getting-started-with-nextjs-typescript-stripe
import { loadStripe } from '@stripe/stripe-js';
import config from '../config';

const stripePublicKey = config.stripePublicKey;
if (!stripePublicKey) {
  throw new Error('No stripe key defined.');
}

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    try {
      stripePromise = loadStripe(stripePublicKey);
    } catch (err) {
      console.error(err);
    }
  }
  return stripePromise;
};

export default getStripe;
