import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Header = ({ className, workout, ...rest }) => {
  return (
    <div {...rest}>
      <Box mb={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/trainer/workouts"
            component={RouterLink}
          >
            Back to Workouts
          </Link>
        </Breadcrumbs>
      </Box>

      <Typography variant="h3" color="textPrimary" gutterBottom>
        {workout.title}
      </Typography>
      <Typography variant="caption">
        Last Saved: {moment(workout.updatedAt).format('LLL')}
      </Typography>
      <br />
      <Typography variant="caption">
        Workout Duration: {Math.floor(workout.durationTotal / 60)}m
      </Typography>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  workout: PropTypes.object,
};

export default Header;
