import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const truncate = (text, len = 10) => {
  if (!text) {
    return null;
  }

  if (text.length > len && text.length > 0) {
    return `${text.split(' ').slice(0, len).join(' ')} ...`;
  } else {
    return text;
  }
};
export default function TrainingPlanCollectionEventCard({ event, onRemove }) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia component="img" height="140" image={event.coverImageUrl} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {event.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {truncate(event.desc, 10) || 'No description.'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          component={RouterLink}
          to={`/trainer/workouts/${event.objectId}`}
          size="small"
        >
          Edit
        </Button>
        <Button onClick={() => onRemove(event)} size="small">
          Remove
        </Button>
      </CardActions>
    </Card>
  );
}
