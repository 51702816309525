import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import DotsHorizontalIcon from '@mui/icons-material/MoreVert';
import TrashIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../hooks/use-auth';

const ChatThreadToolbar = (props) => {
  const { participants, ...other } = props;
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const { user } = useAuth();

  // We hardcode the current user ID because the mocked that is not in sync with the auth provider.
  // When implementing this app with a real database, replace this ID with the ID from Auth Context.
  const otherParticipants = participants.filter(
    (participant) => participant.id !== '5e86809283e28b96d2d38537'
  );
  const displayNames = otherParticipants
    .reduce((names, participant) => [...names, participant.name], [])
    .join(', ');

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexShrink: 0,
        minHeight: 64,
        px: 2,
        py: 1,
      }}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          '& .MuiAvatar-root': otherParticipants.length > 1 && {
            height: 30,
            width: 30,
            '&:nth-of-type(2)': {
              mt: '10px',
            },
          },
        }}
      >
        <AvatarGroup max={2}>
          {otherParticipants.map((participant) => (
            <Avatar key={participant._id} src={participant.avatarUrl} />
          ))}
        </AvatarGroup>
        <Typography color="textPrimary" sx={{ ml: 2 }} variant="subtitle2">
          {displayNames}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }} />

      <Tooltip title="More options">
        <IconButton onClick={handleMenuOpen} ref={moreRef}>
          <DotsHorizontalIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={moreRef.current}
        keepMounted
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
      >
        <MenuItem>
          <ListItemIcon>
            <TrashIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete thread" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

ChatThreadToolbar.propTypes = {
  participants: PropTypes.array,
};

ChatThreadToolbar.defaultProps = {
  participants: [],
};

export default ChatThreadToolbar;
