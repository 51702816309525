import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  FormHelperText,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';

import Loading from '../../components/shared/Loading';
import ServerError from '../error/ServerError';
import TrainingPlanSelect from '../shared/TrainingPlanSelect';
import DialogTitle from '../shared/DialogTitle';
import {
  GET_TRAINING_PLAN_LIST,
  START_TRAINING_PLAN_SESSION,
} from '../../graphql/training_plan';

import { useSnackbar } from 'notistack';

const EnrollUserIntoTrainingPlanDialog = ({ user, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [trainingPlanId, setTrainingPlanId] = useState(null);
  const [trainingPlans, setTrainingPlans] = useState([]);

  const { loading, error, data } = useQuery(GET_TRAINING_PLAN_LIST, {
    variables: { trainerId: null },
  });

  useEffect(() => {
    const plans = data?.trainingPlanList?.trainingPlans;
    setTrainingPlans(plans || []);
  }, [data]);

  const [startTrainingPlanSession] = useMutation(START_TRAINING_PLAN_SESSION, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
      });
    },
    onCompleted: () => {
      alert('Added!');
      onClose();
      window.location.reload();
    },
  });
  const handleSubmit = async (event) => {
    await startTrainingPlanSession({
      variables: { trainingPlanId, userId: user._id },
    });
  };

  const handleSelectTrainingPlan = (trainingPlan) => {
    setTrainingPlanId(trainingPlan._id);
  };

  if (error) {
    return <ServerError message={error.message ?? 'Load failed!'} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle
        style={{ marginTop: 4 }}
        sx={{ width: '40ch' }}
        onClose={onClose}
      >
        Enroll User into Training Plan
      </DialogTitle>

      <DialogContent dividers>
        <Box mb={2}>
          <TrainingPlanSelect
            trainingPlans={trainingPlans}
            onSelect={handleSelectTrainingPlan}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleSubmit} color="primary">
          Enroll
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EnrollUserIntoTrainingPlanDialog;
