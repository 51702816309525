import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { useMutation, gql } from '@apollo/client';
import './stripe.css';
import {
  Link,
  TextField,
  Typography,
  Container,
  Box,
  Button,
} from '@mui/material';
import { Alert } from '@mui/material';

const PAY_TRAINER_SUBSCRIPTION_MUTATION = gql`
  mutation PayTrainerSubscription($input: PayTrainerSubscriptionInput!) {
    payTrainerSubscription(input: $input) {
      _id
      vendor
      status
    }
  }
`;

const CheckoutForm = ({ priceId, agreementUrl }) => {
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const [payTrainerSubscription, { data, error: mutationError }] = useMutation(
    PAY_TRAINER_SUBSCRIPTION_MUTATION
  );

  const createSubscription = async ({
    paymentMethodId,
    priceId,
    promoCode,
  }) => {
    setSubmitting(true);
    enqueueSnackbar('Paying...', { autoHideDuration: 2000 });
    try {
      let input = { paymentMethodId, priceId };
      if (promoCode) {
        input.promoCode = promoCode;
      }
      await payTrainerSubscription({
        variables: { input },
      });
    } catch (err) {
      console.error(err.message);
      // err will be shown with mutationError below
    }
    setSubmitting(false);

    // Some payment methods require a customer to be on session
    // to complete the payment process. Check the status of the
    // payment intent to handle these actions.
    //.then(handlePaymentThatRequiresCustomerAction)
    // If attaching this card to a Customer object succeeds,
    // but attempts to charge the customer fail, you
    // get a requires_payment_method error.
    //.then(handleRequiresPaymentMethod)
    // No more actions required. Provision your service for the user.
    // .then(onSubscriptionComplete)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      alert('Payment failed, please check your card.');
      return;
    }

    await createSubscription({
      paymentMethodId: paymentMethod.id,
      priceId,
      promoCode,
    });
  };

  if (data) {
    return (
      <>
        <Alert severity="info">Thanks for submitting the payment</Alert>
        <Container maxWidth="sm">
          <Box m={3}>
            <Button color="secondary" variant="contained" href="/trainer">
              Start building your App
            </Button>
          </Box>
        </Container>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      {mutationError && <Alert severity="error">{`${mutationError}`}</Alert>}

      <Typography component="p" variant="body2" color="textSecondary">
        You won’t be billed until the end of your trial in 30 days and you can
        cancel anytime before to avoid any charges.
      </Typography>

      <CardElement
        options={{
          style: {
            base: {
              fontSize: '18px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />

      <Box alignItems="center" display="flex" mt={2} mb={2}>
        <TextField
          name="promoCode"
          id="promoCode"
          label="Promo Code"
          variant="outlined"
          value={promoCode || ''}
          onChange={(e) => setPromoCode(e.target.value)}
          style={{ backgroundColor: '#fff' }}
        />
      </Box>

      <Box alignItems="center" display="flex" mt={2} mb={2}>
        <Typography component="p" variant="body2" color="textSecondary">
          By clicking below, you agree to our{' '}
          <Link
            color="secondary"
            href={agreementUrl}
            rel="noreferrer"
            target="_blank"
          >
            terms and agreement
          </Link>
          .
        </Typography>
      </Box>

      <Button
        style={{ width: 232 }}
        color="secondary"
        disabled={!stripe || submitting}
        size="large"
        type="submit"
        variant="contained"
      >
        Start your trial
      </Button>
    </form>
  );
};

export default CheckoutForm;
