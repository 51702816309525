import React, { useEffect, useState } from 'react';
import QueryString from 'query-string';
import { useQuery, useMutation } from '@apollo/client';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Grid,
  Link,
  Card,
  Container,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Button,
} from '@mui/material';
import AppPreview from '../app_preview/AppPreview';
import { Image as ImageIcon } from 'react-feather';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import Label from '../../components/shared/Label';
import Page from '../Page';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import SearchBox from '../shared/SearchBox';

import {
  CREATE_MEAL,
  DELETE_MEAL,
  DUPLICATE_MEAL,
  GET_MEALS,
} from '../../graphql/nutrition';
import config from '../../config';

const LIMIT = 100;
export default function MealList(props) {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;
  const [searchQuery, setSearchQuery] = useState(null);

  const history = useHistory();
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_MEALS, {
    variables: { input: { trainerId, searchTerm: searchQuery, limit: LIMIT } },
  });
  const [createMeal] = useMutation(CREATE_MEAL);
  const [duplicateMeal] = useMutation(DUPLICATE_MEAL);
  const [deleteMeal] = useMutation(DELETE_MEAL);

  const handleSearchSubmit = (event) => {
    const formData = new FormData(event.currentTarget);
    event.preventDefault();
    let searchQuery = formData.get('searchQuery');
    if (searchQuery) {
      setSearchQuery(searchQuery);
    }
  };

  const handleLoadMore = async (event) => {
    event.preventDefault();
    const meals = data.meals;
    const lastId = meals[meals.length - 1]._id;
    const input = {
      trainerId,
      searchTerm: searchQuery,
      limit: LIMIT,
      lastId,
    };

    await fetchMore({
      variables: { input },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          meals: [...previousResult.meals, ...fetchMoreResult.meals],
        };
      },
    });
  };

  useEffect(() => {
    // When coming back to this page do refetch()
    refetch();
  }, [refetch, history.location]);

  if (error) return <ServerError message="Failed to load" />;
  if (loading || !data?.meals) return <Loading />;

  return (
    <Page title={`Meals : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Meals
        </Typography>
        <Typography component="p" variant="body1" color="textPrimary">
          You can create meals including the ingredients and its recipe. After
          you created a few meals, you can group them into a meal plan for your
          clients.
        </Typography>

        <Box mt={3}>
          {/* add item*/}
          <Box mb={3}>
            <Button
              component={RouterLink}
              color="secondary"
              variant="contained"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                createMeal({
                  variables: { input: { title: 'Untitled' } },
                }).then((r) => {
                  if (r.data?.createMeal?._id) {
                    history.push(`/trainer/meals/${r.data.createMeal._id}`);
                  }
                });
              }}
            >
              Create a new Meal
            </Button>
          </Box>
          {/* list */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <SearchBox onSubmit={handleSearchSubmit} />
              {searchQuery && (
                <Box mb={2}>
                  <Typography variant="caption">
                    Results for {searchQuery}
                  </Typography>
                  <Button
                    onClick={() => {
                      setSearchQuery(null);
                    }}
                  >
                    Clear Search
                  </Button>
                </Box>
              )}
              <Card>
                <Box minWidth={700}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.meals.map((w, i) => {
                        const url = `/trainer/meals/${w._id}`;
                        let status = <Label color="warning">Draft</Label>;
                        if (w.status === 'FREE') {
                          status = <Label color="secondary">Free</Label>;
                        } else if (w.status === 'PREMIUM') {
                          status = <Label color="primary">Premium</Label>;
                        }

                        let mediaComponent;
                        if (w.coverImageUrl) {
                          mediaComponent = (
                            <img
                              alt="Meal"
                              src={w.coverImageUrl}
                              style={{ width: 48, height: 48 }}
                            />
                          );
                        } else {
                          mediaComponent = <ImageIcon size={48} />;
                        }
                        const media = (
                          <Link to={url} component={RouterLink}>
                            {mediaComponent}
                          </Link>
                        );

                        return (
                          <TableRow key={w._id} hover>
                            <TableCell>
                              <Box display="flex" alignItems="center">
                                <Box m={1} bgcolor="background.dark">
                                  {media}
                                </Box>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={url}
                                  variant="h6"
                                >
                                  {w.title}
                                </Link>
                              </Box>
                            </TableCell>
                            <TableCell>{status}</TableCell>
                            <TableCell>
                              <Button
                                onClick={() => {
                                  if (w.completed > 0) {
                                    window.alert(
                                      "You can't delete meals that have been done by a subscriber."
                                    );
                                    return;
                                  }
                                  if (!window.confirm('Delete the item?')) {
                                    return;
                                  }
                                  deleteMeal({ variables: { id: w._id } })
                                    .then((r) => refetch())
                                    .catch((e) => {
                                      window.alert(e.toString());
                                    });
                                }}
                                startIcon={<DeleteIcon />}
                              >
                                Delete
                              </Button>
                              <br />
                              <Button
                                onClick={() => {
                                  duplicateMeal({
                                    variables: { id: w._id },
                                  })
                                    .then((r) => {
                                      if (r.data?.duplicateMeal?._id) {
                                        history.push(
                                          `/trainer/meals/${r.data.duplicateMeal._id}`
                                        );
                                      }
                                    })
                                    .catch((e) => {
                                      window.alert(e.toString());
                                    });
                                }}
                                startIcon={<CopyIcon />}
                              >
                                Duplicate
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
                {data.meals.length > 1 && data.meals.length >= LIMIT && (
                  <Box m={3}>
                    <Button variant="outlined" onClick={handleLoadMore}>
                      LOAD MORE
                    </Button>
                  </Box>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppPreview trainerId={trainerId} screen="NUTRITION" />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
