import React, { useState, useEffect } from 'react';
import QueryString from 'query-string';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Chip,
  Grid,
  Link,
  Card,
  Container,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Button,
} from '@mui/material';
import { Image as ImageIcon } from 'react-feather';
import AppPreview from '../app_preview/AppPreview';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import Label from '../../components/shared/Label';
import SearchBox from '../shared/SearchBox';
import Page from '../Page';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import config from '../../config';

const MY_CREATED_WORKOUTS = gql`
  query MyCreatedWorkouts($input: MyCreatedWorkoutsQueryInput) {
    myCreatedWorkouts(input: $input) {
      _id
      title
      desc
      status
      coverImageUrl
      createdAt
      trainingPlans {
        _id
        title
      }
    }
  }
`;

const CREATE_WORKOUT = gql`
  mutation CreateWorkout($input: WorkoutInput!) {
    createWorkout(input: $input) {
      _id
    }
  }
`;

const DUPLICATE_WORKOUT = gql`
  mutation DuplicateWorkout($id: ID!) {
    duplicateWorkout(id: $id) {
      _id
    }
  }
`;

const DELETE_WORKOUT = gql`
  mutation DeleteWorkout($id: ID!) {
    deleteWorkout(id: $id) {
      message
    }
  }
`;

const LIMIT = 50;
export default function WorkoutList(props) {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;
  const [searchQuery, setSearchQuery] = useState(null);
  const history = useHistory();
  const { loading, error, data, refetch, fetchMore } = useQuery(
    MY_CREATED_WORKOUTS,
    {
      variables: { input: { trainerId, query: searchQuery, limit: LIMIT } },
    }
  );
  const [createWorkout] = useMutation(CREATE_WORKOUT);
  const [duplicateWorkout] = useMutation(DUPLICATE_WORKOUT);
  const [deleteWorkout] = useMutation(DELETE_WORKOUT);

  const handleSearchSubmit = (event) => {
    const formData = new FormData(event.currentTarget);
    event.preventDefault();
    let searchQuery = formData.get('searchQuery');
    if (searchQuery) {
      setSearchQuery(searchQuery);
    }
  };
  const handleLoadMore = async (event) => {
    event.preventDefault();
    const workouts = data.myCreatedWorkouts;
    const lastId = workouts[workouts.length - 1]._id;
    const input = {
      trainerId,
      query: searchQuery,
      limit: LIMIT,
      lastId,
    };

    await fetchMore({
      variables: { input },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          myCreatedWorkouts: [
            ...previousResult.myCreatedWorkouts,
            ...fetchMoreResult.myCreatedWorkouts,
          ],
        };
      },
    });
  };

  useEffect(() => {
    // When coming back to this page do refetch()
    refetch();
  }, [refetch, history.location]);

  if (error) return <ServerError message="Failed to load" />;
  if (loading || !data?.myCreatedWorkouts) return <Loading />;

  return (
    <Page title={`Workouts : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Workouts
        </Typography>
        {/* add item*/}
        <Box mb={3}>
          <Button
            component={RouterLink}
            color="secondary"
            variant="contained"
            to="#"
            onClick={(e) => {
              e.preventDefault();
              createWorkout({
                variables: { input: { title: 'Untitled' } },
              }).then((r) => {
                if (r.data?.createWorkout?._id) {
                  history.push(`/trainer/workouts/${r.data.createWorkout._id}`);
                }
              });
            }}
          >
            Create a new Workout
          </Button>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            {/* list */}
            <SearchBox onSubmit={handleSearchSubmit} />
            {searchQuery && (
              <Box mb={2}>
                <Typography variant="caption">
                  Results for {searchQuery}
                </Typography>
                <Button
                  onClick={() => {
                    setSearchQuery(null);
                  }}
                >
                  Clear Search
                </Button>
              </Box>
            )}

            <Box mb={2}>
              {[
                'full body',
                'abs',
                'legs',
                'arms',
                'shoulders',
                'back',
                'balance',
                'stretches',
              ].map((term) => {
                return (
                  <Chip
                    sx={{ mr: 1 }}
                    key={term}
                    size="small"
                    label={term}
                    onClick={() => {
                      setSearchQuery(term);
                    }}
                    clickable
                  />
                );
              })}
            </Box>

            <Card>
              <Box minWidth={700}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.myCreatedWorkouts.map((w, i) => {
                      const url = `/trainer/workouts/${w._id}`;
                      let status = <Label color="warning">Draft</Label>;
                      if (w.status === 'FREE') {
                        status = <Label color="secondary">Free</Label>;
                      } else if (w.status === 'PREMIUM') {
                        status = <Label color="primary">Premium</Label>;
                      }

                      let mediaComponent;
                      if (w.coverImageUrl) {
                        mediaComponent = (
                          <img
                            alt="Workout"
                            src={w.coverImageUrl}
                            style={{ width: 48, height: 48 }}
                          />
                        );
                      } else {
                        mediaComponent = <ImageIcon size={48} />;
                      }
                      const media = (
                        <Link to={url} component={RouterLink}>
                          {mediaComponent}
                        </Link>
                      );

                      return (
                        <TableRow key={w._id} hover>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Box m={1} bgcolor="background.dark">
                                {media}
                              </Box>
                              <Link
                                color="inherit"
                                component={RouterLink}
                                to={url}
                                variant="h6"
                              >
                                {w.title}
                              </Link>
                              <Box m={1}>
                                {w.trainingPlans.map((t) => (
                                  <Link
                                    key={t._id}
                                    href={`/trainer/training_plans/${t._id}`}
                                    style={{ marginRight: 2 }}
                                  >
                                    <Chip
                                      size="small"
                                      sx={{ mr: 1 }}
                                      label={t.title}
                                    />
                                  </Link>
                                ))}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>{status}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => {
                                if (w.completed > 0) {
                                  window.alert(
                                    "You can't delete workouts that have been done by a subscriber."
                                  );
                                  return;
                                }
                                if (!window.confirm('Delete the item?')) {
                                  return;
                                }
                                deleteWorkout({ variables: { id: w._id } })
                                  .then((r) => refetch())
                                  .catch((e) => {
                                    window.alert(e.toString());
                                  });
                              }}
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </Button>
                            <br />
                            <Button
                              onClick={() => {
                                duplicateWorkout({
                                  variables: { id: w._id },
                                })
                                  .then((r) => {
                                    if (r.data?.duplicateWorkout?._id) {
                                      history.push(
                                        `/trainer/workouts/${r.data.duplicateWorkout._id}`
                                      );
                                    }
                                  })
                                  .catch((e) => {
                                    window.alert(e.toString());
                                  });
                              }}
                              startIcon={<CopyIcon />}
                            >
                              Duplicate
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
              {data.myCreatedWorkouts.length > 1 &&
                data.myCreatedWorkouts.length >= LIMIT && (
                  <Box m={3}>
                    <Button variant="outlined" onClick={handleLoadMore}>
                      LOAD MORE
                    </Button>
                  </Box>
                )}
            </Card>

            {/* more TODO */}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppPreview trainerId={trainerId} screen="WORKOUTS" />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
