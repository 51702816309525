import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/client';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CurrencyTextField from '../shared/CurrencyTextField';
import displayHelper from '../../utils/displayHelper';

import {
  Link,
  Chip,
  ListItemButton,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Alert,
  Typography,
  Divider,
  Tabs,
  Tab,
  Card,
  CardContent,
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  Box,
  Button,
  TextField,
  Container,
} from '@mui/material';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Page from '../Page';
import Header from './Header';
import TrainingPlanSelect from '../shared/TrainingPlanSelect';
import Loading from '../../components/shared/Loading';
import DirectUpload from '../../components/shared/DirectUpload';
import {
  GET_PRODUCT_WITH_TRAINING_PLANS,
  UPDATE_PRODUCT,
} from '../../graphql/product';

import ServerError from '../error/ServerError';
import ProductPreview from './ProductPreview';
import config from '../../config';

const tabs = [
  { value: 'details', label: 'Details' },
  { value: 'preview', label: 'Preview Purchase Page' },
];

const ProductEditor = ({ match }) => {
  const params = useParams();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('details');
  const [product, setProduct] = useState(null);
  const [priceAmount, setPriceAmount] = useState(10);
  const [currency, setCurrency] = useState('usd');
  const [trainingPlans, setTrainingPlans] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const editorRef = useRef();
  const id = match ? match.params.id : null;
  const { error, loading, data } = useQuery(GET_PRODUCT_WITH_TRAINING_PLANS, {
    variables: { id },
  });

  useEffect(() => {
    if (params.tab) {
      setCurrentTab(params.tab);
    }
  }, [params]);

  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });

  useEffect(() => {
    setProduct(data?.product);
    const plans = data?.trainingPlanList?.trainingPlans;
    setTrainingPlans(plans || []);
  }, [data]);

  const handleSave = () => {
    enqueueSnackbar('Saving...', { autoHideDuration: 2000 });

    updateProduct({
      variables: {
        id: product._id,
        input: {
          title: product.title,
          desc: product.desc,
          tags: product.tags,
          status: product.status,
          coverImages: product.coverImages || [],
          files: product.files,
          trainingPlanIds: product.trainingPlanIds,
          pricingPlans: product.pricingPlans,
        },
      },
      refetchQueries: [
        {
          query: GET_PRODUCT_WITH_TRAINING_PLANS,
          variables: { id: product._id },
        },
      ],
    });
  };

  const handleEditorChange = (value) => {
    setProduct({ ...product, desc: value });
  };

  const handleChange = (event) => {
    if (event.target.name === 'priceAmount') {
      setPriceAmount(event.target.value);
      return;
    }
    if (event.target.name === 'currency') {
      setCurrency(event.target.value);
      return;
    }
    setProduct({ ...product, [event.target.name]: event.target.value });
  };

  const handleAddTrainingPlan = (trainingPlan) => {
    if (!trainingPlan) {
      return;
    }

    let trainingPlanIds = product.trainingPlanIds || [];
    trainingPlanIds = [...trainingPlanIds, trainingPlan._id];

    const set = new Set(trainingPlanIds);
    trainingPlanIds = Array.from(set);

    setProduct({ ...product, trainingPlanIds });
  };

  const handleRemoveTrainingPlan = (trainingPlanId) => {
    if (!trainingPlanId) {
      return;
    }
    let trainingPlanIds = (product.trainingPlanIds || []).filter(
      (id) => id !== trainingPlanId
    );
    setProduct({ ...product, trainingPlanIds });
  };

  const handleAddPricingPlan = (pricingPlan) => {
    if (!pricingPlan) {
      return;
    }

    let pricingPlans = product.pricingPlans || [];
    pricingPlans = [
      ...pricingPlans,
      { amount: parseFloat(priceAmount || 0), currency: currency },
    ];

    setProduct({ ...product, pricingPlans });
  };

  const handleRemovePricePlan = (stripePriceId) => {
    if (!stripePriceId) {
      return;
    }

    let pricingPlans = (product.pricingPlans || []).filter((plan) => {
      if (plan.stripePriceId == undefined && stripePriceId === 'new') {
        return false;
      }

      if (plan.stripePriceId !== stripePriceId) {
        return true;
      }

      return false;
    });
    setProduct({ ...product, pricingPlans });
  };

  const handleUploadError = () => {
    enqueueSnackbar(`Media upload failed`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };
  const handleUploadSuccess = (uploadedResponse) => {
    let newProduct = { ...product, coverImages: [uploadedResponse.image] };
    if (uploadedResponse.videos) {
      newProduct.videos = uploadedResponse.videos;
    }

    setProduct(newProduct);
  };
  const handleUploadRemove = () => {
    setProduct({ ...product, coverImages: [], videos: null });
  };
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    history.push(`/trainer/products/${product._id}/${value}`);
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading || !product) {
    return <Loading />;
  }

  return (
    <Page title={`Product Editor : ${config.appDisplayName}`}>
      <Container maxWidth={false}>
        <Box mb={3}>
          <Header product={product} />
          <Box mt={3}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <Divider />
          </Box>
        </Box>

        {currentTab === 'details' && (
          <Card id="main">
            <CardContent>
              <Box mb={3}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  onChange={handleChange}
                  value={product.title}
                  variant="outlined"
                />
              </Box>
              <Box mb={3}>
                <Typography variant="h5" gutterBottom>
                  Cover Image
                </Typography>
                <DirectUpload
                  fileType="image"
                  images={product.coverImages || []}
                  onError={handleUploadError}
                  onSuccess={handleUploadSuccess}
                  onRemove={handleUploadRemove}
                  streaming={true}
                  maxFiles={1}
                />{' '}
                <FormHelperText>A cover image for the product.</FormHelperText>
              </Box>

              <Box style={{ height: 300 }} mb={3}>
                <Typography variant="h5" gutterBottom>
                  Description
                </Typography>

                <ReactQuill
                  style={{ height: 220, minHeight: 80 }}
                  ref={editorRef}
                  theme="snow"
                  value={product.desc}
                  bounds="#main"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' },
                      ],
                      ['link', 'image', 'video'],
                      ['clean'],
                    ],
                  }}
                  onChange={handleEditorChange}
                />
              </Box>

              <Box mb={3}>
                <Typography variant="h5">Included Training Plans</Typography>

                {(product.trainingPlanIds || []).length === 0 ? (
                  <Alert sx={{ width: 300, mb: 2 }} severity="warning">
                    No training plans selected. Add at least one below.
                  </Alert>
                ) : (
                  <></>
                )}

                <List sx={{ maxWidth: 500 }}>
                  {(product.trainingPlanIds || []).map((trainingPlanId) => {
                    const trainingPlan = trainingPlans.find(
                      (plan) => plan._id === trainingPlanId
                    );
                    if (!trainingPlan) {
                      return <ListItem key={trainingPlanId}></ListItem>;
                    }

                    return (
                      <ListItem
                        key={trainingPlanId}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              handleRemoveTrainingPlan(trainingPlanId)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <img
                              style={{ marginRight: 4 }}
                              width="48"
                              height="48"
                              src={trainingPlan.coverImageUrl}
                            />
                          </ListItemIcon>
                          <ListItemText primary={trainingPlan.title} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>

                <TrainingPlanSelect
                  trainingPlans={trainingPlans}
                  onSelect={handleAddTrainingPlan}
                />
                <FormHelperText>
                  You can only include published training plans.
                </FormHelperText>
              </Box>

              <Box mb={3}>
                <Typography variant="h5" gutterBottom>
                  Pricing
                </Typography>

                {(product.pricingPlans || []).length === 0 ? (
                  <Alert sx={{ width: 300, mb: 2 }} severity="warning">
                    Missing a pricing plan. Add one below.
                  </Alert>
                ) : (
                  <></>
                )}

                <List sx={{ maxWidth: 500 }}>
                  {(product.pricingPlans || []).map((pricePlan) => {
                    const label = pricePlan.stripePriceId ? (
                      <Link
                        target="_blank"
                        href={`https://dashboard.stripe.com/prices/${pricePlan.stripePriceId}`}
                      >
                        <Chip size="small" label={pricePlan.stripePriceId} />
                      </Link>
                    ) : (
                      <Chip size="small" label="new" />
                    );

                    return (
                      <ListItem
                        key={pricePlan.stripePriceId || 'new'}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              handleRemovePricePlan(
                                pricePlan.stripePriceId || 'new'
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemButton>
                          <ListItemText
                            primary={`${
                              displayHelper.currencySymbolMap[
                                pricePlan.currency
                              ]
                            } ${pricePlan.amount}`}
                            secondary={'one time purchase'}
                          />
                          {label}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>

                <Box sx={{ flexDirection: 'row' }} mb={3}>
                  <Select
                    sx={{ mr: 2 }}
                    variant="outlined"
                    native
                    name="currency"
                    onChange={handleChange}
                    value={currency ?? 'usd'}
                  >
                    <option value="">-</option>
                    <option value="usd">USD - US Dollar</option>
                    <option value="gbp">GBP - British Pound</option>
                    <option value="eur">EUR - Euro</option>
                  </Select>
                  <CurrencyTextField
                    currencySymbol={
                      displayHelper.currencySymbolMap[currency] || '$'
                    }
                    label="One Time Cost"
                    name="priceAmount"
                    onChange={handleChange}
                    value={priceAmount || ''}
                    decimalCharacter={displayHelper.decimalCharacter}
                    digitGroupSeparator={displayHelper.digitGroupSeparator}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                  <Button
                    sx={{ ml: 2 }}
                    onClick={handleAddPricingPlan}
                    variant="outlined"
                  >
                    Add
                  </Button>
                </Box>
              </Box>

              <Box mb={3}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="status">Status</InputLabel>
                  <Select
                    native
                    value={product.status}
                    onChange={handleChange}
                    label="Status"
                    inputProps={{
                      name: 'status',
                      id: 'status',
                    }}
                  >
                    <option value="DRAFT">Draft</option>
                    <option value="PUBLISHED">Published</option>
                  </Select>
                  <FormHelperText>
                    Drafts are seen only by you while you create the product.{' '}
                    <br /> Published products are available to sell.
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box mt={3}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}

        {currentTab === 'preview' && <ProductPreview product={product} />}
      </Container>
    </Page>
  );
};

export default ProductEditor;
