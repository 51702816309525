import moment from 'moment';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@mui/material';
import UrlHelper from '../../utils/urlHelper';
import DirectUpload from '../../components/shared/DirectUpload';

import {
  Divider,
  Tabs,
  Tab,
  InputLabel,
  FormControl,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  FormHelperText,
  TextField,
  Select,
} from '@mui/material';
import LabelledOutline from '../../components/shared/LabelledOutline';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import DialogTitle from '../shared/DialogTitle';
import ChipInput from '../../components/shared/ChipInput';
import { apiUrl } from '../../config';
import { useAuth } from '../../hooks/use-auth';
import { useMutation } from '@apollo/client';
import { CREATE_EXERCISE, UPDATE_EXERCISE } from '../../graphql/exercise';
import { useSnackbar } from 'notistack';

const tabs = [
  { value: 'playback', label: 'Playback Media' },
  { value: 'howto', label: 'How To Video' },
];

const exerciseTitleSearch = async (q, authToken) => {
  if (!q) {
    return [];
  }

  try {
    const res = await fetch(
      `${apiUrl}/v1/exercises/title_suggestions?q=${encodeURI(q) ?? ''}`,
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
    const parsed = await res.json();
    if (res.status !== 200) {
      return [];
    }
    return parsed.titleSuggestions;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const ExerciseEditorDialog = ({
  onSaved,
  open = false,
  onClose,
  exercise = {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [authToken, setAuthToken] = useState('');
  const auth = useAuth();
  useEffect(() => {
    auth.user.getIdToken().then((authToken) => {
      setAuthToken(authToken);
    });
  }, [auth]);
  const [exerciseChanges, setExerciseChanges] = useState(exercise);
  const [suggestions, setSuggestions] = useState([]);
  const [createExercise] = useMutation(CREATE_EXERCISE, {
    onCompleted: (data) => {
      if (onSaved) {
        onSaved(data.createExercise);
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Error creating exercise: ${error.message}`, {
        variant: 'error',
      });
    },
  });
  const [updateExercise] = useMutation(UPDATE_EXERCISE, {
    onCompleted: (data) => {
      if (onSaved) {
        onSaved(data.updateExercise);
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Error updating exercise: ${error.message}`, {
        variant: 'error',
      });
    },
  });

  const [currentTab, setCurrentTab] = useState('playback');
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    setExerciseChanges(exercise);
  }, [exercise]);

  useEffect(() => {
    let active = true;
    exerciseTitleSearch(exerciseChanges.title, authToken).then((s) => {
      setSuggestions(s ?? []);
    });
    return () => {
      active = false;
    };
  }, [authToken, exerciseChanges.title]);

  const handleUploadError = () => {
    console.error('ExerciseEditor#error');
  };

  const handleUploadSuccess = (uploadedRes) => {
    const changes = _.cloneDeep(exerciseChanges);
    if (uploadedRes.videos) {
      changes.videos = uploadedRes.videos;
      changes.images = [uploadedRes.image];
    } else if (uploadedRes.image) {
      changes.images = [uploadedRes.image];
    }
    setExerciseChanges(changes);
  };

  const handleUploadRemove = (file) => {
    const changes = _.cloneDeep(exerciseChanges);
    changes.images = [];
    changes.videos = [];
    setExerciseChanges(changes);
  };

  const handleHowtoUploadError = () => {
    console.error('ExerciseEditor#error');
  };

  const handleHowtoUploadSuccess = (uploadedRes) => {
    const changes = _.cloneDeep(exerciseChanges);
    console.log(
      `handleHowtoUploadSuccess uploadedRes.videos=${JSON.stringify(
        uploadedRes.videos
      )} uploadedRes.videos=${JSON.stringify(uploadedRes.image)}`
    );
    if (uploadedRes.videos) {
      console.log(`handleHowtoUploadSuccess ${JSON.stringify(changes)}`);
      changes.howtoVideos = uploadedRes.videos;
      changes.howtoImages = [uploadedRes.image];
    }
    setExerciseChanges(changes);
  };

  const handleHowtoUploadRemove = (file) => {
    const changes = _.cloneDeep(exerciseChanges);
    changes.howtoVideos = [];
    changes.howtoImages = [];
    setExerciseChanges(changes);
  };

  const handleEdit = (e) => {
    const changes = _.cloneDeep(exerciseChanges);
    changes[e.target.name] = e.target.value;

    if (e.target.name === 'youtubeVideoUrl') {
      const thumbnail = UrlHelper.extractYoutubeThumbUrl(e.target.value);
      if (thumbnail) {
        if (!changes.images || changes.images.length === 0) {
          changes.images = [{ url: thumbnail, w: 480, h: 360 }];
        }
        if (!changes.howtoImages || changes.howtoImages.length === 0) {
          changes.howtoImages = [{ url: thumbnail, w: 480, h: 360 }];
        }
      }
    }

    setExerciseChanges(changes);
  };
  const handleEditInt = (e) => {
    const changes = _.cloneDeep(exerciseChanges);
    changes[e.target.name] = parseInt(e.target.value);
    setExerciseChanges(changes);
  };

  const handleAddTag = (tag) => {
    const changes = _.cloneDeep(exerciseChanges);
    changes.tags = changes.tags ?? [];
    changes.tags.push(tag);
    setExerciseChanges(changes);
  };

  const handleDeleteTag = (chip, index) => {
    const changes = _.cloneDeep(exerciseChanges);
    changes.tags = changes.tags ?? [];
    changes.tags = changes.tags.filter((item) => item !== chip);
    setExerciseChanges(changes);
  };

  const handleSave = (e) => {
    e.preventDefault();
    enqueueSnackbar('Saving...');
    const input = {
      title: exerciseChanges.title,
      desc: exerciseChanges.desc,
      equipment: exerciseChanges.equipment,
      images: exerciseChanges.images,
      videos: exerciseChanges.videos,
      howtoVideos: exerciseChanges.howtoVideos,
      howtoImages: exerciseChanges.howtoImages,
      youtubeVideoUrl: exerciseChanges.youtubeVideoUrl,
      tags: exerciseChanges.tags,
    };
    if (exerciseChanges._id) {
      // update
      updateExercise({
        variables: {
          id: exerciseChanges._id,
          input,
        },
      });
    } else {
      // new
      createExercise({
        variables: {
          input,
        },
      });
    }
  };

  let lastSavedAlert = null;
  if (exercise && exercise._id) {
    const lastSaved = moment(exercise.updatedAt).format('LLL');
    lastSavedAlert = (
      <Typography variant="caption">Last Saved: {lastSaved}</Typography>
    );
  }

  return (
    <Dialog onClose={onClose} open={!!open}>
      <form onSubmit={handleSave}>
        <DialogTitle onClose={onClose}>
          {exercise._id ? 'Update Exercise' : 'Create an Exercise'}
        </DialogTitle>

        <DialogContent dividers>
          <Box mb={2}>
            <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">
              <Autocomplete
                fullWidth
                options={
                  exerciseChanges.title
                    ? [exerciseChanges.title, ...suggestions]
                    : suggestions
                }
                onChange={(e, value) => {
                  setExerciseChanges({ ...exerciseChanges, title: value });
                }}
                noOptionsText={''}
                inputValue={exerciseChanges.title ?? ''}
                renderInput={(params) => (
                  <TextField
                    required
                    variant="outlined"
                    label="Title"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      setExerciseChanges({
                        ...exerciseChanges,
                        title: e.target.value,
                      });
                    }}
                    {...params}
                  />
                )}
              />
            </div>
          </Box>
          <Box mb={2}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="equipment">Equipment</InputLabel>
              <Select
                native
                label="Equipment"
                onChange={handleEditInt}
                value={exerciseChanges.equipment}
                inputProps={{
                  name: 'equipment',
                  id: 'equipment',
                }}
              >
                <option value="0">No Equipment (only body weight)</option>
                <option value="1">
                  Home Gym (dumbbells, kettlebell, bench, and objects every home
                  has)
                </option>
                <option value="2">
                  Full Gym (machines and large equipment)
                </option>
              </Select>
            </FormControl>
          </Box>

          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>

          {currentTab === 'playback' && (
            <Box m={2}>
              <DirectUpload
                truncateVideo={true}
                removeAudio={true}
                streaming={false}
                images={exerciseChanges.images}
                onError={handleUploadError}
                onSuccess={handleUploadSuccess}
                onRemove={handleUploadRemove}
              />
              <FormHelperText>
                This can be an image (jpeg, png), animated GIF, or a short video
                (10 seconds or less). This is used during the playback of the
                workout. This is also used for the exercise thumbnail.
              </FormHelperText>
            </Box>
          )}

          {currentTab === 'howto' && (
            <Box m={2}>
              <TextField
                fullWidth
                label="Youtube Video Url"
                name="youtubeVideoUrl"
                onChange={handleEdit}
                value={exerciseChanges.youtubeVideoUrl}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <FormHelperText>
                If the exercise requires more detailed instructions, you can
                either include a YouTube video link or upload a video here.
                Users can pause the workout to watch these instructional videos.
              </FormHelperText>

              <LabelledOutline id="upload" label="Or upload video here">
                <DirectUpload
                  streaming={true}
                  truncateVideo={false}
                  removeAudio={false}
                  fileType={'videos'}
                  images={exerciseChanges.howtoImages}
                  onError={handleHowtoUploadError}
                  onSuccess={handleHowtoUploadSuccess}
                  onRemove={handleHowtoUploadRemove}
                />
              </LabelledOutline>
              <FormHelperText>
                <strong>NOTE:</strong> You will also need to upload an image or
                video to the "Playback Media" tab to get a thumbnail.{' '}
              </FormHelperText>
            </Box>
          )}

          <Divider />

          <Box mb={2}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label="Description"
              fullWidth
              variant="outlined"
              name="desc"
              multiline={true}
              rows={4}
              value={exerciseChanges.desc || ''}
              onChange={handleEdit}
            />
            <FormHelperText>
              This is an optional field to describe the exercise in detail.
              Include any important tips here.
            </FormHelperText>
          </Box>

          <Box mb={2}>
            <ChipInput
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="Tags"
              value={exerciseChanges.tags ?? []}
              onAdd={handleAddTag}
              onDelete={handleDeleteTag}
              variant="outlined"
            />
            <FormHelperText>
              Use helpful tags like "cardio" or "abs"
            </FormHelperText>
          </Box>
        </DialogContent>

        <DialogActions>
          {lastSavedAlert}

          <Button autoFocus onClick={onClose} color="primary">
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: '4px' }}
            startIcon={<SaveIcon />}
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ExerciseEditorDialog;
