import React, { useState, useEffect } from 'react';
import QueryString from 'query-string';
import { defaultVideoThumbnail } from '../shared/UploadStyles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import _ from 'lodash';

import { useSnackbar } from 'notistack';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  Tabs,
  Tab,
  IconButton,
  Divider,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { mongoObjectId } from '../../utils/mongoObjectId';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import LabelledOutline from '../../components/shared/LabelledOutline';
import Loading from '../../components/shared/Loading';
import FormField from './LandingPageFormField';
import { useMutation, useQuery } from '@apollo/client';
import { GET_WEB_PAGE, UPDATE_WEB_PAGE } from '../../graphql/web_page';
import ServerError from '../error/ServerError';
import WebPreviewIframe from '../../components/app_preview/WebPreviewIframe';
import TestimonialForm from './TestimonialForm';
import MediaUpload from '../../components/shared/DirectUpload';
import config from '../../config';

const tabs = [
  { value: 'preview', label: 'Preview' },
  { value: 'edit', label: 'Edit' },
];

const siteFields = [];

const heroFields = [
  {
    name: 'heroSection.imageUrl',
    label: 'Main Background Image',
    help: 'The background image when the page loads.',
    type: 'upload',
  },
  {
    name: 'heroSection.title',
    label: 'Title',
    help: 'Max 25 characters. This title will be the large text on the web page.',
    type: 'text',
  },
  {
    name: 'heroSection.subtitle',
    label: 'Subtitle',
    help: 'This will be below your headline',
    type: 'textarea',
  },
  {
    name: 'heroSection.ctaButtonText',
    label: 'Button Text',
    help: 'The button for customers to go to checkout page',
    type: 'text',
  },
];
const aboutFields = [
  {
    name: 'aboutSection.imageUrl',
    label: 'Portrait Photo',
    help: 'A large portrait photo of yourself',
    type: 'upload',
  },

  {
    name: 'aboutSection.content',
    label: 'Content',
    help: 'Talk about your training history and qualifications.',
    type: 'textarea',
  },
];

const appDownloadFields = [
  {
    name: 'appDownloadSection.title',
    label: 'Title',
    help: 'Max 25 characters. This title will be the large text on the web page.',
    type: 'text',
  },
  {
    name: 'appDownloadSection.subtitle',
    label: 'Subtitle',
    help: 'This will be below your headline',
    type: 'textarea',
  },
  {
    name: 'appDownloadSection.ctaButtonText',
    label: 'Button Text',
    help: 'The button for customers to go to checkout page',
    type: 'text',
  },
  {
    name: 'appDownloadSection.imageUrl',
    label: 'App Screenshot',
    help: 'Upload a screenshot of your app',
    type: 'upload',
  },
];

const Landing = ({ className, match, ...rest }) => {
  const queryParams = QueryString.parse(window.location.search);
  const trainerId = queryParams.trainerId || null;

  const [currentTab, setCurrentTab] = useState('preview');

  const { enqueueSnackbar } = useSnackbar();
  const [previewRefresh, setPreviewRefresh] = useState(1);
  const [webPage, setWebPage] = useState({});
  const { error, loading, data } = useQuery(GET_WEB_PAGE, {
    variables: { trainerId },
  });
  const [updateWebPage] = useMutation(UPDATE_WEB_PAGE, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });

  useEffect(() => {
    setWebPage(data?.webPage);
  }, [data]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleUploadError = () => {
    enqueueSnackbar(`Media upload failed!`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };

  const handleUploadSuccess = (uploadedRes, fieldName) => {
    let changes = _.cloneDeep(webPage);
    _.set(changes, fieldName, uploadedRes.image.url);
    setWebPage(changes);
  };

  const handleUploadRemove = (file, fieldName) => {
    let changes = _.cloneDeep(webPage);
    _.set(changes, fieldName, null);
    setWebPage(changes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!webPage) {
      return;
    }
    enqueueSnackbar(`Saving...`, {
      autoHideDuration: 2000,
    });

    const input = {
      slug: webPage.slug,
      logo: webPage.logo,
      colorTheme: webPage.colorTheme,
      heroSection: webPage.heroSection,
      appDownloadSection: webPage.appDownloadSection,
      testimonialsSection: {
        testimonials: webPage.testimonialsSection?.testimonials.map((t) => {
          return { name: t.name, quote: t.quote, imageUrl: t.imageUrl };
        }),
      },
      aboutSection: webPage.aboutSection,
      footerSection: {
        links: webPage.footerSection?.links.map((l) => {
          return { title: l.title, url: l.url };
        }),
      },
    };

    await updateWebPage({
      variables: {
        id: webPage._id,
        input,
      },
    });

    setTimeout(() => {
      setPreviewRefresh(previewRefresh + 1);
    }, 2000);
  };

  const handleTestimonialChange = (testimonial) => {
    let changes = _.cloneDeep(webPage);

    changes.testimonialsSection = {
      testimonials: (changes.testimonialsSection.testimonials || []).map(
        (t) => {
          if (t._id === testimonial._id) {
            return testimonial;
          }
          return t;
        }
      ),
    };

    setWebPage(changes);
  };

  const handleChange = (e) => {
    e.preventDefault();

    let changes = _.cloneDeep(webPage);
    _.set(changes, e.target.name, e.target.value);
    setWebPage(changes);
  };

  const handleAddTestimonial = () => {
    let newWebPage = { ...webPage };
    if (newWebPage.testimonialsSection?.testimonials?.length > 0) {
      newWebPage.testimonialsSection = {
        testimonials: [
          ...newWebPage.testimonialsSection.testimonials,
          { name: '', quote: '' },
        ],
      };
    } else {
      newWebPage.testimonialsSection = {
        testimonials: [{ name: '', quote: '' }],
      };
    }

    setWebPage(newWebPage);
  };

  const handleRemoveTestimonial = (testimonial) => {
    let newWebPage = _.cloneDeep(webPage);
    newWebPage.testimonialsSection.testimonials =
      newWebPage.testimonialsSection.testimonials.filter(
        (t) => t._id !== testimonial._id
      );

    setWebPage(newWebPage);
  };

  const handleAddLink = (event) => {
    let newWebPage = { ...webPage };
    let pendingLink = { _id: mongoObjectId(), title: '', url: '' };
    if (newWebPage.footerSection?.links?.length > 0) {
      newWebPage.footerSection = {
        links: [...newWebPage.footerSection.links, pendingLink],
      };
    } else {
      newWebPage.footerSection = { links: [pendingLink] };
    }

    setWebPage(newWebPage);
  };
  const handleRemoveLink = (link, index) => {
    let newWebPage = _.cloneDeep(webPage);
    newWebPage.footerSection.links = newWebPage.footerSection.links.filter(
      (l) => link._id !== l._id
    );
    setWebPage(newWebPage);
  };

  if (error) {
    return <ServerError message={error.message} />;
  }

  if (loading || !webPage) {
    return <Loading />;
  }

  const previewUrl = webPage.url;

  return (
    <Card>
      <CardContent>
        <Box mb={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />
        </Box>

        {currentTab === 'preview' && (
          <Box>
            <Box mb={3}>
              <Typography variant="h5" gutterBottom>
                Link:{' '}
                <a href={previewUrl} target="_blank" rel="noreferrer">
                  {previewUrl}
                </a>
              </Typography>

              <CopyToClipboard
                text={previewUrl}
                onCopy={() => {
                  window.alert(`Copied ${previewUrl}`);
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<CopyIcon />}
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
            </Box>

            <Box>
              <WebPreviewIframe key={previewRefresh} url={previewUrl} />
            </Box>
          </Box>
        )}

        {currentTab === 'edit' && (
          <form className={className} onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  Design
                </Typography>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="colorTheme">Color Mode</InputLabel>
                  <Select
                    name="colorTheme"
                    style={{ width: 200 }}
                    native
                    value={webPage.colorTheme ?? 'DARK'}
                    onChange={handleChange}
                    label="Background Color"
                  >
                    <option value="DARK">Dark</option>
                    <option value="LIGHT">Light</option>
                  </Select>
                </FormControl>
              </Grid>

              {siteFields.map((field) => {
                return (
                  <FormField
                    key={field.name}
                    field={field}
                    handleUploadError={handleUploadError}
                    handleUploadSuccess={handleUploadSuccess}
                    handleUploadRemove={handleUploadRemove}
                    handleChange={handleChange}
                    webPage={webPage}
                  />
                );
              })}
              <Divider />
              <Grid item xs={12} md={6}>
                <Typography variant="h5">Hero Section</Typography>
              </Grid>

              {heroFields.map((field) => {
                return (
                  <FormField
                    key={field.name}
                    field={field}
                    handleUploadError={handleUploadError}
                    handleUploadSuccess={handleUploadSuccess}
                    handleUploadRemove={handleUploadRemove}
                    handleChange={handleChange}
                    webPage={webPage}
                  />
                );
              })}

              <Grid item xs={12} md={8}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1">
                      Optional: Background Video
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <LabelledOutline id="videoUrl" label="Background Video">
                      <MediaUpload
                        key="heroSection.videoUrl"
                        images={
                          webPage.heroSection?.videoUrl
                            ? [{ url: defaultVideoThumbnail }]
                            : null
                        }
                        onError={handleUploadError}
                        onSuccess={(uploadRes) => {
                          let changes = _.cloneDeep(webPage);
                          _.set(
                            changes,
                            'heroSection.videoUrl',
                            uploadRes.videos[0]?.url
                          );
                          setWebPage(changes);
                        }}
                        onRemove={(file) =>
                          handleUploadRemove(file, 'heroSection.videoUrl')
                        }
                        maxFiles={1}
                      />
                      <FormHelperText>
                        You can upload a short video as the background.
                      </FormHelperText>
                    </LabelledOutline>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Divider />
              <Grid item xs={12} md={6}>
                <Typography variant="h5">App Download Section</Typography>
              </Grid>
              {appDownloadFields.map((field) => {
                return (
                  <FormField
                    key={field.name}
                    field={field}
                    handleUploadError={handleUploadError}
                    handleUploadSuccess={handleUploadSuccess}
                    handleUploadRemove={handleUploadRemove}
                    handleChange={handleChange}
                    webPage={webPage}
                  />
                );
              })}

              <Divider />

              <Grid item xs={12} md={6}>
                <Typography variant="h5">Testimonials Section</Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {webPage.testimonialsSection?.testimonials.map(
                    (testimonial) => {
                      return (
                        <Grid item key={testimonial._id} xs={12} md={4} lg={3}>
                          <TestimonialForm
                            testimonial={testimonial}
                            onChange={handleTestimonialChange}
                            onRemove={handleRemoveTestimonial}
                          />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleAddTestimonial}
                >
                  Add Testimonial
                </Button>
              </Grid>
              <Divider />

              <Grid item xs={12} md={6}>
                <Typography variant="h5">About Section</Typography>
              </Grid>

              {aboutFields.map((field) => {
                return (
                  <FormField
                    key={field.name}
                    field={field}
                    handleUploadError={handleUploadError}
                    handleUploadSuccess={handleUploadSuccess}
                    handleUploadRemove={handleUploadRemove}
                    handleChange={handleChange}
                    webPage={webPage}
                  />
                );
              })}
              <Divider />

              <Grid item xs={12} md={8}>
                <Typography variant="h5">Footer Section</Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <LabelledOutline id="links" label="Links">
                  {(webPage.footerSection?.links || []).map((link, index) => {
                    return (
                      <Box
                        pb={2}
                        key={index}
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <TextField
                          fullWidth
                          label="Title"
                          name={`footerSection.links[${index}].title`}
                          onChange={handleChange}
                          value={link.title}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          fullWidth
                          label="URL"
                          name={`footerSection.links[${index}].url`}
                          onChange={handleChange}
                          value={link.url}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                        <IconButton
                          style={{ marginLeft: 2 }}
                          onClick={(event) => handleRemoveLink(link, index)}
                          size="large"
                        >
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    );
                  })}
                  <Box mt={2}>
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleAddLink}
                    >
                      Add Link
                    </Button>
                  </Box>
                </LabelledOutline>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Button variant="contained" color="secondary" type="submit">
                Save
              </Button>
            </Box>
          </form>
        )}
      </CardContent>
    </Card>
  );
};

export default Landing;
