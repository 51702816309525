import { gql } from '@apollo/client';

export const MEDIA_FRAGMENT = gql`
  fragment MediaFragment on Media {
    __typename
    url
    w
    h
    aspect
  }
`;
