import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar, IconButton, Input, Paper, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SendIcon from '@mui/icons-material/Send';
import { useAuth } from '../../hooks/use-auth';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
  },
  inputContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  divider: {
    height: 24,
    width: 1,
  },
  fileInput: {
    display: 'none',
  },
}));

const CommentAdd = ({ className, onSubmit, ...rest }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [body, setBody] = useState('');

  const handleChange = (event) => {
    event.persist();
    setBody(event.target.value);
  };

  const send = () => {
    onSubmit(body);
    setBody('');
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        send();
      }}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Avatar alt="Person" src={user.photoURL} />
      <Paper className={classes.inputContainer} variant="outlined">
        <Input
          disableUnderline
          fullWidth
          name="body"
          value={body}
          onChange={handleChange}
          placeholder="Leave a message"
        />
      </Paper>
      <Tooltip title="Send">
        <IconButton
          color={body.length > 0 ? 'primary' : 'default'}
          onClick={send}
          size="large"
        >
          <SendIcon />
        </IconButton>
      </Tooltip>
    </form>
  );
};

CommentAdd.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default CommentAdd;
