import React, { useState, useEffect, useContext, createContext } from 'react';
import firebase from 'firebase/compat/app';
import * as Sentry from '@sentry/react';

import 'firebase/compat/auth';

import config, { apiUrl } from '../config';

// Add your Firebase credentials
if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}
export const AuthContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

export function withAuthContext(Component) {
  return function authContextComponent(props) {
    return (
      <AuthContext.Consumer>
        {(context) => <Component {...props} context={context} />}
      </AuthContext.Consumer>
    );
  };
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.

  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        return response.user;
      });
  };

  const signup = (email, password, referralCode = null, platformUrl = null) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        return response.user.getIdToken();
      })
      .then((token) => {
        const url = `${apiUrl}/v1/accounts/complete_signup`;
        return fetch(url, {
          method: 'POST',
          withCredentials: true,
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ isTrainer: true, referralCode, platformUrl }),
        });
      })
      .then(async (response) => {
        if (!response.ok) {
          const msg = await response.text();
          throw new Error(msg);
        }
        return response.json();
      })
      .then((response) => {
        return { success: true };
      })
      .catch((error) => {
        return { success: false, message: error.toString() };
      });
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((res) => {
        return res;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then((res) => {
        return res;
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      setUser(user);
      if (!user) {
        setLoading(false);
        return;
      }
      Sentry.setUser({ id: user.user_id });

      await user.getIdToken().then((token) => {
        setLoading(false);
      });
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    loading,
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
}
