import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, CardContent, Box, Typography } from '@mui/material';
import Login from './Login';
import Signup from './Signup';
import { withRouter } from 'react-router-dom';

const AuthEmbed = ({ history, onSuccess, onError, show }) => {
  const [showSignup, setShowSignup] = useState(show === 'signup');
  const [showLogin, setShowLogin] = useState(show === 'login');

  return (
    <Container maxWidth="md">
      <Box mb={8} display="flex" justifyContent="center">
        <CardContent>
          <Box mt={3}>
            <Typography variant="subtitle1" color="textSecondary">
              {showSignup && (
                <Signup
                  onSuccess={() => {
                    onSuccess();
                    history.push('/trainer/onboarding');
                  }}
                  onError={onError}
                  onLoginClick={() => {
                    setShowLogin(true);
                    setShowSignup(false);
                  }}
                />
              )}
              {showLogin && (
                <Login
                  onSuccess={() => {
                    onSuccess();
                    history.push('/trainer');
                  }}
                  onError={onError}
                  onSignupClick={() => {
                    setShowLogin(false);
                    setShowSignup(true);
                  }}
                />
              )}
            </Typography>
          </Box>
        </CardContent>
      </Box>
    </Container>
  );
};

AuthEmbed.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

AuthEmbed.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

export default withRouter(AuthEmbed);
