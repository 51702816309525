import React from 'react';
import Loading from '../../components/shared/Loading';
import AppAndWebPreview from '../../components/app_preview/AppAndWebPreview';

import { Typography, Card, CardContent } from '@mui/material';

export default function TrainingPlanPreview({ trainingPlan }) {
  if (!trainingPlan) {
    return <Loading />;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="body1" paragraph>
          NOTE: Below is a preview which may not have all functionality or look
          like on the mobile phone. Checking the training plan on the mobile app
          on your iphone or android is the best way to preview what the customer
          will see.
        </Typography>

        <AppAndWebPreview
          mobilePreviewUrl={trainingPlan.mobilePreviewUrl}
          webUrl={trainingPlan.webUrl}
        />
      </CardContent>
    </Card>
  );
}
