import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';

import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@mui/material';
import { GET_TRAINING_PLAN_SESSIONS } from '../../graphql/training_plan_session';

export default function TrainingPlanAnalytics({ trainingPlan }) {
  const input = { trainingPlanId: trainingPlan?._id };
  const { loading, error, data } = useQuery(GET_TRAINING_PLAN_SESSIONS, {
    variables: { input },
  });

  if (error) return <ServerError message="Failed to load" />;
  if (loading || !data?.trainingPlanSessions) return <Loading />;
  if (!trainingPlan) {
    return <ServerError message="Failed to load" />;
  }
  const trainingPlanSessions = data?.trainingPlanSessions;

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        Recent Sessions
      </Typography>
      <Box>
        {trainingPlanSessions.length === 0 && (
          <Typography variant="caption" gutterBottom>
            None enrolled.
          </Typography>
        )}
        {trainingPlanSessions.map((trainingPlanSession) => {
          return (
            <Card key={trainingPlanSession._id} style={{ marginBottom: 16 }}>
              <CardHeader
                avatar={
                  <Avatar
                    alt="Person"
                    component={RouterLink}
                    src={trainingPlanSession.user.avatarUrl}
                    to={`/trainer/users/${trainingPlanSession.user._id}`}
                  />
                }
                subheader={
                  <Box display="flex" alignItems="center">
                    <Typography variant="caption" color="textSecondary">
                      <RouterLink
                        to={`/trainer/users/${trainingPlanSession.user._id}`}
                      >
                        {trainingPlanSession.user.name}
                      </RouterLink>{' '}
                      completed on {trainingPlanSession.completedAt}
                    </Typography>
                  </Box>
                }
                title={
                  <Typography color="textPrimary" variant="h6"></Typography>
                }
              />
              <CardContent>
                <Typography component="div" variant="body1" color="textPrimary">
                  Enrolled At: {trainingPlanSession.enrolledAt} <br />
                  Completed Event Count:{' '}
                  {trainingPlanSession.completedEventCount} <br />
                  Next Event: {trainingPlanSession.nextEvent?.title}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
}
