import React, { useState } from 'react';
import QueryString from 'query-string';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Base64 } from 'js-base64';
import { Formik } from 'formik';
import * as Yup from 'yup';
import YouTube from 'react-youtube';

import {
  Box,
  Divider,
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  FormHelperText,
} from '@mui/material';
import { Alert } from '@mui/material';
import Label from '../shared/Label';
import LabelledOutline from '../shared/LabelledOutline';

import { useAuth } from '../../hooks/use-auth';

import {
  APP_STORE_INTEGRATIONS_QUERY,
  SAVE_APP_STORE_INTEGRATIONS_MUTATION,
} from '../../graphql/app_publishing';
import ServerError from '../error/ServerError';
import Loading from '../../components/shared/Loading';
import config from '../../config';

const fields = [
  {
    name: 'appleAppId',
    label: 'Apple App Id',
    help: (
      <>
        Create your app on{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://appstoreconnect.apple.com/"
        >
          Apple App Store Connect
        </a>
        . Once created, click the app and under App Information > General
        Information, copy the Apple ID to here.
        <br />
        Example: 1193471220
      </>
    ),
  },

  {
    name: 'appleBundleId',
    label: 'Apple Bundle Id',
    help: (
      <>
        Register a new bundle ID in{' '}
        <a
          href="https://developer.apple.com/account/resources/identifiers/bundleId/add/"
          rel="noreferrer"
          target="_blank"
        >
          Certificates, Identifiers & Profiles
        </a>{' '}
        and copy it here.
        <br />
        Example: com.{config.appDisplayName.toLowerCase()}.AppName
      </>
    ),
  },
  {
    name: 'appleIapSharedSecret',
    label: 'Apple App Specific Shared Secret',
    help: (
      <>
        Go to{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://appstoreconnect.apple.com/apps"
        >
          "My Apps"
        </a>{' '}
        and select your app.
        <br />
        <br />
        Click "Manage" under the "In-App Purchases" section on the left side
        menu.
        <br />
        <br />
        Select App-Specific Share Secret from the right side above your in-app
        products.
        <br />
        <br />
        Generate and copy your shared secret to here.
        <br />
        Example: 1525665a301f49928c30bbee7d044ba3
      </>
    ),
  },
  {
    name: 'appleAppStoreConnectVendorId',
    label: 'Apple Vendor Id',
    help: (
      <>
        Visit the{' '}
        <a
          href="https://appstoreconnect.apple.com/itc/payments_and_financial_reports"
          rel="noreferrer"
          target="_blank"
        >
          Payments and Financial Reports Page
        </a>
        . The Vendor # is in the upper left.
        <br />
        Example: Vendor # 92465163
      </>
    ),
  },
  {
    name: 'appleAppStoreConnectIssuerId',
    label: 'App Store Connect Issuer Id',
    help: (
      <>
        Visit{' '}
        <a
          href="https://appstoreconnect.apple.com/access/api"
          rel="noreferrer"
          target="_blank"
        >
          Users and Access > Keys > App Store Connect API
        </a>{' '}
        to get the issuer id (Click Keys in the header).
        <br />
        Example: 45a6de8d-86d7-47e3-e053-5b8c7c11a4d5
      </>
    ),
  },
  {
    name: 'appleAppStoreConnectKeyId',
    label: 'App Store Connect Key Id',
    help: (
      <>
        Visit{' '}
        <a
          href="https://appstoreconnect.apple.com/access/api"
          rel="noreferrer"
          target="_blank"
        >
          Users and Access > Keys > App Store Connect API
        </a>{' '}
        and click (+). Enter the key name(eg. "{config.appDisplayName}") and
        select an access level of <i>Admin</i>.
        <br />
        Example: 83928MHPN1
      </>
    ),
  },
];

export default function AppleAppStoreIntegration() {
  const params = useParams();
  const location = useLocation();
  const queryParams = QueryString.parse(location.search);
  const trainerId = queryParams.trainerId || params.id;

  const {
    loading: dataLoading,
    error,
    data,
    refetch,
  } = useQuery(APP_STORE_INTEGRATIONS_QUERY, { variables: { trainerId } });
  const [saveAppStoreIntegration, { data: saveData }] = useMutation(
    SAVE_APP_STORE_INTEGRATIONS_MUTATION
  );

  const { enqueueSnackbar } = useSnackbar();
  const [appStoreIntegrationInput, setAppStoreIntegrationInput] = useState({
    appleAppId: '',
    appleBundleId: '',
    appleIapSharedSecret: '',
    appleAppStoreConnectVendorId: '',
    appleAppStoreConnectIssuerId: '',
    appleAppStoreConnectKeyId: '',
    appleAppStoreConnectPrivateKey: '',
  });
  const [
    hasAppleAppStoreConnectPrivateKey,
    setHasAppleAppStoreConnectPrivateKey,
  ] = useState(false);
  const auth = useAuth();
  const authLoading = auth.loading;

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        let base64result = fileReader.result.split(',')[1];
        resolve(base64result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handlePrivateKeyUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    let decoded;
    try {
      decoded = Base64.decode(base64);
    } catch (err) {
      alert('Is this the right file?');
      console.error(err);
    }

    const changes = { ...appStoreIntegrationInput };
    changes.appleAppStoreConnectPrivateKey = decoded;
    setAppStoreIntegrationInput(changes);
    setHasAppleAppStoreConnectPrivateKey(true);
  };

  const handleReplacePrivateKey = (e) => {
    const changes = { ...appStoreIntegrationInput };
    changes.appleAppStoreConnectPrivateKey = null;
    setAppStoreIntegrationInput(changes);
    setHasAppleAppStoreConnectPrivateKey(false);
  };

  React.useEffect(() => {
    if (!data && !saveData) {
      return;
    }

    let appStoreIntegrations;
    if (saveData && saveData.saveAppStoreIntegrations) {
      appStoreIntegrations = saveData.saveAppStoreIntegrations;
    }
    if (!appStoreIntegrations && data && data.appStoreIntegrations) {
      appStoreIntegrations = data.appStoreIntegrations;
    }

    if (!appStoreIntegrations) {
      return;
    }

    setHasAppleAppStoreConnectPrivateKey(
      appStoreIntegrations.hasAppleAppStoreConnectPrivateKey
    );

    setAppStoreIntegrationInput({
      appleAppId: appStoreIntegrations.appleAppId,
      appleBundleId: appStoreIntegrations.appleBundleId,
      appleIapSharedSecret: appStoreIntegrations.appleIapSharedSecret,
      appleAppStoreConnectVendorId:
        appStoreIntegrations.appleAppStoreConnectVendorId,
      appleAppStoreConnectIssuerId:
        appStoreIntegrations.appleAppStoreConnectIssuerId,
      appleAppStoreConnectKeyId: appStoreIntegrations.appleAppStoreConnectKeyId,
    });
  }, [data, saveData]);

  if (authLoading || dataLoading) {
    return <Loading />;
  }
  if (error) return <ServerError message="Failed to load" />;
  if (!data) return <Loading />;

  let appStoreIntegrationResponse = {
    status: 'WARNING',
    message: 'Form still incomplete.',
  };

  if (
    appStoreIntegrationInput.appleAppId &&
    appStoreIntegrationInput.appleBundleId &&
    appStoreIntegrationInput.appleIapSharedSecret &&
    appStoreIntegrationInput.appleAppStoreConnectVendorId &&
    appStoreIntegrationInput.appleAppStoreConnectIssuerId &&
    appStoreIntegrationInput.appleAppStoreConnectKeyId
  ) {
    appStoreIntegrationResponse = data.verifyAppleAppStoreIntegration;
  }

  return (
    <Box style={{ maxWidth: 1080 }}>
      <Formik
        enableReinitialize
        initialValues={appStoreIntegrationInput}
        validationSchema={Yup.object().shape({
          appleAppId: Yup.number().nullable(),
          appleBundleId: Yup.string()
            .matches(
              /^[a-z0-9]+(\.[a-z0-9]+)+$/gi,
              'Is not a correct bundle Id. Can you check you copied it correctly?'
            )
            .nullable(),
          appleAppStoreConnectVendorId: Yup.number().nullable(),
          appleAppStoreConnectIssuerId: Yup.string()
            .matches(
              /^[a-zA-Z0-9\-]*$/,
              'Is not a correct key id. Can you check you copied it correctly? There cannot be any spaces'
            )
            .nullable(),
          appleAppStoreConnectKeyId: Yup.string()
            .matches(
              /^[a-zA-Z0-9]*$/,
              'Is not a correct key id. Can you check you copied it correctly? There cannot be any spaces'
            )
            .nullable(),
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            setAppStoreIntegrationInput(values);

            enqueueSnackbar('Saving...', { autoHideDuration: 1000 });

            values.trainerId = trainerId;
            await saveAppStoreIntegration({
              variables: { input: values },
            });

            resetForm();
            setStatus({ success: true });
            setSubmitting(false);

            refetch();
            enqueueSnackbar('App updated', {
              variant: 'success',
            });
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader title="Apple App Store Connect" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {appStoreIntegrationResponse.message?.length > 0 && (
                      <Alert
                        severity={appStoreIntegrationResponse.status?.toLowerCase()}
                        style={{ marginBottom: 8 }}
                      >
                        {appStoreIntegrationResponse.message}
                      </Alert>
                    )}

                    {config.showInstructionalVideos && (
                      <Box mb={4}>
                        <YouTube
                          videoId="OUdtI8wdQW4"
                          opts={{
                            height: 480,
                            width: 640,
                            playerVars: { autoplay: 0 },
                          }}
                        />
                      </Box>
                    )}

                    <Typography variant="body1" paragraph>
                      For us to build and publish your app, we need you to do
                      the following to your App Store Connect account:{' '}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Add <i>{config.appstoreAccessEmailAddress}</i> as a{' '}
                      <a
                        href="https://appstoreconnect.apple.com/access/users"
                        rel="noreferrer"
                        target="_blank"
                      >
                        user account with the "Admin" role
                      </a>
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Then complete the information below:
                    </Typography>
                  </Grid>

                  {fields.map((field) => {
                    return (
                      <Grid key={field.name} item xs={8}>
                        <TextField
                          error={Boolean(
                            touched[field.name] && errors[field.name]
                          )}
                          fullWidth
                          helperText={touched[field.name] && errors[field.name]}
                          label={field.label}
                          name={field.name}
                          InputLabelProps={{ shrink: true }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values[field.name] || ''}
                          variant="outlined"
                        />
                        <FormHelperText>{field.help}</FormHelperText>
                      </Grid>
                    );
                  })}
                  <Grid item xs={8}>
                    <LabelledOutline id="private-key" label="Private Key">
                      {hasAppleAppStoreConnectPrivateKey && (
                        <Box>
                          <Label>* Added </Label>{' '}
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={handleReplacePrivateKey}
                          >
                            Replace
                          </Button>
                        </Box>
                      )}
                      {!hasAppleAppStoreConnectPrivateKey && (
                        <input type="file" onChange={handlePrivateKeyUpload} />
                      )}
                    </LabelledOutline>
                    <FormHelperText>
                      Once you generated an API Key in the above step, click
                      'Download API Key'. Upload this API Key file here.
                    </FormHelperText>
                  </Grid>
                </Grid>

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Box>
  );
}
