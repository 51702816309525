import { gql } from '@apollo/client';
import { MEDIA_FRAGMENT } from './media';
import { APP_THEME_FRAGMENT } from './app_theme';
import { APP_WORKOUT_PLAYER_FRAGMENT } from './app_workout_player';

export const APP_INFO_FRAGMENT = gql`
  fragment AppInfoFragment on AppInfo {
    __typename
    _id
    title
    legalName
    supportEmail
    icon {
      ...MediaFragment
    }
    onboardingImages {
      ...MediaFragment
    }
    blogUrl
    termsUrl
    faqUrl
    aboutUrl
    privacyPolicyUrl
    merchStoreUrl

    colorMode
    mainColor
    secondaryColor
    appThemeId
    appWorkoutPlayerSlug

    nutritionEnabled
    postsEnabled
    communityPostsEnabled
    postRepliesEnabled
    chatEnabled
    trainingPlansEnabled
    merchStoreEnabled

    updatedAt
  }
  ${MEDIA_FRAGMENT}
`;

export const GET_APP_INFO = gql`
  query GetAppInfo {
    appInfo {
      ...AppInfoFragment
    }
  }
  ${APP_INFO_FRAGMENT}
`;

export const UPDATE_APP_INFO = gql`
  mutation UpdateAppInfo($id: ID!, $input: AppInfoInput!) {
    updateAppInfo(id: $id, input: $input) {
      ...AppInfoFragment
    }
  }
  ${APP_INFO_FRAGMENT}
`;

export const GET_APP_INFO_PAGE = gql`
  query AppInfoPage($trainerId: ID) {
    appInfo(trainerId: $trainerId) {
      ...AppInfoFragment
    }
    appThemes {
      ...AppThemeFragment
    }
    appWorkoutPlayers {
      ...AppWorkoutPlayerFragment
    }
  }
  ${APP_INFO_FRAGMENT}
  ${APP_THEME_FRAGMENT}
  ${APP_WORKOUT_PLAYER_FRAGMENT}
`;
