import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Card,
  CardContent,
  Divider,
  Link,
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useAuth } from '../../hooks/use-auth';
import { getRedirectQueryParameter } from '../../utils/urlHelper';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {},
  banner: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  bannerChip: {
    marginRight: theme.spacing(2),
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%',
    },
  },
  googleButton: {
    backgroundColor: theme.palette.common.white,
  },
  providerIcon: {
    marginRight: theme.spacing(2),
  },
  divider: {
    flexGrow: 1,
  },
  dividerText: {
    margin: theme.spacing(2),
  },
}));

const FirebaseAuthLogin = ({
  history,
  location,
  className,
  onSuccess,
  onError,
  onSignupClick,
  ...rest
}) => {
  const redirectUrl = getRedirectQueryParameter(location);
  const classes = useStyles();
  const auth = useAuth();
  const isMountedRef = useIsMountedRef();

  const handleForgotPassword = (email) => {
    auth
      .sendPasswordResetEmail(email)
      .then((res) => {
        window.alert(`A reset password link has been sent to ${email}`);
      })
      .catch((err) => {
        console.error(err);
        window.alert(err);
      });
  };
  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const user = await auth.signin(values.email, values.password);

      if (isMountedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
        onSuccess(user);
      }
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: 'Error logging in. Check the email or password.' });
      setSubmitting(false);
    }
  };

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb={3}
        >
          <div>
            <Typography color="textPrimary" gutterBottom variant="h2">
              Login
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <Link
                color="textSecondary"
                variant="body2"
                href="#"
                onClick={onSignupClick}
              >
                {config.allowSignup ? "Don't have an account? Signup!" : ''}
              </Link>
            </Typography>
          </div>
        </Box>
        <Box flexGrow={1} mt={3}>
          <Formik
            initialValues={{
              email: '',
              password: '',
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).required('Password is required'),
            })}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                className={clsx(classes.root, className)}
                {...rest}
              >
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
                <Box mt={2}>
                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Log In
                  </Button>
                </Box>
                <Box my={3}>
                  <Divider />
                </Box>
                {config.showForgotPassword && (
                  <Link
                    color="textSecondary"
                    variant="body2"
                    href="#"
                    onClick={() => handleForgotPassword(values.email)}
                  >
                    Forgot Password?
                  </Link>
                )}
              </form>
            )}
          </Formik>
        </Box>
      </CardContent>
    </Card>
  );
};

FirebaseAuthLogin.propTypes = {
  className: PropTypes.string,
};

export default withRouter(FirebaseAuthLogin);
