import React, { useEffect } from 'react';
import _ from 'lodash';
import ItemTypes from '../../constants/AppConstants';
import {
  InputAdornment,
  FormHelperText,
  Tooltip,
  TextField,
  Select,
  Typography,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  TableCell,
  IconButton,
} from '@mui/material';
import { Image as ImageIcon } from 'react-feather';
import WorkoutExerciseItemMenu from './WorkoutExerciseItemMenu';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { DragSource, DropTarget } from 'react-dnd';

const exerciseSource = {
  beginDrag: function (props) {
    return {
      id: props.workoutExercise._id,
      index: props.index,
    };
  },

  isDragging: function (props, monitor) {
    // If your component gets unmounted while dragged
    // (like a card in Kanban board dragged between lists)
    // you can implement something like this to keep its
    // appearance dragged:
    return monitor.getItem().id === props.id;
  },
};

const collectDragSource = function (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const collectDropTarget = function (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
};

const exerciseTarget = {
  drop: function (targetProps, monitor, component) {
    const sourceIndex = monitor.getItem().index;
    const targetIndex = targetProps.index;

    if (sourceIndex === targetIndex) {
      return;
    }

    // Time to actually perform the action
    targetProps.onMove(sourceIndex, targetIndex);

    // Mutating the monitor item! Generally better to avoid mutations.
    // But good here for performance, to avoid expensive index searches.
    monitor.getItem().index = targetIndex;
  },
};

const shortTextFieldNumberWidth = 72;
const textFieldNumberWidth = 84;

const WorkoutExerciseItem = ({
  index,
  workoutExercise,
  onUpdate,
  onMove,
  onRemove,
  onDuplicate,
  onEditExercise,
  onSelectedWorkoutExercise,
  onWorkoutExercisePreview,
  onAddNotes = () => {},
  isSelected,
  ...props
}) => {
  const isOneSet = workoutExercise.sets === 1;

  useEffect(() => {
    if (isOneSet && !restBetweenSetsDurationRef.current.disabled) {
      restBetweenSetsDurationRef.current.disabled = true;
    } else if (!!restBetweenSetsDurationRef.current.disabled) {
      restBetweenSetsDurationRef.current.disabled = false;
    }
  }, [isOneSet]);

  const setsRef = React.createRef();
  const restBetweenSetsDurationRef = React.createRef();
  const restAfterExerciseDurationRef = React.createRef();

  const handleChangeInt = (e) => {
    const changes = _.cloneDeep(workoutExercise);
    changes[e.target.name] = parseInt(e.target.value);
    if (e.target.name === 'sets' && parseInt(e.target.value) === 1) {
      changes.restBetweenSetsDuration = 0;
    }
    onUpdate(changes);
  };

  const handleChange = (e) => {
    const changes = _.cloneDeep(workoutExercise);
    if (e.target.name === 'isTimed') {
      changes['isTimed'] = !e.target.checked;
    } else {
      changes[e.target.name] = e.target.value;
    }
    onUpdate(changes);
  };

  const handleRemove = (e) => {
    e.preventDefault();
    onRemove(index);
  };

  const handleDuplicate = (e) => {
    e.preventDefault();
    onDuplicate(index);
  };

  const handleAddNotes = (e) => {
    e.preventDefault();
    onAddNotes(index);
  };
  const handleEditExercise = (e) => {
    e.preventDefault();
    const exercise = _.cloneDeep(workoutExercise);
    exercise._id = workoutExercise.exerciseId;
    onEditExercise(exercise);
  };

  const connectDragSource = props.connectDragSource;
  const connectDropTarget = props.connectDropTarget;

  if (!workoutExercise) {
    return <tr />;
  }

  let media = <ImageIcon size={48} />;
  if (workoutExercise.images && workoutExercise.images[0]) {
    media = (
      <img
        alt={workoutExercise.title}
        src={workoutExercise.images[0].url}
        style={{
          width: 48,
          height: 48,
          margin: 3,
          objectFit: 'cover',
          verticalAlign: 'middle',
        }}
      />
    );
  }

  const edit = (
    <tr>
      <TableCell style={{ verticalAlign: 'top' }}>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              onClick={() => {
                //workoutExercise isn't exactly an exercise, it's the exercise in the workout with its sets/reps
                // mismatched _id
                onWorkoutExercisePreview(workoutExercise);
              }}
            >
              {media}
              {workoutExercise.title}
            </Typography>
            {workoutExercise.notes && (
              <Typography variant="caption" color="textSecondary">
                {workoutExercise.notes}
              </Typography>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="standard"
          type="number"
          disabled={!workoutExercise.isTimed}
          InputProps={{
            inputProps: { min: 1, max: 1000 },
            endAdornment: <InputAdornment position="end">secs</InputAdornment>,
          }}
          name="duration"
          onChange={handleChangeInt}
          value={workoutExercise.duration ?? ''}
          sx={{ width: '15ch' }}
        />
        <br />
        <FormHelperText>or</FormHelperText>

        <Tooltip
          title={
            'Check this if you want the player to stay on this exercise until the user clicks next.'
          }
          aria-label="timed"
        >
          <FormControlLabel
            control={
              <Checkbox
                value={!workoutExercise.isTimed}
                checked={!workoutExercise.isTimed}
                onChange={handleChange}
                name="isTimed"
              />
            }
            size="small"
            label="No Set Time"
          />
        </Tooltip>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="standard"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 100 } }}
          name="reps"
          onChange={handleChangeInt}
          value={workoutExercise.reps ?? ''}
          sx={{ width: '5ch' }}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="standard"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 100 } }}
          name="sets"
          ref={setsRef}
          onChange={handleChangeInt}
          value={workoutExercise.sets ?? ''}
          sx={{ width: '5ch' }}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="standard"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 1000 } }}
          name="weight"
          onChange={handleChangeInt}
          value={workoutExercise.weight ?? ''}
          sx={{ width: '10ch' }}
        />
        <Select
          variant="standard"
          native
          name="weightUnit"
          onChange={handleChange}
          value={workoutExercise.weightUnit ?? ''}
        >
          <option value="">-</option>
          <option value="lbs">lbs</option>
          <option value="kg">kg</option>
        </Select>
        <FormHelperText>or</FormHelperText>
        <TextField
          variant="standard"
          type="number"
          InputProps={{
            inputProps: { type: 'number', min: 1, max: 100 },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          name="percentOf1RM"
          onChange={(e) => {
            if (e.target.value >= 0 && e.target.value <= 100) {
              handleChangeInt(e);
            }
          }}
          value={
            Number.isInteger(workoutExercise.percentOf1RM)
              ? workoutExercise.percentOf1RM
              : ''
          }
          sx={{ width: '10ch' }}
        />{' '}
        <FormHelperText>% of 1 RPM</FormHelperText>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="standard"
          name="restBetweenSetsDuration"
          type="number"
          InputProps={{
            inputProps: { min: 0, max: 1000 },
            endAdornment: <InputAdornment position="end">secs</InputAdornment>,
          }}
          ref={restBetweenSetsDurationRef}
          disabled={isOneSet}
          onChange={handleChangeInt}
          value={workoutExercise.restBetweenSetsDuration ?? ''}
          sx={{ width: '15ch' }}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <TextField
          variant="standard"
          name="restAfterExerciseDuration"
          type="number"
          InputProps={{
            inputProps: { min: 0, max: 1000 },
            endAdornment: <InputAdornment position="end">secs</InputAdornment>,
          }}
          ref={restAfterExerciseDurationRef}
          onChange={handleChangeInt}
          value={workoutExercise.restAfterExerciseDuration ?? ''}
          sx={{ width: '15ch' }}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <WorkoutExerciseItemMenu
          onRemove={handleRemove}
          onAddNotes={handleAddNotes}
          onEditExercise={handleEditExercise}
        />
      </TableCell>
    </tr>
  );

  return connectDragSource(connectDropTarget(edit));
};

export default DragSource(
  ItemTypes.EXERCISE,
  exerciseSource,
  collectDragSource
)(
  DropTarget(
    ItemTypes.EXERCISE,
    exerciseTarget,
    collectDropTarget
  )(WorkoutExerciseItem)
);
