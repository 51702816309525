import React from 'react';

import { Box } from '@mui/material';

export default function AppPreviewIframe({ url }) {
  return (
    <Box
      style={{
        border: '9px solid #000',
        borderRadius: '40px',
        background: '#000',
        maxWidth: 345,
        height: 745,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <iframe
        title="App Preview"
        src={url}
        style={{
          border: 0,
          width: 325,
          height: 735,
        }}
      />
    </Box>
  );
}
