import React from 'react';
import { useQuery } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Avatar, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MY_TRAINER_SUBSCRIPTION_QUERY } from '../../../graphql/trainer_subscription';
import Loading from '../../../components/shared/Loading';

const useStyles = makeStyles((theme) => ({
  accountBox: {
    alignItems: 'center',
    backgroundColor: 'background.default',
    borderRadius: 1,
    display: 'flex',
    overflow: 'hidden',
    p: 2,
  },
}));

const YourPlanBox = ({ user }) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(MY_TRAINER_SUBSCRIPTION_QUERY);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <></>;
  }

  return (
    <Box p={2}>
      <Box className={classes.accountBox}>
        <RouterLink to="/trainer/account">
          <Avatar
            src={user.photoURL}
            sx={{
              cursor: 'pointer',
              height: 48,
              width: 48,
            }}
          />
        </RouterLink>
        <Box ml={2}>
          <Typography color="textPrimary" variant="subtitle2">
            {user.displayName || user.email}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <Link
              color="primary"
              component={RouterLink}
              to="/trainer/account/subscription"
            >
              Your Plan:{' '}
              {data.myTrainerSubscription?.status ||
                'Free, Upgrade to Publish your App'}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default YourPlanBox;
