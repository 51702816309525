import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import _ from 'lodash';
import {
  CardHeader,
  CardActions,
  Button,
  Drawer,
  Box,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import TrainingPlanWorkoutSearch from './TrainingPlanWorkoutSearch';
import TrainingPlanCollectionEventCard from './TrainingPlanCollectionEventCard';

const CREATE_WORKOUT = gql`
  mutation CreateWorkout($input: WorkoutInput!) {
    createWorkout(input: $input) {
      _id
      title
      coverImageUrl
    }
  }
`;

const createEventFromWorkout = function (workout, day) {
  return {
    objectId: workout.objectId ?? workout._id,
    eventType: 0,
    day: day,
  };
};

const TrainingPlanCollectionEditor = ({ trainingPlan, onSave }) => {
  const [addWorkoutOpen, setAddWorkoutOpen] = useState(false);
  const [createWorkout] = useMutation(CREATE_WORKOUT);
  const handleAddWorkoutClose = () => {
    setAddWorkoutOpen(false);
  };
  const handleWorkoutSelected = (workout) => {
    handleAdd(workout);
    setAddWorkoutOpen(false);
  };
  const handleCreateWorkout = async () => {
    const title = prompt('Enter in a Workout Title', '');

    if (!title || title?.trim().length === 0) {
      alert('You need to enter in a title');
      return;
    }

    try {
      const r = await createWorkout({
        variables: { input: { title } },
      });

      const workout = r.data?.createWorkout;
      if (!workout) {
        setAddWorkoutOpen(false);
        alert('Error creating workout.');
        return;
      }
      handleAdd(workout);
    } catch (e) {
      alert(e.message);
      return;
    }

    setAddWorkoutOpen(false);
  };

  const handleOpenAddWorkoutClick = (event) => {
    event.preventDefault();
    setAddWorkoutOpen(true);
  };

  const handleRemove = (event) => {
    let changes = { ...trainingPlan };
    changes.events = (trainingPlan.events || []).filter(
      (e) => event._id !== e._id
    );
    onSave(changes);
  };

  const handleAdd = (workout, day) => {
    let changes = { ...trainingPlan };
    const event = createEventFromWorkout(workout);

    if (changes.events && changes.events.length > 0) {
      changes.events = [...changes.events, event];
    } else {
      changes.events = [event];
    }
    onSave(changes);
  };

  const handleMove = (workout, day) => {
    const changes = _.cloneDeep(trainingPlan);
    changes.events = changes.events ?? [];
    changes.events = changes.events.map((i) => {
      if (i._id === workout._id) {
        return {
          ...i,
          day,
        };
      }
      return i;
    });
    onSave(changes);
  };

  const events = trainingPlan && trainingPlan.events ? trainingPlan.events : [];

  return (
    <Card>
      <CardHeader title="Workout Collection" />
      <CardContent>
        <Grid container spacing={3}>
          {events.map((event) => {
            return (
              <Grid item key={event._id} xs={12} md={3}>
                <TrainingPlanCollectionEventCard
                  event={event}
                  onRemove={handleRemove}
                  onMove={handleMove}
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
      <CardActions sx={{ mb: 5 }}>
        <Button variant="outlined" onClick={handleOpenAddWorkoutClick}>
          Add Workout
        </Button>
      </CardActions>

      <Drawer
        sx={{ zIndex: 2000 }}
        anchor="bottom"
        open={addWorkoutOpen}
        onClose={handleAddWorkoutClose}
      >
        <Box p={3}>
          <TrainingPlanWorkoutSearch
            onSelect={handleWorkoutSelected}
            onAddNew={handleCreateWorkout}
            onClose={handleAddWorkoutClose}
          />
        </Box>
      </Drawer>
    </Card>
  );
};

export default TrainingPlanCollectionEditor;
