import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Chip,
  Grid,
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';

import ChipInput from '../../components/shared/ChipInput';
import { Done as DoneIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { SUBMIT_APP_CONTENT } from '../../graphql/onboarding';
import DirectUpload from '../shared/DirectUpload';

const AppContent = ({ className, onBack, onNext, appInfo, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [save] = useMutation(SUBMIT_APP_CONTENT, {
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      if (onNext) {
        onNext();
      }
    },
  });

  const handleWorkoutImageUploadError = () => {
    enqueueSnackbar(`Media upload failed!`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };

  const handleWorkoutImageUploadSuccess = (uploadedRes, setFieldValue) => {
    const newImage = {
      url: uploadedRes.image.url,
      w: uploadedRes.image.w,
      h: uploadedRes.image.h,
      aspect: uploadedRes.image.aspect,
    };

    setFieldValue('workoutCoverImage', newImage);
  };

  const handleWorkoutImageUploadRemove = (file, setFieldValue) => {
    setFieldValue('workoutCoverImage', null);
  };

  const handleTrainingPlanImageUploadError = () => {
    enqueueSnackbar(`Media upload failed!`, {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };

  const handleTrainingPlanImageUploadSuccess = (uploadedRes, setFieldValue) => {
    const newImage = {
      url: uploadedRes.image.url,
      w: uploadedRes.image.w,
      h: uploadedRes.image.h,
      aspect: uploadedRes.image.aspect,
    };

    setFieldValue('trainingPlanCoverImage', newImage);
  };

  const handleTrainingPlanImageUploadRemove = (file, setFieldValue) => {
    setFieldValue('trainingPlanCoverImage', null);
  };

  const handleAddTag = (selectedTag, tags, setFieldValue) => {
    const newTags = [...tags, selectedTag];
    setFieldValue('trainingPlanTags', newTags);
  };

  const handleDeleteTag = (selectedTag, tags, setFieldValue) => {
    const newTags = (tags || []).filter((item) => item !== selectedTag);
    setFieldValue('trainingPlanTags', newTags);
  };

  return (
    <Formik
      initialValues={{
        trainingPlanTitle: '',
        trainingPlanCoverImage: null,
        trainingPlanTags: ['mobility', 'strength training', 'endurance'],
        workoutTitle: '',
        workoutCoverImage: null,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        trainingPlanTitle: Yup.string()
          .min(3, 'Must be at least 3 characters')
          .max(255)
          .required('Required'),
        workoutTitle: Yup.string()
          .min(3, 'Must be at least 3 characters')
          .max(255)
          .required('Required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setSubmitting(true);
          enqueueSnackbar('Saving...');
          await save({
            variables: {
              input: {
                trainingPlanTitle: values.trainingPlanTitle,
                trainingPlanCoverImage: values.trainingPlanCoverImage || null,
                trainingPlanTags: values.trainingPlanTags || [],
                workoutTitle: values.workoutTitle,
                workoutCoverImage: values.workoutCoverImage || null,
              },
            },
          });
          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} {...rest}>
          <Typography variant="h3" color="textPrimary" gutterBottom>
            App Content
          </Typography>
          <Typography variant="body1" paragraph>
            Let's get started adding one of your training programs so you can
            see it in a preview app.
          </Typography>

          <Box mt={2}>
            <Typography variant="h4" gutterBottom>
              What's the name of a training program you want to sell?
            </Typography>
          </Box>
          <Box mt={2}>
            <TextField
              error={Boolean(
                touched.trainingPlanTitle && errors.trainingPlanTitle
              )}
              fullWidth
              helperText={touched.trainingPlanTitle && errors.trainingPlanTitle}
              label="Training Program Name"
              name="trainingPlanTitle"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.trainingPlanTitle}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <FormHelperText>
              Example: The Ultimate 6-Week Home Workout Program
              <br />
              Don't worry, you can change this later.
            </FormHelperText>
          </Box>

          <Box mt={2}>
            <Typography variant="h4" gutterBottom>
              What will this training program target?
            </Typography>

              <ChipInput
                label="Tags"
                fullWidth
                style={{ marginBottom: 24 }}
                InputLabelProps={{ shrink: true }}
                value={values.trainingPlanTags ?? []}
                onAdd={(tag) => { handleAddTag(tag, values.trainingPlanTags, setFieldValue)}}
                onDelete={(tag) => {handleDeleteTag(tag, values.trainingPlanTags, setFieldValue)}}
                variant="outlined"
                helperText="Pick a few keywords to help athletes find your plan. Press enter after each tag."
              />
 
          </Box>


          <Box mt={2}>
            <Typography variant="h4" gutterBottom>
              Got promotional image for this training program?
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <Box style={{ minHeight: 200 }}>
                  <DirectUpload
                    images={[values.trainingPlanCoverImage]}
                    onError={handleTrainingPlanImageUploadError}
                    onSuccess={(res) => {
                      handleTrainingPlanImageUploadSuccess(res, setFieldValue);
                    }}
                    onRemove={(res) => {
                      handleTrainingPlanImageUploadRemove(res, setFieldValue);
                    }}
                    maxFiles={1}
                    style={{ minHeight: 200 }}
                  />
                  <br />

                  <FormHelperText>
                    This photo is a promotional photo for this program.
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <img
                  alt="example trainer welcome"
                  src="https://image-resizer.tempokit.com/scale?url=https%3A%2F%2Ftempo-media-production.s3-us-west-2.amazonaws.com%2F451184%2Fe54fa2c0-d491-11eb-9a78-c5cb2eea6202.JPG&w=400&h=330"
                  style={{ width: '100%', height: 230, objectFit: 'cover' }}
                />
                <FormHelperText>Example Image</FormHelperText>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Typography variant="h4" gutterBottom>
              What's the name of a workout in this training program?
            </Typography>
          </Box>
          <Box mt={2}>
            <TextField
              error={Boolean(touched.workoutTitle && errors.workoutTitle)}
              fullWidth
              helperText={touched.workoutTitle && errors.workoutTitle}
              label="Workout Title"
              name="workoutTitle"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.workoutTitle}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <FormHelperText>
              Example: Day 1 Legs and Shoulders
              <br />
              You'll add exercises to the workout later on. This will be to get
              you to preview your app quickly.
            </FormHelperText>
          </Box>

          <Box mt={2}>
            <Typography variant="h4" gutterBottom>
              Got a cover image for this workout?
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <Box style={{ minHeight: 200 }}>
                  <DirectUpload
                    images={[values.workoutCoverImage]}
                    onError={handleWorkoutImageUploadError}
                    onSuccess={(res) => {
                      handleWorkoutImageUploadSuccess(res, setFieldValue);
                    }}
                    onRemove={(res) => {
                      handleWorkoutImageUploadRemove(res, setFieldValue);
                    }}
                    maxFiles={1}
                    style={{ minHeight: 200 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <img
                  alt="example trainer welcome"
                  src="https://image-resizer.tempokit.com/scale?url=https%3A%2F%2Ftempo-media-production.s3-us-west-2.amazonaws.com%2F844032%2F81362380-e1f8-11eb-ad0c-f9c2b6e73dcf.jpg&w=500&h=330"
                  style={{ width: '100%', height: 230, objectFit: 'cover' }}
                />
                <FormHelperText>Example Image</FormHelperText>
              </Grid>
            </Grid>
          </Box>

          <Box mt={6} display="flex">
            {onBack && (
              <Button onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

AppContent.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  appInfo: PropTypes.object,
};

AppContent.defaultProps = {
  onNext: () => {},
  onBack: () => {},
};

export default AppContent;
